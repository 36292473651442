// editTable.js
//

import React from 'react';
import { useState,useRef,useEffect,useLayoutEffect } from 'react';
import { useQuery, useEval } from  './serverState.js';
import { TableHeader} from './resultTable.js';
import { WebSocket_Send } from './webSocketClient.js';
import { TableColumnHeaders, /*EmptyCellRenderer,*/ TableRows } from './resultTable.js';
import { useObjAtt } from './serverState.js';
import { DoInputChoice } from './choice.js';
import { useKeyDown } from './miscTools.js';
import { Slider } from './slider.js';

function updateCellValue(floatingEditRef, vid, isPercent, entry, setPromptToSaveOnClosing, cellBeingEdited, x, y, setCellBeingEdited) {
    console.log("UpdateCellValue floatingEditRef")
    let newVal = floatingEditRef.textContent;
    console.log("newVal", newVal);
    if (isPercent && !newVal.trim().endsWith("%")) newVal += "%"; // 1481
    if (newVal.trim().length === 0) newVal = 0; // 1829 - should actually use table cell default which might not be zero in some cases
    console.log("newVal2", newVal);
    WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'setCellText', col: x, row: y, val: newVal, entry: entry });

    if (setPromptToSaveOnClosing) setPromptToSaveOnClosing(true);

    if (cellBeingEdited !== null && cellBeingEdited.x === x && cellBeingEdited.y === y)
        setCellBeingEdited(null);
}

function IsInSelRect([x,y],rect)
{
    if (rect === null || rect === undefined) return false;
    return (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom);
}

function isAnchor([x, y], anchor) {
    //console.log("isAnchor", x, y, anchor);
    //if (x === anchor?.left && y === anchor.top)
    //    console.log("anchor found!");
    if (x === anchor?.left && y === anchor.top)
        return true;
}

function getSelectionClass([x, y], rect) {
    //console.log("getSelectionClass x y rect", x, y, rect);

    if (rect === null || true)  // selected cells are now shown w/ svg rect
        return "";

    let boxShadowCss = ""

    // doing the selection w/ inline styles seems to make sense because it easily handles are the permutations
    // which would be numerous if using CSS classes
    if (x === rect.left && y <= rect.bottom && y >= rect.top) boxShadowCss += "1.5px 0px 0 #007B8A inset";
    if (x === rect.right && y <= rect.bottom && y >= rect.top) boxShadowCss += ",-1.5px 0px 0 #007B8A inset";
    if (y === rect.top && x >= rect.left && x <= rect.right) boxShadowCss += ",0px 1.5px 0 #007B8A inset";
    if (y === rect.bottom && x >= rect.left && x <= rect.right) boxShadowCss += ",0px -1.5px 0 #007B8A inset";

    boxShadowCss = boxShadowCss.split(',').filter(Boolean).join(',');  // trim leading comma if present

    return boxShadowCss;
}

function TableStartingUp(props)
{
    const [title,] = useObjAtt(props.oid, "_title");

    return (
        <div>
            Loading {props.view!=="edit"?"result table for ":"edit table for "}{title}.
        </div>
    );
}

function FloatingEdit(props)
{
    
    const ref = useRef(null);
    const [isPercent, setIsPercent] = useState(false);
    //const [selectText, setSelectText] = useState(true);
    const entry = props.entry || "";
    const val = entry === "text" || entry === "literal" ? props.val.replace(/^'(.*)'$/, '$1') : props.val;

    //const hasTabs = false;
    //const hasNewLines = false;

    // Focus immediately
    useEffect(() => {

        ref.current.focus();

        if (val.endsWith && val?.endsWith("%") && isPercent === false)
            setIsPercent(true);

        /*
        if (selectText) {
            setSelectText(false);
            var range = document.createRange();
            range.selectNode(ref.current);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
        }

        setTimeout(focusFloat, 2000, ref)*/

        //ref.current.addEventListener('paste', onPaste);
        //return () => { ref?.current?.removeEventListener('paste', onPaste) }
    });


    /*function focusFloat(ref) {
        console.log("timeout float")
        ref.current.focus();
    }*/
    /*
    function onPaste(e) {
        console.log("on paste", e);

        // Stop data actually being pasted into div
        e.stopPropagation();
        e.preventDefault();

        // Get pasted data via clipboard API
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text/plain');

        pasteCopiedCells(pastedData, props.x, props.y, props.vid);

        if (props.cellBeingEdited !== null && props.cellBeingEdited.x === props.x && props.cellBeingEdited.y === props.y)
            props.setCellBeingEdited(null);
    }*/

    function onBlur(ev)
    {
        console.log("cell on blur");

        updateCellValue(ref.current, props.vid, isPercent, entry, props.setPromptToSaveOnClosing, props.cellBeingEdited, props.x, props.y, props.setCellBeingEdited)
 
        /*
        const tableCellId = "v" + props.vid + "_" + props.y + "_" + props.x;
        console.log("table cell id", tableCellId);
        const cell = document.getElementById(tableCellId);
        console.log("cell", cell);
        setTimeout(() => {
            const cell = document.getElementById(tableCellId);
            console.log("cell", cell);
            //cell.innerHTML = "666"; 
        }, 50);
        */
    }
    function onKeyPress(ev)
    {
        console.log("**onKeyPress floating edit", ev.key)
        if (ev.charCode === 13 && !ev.shiftKey) {       // Enter
            ev.preventDefault();
            onBlur(ev);
            props.setCellBeingEdited(null);
            return;
        } else if (ev.charCode === 27) {                // ESC
            ev.preventDefault();
            props.setCellBeingEdited(null);
        } else if (ev.key === 'Tab') {
            console.log("****Tab key pressed while editing cell********")
            ev.preventDefault();
            onBlur(ev);
            props.setCellBeingEdited(null);
        }
    }
    function onFocus(ev) {
        console.log("***on foccus", ev)

        var range = document.createRange();
        var text = ev.target.textContent.trim();

        if (text.endsWith("%")) {
            console.log("looks like percent")
            // select number, but not % character
            var regex = /(\d+(\.\d+)?)/; // Regular expression to match numeric digits
            var match = text.match(regex);

            if (match) {
                var index = text.indexOf(match[0]);
                range.setStart(ev.target.firstChild, index);
                range.setEnd(ev.target.firstChild, index + match[0].length);
            } else {
                range.selectNodeContents(ev.target);
            }

            var selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        } else {
            console.log("select cell contents")
            var range = document.createRange();
            range.selectNodeContents(ev.target);
            var selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    return (<div id="FloatingEdit" className="FloatingEdit" style={{ outline: "none" }} contentEditable="true" ref={ref} onBlur={onBlur} onFocus={onFocus} onKeyPress={onKeyPress}
        suppressContentEditableWarning={true} spellCheck="false">{val}</div>);
}

function TableCellChoice(props) {
    //console.log("TableCellChoice", props)
    const cell = props.cell;
    const options = cell.val;
    const opts = options.opts;
    if (opts === undefined || opts === null) return null;
    const isMulti = (cell.type === "multichoice");

    function setCurSel(sel) {
        if (isMulti) {
            WebSocket_Send({ fn: 'Exec', vid: props.vid, cmd: 'multiChoiceSelect', col: props.x, row: props.y, pos: sel });
        } else {
            WebSocket_Send({ fn: 'Exec', vid: props.vid, cmd: 'choiceSelect', col: props.x, row: props.y, pos: sel });
        }
    }

    function setCellSelectTable() {
        console.log("setCellSelectTable", props, props.setCellSelection)
        props.setCellSelection({ left: props.x, right: props.x, top: props.y, bottom: props.y });
    }

    function clearCellSelect() {
        console.log("clear table's cell selection ()")
        props.setCellSelection(null);
    }

    const colWidths = props.colWidths;
    const colWidth = Array.isArray(colWidths) ? (props.x - 1 < colWidths.length ? colWidths[props.x] : undefined) : undefined;
    return <DoInputChoice {...options} options={opts} setCurSel={setCurSel} isMulti={isMulti} boxShadow={props.boxShadow}
        zoom={props.zoom} bInEditTable={true} setCellSelectTable={setCellSelectTable} cellId={"v" + props.vid + "_" + props.y + "_" + props.x} clearCellSelect={clearCellSelect} colWidth={colWidth} cellFormat={props?.cell?.cellFmt} />;
}

function TableCellCheckbox(props)
{
    const cell = props.cell;

    function onClick(ev) {
        ev.stopPropagation();
        WebSocket_Send({ fn: 'Exec', vid: props.vid, cmd: 'setCellCheck', col: props.x, row: props.y, val: ev.currentTarget.checked ? 1 : 0 });
    };
    return (<input type="checkbox" checked={cell.val} onChange={onClick} />);
}

function TableCellSlider({state,cell,readOnly,x,y,vid,zoom})
{
    function setVal(newVal)
    {
        WebSocket_Send({ fn:'Exec', vid:vid, cmd:'setSlider', col:x,row:y,val:newVal});
        console.log("TableCellSlider setVal(",newVal,")");
    }
    return <Slider state={state} setVal={setVal} zoom={zoom} />;
}

function CellBar(props)
{
    const bNeg = props.barPos < props.zeroPos;
    const rgb = "rgba(" + props.r + ',' + props.g + ',' + props.b + ',';
    const color = rgb + "0.6)";
    let colorStyle = { backgroundColor:color, border:'none' };
    if (props.gradient) {
        colorStyle = { background: "linear-gradient(" + (bNeg ? "to left," : "to right,") + rgb + "0.5)," + rgb + "0))", borderColor:color };
    }
    const style = { ...colorStyle, width: 100 * Math.abs(props.barPos-props.zeroPos) + '%', left:100 * Math.min(props.barPos,props.zeroPos) + '%' };

    return (
        <div className="CellBarHolder">
            <div className="CellBar" style={style}>
            </div>
        </div>
    );
}

function DiagonalBorder(props)
{
    if (!props.borderFwd && !props.borderRev) return null;

    function DoubleDef(b1,b2)
    {
        if ((!b1 || b1.indexOf("double") < 0) && (!b2 || b2.indexOf("double") < 0)) return null;
        return (
            <filter id="double">
                <feMorphology in="SourceGraphic" result="a" operator="dilate" radius="1" />
                <feComposite in="SourceGraphic" in2="a" result="xx" operator="xor" />
            </filter>
        );
    }

    function Draw(bFwd,desc)
    {
        if (!desc) return null;

        const [style, color, width] = desc.split(/\s+/);
        return <line className={"Dash_" + style} x1="0" y1={bFwd ? 0 : "100%"} x2="100%" y2={bFwd?"100%":0} stroke={color} strokeWidth={width} />;
    }

    return (<svg className="Diag">
                {DoubleDef(props.borderFwd, props.borderRev)}
                {Draw(true, props.borderFwd)}
                {Draw(false,props.borderRev)}
            </svg>);
}

function TableBodyCellRenderer(props) {
    //console.log("TableBodyCellRenderer", props)
    const cells = useQuery({
        req: "cells", cellInfo: "RC", qidCellFmts: props.qidCellFmts,
        maxBodyRows: props.maxBodyRows, maxBodyCols: props.maxBodyCols, forView: props.pivot?.viewType, bOnlyForThisPivot: false,         // LDC 6/28/2021 ER S-720 // LDC 7/12/2021 Bug S-895.
    }, props.vid);

    const getKeyPressed = useKeyDown(false);

    // Handle key presses
    const keyEvent = getKeyPressed();
    if (keyEvent !== null) {

        console.log("key pressed cell renderer", keyEvent.key);
        const cellSelAnchor = props.cellSelAnchor;

        const cellSel = props.cellSelection;
        if (cellSel && cellSel.top === cellSel.bottom && cellSel.left === cellSel.right &&
            (!keyEvent.shiftKey || (keyEvent.shiftKey && keyEvent.key === 'Tab')
            || (keyEvent.shiftKey && (keyEvent.key === 'Enter' || keyEvent.key === '"')) && !keyEvent.ctrlKey)) {

            const floatingEdit = document.getElementById("FloatingEdit")
            if (floatingEdit && keyEvent.key !== 'Tab' && keyEvent.key !== 'Enter') {
                console.log("floating edit present, return from key event listener");
                return;
            }
            
            if ((keyEvent.key === 'Tab' || keyEvent.key === "Enter") && floatingEdit && props.cellBeingEdited) {
                console.log("floating edit and Tab", props.cellBeingEdited);
                window.getSelection().removeAllRanges(); // removes focus from foating div hopefully, but doesn't seem to work, no onBlur called
                props.setCellBeingEdited(null);
                keyEvent.preventDefault();
                console.log(props.cellBeingEdited.y, props.cellBeingEdited.x)
                const oldCellValue = cells[props.cellBeingEdited.y][props.cellBeingEdited.x].val;
                const isPercent = oldCellValue.endsWith("%");
                const entry = cells[props.cellBeingEdited.y][props.cellBeingEdited.x]?.entry || "";
                console.log("oldCellValue", oldCellValue, isPercent);
                updateCellValue(floatingEdit, props.vid, isPercent, entry, props.setPromptToSaveOnClosing, props.cellBeingEdited, props.cellBeingEdited.x, props.cellBeingEdited.y, props.setCellBeingEdited)
                //WebSocket_Send({ fn: 'Exec', vid: props.vid, cmd: 'setCellText', col: props.cellBeingEdited.x, row: props.cellBeingEdited.y, val: "666", entry: "" });
            }

            if (!keyEvent.ctrlKey && keyEvent.key.length === 1 && props.view === "edit") { // length === 1 ignores keys like ArrowRight, Tab
                // if a cell is selected and someone types, then start editing the cell and place cursor after first character typed
                if (startEditingCellMaybe(keyEvent, cellSel.left, cellSel.top, props.setCellBeingEdited) === "return")
                    return;
            }

            if (!keyEvent.ctrlKey) {
                console.log("keyEvent", keyEvent)
                if (keyEvent.key === 'ArrowUp' || (keyEvent.key === 'Enter' && keyEvent.shiftKey)) {
                    console.log("Arrow up or shift+Enter")
                    if (cellSel.top !== 0) { 
                        props.setCellSelection({ left: cellSel.left, right: cellSel.right, top: cellSel.top - 1, bottom: cellSel.bottom - 1 });
                        props.setCellSelAnchor({ left: cellSelAnchor.left, top: cellSelAnchor.top - 1 });
                    } else {
                        const nextCol = cellSel.right === 0 ? props.nCols - 1 : cellSel.right - 1;
                        console.log("nextCol", nextCol, cellSel.right)
                        props.setCellSelection({ left: nextCol, right: nextCol, top: props.nRows - 1, bottom: props.nRows - 1 });
                        props.setCellSelAnchor({ left: nextCol, top: props.nRows - 1});
                    }
                    keyEvent.stopPropagation();
                    keyEvent.preventDefault();
                    return;
                }
                else if (keyEvent.key === 'ArrowDown' || keyEvent.key === 'Enter') {
                    console.log("Arrow down or Enter")
                    if (cellSel.bottom < props.nRows - 1) {
                        props.setCellSelection({ left: cellSel.left, right: cellSel.right, top: cellSel.top + 1, bottom: cellSel.bottom + 1 });
                        props.setCellSelAnchor({ left: cellSelAnchor.left, top: cellSelAnchor.top + 1 });
                    } else {
                        const nextCol = cellSel.right < props.nCols - 1 ? cellSel.right + 1 : 0;
                        console.log("nextCol", nextCol)
                        props.setCellSelection({ left: nextCol, right: nextCol, top: 0, bottom: 0 });
                        props.setCellSelAnchor({ left: nextCol, top: 0 });
                    }
                    keyEvent.stopPropagation();
                    keyEvent.preventDefault();
                    return
                }
                else if (keyEvent.key === 'ArrowRight' || (keyEvent.key === 'Tab' && !keyEvent.shiftKey)) {
                    console.log("Arrow right or Tab key");
                    if (cellSel.right < props.nCols - 1) { 
                        props.setCellSelection({ left: cellSel.left + 1, right: cellSel.right + 1, top: cellSel.top, bottom: cellSel.bottom });
                        props.setCellSelAnchor({ left: cellSelAnchor.left + 1, top: cellSelAnchor.top });
                    } else {
                        const nextRow = cellSel.top < props.nRows - 1 ? cellSel.top + 1 : 0;
                        props.setCellSelection({ left: 0, right: 0, top: nextRow, bottom: nextRow });
                        props.setCellSelAnchor({ left: 0, top: nextRow });
                    }
                    keyEvent.stopPropagation();
                    keyEvent.preventDefault();
                    return;
                }
                else if (keyEvent.key === 'ArrowLeft' || (keyEvent.key === 'Tab' && keyEvent.shiftKey)) {
                    console.log("Arrow left or shift+tab")
                    if (cellSel.left !== 0) {
                        props.setCellSelection({ left: cellSel.left - 1, right: cellSel.right - 1, top: cellSel.top, bottom: cellSel.bottom });
                        props.setCellSelAnchor({ left: cellSelAnchor.left - 1, top: cellSelAnchor.top });
                    } else {
                        const nextRow = cellSel.top === 0 ? props.nRows - 1 : cellSel.top - 1;
                        props.setCellSelection({ left: props.nCols - 1, right: props.nCols - 1, top: nextRow, bottom: nextRow });
                        props.setCellSelAnchor({ left: props.nCols - 1, top: nextRow});
                    }
                    keyEvent.stopPropagation();
                    keyEvent.preventDefault();
                    return;
                }
            }
        }

        if (keyEvent.key.startsWith("Arrow") && keyEvent.shiftKey && cellSel && !keyEvent.ctrlKey) {
            console.log("Change selected cells with arrow key", cellSel, props.nCols);
            if (keyEvent.key === "ArrowRight") {
                console.log(cellSel, cellSelAnchor);
                if (cellSelAnchor.left === cellSel.left) {
                    if (cellSel.right < props.nCols - 1)
                        props.setCellSelection({ left: cellSel.left, right: cellSel.right + 1, top: cellSel.top, bottom: cellSel.bottom })
                }
                else
                    props.setCellSelection({ left: cellSel.left + 1, right: cellSel.right, top: cellSel.top, bottom: cellSel.bottom })

            }
            else if (keyEvent.key === "ArrowDown") {
                if (cellSelAnchor.top === cellSel.top) {
                    if (cellSel.bottom < props.nRows - 1)
                        props.setCellSelection({ ...cellSel, bottom: cellSel.bottom + 1 })
                }
                else {
                    console.log("arrow down, make selection smaller", cellSel, cellSelAnchor)
                    props.setCellSelection({ left: cellSel.left, right: cellSel.right, top: cellSel.top + 1, bottom: cellSel.bottom })
                }
            }
            else if (keyEvent.key === "ArrowLeft") {
                if (cellSelAnchor.left === cellSel.right) {
                    if (cellSel.left !== 0)
                        props.setCellSelection({ left: cellSel.left - 1, right: cellSel.right, top: cellSel.top, bottom: cellSel.bottom })
                }
                else
                    props.setCellSelection({ left: cellSel.left, right: cellSel.right - 1, top: cellSel.top, bottom: cellSel.bottom })
            }     
            else if (keyEvent.key === "ArrowUp") {
                if (cellSelAnchor.top === cellSel.bottom) {
                    if (cellSel.top !== 0)
                        props.setCellSelection({ left: cellSel.left, right: cellSel.right, top: cellSel.top - 1, bottom: cellSel.bottom })
                }
                else
                    props.setCellSelection({ left: cellSel.left, right: cellSel.right, top: cellSel.top, bottom: cellSel.bottom - 1 })
            }

            return;
        } 

        if (keyEvent.key === "v" && keyEvent.ctrlKey) {
            console.log("pasting cells");

            if (props.view !== "edit") {
                alert("You cannot paste into this table.");
                return;
            }

            keyEvent.stopPropagation();
            keyEvent.preventDefault();

            const x = props?.cellSelection?.left;
            const y = props?.cellSelection?.top;

            if (typeof x === "number" && typeof y === "number") { 
                navigator.clipboard.readText().then((pastedData) => {
                    pasteCopiedCells(pastedData, x, y, props.vid, props.setShowWarningMsgBox, props.nRows, props.nCols, false, cells, props.cellSelection, props.setCellSelection, props.setTableCellsOnClipboard);
                });
            }
        } else if (keyEvent.key === 'c' && keyEvent.ctrlKey) {
            console.log("copy table event");
            keyEvent.stopPropagation();
            keyEvent.preventDefault();

            props.setTableCellsOnClipboard(true);

            if (props?.cellSelection) {
                let clipboard = "";
                for (let y = props.cellSelection?.top; y <= props.cellSelection?.bottom; y++) {
                    for (let x = props.cellSelection?.left; x <= props.cellSelection?.right; x++) {
                        const id = "v" + props.vid + "_" + y + "_" + x;
                        let cellFromDom = document?.getElementById(id)?.innerHTML;

                        if (cellFromDom && cellFromDom?.indexOf("<") != -1) { // 1690 - remove html tags
                            let parser = new DOMParser();
                            let doc = parser.parseFromString(cellFromDom, "text/html");
                            cellFromDom = doc.body.textContent || "";
                        }

                        //console.log("cell from dom", id, cellFromDom)
                        if (cellFromDom === undefined) cellFromDom = "";
                        clipboard += props.cellSelection?.right > x ? cellFromDom + '\t' : cellFromDom;
                    }
                    if (props.cellSelection?.bottom > y) clipboard += '\n';
                }
                //console.log("clip", clipboard, clipboard.split(''));
                navigator.clipboard
                    .writeText(clipboard)
                    .then(
                        success => console.log("text copied"), err => console.log("error copying text")
                    );
            }
        }

        if (cellSel && keyEvent.key.startsWith("Arrow")) {
            console.log("Arrow key with multiple cells selected");

            if (arrowKeyWithShiftCtrl(keyEvent, cellSel, cellSelAnchor, props.setCellSelection, props.setCellSelAnchor, props.nRows, props.nCols) === "return")
                return;

            // EW 1911 With multiple cells selected, arrow keys reselects to a single cell in the direction of the arrow key as in Excel
            arrowKeyWithMultiSelect(keyEvent.key, props.cellSelAnchor, props.setCellSelection, props.setCellSelAnchor, props.nRows, props.nCols);
            return;            
        }

        if (props.view === "edit" && cellSel && props.cellSelAnchor && (!keyEvent.shiftKey || (keyEvent.shiftKey && (keyEvent.key === 'Tab' || keyEvent.key === 'Enter' || keyEvent.key === '"')) && !keyEvent.ctrlKey)) {
            // if multiple cells selected and someone starts typing, edit anchor cell EW 1910
            if (startEditingCellMaybe(keyEvent, props.cellSelAnchor.left, props.cellSelAnchor.top, props.setCellBeingEdited) === "return")
                return;   
        }
    }

    function onClick(e,x,y,bSelectable,view)
    {
        console.log("onClick cell", x, y);
        e.preventDefault();
        e.stopPropagation();

        if (e.target.className.includes("ReadOnlyCell") && view === "edit") alert("This cell can't be edited."); // 1371

        if(!bSelectable) {
            if (e.detail===1)
                WebSocket_Send({fn:'Exec', vid:props.vid, cmd:'cellOnClick', col:x, row:y});       // LDC 6/7/2022 S-170

            setCellSelection(e, x, y);
        } else {

            let r = props.cellSelection;
            if (!e.shiftKey && r !== null && r.left===x && r.right===x && r.top===y && r.bottom===y) {
                // Clicked in an already selected cell. Enter edit mode.
                if (e.detail===1)
                    WebSocket_Send({fn:'Exec', vid:props.vid, cmd:'cellOnClick', col:x, row:y});       // LDC 6/7/2022 S-170
                else if (e.detail===2)
                    props.setCellBeingEdited({ x: x, y: y });
                return false;
            }
            if (props.cellBeingEdited !== null) {
                // Need to push changes
            }
            props.setCellBeingEdited(null);
            setCellSelection(e, x, y);
        }
        return false;
    }

    function setCellSelection(e, x, y) {
        console.log("set cell selection (), x, y", x, y);
        let r = props.cellSelection;
        if (e.shiftKey && r !== null) {
            props.setCellSelection({ left: Math.min(r.left, x), right: Math.max(r.right, x), top: Math.min(r.top, y), bottom: Math.max(r.bottom, y) });
        }
        else {
            props.setCellSelection({ left: x, right: x, top: y, bottom: y });
            props.setCellSelAnchor({ left: x, top: y });
        }
        props.setTableCellsOnClipboard(false);
    }

    //function onDrag(e,x,y,bStart)
    //{
    //    //if (bStart) return onClick(x, y);
    //    //else {
    //    //e.preventDefault();
    //        let r = props.cellSelection;
    //    console.log([x, y]);
    //        props.setCellSelection({ left: Math.min(r.left, x), right: Math.max(r.right, x),top: Math.min(r.top, x),  bottom: Math.max(r.bottom, x) });
    //    return false;
    //    //}
    //}
    function onEnter(e,x,y)
    {
        //console.log("on enter", e)
        let r = props.cellSelection;
        const anchor = props.cellSelAnchor;

        console.log("onEnter cell", (e.buttons & 1) && anchor)

        if ((e.buttons & 1) && anchor) {
            const newCellSel = {
                left: Math.min(anchor.left, x), right: Math.max(anchor.left, x), top: Math.min(anchor.top, y), bottom: Math.max(anchor.top, y)
            }
            console.log("updateCellSelection maybe", x, y, anchor, newCellSel);
            if (newCellSel.right !== r.right || newCellSel.left !== r.left || newCellSel.top !== r.top || newCellSel.bottom != r.bottom)
                props.setCellSelection(newCellSel);
        }
    }

    function test(event) {
        alert("Paste " + event)
    }

    function mouseDown(e, x, y) {  // for drag selection cells, I moved setCellSelection from onClick to here
        console.log("Mouse Down", e);
        if (!e.shiftKey)
            setCellSelection(e, x, y);
    }

    return (x, y) => {
        if (Array.isArray(cells) && y<cells.length) {
            let row = cells[y];
            let bEditing = props.cellBeingEdited!==null && props.cellBeingEdited.x === x && props.cellBeingEdited.y === y;
            if (Array.isArray(row) && x < row.length) {
                let cell = row[x];
                /* comment out 1455, fix has some issues e.g.
                if (cell?.val?.length < 15 && cell?.val?.includes("-")) {
                    const cellSplit = cell.val.split("-");
                    if (cellSplit.length === 3 && cellSplit[2].length) {
                        cell.val = <>{cellSplit[0]}&#8209;{cellSplit[1]}&#8209;{cellSplit[2]}</>;  // 1455 - stop dates from wrapping by using nonbreaking hypen
                    }
                } */
                let bReadOnly = 'readOnly' in cell && cell.readOnly;
                let cls = bReadOnly ? "ReadOnlyCell" : bEditing ? "CurEditCell" : "EditCell";
                const isSel = IsInSelRect([x, y], props.cellSelection);
                const isAnch = isAnchor([x, y], props.cellSelAnchor);
                const boxShadowCss = getSelectionClass([x, y], props.cellSelection);
                if (isSel) cls = cls + " InSelection";
                if (isAnch) cls += " AnchorCell";
                let type = cell.type;
                if (props.bHasColTotal) {
                    if (x === props.nCols) {
                        cls += " TotalCol";
                    } else if (x+1 === props.nCols) {
                        cls += " PreTotalCol";
                    }
                }

                const cellFmt = cell.cellFmt === undefined ? { boxShadow: boxShadowCss } : { ...(cell.cellFmt), boxShadow: boxShadowCss };
                const cellIcon = cell.icon ? (<img className={"CellIcon Side" + cell.icon.side} width={cell.icon.width} height={cell.icon.height} alt="" src={"data:" + cell.icon.mime + ";base64," + cell.icon.base64} />) : "";
                const cellIconSide = cell.icon ? cell.icon.side : "";
                const ciL = cellIconSide === "Left" ? cellIcon : "";
                const ciR= cellIconSide === "Right" ? cellIcon : "";

                const id = "v" + props?.vid + "_" + y + "_" + x;

                if (cellIconSide === "Only") {
                    return (<td key={x + 1} className={cls} style={cellFmt}>{cellIcon}</td>);
                } else if (type === "checkbox")
                    return (<td key={x + 1} className={cls} style={cellFmt}>{ciL}<TableCellCheckbox cell={cell} readOnly={bReadOnly} x={x} y={y} {...props} />{ciR}</td>);
                else if (type === "choice" || type === "multichoice")
                    return (<td key={x + 1} className={cls + " Choice"} style={cellFmt}>{ciL}<TableCellChoice cell={cell} readOnly={bReadOnly} x={x} y={y} {...props} boxShadow={boxShadowCss} />{ciR}</td>);
                else if (type === "slider")                             // LDC 11/1/2022 ER S-1468
                    return (<td key={x + 1} className={cls + " Slider"} >{ciL}<TableCellSlider cell={cell} readOnly={bReadOnly} x={x} y={y} state={cell.val} vid={props.vid} zoom={props.zoom} />{ciR}</td>);
                else if (type === "image")
                    return (<td key={x + 1} className={cls} style={cellFmt}>{ciL}<img width={cell.val.width} height={cell.val.height} alt="" src={"data:" + cell.val.mime + ";base64," + cell.val.base64} onClick={e => onClick(e, x, y, false)} />{ciR}</td>);
                else if (bReadOnly) {
                    if (cellFmt?.textAlign === 'inherit') delete cellFmt.textAlign; // 1627
                    if (cell?.val?.includes("\n")) cls += " MultiLine";
                    return (<td key={x + 1} id={id} className={cls + (cell.bar ? " HasBar" : "")} style={cellFmt} onClick={e => onClick(e, x, y, false, props.view)}
                        onMouseDown={e => mouseDown(e, x, y)} onMouseEnter={e => onEnter(e, x, y, false, props.view)}>
                        <CellNonCtrlContents ciL={ciL} ciR={ciR} cell={cell} />
                    </td>);
                }
                else if (bEditing) {
                    console.log("**cell ", cell.entry)
                    return (<td key={x + 1} className={cls} style={cellFmt}>{ciL}<FloatingEdit entry={cell.entry} val={cell.val} x={x} y={y} {...props} onPaste={test} />{ciR}</td>);
                }
                else {
                    if (cell?.val?.includes("\n")) cls += " MultiLine";
                    return (<td key={x + 1} id={id} className={cls + (cell.bar ? " HasBar" : "")} style={cellFmt} onClick={e => onClick(e, x, y, true)} onMouseDown={e => mouseDown(e, x, y)} onMouseEnter={e => onEnter(e, x, y, false, props.view)}>
                        <CellNonCtrlContents ciL={ciL} ciR={ciR} cell={cell} />
                    </td>);
                }
            }
        }
        return null;
    }
}

function pasteCopiedCells(pastedData, x, y, vid, setShowWarningMsgBox, numRows, numCols, warningIgnored, cells, cellSelection, setCellSelection, setTableCellsOnClipboard) {

    // trim new line at end (copied excel cells has newline at end )
    console.log("pasteCopiedCells()", x, y);

    const rowHeaderCellSelected = x === -1 ? 1 : 0;
    const colHeaderCellSelected = y === -1 ? 1 : 0;

    const pastedDataTrimmed = pastedData.trim();
    const pastedDataNewLineSplit = pastedDataTrimmed.split('\r');

    if (rowHeaderCellSelected) {

        if (pastedDataNewLineSplit.length !== 1) {
            console.log("when one row is selected, only allow pasting if pasted data is also one row, returning...")
            return;
        }
        const numColsPasted = pastedDataNewLineSplit[0].split('\t').length;
        if (numColsPasted <= numCols) {
            // add a row header which will be ignored, and makes the pasting work right
            pastedDataNewLineSplit[0] = "'ignore me'\t" + pastedDataNewLineSplit[0];
        }
    }
    else if (colHeaderCellSelected) {

        if (pastedDataNewLineSplit[0].split('\t').length !== 1) {
            console.log("when one col is selected, only allow pasting if pasted data is also one col, returning...")
            return; 
        }

        console.log("num rows pasted", pastedDataNewLineSplit.length, numRows);
        if (pastedDataNewLineSplit.length <= numRows) {
            // add a row header which will be ignored, and makes the pasting work right
            pastedDataNewLineSplit.unshift("'ignore me'");
        }
    }

    const numRowsPasted = pastedDataNewLineSplit.length;
    let numColsPasted;

    for (let j = 0; j < pastedDataNewLineSplit.length; j++) {
        console.log("j", j)
        const pastedDataTabSplit = pastedDataNewLineSplit[j].split('\t');
        numColsPasted = pastedDataTabSplit.length;

        for (let i = 0; i < pastedDataTabSplit.length; i++) {
            console.log("i", i)

            if (pastedDataTabSplit.length + x > numCols && !warningIgnored) {
                console.log("num colss exceeded")
                setShowWarningMsgBox(true);
                return;
            }

            if (pastedDataNewLineSplit.length + y > numRows && !warningIgnored) {
                console.log("num rows exceeded")
                setShowWarningMsgBox(true);
                return;
            }

            const cellVal = prepCellForPasting(pastedDataTabSplit[i], cells, i, j, x, y);
            console.log("**cellVal", cellVal, i, x, j, y)
            if (i + x === -1 || j + y === -1) {
                console.log("**skip header cell", x, y);
                continue;
            }

            //console.log("web socket send x,i", x,i, "y,j", y,j, "val", cellVal)
            WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'setCellText', col: i + x, row: j + y, val: cellVal, entry: null });
        }

        const newCellSel = {};
        newCellSel.top = y;
        newCellSel.bottom = y + numRowsPasted - 1;
        newCellSel.left = x;
        newCellSel.right = x + numColsPasted - 1;

        setTableCellsOnClipboard(true);  // dashed lines around cells pasted

        setCellSelection(newCellSel);
    }
}

function prepCellForPasting(cellVal, cells, i, j, x, y) {
    //console.log("prep cell for pasting", cellVal, typeof cellVal, i, j, x, y);
    cellVal = cellVal.trim();

    if (x === -1 || y === -1) {
        console.log("exit prepCellForPasting, header cell")
        return cellVal;
    }

    // does it start with a quote (Excel seems to use double quotes, so let's start with that)
    const isQuotedDouble = cellVal.startsWith('"') && cellVal.endsWith('"') ? true : false;
    const isQuotedSingle = cellVal.startsWith("'") && cellVal.endsWith("'") ? true : false;
    const isQuoted = isQuotedSingle || isQuotedDouble ? true : false;

    const hasNumbers = /\d/.test(cellVal);
    const hasLetters = /[ABCDF-Zabcdf-z]/.test(cellVal); // skipping 'e' cause of exp format
    const hasDoubleQuotes = /"/.test(cellVal);
    const hasSpace = /\s/.test(cellVal);
    const hasTwoSlashes = /\/.+\//.test(cellVal);
    const hasTwoDashes = /-.+-/.test(cellVal);
    const hasYearMaybe = /\d\d\d\d/.test(cellVal);
    const endsWithPercent = /%$/.test(cellVal);
    //console.log("endWithPercent", endsWithPercent, cellVal)

    if (cells && cells[j + y][i + x]?.entry === "text") {
        //console.log("do nothing, text only")
    }
    else if (!isQuoted) {
        /*console.log("hasNumbers", hasNumbers)
        console.log("hasLetters", hasLetters)
        console.log("hasDoubleQuotes", hasDoubleQuotes)
        console.log("has two slashes", hasTwoSlashes)
        console.log("has two dashes", hasTwoDashes)
        console.log("has year maybe", hasYearMaybe)*/

        // test to see if

        let isDate = false;

        if ((hasTwoDashes || hasTwoSlashes) && hasNumbers && hasYearMaybe) {
            // let's see if it parses as a date
            if (!isNaN(Date.parse)) isDate = true;
        }

        if ((hasLetters || hasSpace) && !isDate) {
            // first stab, let's assume it's a string (though could be a date...)

            // we are going to wrap in double quotes, so escape any double quotes w/in the string
            //cellVal = hasDoubleQuotes ? cellVal.split('"').join('""') : cellVal;

            if (hasDoubleQuotes) {
                cellVal = cellVal.substring(1, cellVal.length - 2);
                return;
            }

            // wrap in double quotes
            cellVal = '"' + cellVal + '"';

        }
        else if (!isDate) {
            let bParsesAsNumber = false;

            if (cellVal.startsWith("$")) {
                //console.log("looks like number with dollar sign");
                cellVal = cellVal.substring(1)
            }

            if (endsWithPercent) {
                cellVal = cellVal.substring(0, cellVal.length - 1);
                //console.log("**cell", cellVal)
            }

            const cellValNoCommas = cellVal.replace(/,/g, "");
            //console.log("cellVal no commas", cellValNoCommas)

            if (!isNaN(Number(cellVal.replace(/,/g, "")))) {
                bParsesAsNumber = true;
                //console.log("number", Number(cellVal));
                if (endsWithPercent)
                    cellVal += "%";
            }
            else {
                cellVal = '"' + cellVal + '"';
            }
        }
    }
    else {
        // need to look for quotes w/in string so we can escape them
        cellVal = hasDoubleQuotes ? '"' + cellVal.substring(1, cellVal.length - 1).split('"').join('""') + '"' : cellVal;
    }

    return cellVal.length ? cellVal : '""';
}

function CellNonCtrlContents({ciL,ciR,cell})
{
    const val0 = cell.val === "" ? (<>&nbsp;</>) : cell.val;              // LDC 1/12/2021 Bug 471
    const val = (cell.entry === "text" || cell.entry === "literal") && val0.replace ? val0.replace(/^'(.*)'$/, '$1') : val0;

    const html = (
        <>
            {ciL}
            {cell.bar ? <div className="CD">{val}<CellBar {...cell.bar} /></div> : val}
            {ciR}
            {cell.diag && <DiagonalBorder {...cell.diag} />}
        </>);

    if (ciL || ciR || cell.bar)
        return <div className="CDFull">{html}</div>;
    else
        return html;
}

function EditTableRows(props)
{
    const rowOid = props.rowOid;
    const [rowIndVal,] = useObjAtt(rowOid, "_FormattedIndexValue");
    const nRows = Array.isArray(rowIndVal) ? rowIndVal.length : 0;
    const props2 = { bodyCellRenderer: TableBodyCellRenderer(props), bodyCellRenderPrintsTD:true, nRows: nRows, ...props };

    return TableRows(props2);
}

function EditTableGrid(props)
{
    //console.log("EditTableGrid", props)
    const tableContainer = useRef();
    const [topOfTabCont, setTopOfTabCont] = useState(155);
    const [diagSize,] = useObjAtt(props.topOid, "_diagSize");
    const [showWarningMsgBox, setShowWarningMsgBox] = useState(false);
    const [pasteClipboard, setPasteClipboard] = useState(false);
    const editTableRef = useRef();
    const [tableCellsOnClipboard, setTableCellsOnClipboard] = useState(false); // makes cell selection border dashed (maybe rename?)

    // This one doesn't intercept TAB key successfully yet.
    function onKeyPress(ev) {
        console.log("**onKeyPress() EditTableGrid**", ev.key, ev.charCode)
        if (ev.charCode === 9) {     // TAB key
            console.log("**tab key EditTableGrid**")
            ev.preventDefault();
            const s = props.cellSelection;
            if (s === null) {
                console.log("EditTableGrid setCellSelection 1")
                props.setCellSelection({ left: 1, right: 1, top: 1, bottom: 1 });
            } else if (s.left === s.right && s.top === s.bottom) {
                let x = s.left + 1;
                let y = s.top;
                if (x >= props.nCols) {
                    x = 0;
                    ++y;
                }
                if (y >= props.nRows) {
                    y = 0;
                }
                console.log("EditTableGrid setCellSelection 2")
                props.setCellSelection({ left: x, right: x, top: y, bottom: y });
            }
        }
    }

    useLayoutEffect(() => {
        const rect = tableContainer.current.getBoundingClientRect();
        if (topOfTabCont !== rect.top)
            setTopOfTabCont(rect.top);
    })  // Note EW 698 was fixed by removing the second parameter to UseEffect, so check that if you add them back.

    useEffect(() => {
        if (pasteClipboard) {
            const x = props?.cellSelection?.left;
            const y = props?.cellSelection?.top;

            if ((x + 1) && (y + 1)) { // plus 1 helps if first row or column is selected i.e. their value is zero (false)
                navigator.clipboard.readText().then((pastedData) => {
                    pasteCopiedCells(pastedData, x, y, props.vid, props.setShowWarningMsgBox, props.nRows, props.nCols, true, null, props.cellSelection, props.setCellSelection, setTableCellsOnClipboard);
                });
            }
            setPasteClipboard(false);
        }
    })

    const nav_style = props?.cloudStyles?.navigation_style;
    const use_top_diag_size = props?.cloudStyles?.use_top_diagram_size;

    let className = "TableContainer";
    if (props.bTallNode === 1 || props.isFrameNode === true) className += " Embedded";
    else if (props.bShowingOutline)  className += " Outline";
    else {
        className += nav_style === "top_tabs" && use_top_diag_size !== "yes" ? " TopTabsUseFullWindow" : "";
        className += nav_style === "top_tabs" && use_top_diag_size === "yes" ? " TopTabsUseTopDiagSize" : "";
        className += nav_style === "side_tabs" && use_top_diag_size !== "yes" ? " SideTabsUseFullWindow" : "";
        className += nav_style === "side_tabs" && use_top_diag_size === "yes" ? " SideTabsUseTopDiagSize" : "";
    }

    let style = {};
    style = props.bTallNode !== 1 && props.isFrameNode !== true && nav_style !== "top_tabs" && nav_style !== "side_tabs" ? style = { maxHeight: "Calc(100vh - " + (topOfTabCont + 50) + "px)" } : style;
    style = nav_style === "top_tabs" && props.cloudStyles.use_top_diagram_size !== "yes" ? style = { maxHeight: "Calc(100vh - " + (topOfTabCont + 40 + 8 + 2) + "px)" } : style;
    style = nav_style === "top_tabs" && use_top_diag_size === "yes" && diagSize !== undefined ? { maxHeight: (diagSize.height - props.tableHeaderHeight - 8) + "px" } : style;
    style = nav_style === "side_tabs" && use_top_diag_size !== "yes" ? { maxHeight: "Calc(100vh - " + (topOfTabCont + 50) + "px)", maxWidth: "Calc(100vw - " + (props.leftSideContent + 27) + "px" } : style;
    style = nav_style === "side_tabs" && use_top_diag_size === "yes" && diagSize !== undefined ? { maxHeight: (diagSize.height - props.tableHeaderHeight - 8) + "px" } : style;

    if (props.isFrameNode || (props.bTallNode === 1 && props.tableMargin)) {
        const tableMargin = props.tableMargin;
        style = { marginLeft: (tableMargin + "px"), maxWidth: "Calc(100% - " + (2 * tableMargin + 2 + "px"), marginBottom: (tableMargin + "px") }
    }

    function handleClickOutside(ev) {
        if (!editTableRef.current.contains(ev.target) && props.setCellSelection) {
            console.log("EDIT TABLE *** handleClickOutside", ev)
            props.setCellSelection(null);
            setTableCellsOnClipboard(false);
        }
    }

    // This is to close the menu when the user clicks outside of it
    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);                       // LDC 10/14/2020 Suan Bug 300. When it was document.addEventListener, a react-hooks handler co-opted it.  Has to be window.addEventListener
        return () => window.removeEventListener("mousedown", handleClickOutside);
    });
   
    let xSelRect;
    let ySelRect;
    let widthSelRect;
    let heightSelRect;
    if (props.cellSelection) {
        const cellSel = props.cellSelection;
        let topLeftSelectionCell = document.getElementById("v" + props.vid.toString() + "_" + cellSel.top + "_" + cellSel.left);
        let bottomRightSelectionCell = document.getElementById("v" + props.vid.toString() + "_" + cellSel.bottom + "_" + cellSel.right);

        if (topLeftSelectionCell?.className?.includes("ChoiceText")) // if choice cell, then get the td element
            topLeftSelectionCell = topLeftSelectionCell.parentNode.parentNode;
        if (topLeftSelectionCell?.className?.includes("ChoiceText")) 
            bottomRightSelectionCell = bottomRightSelectionCell.parentNode.parentNode;

        if (topLeftSelectionCell && bottomRightSelectionCell) {
            if (cellSel.top === cellSel.bottom && cellSel.left === cellSel.right) {
                //console.log("Just ONE cell selected")
                xSelRect = topLeftSelectionCell.offsetLeft;
                ySelRect = topLeftSelectionCell.offsetTop;
                widthSelRect = topLeftSelectionCell.offsetWidth - 2;
                heightSelRect = topLeftSelectionCell.offsetHeight - 2;
            }
            else {
                //console.log("MORE THAN ONE cell selected")
                xSelRect = topLeftSelectionCell.offsetLeft;
                ySelRect = topLeftSelectionCell.offsetTop;
                widthSelRect = bottomRightSelectionCell.offsetLeft - topLeftSelectionCell.offsetLeft + bottomRightSelectionCell.offsetWidth - 2;
                heightSelRect = bottomRightSelectionCell.offsetTop - topLeftSelectionCell.offsetTop + bottomRightSelectionCell.offsetHeight - 2;
            }
        }
       // else 
         //   console.log("Somethings wrong, topLeftSelectionCell or bottomRightSelectionCell not good", topLeftSelectionCell, bottomRightSelectionCell)

    }

    const selectedCellsRectCls = tableCellsOnClipboard ? "SelectedCellsRect CellsCopied" : "SelectedCellsRect";

    return (
        <div className={className} ref={tableContainer} style={style}>
            {showWarningMsgBox && <MessageBox setShowWarningMsgBox={setShowWarningMsgBox} setPasteClipboard={setPasteClipboard} />}
            <table className="ATable" id={props.tableId} ref={editTableRef}>
                <thead>
                    <TableColumnHeaders {...props} setTableCellsOnClipboard={setTableCellsOnClipboard} />
                </thead>
                {
                    <tbody onKeyPress={onKeyPress}>
                        <EditTableRows {...props} setShowWarningMsgBox={setShowWarningMsgBox} setTableCellsOnClipboard={setTableCellsOnClipboard}/>
                </tbody>
                }
            </table>
            {props.cellSelection && <svg className="SelectedCellsSvg" height="10" width="30" style={{ top: ySelRect, left: xSelRect }}><rect className={selectedCellsRectCls} x="0" y="0" width={widthSelRect} height={heightSelRect} style={{ stroke: "#007B8A", strokeWidth: 2, fill: "none" }} strokeDasharray="" /></svg>}
        </div>
    );

}

function MessageBox(props) {
    const messageAnswer = useEval('MsgBox("The data you are trying to paste would go \nbeyond the right or bottom of this table.  \nWould you like to paste only the cells that fit? ", 4, "Warning")');

    useEffect(() => {
        if (messageAnswer === 7) props.setShowWarningMsgBox(false); // No clicked

        if (messageAnswer === 6) {
            props.setShowWarningMsgBox(false);
            props.setPasteClipboard(true);
        }
    })

    return "";
}

export function getTableOrGraphMargin(nodeInfo) {
    let tabMargin = 8 + 1; // plus one based on pixel counting and fact that node svg border spans 2 pixels
    const nodeInfoArray = nodeInfo ? nodeInfo.split(',') : [];
    if (nodeInfoArray.length > 17 && nodeInfoArray[17].trim().length) // nodeInfoArray[17] is the corner radius
        tabMargin = Math.floor(parseInt(nodeInfoArray[17].trim()) * .75) + 1; // plus one based on pixel counting and fact that node svg border spans 2 pixels

    // Max wants min margin to be 8px
    if (tabMargin < 9) tabMargin = 8 + 1;

    return tabMargin;
}


// LDC 6/28/2021 ER S-720. This table interface isn't designed to handle huge tables. If you get a table with >50K cells,
// it will take extremely long to render and leaves the impression that the browser is hanging. Thus, to prevent this hanging,
// we truncate tables if they get too big.
function HugeTableSizeLimits(pivot, topCloudStyles)
{
    //console.log("HugeTableSizeLimits", topCloudStyles);
    if (!pivot || !pivot.nBodyRows || !pivot.nBodyCols) return {};
    let [nr, nc] = [pivot.nBodyRows, pivot.nBodyCols];

    const nCells = nr * nc;

    const maxCellsAcpConfig = document?.acpServerConfig?.EW1484_max_num_table_cells;
    const maxCellsAcpStyles = topCloudStyles?.acp_max_table_size_to_show; // EW 1774
    const maxCells = maxCellsAcpStyles ? parseInt(maxCellsAcpStyles) : maxCellsAcpConfig;

    if (nCells <= maxCells)
        return { };

    let max_c = undefined, max_r= undefined;

    if (nr >= nc) {
        if (nc > 550) max_c = nc = 500;
        max_r = Math.ceil(maxCells / nc);
        if (nr - max_r < 50) max_r = undefined;
    } else {
        if (nr > 550) max_r = nr = 500;
        max_c = Math.ceil(maxCells / nr);
        if (nc - max_c < 50) max_c = undefined;
    }

    return { maxBodyCols: max_c, maxBodyRows: max_r };
}

export function AcceptCancelButtons({vid, onAccept,onCancel, style})
{
    const dirty = useQuery({req: "isDirty" }, vid);               // LDC 11/4/2021 ER S-1050

    return (
        <div id="TableAcceptCancelHolder" style={style} >
            <div style={{display: "flex"}} >
                <input id="Accept" type="image" alt="Accept" src="img/GreenCheckButton.png" disabled={!dirty} onClick={onAccept} />
                <input id="Cancel" type="image" alt="Cancel" src="img/RedXButton2.png" disabled={!dirty} onClick={onCancel} />
            </div>
        </div>
    );
}

function TableByFullTable1(props)
{
    //console.log("TableByFullTable1", props);
    const tableHeaderRef = useRef();
    const [tableHeaderHeight, setTableHeaderHeight] = useState(50);
    const pivot = props.pivot;
    const vid = props.vid;
    const colWidths = useQuery({ req: "colWidths" }, props.vid);
    const col1Oid = pivot.cols[0];
    const [col1IndVal,] = useObjAtt(col1Oid, "_FormattedIndexValue");           // LDC 3/22/2021 Bug S-68
    const bHasColTotal = useQuery({ req: "hasTotal", index: col1Oid }, vid);
    const nCols = Array.isArray(col1IndVal) ? col1IndVal.length : 1;
    const row1Oid = pivot.rows[0];
    const [row1IndVal,] = useObjAtt(row1Oid, "_FormattedIndexValue");
    const bHasRowTotal = useQuery({ req: "hasTotal", index: row1Oid }, vid);
    const nRows = Array.isArray(row1IndVal) ? row1IndVal.length : 1;
    const [cellBeingEdited, setCellBeingEdited] = useState(null);
    const [cellSelection, setCellSelection] = useState(null);               // When there is a selection, assoc {left,top,right,bottom}, with inclusive corners, i.e., (right,bottom) is in selected rect. When no selection, null.
    const [cellSelAnchor, setCellSelAnchor] = useState(null);
    const qidCellFmts = useQuery({ req: 'useCellFmts' }, props.vid);        // Required before using cell formats in cell queries
    const [rowHeadersWidth, setRowHeadersWidth] = useState(100);    // So we can align the left edge of the column pivoter with the left edge of the body cells
    const rowHeadCellRef = useRef(null);
    const textNodeInfo = useEval("nodeinfo Text");
    const frameNodeInfo = useEval("nodeinfo FrameNode");
    const formnodeNodeInfo = useEval("nodeinfo formnode");
    const [togglePivoters, setTogglePivoters] = useState(false);
    const [oidOrig,] = useObjAtt(props.oid, "_original");
    const [proactivelyEvaluate,] = useObjAtt(oidOrig?.oid, "ProactivelyEvaluate");
    const [nodeClassOrig,] = useObjAtt(oidOrig?.oid, "class");
    const [orphansDesc,] = useObjAtt(props.orphansOid, "description");

    //console.log("widths**", colWidths)

    const tableId = Math.floor(Math.random() * 100000000).toString();  // will be used for copy table

    const isIndexTable = false; //IsIndexTableTest(props.identOrig);

    // This layout is to measure the width of the row headers after layout so we can position the column pivot control's left edge above the body cell's left edge.
    useLayoutEffect(() => {
        if (rowHeadCellRef && rowHeadCellRef.current && setRowHeadersWidth) {
            setRowHeadersWidth(rowHeadCellRef.current.clientWidth + 1);
        }
    });         // Intentionally no dependendencies

    //const rowOid = 1281;
    //const colOid = 1279;
    //const rowIndVal = "placeholder";
    //const colIndVal = "placeholder";
    //const nCols = 5;
    //const nRows = 10;

    useEffect(() => {
        //console.log("tableHeaderRef", tableHeaderRef)
        const tableHeaderRect = tableHeaderRef.current.getBoundingClientRect();
        if (tableHeaderHeight !== tableHeaderRect.height)
            setTableHeaderHeight(tableHeaderRect.height);
    }, [setTableHeaderHeight, tableHeaderHeight, tableHeaderRef?.current?.offsetHeight])

    function msgBoxReplyHandler() {
        console.log("msgBoxReplyHandler, do nothing");
    }

    const state = { view: props.view, computed: true, ...pivot };

    function OnColPivot(newColInd) {
        WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'colPivot', sub: newColInd });
    }
    function OnRowPivot(newRowInd) {
        WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'rowPivot', sub: newRowInd });
    }
    function OnChangeSlicer(oid, newPos) {
        WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'setSlicer', sub: oid, pos: newPos });
    }
/*
 * moved to result title in resultTable.js
 * */
    function OnAcceptChanges(ev) {
        console.log("OnAcceptChanges, don't use, moved resultTable.js");
        WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'acceptChanges' });
    }
    function OnCancelChanges(ev) {
        WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'dropChanges' });
        //const oldQ = props.q;
        //props.resetQ(undefined);
        //props.resetQ(oldQ);
    }
    const props2 = {
        colOid:col1Oid, nCols:nCols, colIndVal:col1IndVal,
        rowOid:row1Oid, nRows:nRows, rowIndVal:row1IndVal,
        state: state,
        pivotCol: OnColPivot, pivotRow: OnRowPivot, changeSlicer: OnChangeSlicer,
        cellBeingEdited:cellBeingEdited,
        setCellBeingEdited:setCellBeingEdited,
        cellSelection:cellSelection,
        setCellSelection:setCellSelection,
        onAcceptChanges:OnAcceptChanges,
        onCancelChanges:OnCancelChanges,
        qidCellFmts: qidCellFmts,
        bHasColTotal:bHasColTotal,
        bHasRowTotal: bHasRowTotal,
        rowHeadersWidth: rowHeadersWidth,
        rowHeadCellRef: rowHeadCellRef,
        togglePivoters: togglePivoters,
        ...HugeTableSizeLimits(pivot, props.topCloudStyles),              // LDC 6/28/2021 ER S-720
        ...props,
        tableId: tableId,
        isIndexTable: isIndexTable,
        cellSelAnchor, setCellSelAnchor, colWidths
    };

    let clsName = (props.view === "edit" ? "EditTable" : "ResultTable") + " Table";
    if (orphansDesc === "csv download in progress") clsName += " DownloadInProgress";

    // these lines affect tall edit tables (and probably acp1 tabs)
    let tableSize = { ...props.wndSize };
    if ('height' in tableSize) tableSize.height -= 1;
    if ('width' in tableSize) tableSize.width -= 1;

    // for framenodes and tall nodes, check corner radius
    let tableMargin = 9; // the default for framenodes and tall nodes, unless set otherwise in the nodeinfo
    if (props.isFrameNode || props.bTallNode === 1) {
        if (props.isFrameNode && props.nodeClass0 === "Text") tableMargin = getTableOrGraphMargin(textNodeInfo);
        if (props.isFrameNode && props.nodeClass0 === "FrameNode") tableMargin = getTableOrGraphMargin(frameNodeInfo);
        if (props.bTallNode === 1) tableMargin = getTableOrGraphMargin(formnodeNodeInfo);
    }

    // showAcceptCancelButtons does not affect result tables
    const showAcceptCancelButtons = props.view === "edit" && typeof proactivelyEvaluate === "number" && proactivelyEvaluate & 16 ? false : true;

    return (
        <div className={clsName} style={tableSize} onClick={ev=>ev.stopPropagation()} >
            <div className="TableWindowHeaderArea" ref={tableHeaderRef} >
                <TableHeader {...props2} tableMargin={tableMargin} setTogglePivoters={setTogglePivoters} nodeClassOrig={nodeClassOrig} colWidths={colWidths}/>
                {/*showAcceptCancelButtons && <AcceptCancelButtons vid={vid} onAccept={OnAcceptChanges} onCancel={OnCancelChanges} style={{ paddingRight: tableMargin }} />*/}
            </div>
            <EditTableGrid {...props2} tableHeaderHeight={tableHeaderHeight} tableMargin={tableMargin} nodeClassOrig={nodeClassOrig}/>
            {
            //An edit table for {props.oid} using vid={vid}.  (body cell contents TBD)<br />
            //The pivot is: {JSON.stringify(pivot)}
            }
        </div>
    );
}

function TableByFullTableByVid(props)
{
    //console.log("TableByFullTableByVid", props.oirOrig)
    const [ident,] = useObjAtt(props.oidOrig, "identifier");
    const pivot = useQuery({ req: 'pivot' }, props.vid);
    if (pivot === undefined)
        return (<TableStartingUp style={props.wndSize} {...props} />);
    else
        return (<TableByFullTable1 pivot={pivot} {...props} identOrig={ident}/>);
}

export function TableByFullTable(props)
{
    //console.log("TableByFullTable", props);
    const vid = useQuery(props.q);
    const [oidOrig,] = useObjAtt(props.oid, "_original");

    if (vid)
        return <TableByFullTableByVid vid={vid} {...props} oidOrig={oidOrig?.oid} />;
    else
        return <TableStartingUp {...props} style={props.wndSize} />;
}

export function EditTable(props)
{
    const [q,setQ] = useState({ req: 'editTable', oid: props.oid });       // We need to keep this ===, always pointing to the same json, not identical copies. That is the only purpose of making an extra state variable here.
    const bACP = props.uiStyle === "ACP1";
    const [wndSize,] = useObjAtt(bACP?props.topOid:props.oid, bACP?"_diagSize":"_editTableWindowSize");

    return (<TableByFullTable wndSize={wndSize} view="edit" q={q} resetQ={setQ} {...props} />);
}

// When showing the result of an index (i.e. unindexed one d array), we don't want row headers that duplicate the body cells
function IsIndexTableTest(id) {
    // LDC 10/27/2022 Bugs S-1456, S-1459. Added IsResultComputed(id) to prevent evaluation if not already evaluated.
    const testOne = useEval("IsResultComputed(" + id + ") AND (local indexes_of := IndexesOf(" + id + "); local is_one_d := IndexLength(indexes_of) = 1; local is_self_indexed := sum(indexes_of = handle(" + id + ")); is_self_indexed and is_one_d)");
    const testTwo = useEval("try(IsResultComputed(" + id + ") AND local first_cell_table := " + id + "[@" + id + " = 1]; local last_cell_table := " + id + "[@" + id + " = size(" + id + ")]; local index_values := indexvalue(" + id + "); local first_index_value := index_values[@" + id + " = 1]; local last_index_value := index_values[@" + id + " = size(index_values)]; first_cell_table = first_index_value and last_cell_table = last_index_value, -1))");
    //console.log("testOne testTwo", testOne, testTwo)
    return testOne === 1 && testTwo === 1 ? true : false;
}

/*
function IsIndexTableTest2(props) {
    const id = "one_d_table";
    const testTwo = useEval("local first_cell_table := " + id + "[@" + id + " = 1]; local last_cell_table := " + id + "[@" + id + " = size(" + id + ")]; local index_values := indexvalue(" + id + "); local first_index_value := index_values[@" + id + " = 1]; local last_index_value := index_values[@" + id + " = size(index_values)]; first_cell_table = first_index_value and last_cell_table = last_index_value");
    console.log("test two", testTwo);
    return testTwo;
}
*/

function arrowKeyWithMultiSelect(key, anchor, setCellSelection, setCellSelAnchor, nRows, nCols) {
    //const anchor = props.cellSelAnchor;  // remember anchor has just "top" and "left" properties

    // EW 1911 With multiple cells selected, arrow keys reselects to a single cell in the direction of the arrow key as in Excel
    switch (key) {
        case "ArrowRight":
            const newLeftRight = anchor.left < nCols - 1 ? anchor.left + 1 : anchor.left;
            setCellSelection({ left: newLeftRight, right: newLeftRight, top: anchor.top, bottom: anchor.top });
            setCellSelAnchor({ left: newLeftRight, top: anchor.top });
            break;
        case "ArrowLeft":
            const newLeftRight2 = anchor.left !== 0 ? anchor.left - 1 : anchor.left;
            setCellSelection({ left: newLeftRight2, right: newLeftRight2, top: anchor.top, bottom: anchor.top });
            setCellSelAnchor({ left: newLeftRight2, top: anchor.top });
            break;
        case "ArrowUp":
            const newTopBottom = anchor.top !== 0 ? anchor.top - 1 : anchor.top;
            setCellSelection({ left: anchor.left, right: anchor.left, top: newTopBottom, bottom: newTopBottom });
            setCellSelAnchor({ left: anchor.left, top: newTopBottom });
            break;
        case "ArrowDown":
            const newTopBottom2 = anchor.top < nRows - 1 ? anchor.top + 1 : anchor.top;
            console.log("ArrowDown", newTopBottom2)
            setCellSelection({ left: anchor.left, right: anchor.left, top: newTopBottom2, bottom: newTopBottom2 });
            setCellSelAnchor({ left: anchor.left, top: newTopBottom2 });
            break;
    }
}

function startEditingCellMaybe(keyEvent, cellX, cellY, setCellBeingEdited) {
    console.log("startEditingCellMaybe")

    const floatingEdit = document.getElementById("FloatingEdit")
    if (floatingEdit && keyEvent.key !== 'Tab' && keyEvent.key !== 'Enter') {
        console.log("floating edit present, return from key event listener");
        return "return";
    }

    if (!keyEvent.ctrlKey && keyEvent.key.length === 1) { // length === 1 ignores keys like ArrowRight, Tab
        // if a cell is selected and someone types, then start editing the cell and place cursor after first character typed
        setCellBeingEdited({ x: cellX, y: cellY });
        setTimeout(() => {
            const floatingEdit = document.getElementById("FloatingEdit")
            if (!floatingEdit) return;
            floatingEdit.innerHTML = keyEvent.key;
            setTimeout(() => {
                const range = document.createRange();//Create a range (a range is a like the selection but invisible)
                range.selectNodeContents(floatingEdit);//Select the entire contents of the element with the range
                range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
                const selection = window.getSelection();//get the selection object (allows you to change selection)
                selection.removeAllRanges();//remove any selections already made
                selection.addRange(range);//make the range you have just created the visible selection
                console.log(floatingEdit)
            }, 10);
        }, 5);
        keyEvent.stopPropagation();
        keyEvent.preventDefault();
        return "return";
    }
}

function arrowKeyWithShiftCtrl(keyEvent, cellSel, anchor, setCellSelection, setCellSelAnchor, nRows, nCols) {
    // ctrl + 
    if (keyEvent.key.startsWith("Arrow") && keyEvent.ctrlKey && !keyEvent.shiftKey && cellSel) {
        console.log("Control + Arrow")
        if (keyEvent.key === "ArrowRight") {
            console.log("ctrl right arrow", anchor)
            setCellSelection({ left: nCols - 1, right: nCols - 1, top: anchor.top, bottom: anchor.top });
            setCellSelAnchor({ top: anchor.top, left: nCols - 1 });
            return "return";
        }
        else if (keyEvent.key === "ArrowLeft") {
            console.log("ctrl left arrow")
            setCellSelection({ left: 0, right: 0, top: anchor.top, bottom: anchor.top });
            setCellSelAnchor({ top: anchor.top, left: 0 });
            return "return";
        }
        else if (keyEvent.key === "ArrowDown") {
            console.log("ctrl down arrow")
            setCellSelection({ left: anchor.left, right: anchor.left, top: nRows - 1, bottom: nRows - 1 });
            setCellSelAnchor({ top: nRows - 1, left: anchor.left });
            return "return";
        }
        else if (keyEvent.key === "ArrowUp") {
            console.log("ctrl up arrow")
            setCellSelection({ left: anchor.left, right: anchor.left, top: 0, bottom: 0 });
            setCellSelAnchor({ top: 0, left: anchor.left });
            return "return";
        }
    }

    if (keyEvent.key.startsWith("Arrow") && keyEvent.ctrlKey && keyEvent.shiftKey && cellSel) {
        console.log("Control + Shift + Arrow, select row or column from anchor");
        switch (keyEvent.key) {
            case "ArrowRight":
                console.log("ctrl shift right arrow", anchor)
                setCellSelection({ ...cellSel, left: anchor.left, right: nCols - 1 });
                return "return";
                break;
            case "ArrowLeft":
                console.log("ctrl shift left arrow")
                setCellSelection({ ...cellSel, right: anchor.left, left: 0 });
                return "return";
                break;
            case "ArrowDown":
                console.log("ctrl shift down arrow");
                setCellSelection({ ...cellSel, top: anchor.top, bottom: nRows - 1 });
                return "return";
                break;
            case "ArrowUp":
                console.log("ctrl shift up arrow");
                setCellSelection({ ...cellSel, bottom: anchor.top, top: 0 });
                return "return";
                break;
        }
    }
}
