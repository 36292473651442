import React from 'react';
import { useState, useEffect } from 'react';
import { AddServerMessageReceiver } from './serverState.js';
import { Lineify } from './miscTools.js';

import './styles/errorMessages.scss';

var AppendErrorMessage = null;


function ErrorMessage(props) {
    const key = props.n;
    const CloseMsg = ev => props.CloseMsg(key);
    const url = props.errNum && ("https://wiki.analytica.com/index.php/Error_Messages/" + props.errNum);
    const errType = props.errType ? props.errType + " " : "";

    //console.log("ErrorMessage", props.message)

    let path = document?.acpServerConfig?.EW1737_root_path_users_files;
    path = path.replace(/\\/g, "\\\\"); 
    const re = new RegExp(path, "ig"); 
    let message = props.message
    if (props.message.match(re) !== null) {
        message = message.replace(re, "");
    }
    
    return (
        <div className="ErrorMessage" key={key} id={"ErrMsg_" + key}>
            <button className="CloseBtn" onClick={CloseMsg}>x</button>
            <div className="ErrCount">{key}</div>
            {props.objIdent && <h1>{errType}Error in {props.objIdent}</h1>}
            {props.qid && <h1>Error returned by query #{props.qid}</h1>}
            <p><Lineify text={message} /></p>
            {url && <a href={url} className="MoreInfoUrl" target="_blank" rel="noopener noreferrer">More</a>}
        </div>
    );
}

export function ErrorMessageWindow(props) {
    const [messageList, setMessageList] = useState([]);
    const [key, setKey] = useState(1);

    const appendFn = (json) => {
        setMessageList([...messageList, { ...json, key: key }]);
        setKey(key + 1);
    };

    AppendErrorMessage = appendFn;

    useEffect(() => {
        return () => {
            AppendErrorMessage = null;
        }
    }, [setMessageList, setKey]);

    useEffect(() => {
        if (messageList.length === 1 && messageList[0].message === "") setMessageList([]);
    }, [messageList, setMessageList])

    function CloseMsg(key) {
        const newList = messageList.filter(item => (item.key !== key));
        setMessageList(newList);
    }

    if (messageList.length === 1 && messageList[0].message === "") {
        console.log("Assert: blank error message");
        return null;
    }

    return (
        <div className="ErrorMessageWindow">
            {messageList.map(json => <ErrorMessage key={json.key} {...json} n={json.key} CloseMsg={CloseMsg} />)}
        </div>
    );
}

function onErrorMessage(json) {
    if (AppendErrorMessage)
        AppendErrorMessage(json);
}

AddServerMessageReceiver("Error", onErrorMessage);
