// parentKidPopups.js -- popup menus that show the parent and children variables for a given variable. 
//                    -- these pop up when you click at the left edge or right edge of a variable.
//

import React from 'react';
import { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useObjAtt, useObjAtts } from './serverState.js';
import { MergeArrays } from './miscTools.js';

function ParentKidItem(props)
{
    const oid = props.oid;
    const [cls, title, isIn, isAlias] = useObjAtts(oid, ["Class", "_title", "isIn", "isAlias"]);    
    const [isInTitle,] = useObjAtt(isIn, "_title");

    function onClick(ev)
    {
        ev.stopPropagation();
        props.sel(oid,isIn);
    }

    const bSelectable = isIn && props.sel;

    return <div className={"Title ObjClass_" + cls + (bSelectable?" Selectable":"") + (isAlias?" IsAlias":"")} key={props.i} onClick={onClick}>
                <span className="Icon"/>
                <span className="Text">{props.showIsIn ? isInTitle : title}</span>
            </div>;
}

function ParentKidPopup(props)
{
    const popupRef = useRef();

    const [loc, setLoc] = useState(null);
    const portalRef = useRef(document.getElementById('modal-root'));

    if (loc === null && props.parentRef) {
        const r = props.parentRef.current.getBoundingClientRect();
        setLoc({ left: r.x, top: r.y });        // Just a guess initially -- refined below in a useEffect
    }

    useEffect(() => {
        const rect = props.parentRef.current.getBoundingClientRect();
        const curRect = popupRef.current.getBoundingClientRect();
        let x = loc.left + (rect.x - curRect.x);
        let y = loc.top + (rect.y - curRect.y);
        let maxHt = {};

        // But make sure it fits on the screen
        const doc = portalRef.current.parentElement;
        if (doc) {
            const boundRect = doc.getBoundingClientRect();
            const brTop = boundRect.top + 0.05 * boundRect.height;
            const brHeight = boundRect.height * 0.9;
            const brBottom = brTop + brHeight;

            x = Math.max(boundRect.left, Math.min(x, boundRect.right - curRect.width));
            y = Math.max(boundRect.top, Math.min(y, brBottom - curRect.height));
            if (curRect.height > brHeight)
                maxHt = { height: brHeight };
        }
        //const portalRect = portalRef.current.getBoundingClientRect();
        setLoc({ left: x, top: y, ...maxHt });
    }, [props.parentRef, portalRef, setLoc]);           // Beware about including loc here. Doing so will put it into an infinite recursion.


    function handleClickOutside(ev) {
        if (!popupRef.current.contains(ev.target)) {
            props.setShowing(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    });

    function sel(oid,diag)
    {
        props.setShowing(false);
        if (oid && diag && props.jumpTo)
            props.jumpTo(diag,oid);
    }

    const html = (
        <div className={"ParentKidPopupMenu Showing"} ref={popupRef} style={{ ...loc, zoom: props.zoom }}>
            { props.groups.map( ({oids,heading,showIsIn}) => { 
                if (!Array.isArray(oids) || oids.length===0) return null;
                return (
                    <>
                        <div className="Heading">{heading}</div>
                        { oids.map( (oid,i) => <ParentKidItem oid={oid} i={i} sel={sel} showIsIn={showIsIn} />) }
                    </>
                );
            })}
            
        </div>
    );

    return ReactDOM.createPortal(html, document.getElementById('modal-root'));
}

function ParentKidPopupMenu(props)
{
    const [bShowing, setShowing] = useState(false);
    const hotSpotRef = useRef();

    function group(oids,head,showIsIn)
    {
        const bPlural = Array.isArray(oids) && oids.length !== 1;

        return {
            oids: oids,
            heading: head.replace("%es", bPlural ? "es" : "").replace("%s", bPlural ? "s" : ""),
            showIsIn:showIsIn
        };
    }

    const groups = [
        group(props.inputVars, "Input variable%s", false),
        group(props.outputVars, "Output variable%s", false ),
        group(props.aliases,    "Alias%es in", true ),
        group(props.userInputs, "User input%s in", true ),
        group(props.userOutputs,"User output%s in", true )
    ];

    let bAny = false;
    groups.forEach(({ oids,  }) => (bAny = bAny || (Array.isArray(oids) && oids.length > 0)));
    if (!bAny) return null;

    const bPopup = bShowing ? null : (ev) => {
        setShowing(true);
        ev.stopPropagation();
    };


    return (
        <div className={props.type + "PopupHotspot"} onClick={bPopup} ref={hotSpotRef}>
            {bShowing && <ParentKidPopup setShowing={setShowing} groups={groups} jumpTo={props.jumpTo} parentRef={hotSpotRef} zoom={props.zoom} /> }
        </div>
    );

}

export function ParentsPopupMenu(props)
{
    const oid = props.oid;
    const [parents,] = useObjAtt(oid, "Inputs");
    const [dparents,] = useObjAtt(oid, "DynInputs");
    const [userInputs,] = useObjAtt(oid, "_userInputs");

    return <ParentKidPopupMenu inputVars={MergeArrays(parents, dparents)} userInputs={userInputs} jumpTo={props.jumpTo} type="Parent" zoom={props.zoom} />;
}

export function KidsPopupMenu(props)
{
    const oid = props.oid;
    const [kids,] = useObjAtt(oid, "Outputs");
    const [dkids,] = useObjAtt(oid, "DynOutputs");
    const [aliases,] = useObjAtt(oid, "_aliases");
    const [userOutputs,] = useObjAtt(oid, "_userOutputs");

    return <ParentKidPopupMenu outputVars={MergeArrays(kids, dkids)} aliases={aliases} userOutputs={userOutputs} jumpTo={props.jumpTo} type="Kid" zoom={props.zoom}/>;
}
