import React from 'react';
import { useState } from 'react';
import { WebSocket_Close } from './webSocketClient.js';

// Appears on the far-right of the top toolbar.  Includes info about and control of the server info.

export function ServerConnectionButton(props)
{
    const [showing, setShowing] = useState(1);

    let btn = (<></>);
    switch (props.status) {
        case "connected":   btn = (<button onClick={()=>props.setStatus("request close")}>Disconnect</button>); break;
        case "closed":      btn = (<button onClick={()=>props.setStatus("request connect")}>Reconnect</button>); break;
        case "none":        btn = (<button onClick={() => props.setStatus("request connect")}>Connect</button>); break;
        default: break;
    };

    let infoStyle = {visibility: showing ? "visible" : "hidden"};

    //function changeGroup(ev)
    //{
    //    props.setGroup(ev.target.value);
    //    props.setStatus("request connect")
    //}

    function LogOff()
    {
        if (props.status==="connected") {
            WebSocket_Close(props.setStatus);
        } 
        props.signOut();
    }

    return (
        <div className="ServerConnection ToolbarButton">
            {false && <i id="serverConnectionIcon" className="fab fa-mixcloud Icon" onClick={(ev) => setShowing(!showing)}></i>}
            <div className="ConnectionInfo" style={infoStyle}>
                Status: {props.status}<br />
                WebSocket: <input id="Server-Input" value={props.server} readOnly={props.status==="connected"} onChange={(ev)=>props.setServer(ev.target.value)}/><br />
                {/*
                  Group:
                    <select id="Group-Input" value={props.group} onChange={changeGroup}>
                    {[1, 2, 3].map((gr) => (<option key={gr} value={gr}>{gr}</option>))}
                </select><br />
                */}
                {/*{btn}
                {props.signOut && <button id="SignOut" onClick={LogOff}>Sign out</button>}*/}
                <div className="ReleaseNumber">{document.acpServerConfig.BuildNumber}</div>
                <div id="closeConnectionStatus" onClick={() => { props.setShowConnectionStatus(false); }}>X</div>
            </div>
            
        </div>
    );
}
