// Dialogs for editing the parameters of Sequence(...) or lists, [....], definitions, when user input buttons are pressed.
// Suan ER 279, Suan ER 291

import React from 'react';
import { useState, useRef, useEffect/*, forwardRef*/ } from 'react';
import { /*useEvalOnce,*/ useEval, useObjAtt, useObjAtts } from './serverState.js';
import { Description } from './distributionPopup.js';
import { TitleBar } from './titleBar.js';
import { useKeyDown, useMouseDownCapture } from './miscTools.js';
import ContentEditable from 'react-contenteditable';

import './styles/functionEditPopups.scss';
import { ExprToHTML } from './miscTools.js';

var gSetSequencePopupOid = null;
var gSetListPopupOid = null;

export function ShowSequencePopup(oid)
{
    if (gSetSequencePopupOid)
        gSetSequencePopupOid(oid);
}

export function ShowListPopup(oid,bListOfText)
{
    if (gSetListPopupOid)
        gSetListPopupOid(oid);
}

export function SequencePopup(props)
{
    const [oid, setOid] = useState(null);
    gSetSequencePopupOid = setOid;
    const [orig,] = useObjAtt(oid, '_original');

    if (!oid || !orig) return null;
    return <SequencePopup2 oid={orig.oid} setOid={setOid} />;
}

function StandardizedDateUnit(dateUnit)
{
    if (!dateUnit || !(typeof dateUnit === "string"))
        return dateUnit;

    if (dateUnit.toUpperCase() === 'WD')
        return 'WD';
    const ch = dateUnit[0];

    if (ch === 'H' || ch === 'S' || ch==='m') 
        return ch.toLowerCase();

    return ch.toUpperCase();
}

function SequencePopup2(props)
{
    const [title, ident] = useObjAtts(props.oid, ['_title', 'identifier']);
    const [, setDef] = useObjAtt(props.oid, 'definition');
    if (!ident || !title) return null;
    return <SequencePopup3 ident={ident} title={title} setDef={setDef} {...props} />
}
function SequencePopup3(props)
{
    const ident=props.ident;
    const templ = "Local x:= ParsedExprParameters(fixeddef of «ident»), fullPrecision: true, getFrom: Handle(«ident»);"+
                  "Local x2:=if SetContains(\\(['start', 'stop', 'step']), x.Parameter) "+
	              "   then NumberToText(x, fullPrecision: true, getFrom: Handle(«ident»))" +
	              "   else x;"+
                  "MakeJSON(x2, objects: x2.Parameter)";
    const expr = templ.replace(/«ident»/g, ident);
    const json = useEval(expr);
    const parsed = json && JSON.parse(json);
    if (!parsed) return null;
    return <SequencePopup4 parsed={parsed} {...props} />;
}
function SequencePopup4({setDef,setOid,parsed,title})
{
    const [start,setStart] = useState(parsed.start);
    const [end, setEnd] = useState(parsed.end);
    const [step, setStep] = useState(parsed.step);
    const [strict, setStrict] = useState(!!parsed.strict);
    const [dateUnit, setDateUnit] = useState(StandardizedDateUnit(parsed.dateUnit));

    const caption = "Edit Sequence for " + title;

    const stdDateUnits = [['Y', 'year'], ['Q', 'quarter'], ['M', 'month'], ['D', 'day'], ['WD', 'weekday'], ['h', 'hour'], ['m', 'minute'], ['s', 'second']];
    
    // **TO DO**: Ensure that inputs parse
    // Handle number formats with commas & currencies
    //   - Idea: Add query that tests if a numeric input parses, and returns the representation that can go into the definition when it does (e.g., no commas).
    //   -       Replace the numeric inputs with a control that subscribes to the query. It can check validity with every keystroke.
    //   -       The okay button can enable only once everything is valid.

    function onOK() 
    {
        let def = "Sequence(" + start + ',' + end;
        if (step) def += ',' + step;
        if (strict) def += ",strict:true";
        if (dateUnit) def += ",dateUnit:'" + dateUnit + "'";
        def += ')';

        setDef(def);
        setOid(null);
    }
    function onCancel() {
        setOid(null);
    }

    return (
        <div className="SequencePopup FunctionEditPopup Window">
            <TitleBar type="SequencePopup" title={caption} closer={onCancel} />
            <div className="SequenceSelector">
                <div id="Body">
                    <span className="ParamLabel" id="StartLabel">start</span> 
                        <input id="Start" type="text" value={start} onChange={(e)=>setStart(e.target.value)}/>
                    <span className="ParamLabel" id="EndLabel">end</span> 
                        <input id="End" type="text" value={end} onChange={(e) => setEnd(e.target.value)}/>
                    <span className="ParamLabel" id="StepLabel">step</span> 
                        <input id="Step" type="text" value={step} onChange={(e) => setStep(e.target.value)} />
                    <span className="ParamLabel" id="StrictLabel">strict</span> 
                        <input id="Strict" type="checkbox" checked={strict} onClick={(e)=>setStrict(!strict)} />
                    <span className="ParamLabel" id="DateUnitLabel">dateUnit</span> 
                        <select id="DateUnit" value={dateUnit} onChange={(e)=>setDateUnit(e.target.value)}>
                            <option id="null" value={null}/>
                            {stdDateUnits.map( ([code,name])=>(<option id={code} value={code}>'{code}' ({name})</option>) )}
                        </select>
                </div>
                <Description objName="Sequence" />
                <div id="Buttons">
                    <button className="Btn" id="OK" onClick={onOK}>OK</button>
                    <button className="Btn" id="Cancel" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

//================================================================================================================================
// Suan ER 291 
//------------


export function ListEditPopup(props)
{
    const [oid, setOid] = useState(null);
    gSetListPopupOid = setOid;
    const [orig,defType] = useObjAtts(oid, ['_original','definitionType']);

    if (!oid || !orig) return null;
    return <ListEditPopup2 oid={orig.oid} setOid={setOid} bListOfText={defType==="ListOfText"} />;
}

function ListEditPopup2({ oid, setOid, bListOfText })
{
    const [items,] = useObjAtt(oid, bListOfText ? "fixeddef" : "_ListItemExpr");
    if (!Array.isArray(items))
        return null;

    return <ListEditPopup3 origItems={items} bListOfText={bListOfText} oid={oid} setOid={setOid} />;
}
function ListEditPopup3({oid,setOid,origItems,bListOfText})
{
    const [title,] = useObjAtt(oid, '_title');
    const [items, setItems] = useState(origItems);
    const [, setDefinition] = useObjAtt(oid, 'definition');
    const [bOkWasPressed, setOkWasPressed] = useState(false);
    const [bCancelPendingEdits, setCancelPendingEdits] = useState(false);
    const [elementWithUnsavedEdits, setElementWithUnsavedEdits] = useState(null);

    const caption = "Edit List " + (bListOfText ? "of Text ":"") + "for " + title;

    useEffect(() => {
        if (bOkWasPressed && !elementWithUnsavedEdits) {
            const val = "[" + ((bListOfText ? items.map((item) => "'" + item.replace(/'/g, "''") + "'") : items).join(',')) + "]";
            setDefinition(val);
            setOid(null);
        }
    }, [bOkWasPressed, elementWithUnsavedEdits]);

    function onOK() {
        if (elementWithUnsavedEdits) {
            elementWithUnsavedEdits.blur();
        } 

        setOkWasPressed(true);
    }
    function onCancel() {
        setCancelPendingEdits(true);
        setElementWithUnsavedEdits(null);
        setOid(null);
    }

    return (
        <div className="ListEditPopup FunctionEditPopup Window" >
            <TitleBar type="ListPopup" title={caption} closer={onCancel} />
            <div className="ListEdit">
                <div id="Body">
                    <ListEditControl items={items} setItems={setItems} bListOfText={bListOfText} doCancel={onCancel} doOK={onOK}
                        setElementWithUnsavedEdits={setElementWithUnsavedEdits} bCancelPendingEdits={bCancelPendingEdits} setCancelPendingEdits={setCancelPendingEdits} /> 
                </div>
                <div id="Buttons">
                    <button className="Btn" id="OK" onClick={onOK}>OK</button>
                    <button className="Btn" id="Cancel" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
}


const imgDragSingle = new Image();
imgDragSingle.src = "img/dragsing.cur";
const imgDragMult = new Image();
imgDragMult.src = "img/dragmult.cur";

const ListEditControlEditableCell = React.forwardRef( ({ initialText, onFocus, onBlur }, ref)=>
{
    const [editText, setEditText] = useState(initialText);


    console.log("EditableCell. editText=", editText);

    function onKeyDown(e)
    {
        if (e.key === "Escape") {
            setEditText(initialText);
            e.current.blur();
            e.stopPropagation();
        }
        // Continue propagation
    }

    function onChange(ev)
    {
        setEditText(ev.target.value);
    }
    return (
        <ContentEditable className="EditContent"
            html={editText}
            onFocus={onFocus}
            onChange={onChange}
            onBlur={onBlur}
            innerRef={ref}
            onKeyDown={onKeyDown}
        />
    );
});

// This one selects all the text in a dom element that is currently being edited.
function selectAllEditText(editElement)
{
    if (document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(editElement);
        range.select();
    } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(editElement);
        selection.removeAllRanges();
        selection.addRange(range);
    }
}

function ListEditControl({ items, setItems, bListOfText, doCancel, doOK, setElementWithUnsavedEdits, bCancelPendingEdits, setCancelPendingEdits})
{
    //const [itemBeingEdited, setItemBeingEdited] = useState(null);
    const [anchorSelected, setAnchorSelected] = useState(null);       // 0-based
    const [lastSelected, setLastSelected] = useState(null);           // Could be greater or less than anchor
    const getKeyPressed = useKeyDown(false);
    const getMouseClick = useMouseDownCapture();
    const [bEditing, setEditing] = useState(false);
    const tableRef = useRef(null);
    const selectedCellRef = useRef(null);

    if (!Array.isArray(items))
        return <div>NON ARRAY: {JSON.stringify(items)}</div>; // should return null.  Some debugging stuff.

    function setCell(i,val)
    {
        if (val === '' && !bListOfText) val = "0";

        const newVal = [...items.slice(0, i), val, ...items.slice(i + 1)];
        setItems(newVal);
    }
    function SelectOne(i) {
        if (selectedCellRef && selectedCellRef.current)
            selectedCellRef.current.blur();

        setAnchorSelected(i);
        setLastSelected(i);
    }
    function DeselectAll() {
        SelectOne(null);
    }

    const bExactlyOneSelected = anchorSelected !== null && anchorSelected === lastSelected;
    const bMultipleSelected = anchorSelected != null && anchorSelected !== lastSelected;
    const selStart = Math.min(anchorSelected, lastSelected);
    const selStop = Math.max(anchorSelected, lastSelected);

    function onCellClick(i,e)
    {
        if (!e.shiftKey || anchorSelected === null) {
            setAnchorSelected(i);
        } 
        setLastSelected(i);
        e.preventDefault();
    }

    function AppendACell()
    {
        const newCell = items.length > 0 ? items[items.length-1] : <>&nbsp;</>;
        const newItems = [...items, newCell];
        setItems(newItems);
        SelectOne(newItems.length - 1);
    }
    function doInsert()
    {
        if (anchorSelected === null) return;
        const newCells = items.filter((val, i) => (i >= selStart && i <= selStop));
        const before   = items.filter((val, i) => (i<selStart));
        const after    = items.filter((val, i) => (i>=selStart));
        const newItems = [...before, ...newCells, ...after];
        setItems(newItems);
        
    }
    function doDeleteSelected()
    {
        if (anchorSelected === null) return;
        const newItems = items.filter((val, i) => (i < selStart || i > selStop));
        setItems(newItems);        
	setTimeout(DeselectAll, 100);
    }

    function doArrowDown(bShift, bCtrl)
    {
        if (bShift && anchorSelected !== null) {
            if (lastSelected + 1 < items.length)
                setLastSelected(bCtrl ? items.length - 1 : lastSelected + 1);
        } else if (bCtrl) {
            SelectOne(items.length - 1);
        } else if (bMultipleSelected) {
            SelectOne(selStop);
        } else if (bExactlyOneSelected && selStop + 1 < items.length) {
            SelectOne(anchorSelected + 1);
        } else if (bExactlyOneSelected) {
            AppendACell();
        }
    }
    function doArrowUp(bShift, bCtrl)
    {
        if (bShift && anchorSelected !== null) {
            if (lastSelected > 0)
                setLastSelected(bCtrl ? 0 : lastSelected - 1);
        } else if (bCtrl) {
            SelectOne(0);
        } else if (bMultipleSelected) {
            SelectOne(selStart);
        } else if (bExactlyOneSelected && selStart > 0) {
            SelectOne(selStart - 1);
        }
    }

    function doEnterKey(keyEvent)
    {
        //const bAlt = keyEvent.bAlt;
        if (bEditing) {
            if (keyEvent.preventDefault) keyEvent.preventDefault();
            if (keyEvent.altKey) {
                let enterKeyEv = new KeyboardEvent("Enter");
                keyEvent.target.trigger(enterKeyEv);
            } else {
                keyEvent.target.blur();
            }
        } else if (anchorSelected === null) {
            doOK();
        }
    }

    // Handle key presses
    const keyEvent = getKeyPressed();
    if (keyEvent) {
        processKeyEvent(keyEvent);
    } else {
        //const click = getMouseClick();
        //if (click) {
        //    processMouseClick(click)
        //}
    }

    function processKeyEvent(keyEvent)
    {
        let key = keyEvent.key;
        if (key === 'Escape') {
            setCancelPendingEdits(true);
            if (anchorSelected !== null) DeselectAll(); else doCancel();
        } else if (key === 'Enter') {
            doEnterKey(keyEvent);
        } else if (key === 'ArrowDown') {
            doArrowDown(keyEvent.shiftKey, keyEvent.ctrlKey);
        } else if (key === 'ArrowUp') {
            doArrowUp(keyEvent.shiftKey, keyEvent.ctrlKey);
        } else if (!bEditing) {
            if (key === 'Backspace' || key === 'Delete') {
                doDeleteSelected();
            } else if (key === 'Insert' || (key === 'i' && keyEvent.ctrlKey)) {
                doInsert();
            } else if (anchorSelected !== null && anchorSelected === lastSelected) {
                const newVal = [...items.slice(0, anchorSelected), '', ...items.slice(lastSelected + 1)];
                setItems(newVal);
                if (selectedCellRef && selectedCellRef.current)
                    selectedCellRef.current.focus();

                //alert("key=" + (keyEvent.altKey ? "Alt+" : "") + (keyEvent.ctrlKey ? "Ctrl+" : "") + (keyEvent.shiftKey ? "Shift+" : "") + key);
            }
        }
    }

    //function processMouseClick(click)
    //{
    //    if (tableRef && !tableRef.current.contains(click.target)) {
    //        // Clear the selection if you click outside the list.
    //        DeselectAll();
    //    }
    //}

    // For re-ordering items
    function onStartDragging(ev) {
        ev.dataTransfer.setData("text/plain", "");
        ev.dataTransfer.setDragImage(bMultipleSelected?imgDragMult:imgDragSingle,32,32);
    }
    function onDrop(ev, i) {
        
    }
    function onDragOver(ev, i) {
        ev.preventDefault();
        
        const nDragging = selStop - selStart + 1;
        const dragged = items.filter((val, i) => (i >= selStart && i <= selStop));
        const before = items.filter((val, j) => (j < i && j<selStart));
        const after = items.filter((val, j) => (j > i && j > selStop));
        let newOrder, newStart;

        const bMovingDown = i > selStop;
        if (bMovingDown) {  // [before,dragged,middle,after> --> [before,middle,dragged,after]
            const middle = items.filter((val, j) => (j > selStop && j <= i));
            newOrder = [...before, ...middle, ...dragged, ...after];
            newStart = before.length + middle.length;
        } else { // moving up.  [before,middle,dragged,after] --> [before,dragged,middle,after]
            const middle = items.filter((val, j) => (j >= i && j < selStart));
            newOrder = [...before, ...dragged, ...middle, ...after];
            newStart = before.length;
        }
        setItems(newOrder);
        setAnchorSelected(newStart);
        setLastSelected(newStart + nDragging - 1);
    }

    function onClickAddButton() {
        console.log("onClickAddButton", selStop, bExactlyOneSelected, anchorSelected, items.length)

        if (anchorSelected === null || anchorSelected === items.length - 1) {
            console.log("append")
            AppendACell();
        }
        else if (anchorSelected !== null)
            doInsert();
    }

    const cls = "ListEditControl" + (bListOfText ? " ListOfText" : " List");
    function Cell(item,i)
    {
        //const startEdit = (itemBeingEdited === null) ? (e) => {alert("edit "+i); setItemBeingEdited(i)} : undefined;

        // Using contentEditable in react is dangerous if the content, item, has any sub-elements that have event handlers
        // or are otherwise controlled by react. That should be fine for simple cell content as we currently only have in
        // list cells.

        // LDC 2/15/2021 ER S612 -- added ability to insert/delete/reorder items
        let bSelected = anchorSelected !== null && selStart <= i && i <= selStop;

        let cls = null;
        if (bSelected) {
            cls = "Selected";
            if (i === selStart) cls += " FirstSelected";
            if (i === selStop)  cls += " LastSelected";
        }
        let bEditable = bSelected && bExactlyOneSelected;
        // Conflict between onClick+contentEditable and onDrag. When bEditable, I'd rather the focus event occur on
        // onMouseUp so the drag would have a chance to occur first. But it insists on focusing (and thus starting the
        // edit) on the onMouseDown event. The drag happens sometimes (apparently cancelling the edit), but it is hit-
        // and-miss. I couldn't find a way to make the focus occur on mouseUp. If you preventDefaults for onMouseDown,
        // it disables drag as well.
        let onClick = !bEditable ? (e) => onCellClick(i, e) : null;

        ////const isJSobj = (typeof (item) === 'object');
        ////const isIdent = !isJSobj && ('oid' in item);
        ////const isDate = !isJSobj && item.type === 'date';

        function onAcceptEdit(target)
        {
            if (!bCancelPendingEdits)
                setCell(i, target.innerText);

            setElementWithUnsavedEdits(null);
            setEditing(false);
        }
        function onFocus(ev)
        {
            console.log("Focusing on i=", i, ", item=", item);
            setEditing(true);
            setCancelPendingEdits(false);
            setElementWithUnsavedEdits(ev.target);

            selectAllEditText(ev.target);
        }
       
        return (
            <tr key={i}>
                <td className={cls}
                    onClick={onClick}
                    draggable={bSelected} onDragStart={bSelected ? onStartDragging : null}
                    onDrop={bSelected ? null : (e) => onDrop(e, i)}
                    onDragOver={bSelected ? (e) => e.preventDefault() : (e) => onDragOver(e, i)}
                >{bEditable ? (<ListEditControlEditableCell initialText={item} onFocus={onFocus} onBlur={(e) => onAcceptEdit(e.target)} ref={selectedCellRef} />)
                            : (<ExprToHTML val={item} />)     // LDC 4/29/2021 Bug S-741
                 }
                </td> 
            </tr>
        );
    }

    return (
        <>
        <table className={cls} ref={tableRef}>
            <tbody>
                {items.map(Cell)}
            </tbody>
        </table>
        <button className="Btn" id="AddItemToListBtn" onClick={onClickAddButton}>+ Add</button>
        </>
    );
}
