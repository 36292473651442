// index.js

import React from 'react';
import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import {useModel, getCtx} from './serverState.js';
//import { Outliner } from './outliner.js';
//import { WindowManager } from './windowManager.js';
import { AppWithSignIn } from './login.js';
//import { TryEffect } from './TryEffect.js';
//import { PeekAttribute } from './peekAttribute.js';
import { WindowManager } from './windowManager.js';
import { Acp1StyleLayout } from './acp1styleLayout.js';
import { TopToolbar, SaveAsDialog } from './Toolbar.js';
import { OnServerMessage, ClearObjAttCache, TrackSessionTimeoutWarning, useEval } from './serverState.js';
import { WebSocketConnection, AddOnCloseObserver, RemoveOnCloseObserver, WebSocket_Send } from './webSocketClient.js';
import { MsgBoxPopup, AskMsgPopup, AskMsgChoicePopup, FileUploader, MsgBoxPopupClient } from './EvalUIFunctions.js';
import { SpinningCubeWithBreakAndProgress, ProgressBarDialog } from './Waiting.js';
import { TechSupportForm } from './techSupport.js';

window.process = {};

// Function to detect if the browser is Firefox
function isFirefox() {
    return typeof InstallTrigger !== 'undefined';
}

// Function to show a warning message if the browser is Firefox
function checkBrowserAndDevice() {
    //
    // check FireFox
    if (isFirefox()) {
        const warningMessage = 'Sorry, Analytica Cloud Platform does not yet fully support Firefox. For the best experience, use Google Chrome, Microsoft Edge, or another Chromium-based browser.';
        alert(warningMessage);
    }

    //
    // check Mobile
    console.log("checkBrowserAndDevice() userAgent", navigator.userAgent);
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);
    //isMobile ? alert("detectDeviceType - Mobile Device") : alert("detectDeviceType - Desktop Computer");
    if (isMobile)
        alert("You are using  Analytica Cloud Platform (ACP) on what appears to be a mobile device.  It should be usable, but there may be occasional graphic glitches.")
}

// Call the function to show the warning message when the page is loaded
document.addEventListener('DOMContentLoaded', checkBrowserAndDevice);


function WebSocketDefaultURL()
{
    let port = window.location.port;
    if (port === "")
        port = window.location.protocol === "https:" ? "443" : "80";

    if (port==="3000" || port==="5000") {
        // 3000 is debug mode under Node.js
        // 5000 is built mode, but still under Node.js (or serve)
        return window.location.hostname + ":8080";
    }
    return window.location.hostname + ":" + port;
}

// LDC 6/3/2021 S-153.
function SessionWillTimeOutWarning({ timeOutEst, setTimeOutEst }) {
    const [secRemaining, setSecRemaining] = useState(null);

    useEffect(() => {

        function UpdateSec()
        {
            if (timeOutEst instanceof Date) {
                const now = new Date();
                setSecRemaining((timeOutEst - now).valueOf() / 1000);
            }
        }

        if (!(timeOutEst instanceof Date)) return;
        let timer = setTimeout(UpdateSec,1000);
        return () => clearTimeout(timer);
    },[timeOutEst,secRemaining]);

    if (timeOutEst === null) return null;
    if (timeOutEst === "timedout") return null;

    function clickIamHere(ev) {
        setTimeOutEst(null);
        WebSocket_Send({ fn: "ping" });
    }

    const minutes = Math.floor(Math.round(secRemaining) / 60);
    const seconds = Math.round(secRemaining) % 60;
    const minutesText = (minutes === 0) ? (<></>) : (minutes === 1) ? (<>1 minute </>) : (<>{minutes} minutes </>);
    const secondsText = (seconds === 1) ? (<>1 second</>) : (<>{seconds} seconds</>);

    const msg = secRemaining <= 0 ? (<>You session will time out now</>) : <>If you don't respond, your session will timeout in about {minutesText}{secondsText}.</>

    return (
        <div className="AboutToTimeOutSession">
            <div id="AboutToTimeOutText">You have been idle for quite a while. Are you still there?</div>
            <div id="AboutToTimeOutRemaining">{msg}</div>
            <button id="AreYouThereButton" onClick={clickIamHere} >OK</button>
        </div>
    );
}

export function App(props)
{
    const [stat, setStat] = useState("request connect");
    const [server, setServer] = useState(WebSocketDefaultURL());
    const [group, setGroup] = useState(null);
    const [browseOnly, setBrowseOnly] = useState(true);
    const [bShowOutliner, setShowOutliner] = useState(true);
    const [uiStyle, setUiStyle] = useState("ACP1");     // or MDI, ACP1
    const [resultTableVersion, setResultTableVersion] = useState("table");
    const [bBusy, setBusy] = useState(false);
    const [bForceShowCube, setForceShowCube] = useState(false);
    const [projectForToolbar, setProjectForToolbar] = useState(null);
    const [accountNameForToolbar, setAccountNameForToolbar] = useState(null);
    const [modelFilePath, setModelFilePath] = useState("");
    const [userRole, setUserRole] = useState(null);
    const [whichUiPortal, setWhichUiPortal] = useState(null);
    const [showProgressBarState, setShowProgressBarState] = useState(null);
    const [timeOutEst, setTimeOutEst] = useState(null);
    const [requestTechSupport, setRequestTechSupport] = useState(null);           // Set to the type of issue being reported, "bug", "ER", "support", or "serverDown"
    const [bShowSaveAsDlg, setShowSaveAsDlg] = useState(false);
    const [diagramForAcpStylesLib, setDiagramForAcpStylesLib] = useState(null);
    const [bModelFullyLoaded, setModelFullyLoaded] = useState(null);
    const [bPromptSaveOnClosing, setPromptToSaveOnClosing] = useState(false);
    const signOut = props.signOut;
    const [msgBoxInfoClient, setMsgBoxInfoClient] = useState(null);
    const [showCreateProjectForm, setShowCreateProject] = useState(false);  // Project Creation/Deletion
    const [noopTimer, setNoopTimer] = useState(null); // 1823 Add periodic heartbeat message on websocket
    const orphansOid = useEval("HandleFromIdentifier('orphans')")?.oid;

    const svrInfo = { server: server, setServer: setServer, group: group, setGroup: setGroup, status:stat, setStatus:setStat,
        browseOnly:browseOnly, setBrowseOnly:setBrowseOnly, bShowOutliner:bShowOutliner, setShowOutliner:setShowOutliner,
        uiStyle:uiStyle, setUiStyle:setUiStyle,
        resultTableVersion:resultTableVersion, setResultTableVersion:setResultTableVersion,
        bBusy, setBusy, setForceShowCube, bForceShowCube, projectForToolbar, setProjectForToolbar, accountNameForToolbar, setAccountNameForToolbar, userRole, setUserRole, whichUiPortal, setWhichUiPortal,
        setModelFullyLoaded, bModelFullyLoaded, bPromptSaveOnClosing, setPromptToSaveOnClosing, setMsgBoxInfoClient, msgBoxInfoClient, orphansOid, showCreateProjectForm, setShowCreateProject, ...props
    };

    useEffect(() => {
        TrackSessionTimeoutWarning(setTimeOutEst);
        return () => { TrackSessionTimeoutWarning(null); };
    },[setTimeOutEst]);

    useEffect(() => {

        return ClearObjAttCache;

    }, [group]);

    useEffect(() => {
        console.log("set noop timer")
        if (document.acpServerConfig.EW_1823_Heartbeat_noop_message)
            setNoopTimer(setInterval(noopPing, document.acpServerConfig.EW_1823_Heartbeat_noop_message * 1000));
        return (() => clearTimeout(noopPing));
    }, []);

    // LDC 6/3/2021 S-153
    if (timeOutEst === 'timedout') {
        setTimeOutEst(null);
        signOut();
        return;
    }

    // If a progress bar dialog is visible, hide the cube. Except when the ShowProgressBar text & caption are both blank, then just include
    // the progress as part of the cube.
    const progBarState = showProgressBarState && (showProgressBarState.caption || showProgressBarState.text) ? showProgressBarState : null;
    const cubeProg = !progBarState && showProgressBarState ? showProgressBarState.p : null;
    const bCubeBusy = (bBusy && !progBarState) || (stat === "connecting" || stat === "request connect");

    return (
        <>
            <div id="modal-root" style={{ zIndex: 2147483647 }} />
            <SessionWillTimeOutWarning timeOutEst={timeOutEst} setTimeOutEst={setTimeOutEst} />
            <MsgBoxPopup {...svrInfo} />
            <MsgBoxPopupClient msgBoxInfoClient={msgBoxInfoClient} setMsgBoxInfoClient={setMsgBoxInfoClient} />
            <AskMsgPopup {...svrInfo} />
            <AskMsgChoicePopup {...svrInfo} />
            <ProgressBarDialog state={progBarState} setState={setShowProgressBarState}/>
            <FileUploader {...svrInfo} />
            <TopToolbar {...svrInfo} projectForToolbar={projectForToolbar} setRequestTechSupport={setRequestTechSupport} setShowSaveAsDlg={setShowSaveAsDlg} diagramForAcpStylesLib={diagramForAcpStylesLib} modelFilePath={modelFilePath} />
            {uiStyle==="MDI" && (<WindowManager {...svrInfo} />)}
            {uiStyle === "ACP1" && (<Acp1StyleLayout {...svrInfo} diagramForAcpStylesLib={diagramForAcpStylesLib} setDiagramForAcpStylesLib={setDiagramForAcpStylesLib} modelFilePath={modelFilePath} setModelFilePath={setModelFilePath}  />) }
            <WebSocketConnection onMessage={OnServerMessage} {...svrInfo} />
            <SpinningCubeWithBreakAndProgress bBusy={bCubeBusy} progress={cubeProg} setBusy={setBusy} bForceShowCube={bForceShowCube} />
            <TechSupportForm {...svrInfo} type={requestTechSupport} setType={setRequestTechSupport} />
            {bShowSaveAsDlg && <SaveAsDialog setShow={setShowSaveAsDlg} /> /* LDC 6/25/2021 ER S-872 */}
        </>
    );
}

// 1823 Add periodic heartbeat message on websocket
function noopPing() {
    console.log("noop");
    WebSocket_Send({ "fn": 'noop' });
}

export function SkipLogin() {
    //return true;  // may set to true when debugging

    return (document?.acpServerConfig?.authenticationMethod === 'apacheWindows');       // LDC 1/28/2022 - Changed this to default to 'firebase' behavior
}

// Get eVite parameters
let urlParams = new URLSearchParams(window.location.search);

function Footer(props) {
    return (
        <div id="FooterWrapper" >
            <div className="Footer">
                <div className="LeftLogoHolder"><img src="img/footerAnalyticaLogo.png" alt="Analytica" height="32"/></div>
                <div className="RightLogoHolder"><img src="img/footerLuminaLogo.png" alt="Lumina"  height="32"/></div>
            </div>
        </div>
    );
}

function AppWithEVitePlayer(props)
{
    const [isEvite,setIsEvite] = useState(/*window.location.pathname.toLowerCase() === "/view" &&*/ urlParams.has('invite') && urlParams.has('code'));

    function OnWebSocketCloses()
    {
        resetEvite();
    }

    useState(() => {
        AddOnCloseObserver(OnWebSocketCloses);

        return ()=>{
            RemoveOnCloseObserver(OnWebSocketCloses);
        }
    },[OnWebSocketCloses,isEvite]);

    function resetEvite(b)
    {
        if (!isEvite) return;
        setIsEvite(false);
        if (window.history.replaceState)
            window.history.replaceState({}, null, window.location.origin);
    }

    const eViteParams = isEvite ? { isEvite: true, invite: urlParams.get("invite"), code: urlParams.get("code"), subName: urlParams.get("subName"), setIsEvite:resetEvite } : { isEvite: false };

    if (SkipLogin() || isEvite) {
        return <App {...eViteParams} />;
    } else {
        return <AppWithSignIn {...eViteParams} />;
    }
}

ReactDOM.render(<AppWithEVitePlayer />, document.getElementById('root'));
