import React from 'react';
import { useEffect, useState } from 'react';
import { SkipLogin } from './index.js';

import './styles/WebSocketClient.scss'; 

var gWebSocket = null;

export function IsWebSocketReady()
{
    return gWebSocket !== null && gWebSocket.readyState !== 0;
}

export function WebSocket_Close(setStat) {
    if (gWebSocket !== null) {
        gWebSocket.close();
        gWebSocket = null;
    }
    setStat("closed");
}

var gWebSocket_OnCloseObservers = new Set();
// An observer needs to be a zero-parameter function with no return value.

export function AddOnCloseObserver(observer)
{
    gWebSocket_OnCloseObservers.add(observer);
}
export function RemoveOnCloseObserver(observer)
{
    gWebSocket_OnCloseObservers.delete(observer);
}

function WebSocket_Connect(uri, setStat, onMessage, onError)
{
    if (gWebSocket != null) WebSocket_Close(setStat);

    setStat("connecting");
    gWebSocket = new WebSocket(uri);

    gWebSocket.onopen = function (ev) {
        setStat("connected");
       // console.log("WebSocket connection opened");
        onMessage({ fn: "socketOpened", socket: gWebSocket })
        //console.log("WebSocket protocol is", JSON.stringify(gWebSocket.protocol));
    }
    gWebSocket.onclose = function (ev) {
        setStat("closed");
   //     console.log("WebSocket connection closed")
        for (const observer in gWebSocket_OnCloseObservers)
            observer();
    }
    gWebSocket.onmessage = function (ev) {
        if (ev.data !== undefined) onMessage(ev.data);
    };
    gWebSocket.onerror = onError;
}

export function WebSocket_Send(message)
{
    if (gWebSocket === null || gWebSocket.readyState === 0)
        return;

    if (typeof message == "string") {
        gWebSocket.send(message);
    } else {
        const txt = JSON.stringify(message);
        gWebSocket.send(txt);
    }
}

export function WebSocketConnection(props)  
{
    const [firstAttemptNumber, setFirstAttemptNumber] = useState(null);
    const [numberOfAttempts, setNumberOfAttempts] = useState(0);
    const numberSuanServers = document?.acpServerConfig?.numberSuanServers;

    if (firstAttemptNumber === null) {
        const firstAttemptNum = Math.floor(Math.random() * numberSuanServers);
        console.log(firstAttemptNum);
        setFirstAttemptNumber(firstAttemptNum);
    }

    let suanServerNumber = firstAttemptNumber + numberOfAttempts;
    if (suanServerNumber >= numberSuanServers) suanServerNumber = suanServerNumber - numberSuanServers;

 //   console.log("firstAttemptNumber", firstAttemptNumber);
  //  console.log("suan server number", suanServerNumber);
   // console.log("numberOfAttempts", numberOfAttempts);

    if (firstAttemptNumber === null)
        return "";

    return <WebSocketConnection1 {...props} suanServerNumber={suanServerNumber} numberOfAttempts={numberOfAttempts}
        setNumberOfAttempts={setNumberOfAttempts} numberSuanServers={numberSuanServers}/>
}

function WebSocketConnection1(props)
{
    const stat = props.status;
    const setStat = props.setStatus;
    const email = props.user ? props.user.email : "";
    const idToken = props.userIdToken;
    const uid = props.user ? props.user.uid : "";
    const suanServerNumber = props.suanServerNumber;

    //const group = props.group;
    /* test code, ok to remove
    console.log("window.location.search", window.location.search)
    const search = "?invite=4014&code=4451739808315829042&data1=xyz&data2=abc";
    let joinReqTest = "/view0?invite=111&code=222";
    const qsParams = new URLSearchParams(search);
    console.log('qsParams', qsParams)
    for (const [qsKey, qsValue] of qsParams) {
        const lcKey = qsKey.toLowerCase();
        if (lcKey !== "useridtoken" && lcKey !== "uid" && lcKey !== "authmethod" && lcKey !== "auth" && lcKey !== "invite" && lcKey !== "code" && lcKey !== "subName")
            joinReqTest = joinReqTest.concat("&", qsKey, '=', qsValue);
    }
    console.log("invite join req", joinReqTest);
    */

    let joinReq = "";
    if (props.isEvite) {
        joinReq = "/view" + suanServerNumber + "?invite=" + props.invite + "&code=" + props.code;
        if (props.subName) joinReq += "&subName=" + props.subName;          // LDC 12/11/2020 Suan bug 443
        const qsParams = new URLSearchParams(window.location.search);
        for (const [qsKey, qsValue] of qsParams) {
            const lcKey = qsKey.toLowerCase();
            if (lcKey !== "useridtoken" && lcKey !== "uid" && lcKey !== "authmethod" && lcKey !== "auth" && lcKey !== "invite" && lcKey !== "code" && lcKey !== "subName")
                joinReq = joinReq.concat("&", qsKey, '=', qsValue);
        }
        console.log("invite join req", joinReq);
    } else if (SkipLogin()) {
        if (document?.acpServerConfig?.authenticationMethod === 'apacheWindows') {
            const windowsUserId = getWindowsUserId();
            joinReq = "/login" + suanServerNumber + "?email=" + windowsUserId.toLowerCase() + "&userIdToken=0";
        } else joinReq = "/new";
    } else if (props.group) {
        joinReq = "/join?group=" + props.group;
    } else {
        //joinReq = "/new";
        joinReq = "/login" + suanServerNumber + "?email=" + email + "&userIdToken=" + idToken + "&uid=" + uid + "&authMethod=" + props.authMethod;
        const qsParams = new URLSearchParams(window.location.search);
        for (const [qsKey,qsValue] of qsParams) {
            const lcKey = qsKey.toLowerCase();
            if (lcKey !== "useridtoken" && lcKey !== "uid" && lcKey !== "authmethod" && lcKey !== "auth")
                joinReq = joinReq.concat("&", qsKey, '=', qsValue);
        }
    }
    const uri = (window.location.protocol==="https:"?"wss://":"ws://") + props.server + joinReq;

   // console.log("web socket uri", uri);
   // console.log("stat", stat)

    useEffect(() => {

   //     console.log("useEffect stat", stat)

        function onError(ev) {
            console.log("***on error stat", stat)
            console.log("*** on error props.numberOfAttempts", props.numberOfAttempts)
            console.log(props.numberOfAttempts > 0)
            if (props.numberOfAttempts >= props.numberSuanServers - 1) {
                let msg = "WebSocket Error: ";

                if (stat === "request connect") {       // LDC 10/14/2020 Suan ER 298
                    msg += "Unable to establish a connection to the Analytica Cloud Platform server.\n"
                        + "\nTry refreshing this page in your browser.\n\nIf refreshing the page doesn't fix the problem, that probably means that the ACP server is down.";
                    if ((/wss?:\/\/[^.]+\.(lumina\.com)|(analytica\.com)|(analyticacloud\.com)|(analytica\.cloud)/i).test(uri))
                        msg += " Report to acp@lumina.com.";
                    else if ((/wss?:\/\/[^/]*localhost/i).test(uri))
                        msg += " Since you are running the server on your own computer, check that your Suan.exe process is running.";

                } else if (stat === "connecting") {
                    msg += "Unable to complete connection to Server";
                } else {
                    msg += 'An error on the connection to the server occurred while in the "' + stat + '" phase.';
                }
                console.log(msg);
                alert(msg);
            } else {
                console.log("****** retry connect ******")
                if (props.numberOfAttempts <= props.numberSuanServers - 1) {
                    props.setNumberOfAttempts(props.numberOfAttempts + 1);
                    console.log("********on error uri", uri);
                    setStat("request connect");
                    //WebSocket_Connect(uri, setStat, props.onMessage, onError);
                }
            }
        }



        if (stat === "request connect") {
            console.log("calling WebSocket_Connect not error")
            WebSocket_Connect(uri, setStat,props.onMessage, onError);
        } else if (stat === "request close") {
            console.log("***")
            WebSocket_Close(setStat);
        }
    }, [stat,setStat,uri,props.onMessage]);

    //const icon = (<i id="serverConnectionIcon" className="fab fa-mixcloud Icon"></i>);
    let id = { stat };
    if (stat === "request connect") id = "connecting";
    return (<div className="WebSocketConnection" style={{ diplay: "none" }} id={id} >{/*icon*/}</div>);

    //if (stat === "request connect") {
    //    return (
    //        <div className="WebSocketConnection" id="connecting">Connecting to { uri }...</div >
    //    );

    //} else if (stat === "connected") {
    //    return <div className="WebSocketConnection" id="connected">Connected to {uri}</div>
    //} else {
    //    return <div className="WebSocketConnection" id="otherStat" >{stat}</div>
    //}
}

export function getWindowsUserId() {
    let windowsUserId = document?.getElementById("acp_user_name")?.innerHTML;
    if (windowsUserId.indexOf("\\") !== -1) {
        const windowsUserIdSplit = windowsUserId.split("\\");
        windowsUserId = windowsUserIdSplit[windowsUserIdSplit.length - 1].toLowerCase();
    }
    return windowsUserId;
}
