// frameNode.js

import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { useObjAtts, useObjAtt, useCloudStyles } from './serverState.js';
import { GraphForViewType } from './graph.js';
import { ResultTable } from './resultTable.js';
import { TableByFullTable } from './editTable.js';
import { ObjectView } from './objectWindow.js';
import { EmbeddedDiagram } from './diagram.js';

export function EmbeddedEditTable(props)
{
    //console.log("*EmbeddedEditTable", props)
    const [q, setQ] = useState({ req: 'editTable', oid: props.oid });
    const [width,height] = useObjAtts(props.frameNodeOid, ["nodeWidth","nodeHeight"]);
    const wndSize = { width: props.width || props.minWidth ? props.width : isFinite(width)?2*width:undefined, 
                      height: isFinite(height)?2*height:undefined,
                      minWidth:props.minWidth };
    const bNoTitle = props.noTitle;

    if (!q || q.oid !== props.oid)
        setQ({ req: 'editTable', oid: props.oid });             // LDC 5/5/2020 Suan bug 23

    const showDesc = props.bTallNode === 1 ? getDescriptionHeightPercentage(props?.frameNodeCloudStyles?.show_description, height, props?.topCloudStyles?.show_description, props?.cloudStylesOrig?.show_description) : props.showDescription;

    return <TableByFullTable wndSize={wndSize} view="edit" q={q} setQ={setQ} oid={props.oid} noTitle={bNoTitle} bTallNode={props.bTallNode} isFrameNode={props.isFrameNode} nodeClass0={props.nodeClass0} zoom={props.zoom} mouseEntered={props.mouseEntered} onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut}
        cloudStyles={props.cloudStyles} cloudStylesOrig={props.cloudStylesOrig} showPivoters={props.showPivoters} showDescription={showDesc} frameNodeCloudStyles={props.frameNodeCloudStyles} topCloudStyles={props.topCloudStyles} onMouseOverNode={props.onMouseOverNode} onMouseOutNode={props.onMouseOutNode} setPromptToSaveOnClosing={props.setPromptToSaveOnClosing}
        setShowObjectView={props.setShowObjectView} frameNodeOid={props.frameNodeOid}/>;
}

export function FrameNodeContent(props)
{
    console.log("FrameNodeContent", props);
    const frameNodeOid = props.oid;
    const [content, nodeHeight] = useObjAtts(frameNodeOid, ["_embeddedContent", "nodeHeight"]);           // Att_FrameNodeShowing
    const [cloudStyles, setCloudStylesFrameNode] = useObjAtt(frameNodeOid, "_CloudPlatformStyles");
    const [toggleTableGraph, setToggleTableGraph] = useState(false);
    const frameNodeContents = props.frameNodeContents

    console.log("frameNodeContents _embeddedContent", props.frameNodeContents, content);
    useEffect(() => {

        if (!frameNodeContents && content?.obj?.oid) {
            props.setFrameNodeContents([{ frameNodeOid: frameNodeOid, frameNodeContent: content.obj.oid, frameView: content.view }])
        }
        else if (frameNodeContents && content?.obj?.oid) {
            for (let i = 0; i < frameNodeContents.length; i++) {
                if (frameNodeOid === frameNodeContents[i].frameNodeOid) {
                    if (content.obj.oid === frameNodeContents[i].frameNodeContent) {
                        //console.log("frameNodeContents have NOT changed");
                        return;
                    }
                    else {
                        //console.log("frameNodeContents HAVE CHANGED")
                        const newFrameNodeContents = [...frameNodeContents];
                        newFrameNodeContents[i] = { frameNodeOid: frameNodeOid, frameNodeContent: content.obj.oid, frameView: content?.view };
                        props.setFrameNodeContents(newFrameNodeContents);
                        return;
                    }
                }
            }
            // no matching frame node found
            //console.log("no matching frame node found, adding new element to frameNodeContents")
            const newFrameNodeContents = [...frameNodeContents];
            newFrameNodeContents.push({ frameNodeOid: frameNodeOid, frameNodeContent: content.obj.oid });
            props.setFrameNodeContents(newFrameNodeContents);
        } 
    })

    if (!content || !content.obj)
        return "";    

    function toggleTableGraphResult() {
        //alert("toggle")
        setToggleTableGraph(!toggleTableGraph);
    }

    let showGraph = content.graph;
    if (showGraph === 1 && toggleTableGraph)
        showGraph = false;
    if (showGraph === 0 && toggleTableGraph)
        showGraph = true;

    return <FrameNodeContent1 {...props} showGraph={showGraph} cloudStyles={cloudStyles} frameNodeCloudStyles={cloudStyles} content={content} frameNodeOid={frameNodeOid}
        toggleTableGraphResult={toggleTableGraphResult} nodeHeight={nodeHeight} setToggleTableGraph={setToggleTableGraph} topCloudStyles={props.cloudStyles}
        setCloudStylesFrameNode={setCloudStylesFrameNode} frameNodeOid={frameNodeOid}
    />
}

export function FrameNodeContent1(props) {
    const [oidOrig,] = useObjAtt(props.content.obj.oid, "_original");
    return <FrameNodeContent2 {...props} oidOrig={oidOrig?.oid}/>
}

export function getDescriptionHeightPercentage(showDescFrame, nodeHeight, showDescGlobal, showDescVar) {

    let descAsPercentage = showDescVar ? showDescVar : (showDescFrame ? showDescFrame : (showDescGlobal ? showDescGlobal : 30)); // LDC 8/14/2020 ER 160. Default to 30%

    if (descAsPercentage) {
        const pct = typeof (descAsPercentage) === "string" ? parseInt(descAsPercentage) : descAsPercentage;
        if (typeof (pct) === "number" && 0 < pct && pct <= 100)
            descAsPercentage = Math.round(pct * nodeHeight * 2 / 100);            // *2 b/c the stored nodeHeight is half the pixel height of a node
        else
            descAsPercentage = 0;
    }

    return descAsPercentage;
}

function FrameNodeContent2(props) {
    //console.log("FrameNodeContent2", props)
    const showGraph = props.showGraph;
    const bDebugging = false;
    const frameRef = useRef();
    const cloudStyles = props.cloudStyles;
    const content = props.content;
    const contentOid = content.obj.oid;
    const cloudStylesOrig = useCloudStyles(props.oidOrig);
    const show_index_menus = cloudStyles && cloudStyles.show_index_menus === "yes";
    const show_title = !cloudStyles || cloudStyles.show_title !== "no";
    const toggleTableGraphResult = props.toggleTableGraphResult;
    const nodeHeight = props.nodeHeight;
    const setToggleTableGraph = props.setToggleTableGraph;
    const frameNodeOid = props.frameNodeOid;
    const [contentOidSaved, setContentOidSaved] = useState(contentOid);
    const [viewType, setViewType] = useObjAtt(contentOid, "_resultViewType");
    const [contentView, setContentView] = useState(null);

    let contentOidBefore = contentOidSaved;
    if (contentOidSaved instanceof Object) {
        console.log("contentOidSaved is object, should be number, so it's a bug...");
        //for (let x in contentOidSaved) console.log(x + " " + contentOidSaved[x]);
        contentOidBefore = contentOidSaved[0];
    }

    if (contentOid !== contentOidBefore) {
        console.log("frame node content has  changed");
        setContentOidSaved(contentOid);
        setToggleTableGraph(false);
    }

    const show_description = getDescriptionHeightPercentage(cloudStyles?.show_description, nodeHeight, props?.topCloudStyles?.show_description, cloudStylesOrig?.show_description);

    useEffect(() => {
        //console.log("use effect once frame", content?.view);
        if (content?.view !== 'OBJV')
            setContentView(content.view);
    })

    const isEditTable = contentView === 'DFNM' ? true : false;

    if (showGraph) {
        if (!viewType) return null;
        return (
            <div className="nodeContent Frame" onClick={ev => ev.stopPropagation()} onDrag={ev => ev.stopPropagation()} ref={frameRef} >
                {bDebugging && <div style={{ position: "absolute", zIndex: "10", right: "0" }}>{JSON.stringify(cloudStyles)}</div>}
                <GraphForViewType viewType={viewType} setView={setViewType} oid={contentOid} containerRef={frameRef} showPivoters={show_index_menus} showTitle={show_title} nodeClass0={props.nodeClass0}
                    showDescription={show_description} toggleTableGraphResult={toggleTableGraphResult} setToggleTableGraph={setToggleTableGraph} proactive={true} isFrameNode={props.isFrameNode} topCloudStyles={props.topCloudStyles} frameNodeCloudStyles={props.frameNodeCloudStyles}
                    onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} onMouseOverNode={props.onMouseOver} onMouseOutNode={props.onMouseOut} setCloudStylesFrameNode={props.setCloudStylesFrameNode} mouseEntered={props.mouseEntered} zoom={props.zoom} msgBoxInfoClient={props.msgBoxInfoClient} setMsgBoxInfoClient={props.setMsgBoxInfoClient}
                    setForceShowCube={props.setForceShowCube} bForceShowCube={props.bForceShowCube} setDescriptionFrameNode={props.setDescriptionFrameNode}
                    frameNodeOid={props.frameNodeOid}
                />
            </div>
        );
    } else if (content.view === 'DFNM') {
        // Edit table 
        // Missing parameters: wndSize
        return <EmbeddedEditTable {...props} frameNodeOid={frameNodeOid} isFrameNode={props.isFrameNode} oid={contentOid} showPivoters={show_index_menus} showTitle={show_title} showDescription={show_description} nodeClass0={props.nodeClass0} cloudStylesOrig={cloudStylesOrig} />;
    } else if (content.view === 'OBJV') {           // LDC 3/25/2021 ER S-687
        return (<ObjectView oid={contentOid} isFrameNode={true} nodeClass0={props.nodeClass0} frameNodeOid={frameNodeOid} mouseEntered={props.mouseEntered} isEditTable={isEditTable}/>);
    } else if (content.view === 'DIAG') {           // LDC 3/25/2021 ER S-687
        return (<EmbeddedDiagram id={contentOid} />);
    } else {
        // Result table

        return (
            <div className="nodeContent Frame">
                {/*frame node content for oid={frameNodeOid}: {JSON.stringify(content)}. Showing result table. Not fully implemented.*/}
                <ResultTable {...props} oid={contentOid} showPivoters={show_index_menus} showTitle={show_title} showDescription={show_description} toggleTableGraphResult={toggleTableGraphResult} cloudStylesOrig={cloudStylesOrig} proactive={false} />
            </div>
        );
    }

}
