import React from 'react';
import {useState} from 'react';
import { useTopLevelOid, useObjAtt, rawSetObjAtt, CacheEntries } from './serverState.js';


function DisplayAttList(props)
{
    return props.atts.join(', ');
}

function DisplayCacheEntry(props)
{
    const oid = props.oid === undefined ? "undefined" : props.oid;
    const thisKey = props.oid !== undefined ? props.oid : -1;
    const attMap = props.atts;
    return (<li key={thisKey}><b>{oid}:</b><DisplayAttList atts={Array.from(attMap.keys())}/></li>);
}

function DisplayCacheEntries(props)
{
    const cache = CacheEntries();

    return (
        <ul>
            {Array.from(cache).map( ([i,k])=>(<DisplayCacheEntry key={i!==undefined?i:-1} oid={i} atts={k}/>) )}
        </ul>
    );
}

export function PeekAttribute(props) 
{
    const [topOid,] = useTopLevelOid();
    const [oid,setOid] = useState(topOid);
	const [att,setAtt] = useState('identifier');
    const [curVal, setCurVal] = useObjAtt(oid, att);
    const [typedVal, setTypedVal] = useState(curVal);
    const [printedCache, setPrintedCache] = useState("");

    let val = typedVal;
    if (val === undefined && curVal !== undefined)
        val = curVal;
    if (val === undefined)
        val = '';
    if (val === null)
        val = 'null';

	function setIt(event)
	{		
        let newVal = isNaN(Number(typedVal)) ? typedVal : Number(typedVal);
        setCurVal(newVal);
    }
    function exogSet(event)
    {
        let newVal = isNaN(Number(typedVal)) ? typedVal : Number(typedVal);
        rawSetObjAtt(oid, att, newVal);
        setTypedVal(undefined);
    }
	function onOidChange(event) { 
		setOid(parseInt(event.target.value));
		setTypedVal(undefined);
	}
	function onAttChange(event) {
		setAtt(event.target.value);
		setTypedVal(undefined);
	}
	function refreshCacheView(event)
    {
        setPrintedCache((<DisplayCacheEntries />));
    }

	let buttonStyle = {visibility:(typedVal!==undefined)?"visible":"hidden"};
	
	return (
		<div id="PeekAttribute" key="PeekAttribute">
			Oid: <input id="Oid" type="number" value={oid} onChange={onOidChange}/><br/>
			Att: <input id="Att" type="text" value={att} onChange={onAttChange}/><br/>
			Val: <input id="Val" value={val} onChange={(event)=>setTypedVal(event.target.value)}/>
            <button onClick={setIt} style={buttonStyle}>Set</button>
            &nbsp;<button onClick={exogSet} style={buttonStyle}>Exogenous Set</button>
            <br/><br/>
                <button onClick={refreshCacheView} >Refresh cache list</button>
                {printedCache}
            
		</div>
	);
}