import React from 'react';
import { useState } from 'react';
import { useObjAtts } from './serverState.js';
import './styles/Hierarchy stripe.scss';
import { isTopSideTabNav } from './diagram.js';
export function HierarchyStripe(props) {

    let className = "ModuleHierarchy";

    if (props.cloudStyles.show_tabs !== "no" && props.cloudStyles.show_toolbar !== "no") {
        //style.width = "2500px"; // = { width: "2500px", height: "2000px", backgroundColor: bgColor };}
    }
    else {
        className += " NoAcp1Tabs";

        if (!isTopSideTabNav(props?.cloudStyles?.navigation_style)) className += " NoTopSideTabs";

        if (props.cloudStyles.use_top_diagram_size === "yes")
            className += " UseTopDiagramSize";
    }

    if (props.cloudStyles.navigation_style === "side_tabs" || props.cloudStyles.navigation_style === "two_side_tabs")
        className += " SideTabs";

    return (
        <div className={className} style={{ backgroundColor: props.bgColor }} >
            <HierarchyLevel moduleOid={props.moduleId} onSelect={props.onSelect} navTabsFirstLevel={props.navTabsFirstLevel} navTabsSecondLevel={props.navTabsSecondLevel} />
        </div>
    )
}


function HierarchyLevel(props) {
    const [title, isin, id] = useObjAtts(props.moduleOid, ['_title', 'isin', 'identifier']);
    const [isSelected, setIsSelected] = useState(false);

    const navTabsFirstLevel = props.navTabsFirstLevel;
    const navTabsSecondLevel = props.navTabsSecondLevel;

    // don't show modules that are already showing as tabs in Hierarchy Strip, max's spec
    let bIsInNavTab = false;
    if (navTabsFirstLevel != null && navTabsFirstLevel.length) {
        for (let i = 0; i < navTabsFirstLevel.length; i++) {
            if (isin === navTabsFirstLevel[i]) {
                //console.log("nav tab encountered");
                bIsInNavTab = true;
            }
        }
    }
    if (navTabsSecondLevel != null && navTabsSecondLevel.length) {
        for (let i = 0; i < navTabsSecondLevel.length; i++) {
            if (isin === navTabsSecondLevel[i]) {
                //console.log("nav tab encountered");
                bIsInNavTab = true;
            }
        }
    }

    function onMouseOver() {

        setIsSelected(true);
    }

    function onMouseOut() {
        setIsSelected(false);
    }

    function onClick(ev) {
        props.onSelect(props.moduleOid);
        ev.stopPropagation();
    }

    let levelTitle = "LevelTitle";
    if (isSelected)
        levelTitle += " Selected";

    return (
        <>
            {(isin && !bIsInNavTab) && <HierarchyLevel moduleOid={isin} onSelect={props.onSelect} navTabsFirstLevel={navTabsFirstLevel} navTabsSecondLevel={navTabsSecondLevel} />}
            {(isin && !bIsInNavTab) && <span className="LevelSep"> &#x25b6;  </span>}
            {id !== "Orphans" && <span className={levelTitle} onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>{title}</span>}
        </>
    );
}
