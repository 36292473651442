// EvalUIFunctions.jsx  -- Implements client-side of UI functions like MsgBox(..) etc.
//

import React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';

import { AddServerMessageReceiver, useTopLevelOid, useCloudStyles, useObjAtt } from './serverState.js';
import { WebSocket_Send } from './webSocketClient';
import { Lineify } from './miscTools.js';
import { ChoiceDropdownMenu } from './choice.js';

import './styles/EvalUIFunctions.scss';

var gSetMsgBoxInfo = null;
var gSetAskMsgInfo = null;
var gSetAskMsgChoiceInfo = null;
var gShowWindowHook = null;         // Window manager should set these to a function(oid,windowType) that shows or hides
var gHideWindowHook = null;
var gSetUploadFileHook = null;      // LDC 11/11/2020 Suan ER 350

function EvalMsgBox(json)
{
    if (gSetMsgBoxInfo !== null)
        gSetMsgBoxInfo(json);
}
function EvalAskMsg(json)
{
    if (gSetAskMsgInfo)
        gSetAskMsgInfo(json);
}
function EvalAskMsgChoice(json)
{
    if (gSetAskMsgChoiceInfo)
        gSetAskMsgChoiceInfo(json);
}

function EvalShowWindow(json)
{
    if (gShowWindowHook)
        gShowWindowHook(json.oid, json.windowType)
}
function EvalCloseWindow(json)
{
    if (gHideWindowHook)
        gHideWindowHook(json.oid, json.windowType)
}
export function SetShowWindowHooks(showFn,closeFn)
{
    gShowWindowHook = showFn;
    gHideWindowHook = closeFn;
}
function EvalUploadFile(json)                   // LDC 11/11/2020 Suan ER 350
{
    if (gSetUploadFileHook !== null)
        gSetUploadFileHook(json);
}

AddServerMessageReceiver('MsgBox', EvalMsgBox);
AddServerMessageReceiver('AskMsgText', EvalAskMsg);
AddServerMessageReceiver('AskMsgNumber', EvalAskMsg);
AddServerMessageReceiver('AskMsgChoice', EvalAskMsgChoice);
AddServerMessageReceiver('ShowWindow', EvalShowWindow);
AddServerMessageReceiver('CloseWindow', EvalCloseWindow);
AddServerMessageReceiver('UploadFile', EvalUploadFile);

export function MsgBoxPopup(props)
{
    const [topOid,] = useTopLevelOid();
    const [modelId,] = useObjAtt(topOid, "identifier");
    const cloudStyles = useCloudStyles(topOid)
    const [msgBoxInfo, setMsgBoxInfo] = useState(null);
    const [checked, setChecked] = useState(msgBoxInfo && msgBoxInfo.checked);

    gSetMsgBoxInfo = setMsgBoxInfo;

    useEffect(() => {
        if (msgBoxInfo?.body?.startsWith("The model file has been only partially loaded") && props.bModelFullyLoaded !== false) {
            console.log("Model file not fully loaded!!")
            props.setModelFullyLoaded(false);
        }
    });

    if (!msgBoxInfo) return null;
    if (checked === undefined || checked === null) setChecked(msgBoxInfo.checked ? true : false);

    if (msgBoxInfo?.body?.startsWith('Save changes to your model')) {
        console.log("suppressing msg box for closing model")
        onClick(7);
    }

    if ((modelId?.toLowerCase() === "acp_custom_startup_m" || modelId?.toLowerCase() === "suan_account")
        && msgBoxInfo?.body?.startsWith('Save changes to your model')) {
        console.log("suppressing msg box for closing model")
        onClick(7);
    }

    if (msgBoxInfo?.caption === "Undefined identifier" && msgBoxInfo?.body?.includes("Would you like to edit the Definition of")) {
        const indexOfWould = msgBoxInfo.body.indexOf("Would you like to edit the Definition of");
        msgBoxInfo.body = msgBoxInfo.body.substring(0, indexOfWould).trim();
    }

    function onClick(buttonId, ev)
    {
        console.log("onClick msg box button", ev);
        ev?.stopPropagation();
        ev?.preventDefault();
        WebSocket_Send({ fn: "Response", responseId: msgBoxInfo.responseId, button: buttonId, checked:checked });
        setMsgBoxInfo(null);    // closes the model window
        setChecked(null);       // reset for next time
    }

    function onMouseDown(ev) {
        // 1969 - the mouse down should not propagate to Window level because that 
        // causes things to happen on the diagram e.g. selected cells in embedded table are cleared
        console.log("onMouseDown msg box button", ev);
        ev?.stopPropagation();
        ev?.preventDefault();
    }

    let bOk = false, bCancel = false, bAbort = false, bRetry = false, bIgnore = false, bYes = false, bNo = false, bNoAll = false;
    switch (msgBoxInfo.buttons) {
        case 0: bOk = true; break;
        case 1: bOk = bCancel = true; break;
        case 2: bAbort = bRetry = bIgnore = true; break;
        case 3: bYes = bNo = bCancel = true; break;
        case 4: bYes = bNo = true; break;
        case 5: bRetry = bCancel = true; break;
        case 6: bYes = bNo = bNoAll = true; break;
        case 7: bYes = bNo = bNoAll = bCancel = true; break;
        default:
            bOk = bCancel = true;         
    }

    let buttonHtml = (
        <div id="Buttons" onMouseDown={(ev) => onMouseDown(ev)}>
            {bOk     && (<button className="Btn" id="OK"     onClick={(ev) => onClick(1, ev)}>OK</button>)    }
            {bAbort  && (<button className="Btn" id="Abort"  onClick={(ev) => onClick(3, ev)}>Abort</button>)  }
            {bRetry  && (<button className="Btn" id="Retry"  onClick={(ev) => onClick(4, ev)}>Retry</button>)  }
            {bIgnore && (<button className="Btn" id="Ignore" onClick={(ev) => onClick(5, ev)}>Ignore</button>) }
            {bYes    && (<button className="Btn" id="Yes"    onClick={(ev)=>onClick(6, ev)}>Yes</button >)   }
            {bNo     && (<button className="Btn" id="No"     onClick={(ev) => onClick(7, ev)}>No</button >)    }
            {bNoAll  && (<button className="Btn" id="NoAll"  onClick={(ev) => onClick(10007, ev)}>No to All</button >) }
            {bCancel && (<button className="Btn" id="Cancel" onClick={(ev) => onClick(2, ev)}>Cancel</button>) }
        </div>);

    function toggleCheck(ev)
    {
        ev.stopPropagation();
        setChecked(!checked);
    }


    let checkboxHtml = msgBoxInfo.checkboxText &&
        (<div id="CheckboxControl">
            <div><input type="checkbox" checked={checked} onClick={toggleCheck}/>{msgBoxInfo.checkboxText}</div>
        </div>);

    const [url, urlText, url2, url2Text, useHtml] = [msgBoxInfo.url, msgBoxInfo.urlText, msgBoxInfo.url2, msgBoxInfo.url2Text, msgBoxInfo.html===true];

    let urlsHtml = (
        <div id="Urls">
            {url && (<a href={url} target="_blank" rel="noopener noreferrer">{urlText}</a>)}
            {url2 && (<a href={url2} target="_blank" rel="noopener noreferrer">{url2Text}</a>)}
        </div>);

    const bodyText = useHtml ? (<div id="BodyText" dangerouslySetInnerHTML={{__html:msgBoxInfo.body}} />) :
        (<div id="BodyText"><Lineify text={msgBoxInfo.body} /></div>);

    return (
    <div className="MsgBox MsgPopup">
        {msgBoxInfo.caption && (<div id="Caption">{msgBoxInfo.caption}</div>)}
        <div id="Body">{bodyText}</div>
        <div id="Footer">
            {checkboxHtml}
            <div id="Footer2">
                {urlsHtml}
                {buttonHtml}
            </div>
        </div>
    </div>);
}

// client side version of message box popup, probably could be moved to misc.js, but
// I will leave it here since it's very similar to the server side massage box popup.
export function MsgBoxPopupClient(props) {
    console.log("MsgBoxPopupClient", props)
    const [topOid,] = useTopLevelOid();
    const [modelId,] = useObjAtt(topOid, "identifier");
    const [checked, setChecked] = useState(props.msgBoxInfo && props.msgBoxInfo.checked);
    const msgBoxInfo = props.msgBoxInfoClient;
    const setMsgBoxInfo = props.setMsgBoxInfoClient;

   // console.log("MsgBoxPopupClient", props)

    if (msgBoxInfo === null) return null;

    console.log("msg box info client not null");

    if (checked === undefined || checked === null) setChecked(msgBoxInfo.checked ? true : false);

    function onClick(buttonId) {
       console.log("on click msg box", buttonId, msgBoxInfo)
        
        props.setMsgBoxInfoClient(null);    // closes the modal window

        if (msgBoxInfo.responseHandler) {
            switch (buttonId) {
                case 1:
                    msgBoxInfo.responseHandler("OK"); break;
                case 6:
                    msgBoxInfo.responseHandler("Yes"); break;
                case 7:
                    msgBoxInfo.responseHandler("No"); break;
                case 2:
                    msgBoxInfo.responseHandler("Cancel"); break;
                default: break;
            }
        }
    }

    let bOk = false, bCancel = false, bAbort = false, bRetry = false, bIgnore = false, bYes = false, bNo = false, bNoAll = false;
    switch (msgBoxInfo.buttons) {
        case 0: bOk = true; break;
        case 1: bOk = bCancel = true; break;
        case 2: bAbort = bRetry = bIgnore = true; break;
        case 3: bYes = bNo = bCancel = true; break;
        case 4: bYes = bNo = true; break;
        case 5: bRetry = bCancel = true; break;
        case 6: bYes = bNo = bNoAll = true; break;
        case 7: bYes = bNo = bNoAll = bCancel = true; break;
        default:
            bOk = bCancel = true;
    }

    const okBtnText = msgBoxInfo.body !== "DownloadMessageToolbar" ? "Ok" : "Download";

    let buttonHtml = (
        <div id="Buttons">
            {bOk && (<button className="Btn" id="OK" onClick={(ev) => onClick(1)}>{okBtnText}</button>)}
            {bAbort && (<button className="Btn" id="Abort" onClick={(ev) => onClick(3)}>Abort</button>)}
            {bRetry && (<button className="Btn" id="Retry" onClick={(ev) => onClick(4)}>Retry</button>)}
            {bIgnore && (<button className="Btn" id="Ignore" onClick={(ev) => onClick(5)}>Ignore</button>)}
            {bYes && (<button className="Btn" id="Yes" onClick={(ev) => onClick(6)}>Yes</button >)}
            {bNo && (<button className="Btn" id="No" onClick={(ev) => onClick(7)}>No</button >)}
            {bNoAll && (<button className="Btn" id="NoAll" onClick={(ev) => onClick(10007)}>No to All</button >)}
            {bCancel && (<button className="Btn" id="Cancel" onClick={(ev) => onClick(2)}>Cancel</button>)}
        </div>);
    
    function toggleCheck(ev) {
        ev.stopPropagation();
        setChecked(!checked);
    }
    

    let checkboxHtml = msgBoxInfo.checkboxText &&
        (<div id="CheckboxControl">
            <div><input type="checkbox" checked={checked} onClick={toggleCheck} />{msgBoxInfo.checkboxText}</div>
        </div>);

    const [url, urlText, url2, url2Text, useHtml] = [msgBoxInfo.url, msgBoxInfo.urlText, msgBoxInfo.url2, msgBoxInfo.url2Text, msgBoxInfo.html === true];

    let urlsHtml = (
        <div id="Urls">
            {url && (<a href={url} target="_blank" rel="noopener noreferrer">{urlText}</a>)}
            {url2 && (<a href={url2} target="_blank" rel="noopener noreferrer">{url2Text}</a>)}
        </div>);

    let bodyText = useHtml ? (<div id="BodyText" dangerouslySetInnerHTML={{ __html: msgBoxInfo.body }} />) :
        (<div id="BodyText"><Lineify text={msgBoxInfo.body} /></div>);

    if (msgBoxInfo.urlBuySessions)
        bodyText = <><div id="BodyText">{msgBoxInfo.body} <a href={msgBoxInfo.urlBuySessions} target="_blank" rel="noopener noreferrer">Buy sessions</a>.</div></>;

    if (msgBoxInfo.body === "DownloadMessageToolbar")
        bodyText = <><div id="BodyText">You will need Analytica to run and explore this model on your Windows computer. If you don't have it, you can <a href="https://analytica.com/products/free-edition/" target="_blank" rel="noopener noreferrer">download Analytica Free</a>.  Click to download this Analytica model onto your computer for closer review and exploration.</div></>;

    return (
        <div className="MsgBox MsgPopup">
            {msgBoxInfo.caption && (<div id="Caption">{msgBoxInfo.caption}</div>)}
            <div id="Body">{bodyText}</div>
            <div id="Footer">
                {checkboxHtml}
                <div id="Footer2">
                    {urlsHtml}
                    {buttonHtml}
                </div>
            </div>
        </div>);
}

export function AskMsgPopup(props)
{
    const topOid = useTopLevelOid();
    const [askMsgInfo, setAskMsgInfo] = useState(null);
    const [entry, setEntry] = useState(askMsgInfo && askMsgInfo.initVal);
    const [initialized, setInit] = useState(false);
    const box = useRef(null);

    useEffect(() => {
        if (box && box.current) {
            box.current.select();
            box.current.focus();
        }
    }, [initialized]);

    gSetAskMsgInfo = setAskMsgInfo;

    if (!askMsgInfo) return null;

    if (!initialized ) {
        setEntry(askMsgInfo.initVal);
        setInit(true);
    }

    function onOk(ev)
    {
        WebSocket_Send({ fn: "Response", responseId: askMsgInfo.responseId, entered:entry });
        gSetAskMsgInfo(null);
        setInit(false);     // for next time
    }

    function onCancel(ev)
    {
        WebSocket_Send({ fn: "Response", responseId: askMsgInfo.responseId, cancel:true });
        gSetAskMsgInfo(null);
        setInit(false);
    }

    return (
        <div className="AskMsg AskMsgText MsgPopup">
            {askMsgInfo.caption && (<div id="Caption">{askMsgInfo.caption}</div>)}
            <div id="Body">
                <div id="Question"><Lineify text={askMsgInfo.question}/></div>
                <input id="Entr" type={askMsgInfo.type} ref={box} value={entry} maxLength={askMsgInfo.maxLen} onChange={(ev)=>setEntry(ev.currentTarget.value)}/>
            </div>
            <div id="Buttons">
                <button className="Btn" onClick={onOk}>{askMsgInfo && askMsgInfo.okBtnText ? askMsgInfo.okBtnText : "OK"}</button>
                <button className="Btn" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    );
}

function prepend(value, array) {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
}

export function AskMsgChoicePopup(props)
{
    const [info, setInfo] = useState(null);
    const [curSel, setCurSel] = useState(null);         // use null for not-initialized
    const [bOpen, setOpen] = useState(false);
    const choiceRef = useRef(null);

    //useEffect(() => {
    //    if (choiceRef && choiceRef.current) {
    //        choiceRef.current.select();
    //        choiceRef.current.focus();
    //    }
    //});

    gSetAskMsgChoiceInfo = setInfo;

    if (!info) return null;

    console.log(info);
    const caption  = info.caption;
    const question = info.question;
    //const bComboBox = info.combo;             // ** TO DO -- combo box ** - a combo's response can have either selected (a number, the item position) or entered (text that is typed)
    const bShowAll = info.showAll;
    const options = info.options;
    const initialSel = info.defPos;
    //const initialText = info.initialText;       // This is used only in combo boxes

    if (curSel===null) {
        setCurSel(initialSel);
        return null;
    }

    function onOk(ev) {
        WebSocket_Send({ fn: "Response", responseId: info.responseId, selected: curSel });
        setInfo(null);
        setCurSel(null);     // for next time
    }

    function onCancel(ev) {
        WebSocket_Send({ fn: "Response", responseId: info.responseId, cancel: true });
        setInfo(null);
        setCurSel(null);
    }

    function onClick(ev) {
        console.log("click ask msg choice", bOpen);
        ev.stopPropagation();
        setOpen(!bOpen);
    }

    const curSelTxt = curSel ? options[curSel-1] : "All";
    const opts0 = options.map((txt, i) => { return {pos: i+1, text: txt, selected: i+1 === curSel } });
    const opts = bShowAll ? prepend({ pos: 0, text: "All", selected:curSel=== 0 },opts0) : opts0;

    return (
        <div className="AskMsgChoice MsgPopup">
            <div id="Caption">{caption}</div>
            <div id="Body">
                <div id="Question"><Lineify text={question} /></div>
            </div>

            <button className="InputChoice" onClick={onClick} ref={choiceRef}>
                <span className="ChoiceText">{curSelTxt}</span><img src="img/ChoiceArrow.png" alt="open pulldown" className="ChoiceDropdownArrow" />
                {bOpen && <ChoiceDropdownMenu inclAll={bShowAll} bOpen={bOpen} setOpen={setOpen} setCurSel={setCurSel} opts={opts} isMulti={false} parentRef={choiceRef} />}
            </button>
            
            <div id="Buttons">
                <button className="Btn" onClick={onOk}>OK</button>
                <button className="Btn" onClick={onCancel}>Cancel</button>
            </div>

        </div>
    );
}

function UploadFileProgress({file,onDone,responseId})
{
    const [reader, setReader] = useState(null);

    useEffect(() => {
        if (reader) {
            reader.readAsDataURL(file);
        }
    }, [reader,file]);

    if (!reader) {
        const r = new FileReader();
        r.onload = function (evt) {
            const result = evt.target.result;
            const startPos = result.indexOf("base64,") + 7;
            const chunkSize = 1000000;
            const len = result.length;

            for (let pos = startPos; pos < len; pos += chunkSize) {         // Loop to avoid sending a chunk that is too big
                WebSocket_Send({ fn: "Response", responseId: responseId, name:file.name, data: result.substring(pos,pos+chunkSize), more:pos+chunkSize<len });
            }
            setReader(null);
            onDone();
        };
        r.onabort = function (evt) {
            WebSocket_Send({ fn: "Response", responseId: responseId, cancel: true });
            setReader(null);
            onDone();
        };
        r.onerror = function (evt) {
            WebSocket_Send({ fn: "Response", responseId: responseId, cancel: true, error:reader.error.message, errorType:reader.error.name });
            setReader(null);
            onDone();
        };

        setReader(r);
    }
    return <div>File upload is in progress</div>;
}

export function FileUploader(props)
{
    const [uploadHook, setUploadHook] = useState(null);
    const fileUploaderRef = useRef(null);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [phase, setPhase] = useState(0);  // 0=inactive, 1=waiting for choose, 2 = Choosing, 3=Uploading
    const [cancelButtonTimeout, setCancelButtonTimeout] = useState(null);

    gSetUploadFileHook = setUploadHook;

    useEffect(() => {
        if (uploadHook && phase === 1 && fileUploaderRef) {
            if (uploadHook.extensions)
                fileUploaderRef.current.accept = uploadHook.extensions;
            fileUploaderRef.current.click();            // Click on it
            setPhase(2);
        }
    }, [uploadHook,phase,fileUploaderRef]);

    const onUploadDone = useCallback(() => {
        if (cancelButtonTimeout)
            clearTimeout(cancelButtonTimeout);

        setUploadHook(null);
        setPhase(0);
        setCancelButtonTimeout(null);
        setFileToUpload(null);
    }, [setUploadHook, setPhase, setCancelButtonTimeout, cancelButtonTimeout, setFileToUpload]);

    const TestForUserPressedCancel = useCallback(() => {
        if (fileUploaderRef.current && fileUploaderRef.current.value.length === 0) {
            WebSocket_Send({ fn: "Response", responseId: uploadHook.responseId, cancel: true });
            onUploadDone();
        }
    },[fileUploaderRef,onUploadDone,uploadHook]);

    // The purpose of the following useEffect is to catch the case where the user presses the Cancel button
    // on the file selector dialog. This is a hack, since there doesn't seem to be any way to directly 
    // intercept the user cancelling the dialog.
    useEffect(() => {
        if (phase === 2 && fileUploaderRef) {
            document.body.onfocus = function () {               // Seems to be the only way to catch that the user aborted the file dialog by pressing the Cancel button. Total hack, which might not work with Firefox.
                if (fileUploaderRef.current)
                    setCancelButtonTimeout(setTimeout(TestForUserPressedCancel, 500));
            };
            return () => {                  // restore
                document.body.onfocus = null
                if (cancelButtonTimeout)
                    clearTimeout(cancelButtonTimeout); 
                setCancelButtonTimeout(null);
            };
        }
    }, [phase,TestForUserPressedCancel,cancelButtonTimeout]);


    if (!uploadHook) return null;
    if (phase === 0) setPhase(1);

    function DoUpload(ev)
    {
        if (cancelButtonTimeout)
            clearTimeout(cancelButtonTimeout);
        setCancelButtonTimeout(null);

        if (!fileUploaderRef.current) return;
        const src = fileUploaderRef.current.files;
        if (src.length < 1) return;

        setFileToUpload(src[0]);
        setPhase(3);
    }


    if (phase <=2) {
        return <input type="file" id="fileUploader" accept=".txt;.csv" ref={fileUploaderRef} onChange={DoUpload} />
    } else {
        return <UploadFileProgress file={fileToUpload} onDone={onUploadDone} responseId={uploadHook.responseId}/>;
    }






    /*
     **** This is one style for upload. An advantage is there could be room to provide descriptive information provided by the 
     **** model. Two disadvantages: it takes an extra click and there is more UI chrome that invites extra design iterations.
    return (
        <div className="FileUploadDialog">
            <div className="Caption">Upload a file</div>
            <div className="Body">
                <div className="DropZone"><div className="Instructions">Drag and drop file here</div></div>
                <div className="ChooseFileZone">
                    <span className="orChoose">or </span>
                    <input type="file" id="fileUploader" accept=".txt;.csv" ref={fileUploaderRef} onChange={DoUpload} />
                </div>
            </div>
        </div>
    );
    */
}
