import React from 'react';
import { useObjAtt, useObjAtts, useQuery, useCloudStyles, useTopLevelOid, useEval} from './serverState.js';
import { useState, useRef } from 'react';
import { HelpBalloon } from './helpBalloon.js';
// ----------------------------------------------------------------------------------------------------
// These routines deal with computing the state of the module tabs (which tabs are visible, which are selected, etc).
// The state needs to be identified BEFORE rendering (since cardinal rule in React is that three should be no state changes
// while rendering). 

// Returns the set of tabs that should appear within moduleIdent (for top level, this would be the model ident. For 2nd level tabs, 
// this would be the selected 1st level tab)
// Set level===1 for top-level, level===2 for second-level, etc.
// This returns undefined when the result isn't yet ready. Returns null if it has determined there are no tabs.
// Or returns a list of the oids of the tab modules when there are tabs
export function useModuleTabs(moduleOid, nav_style, level)
{
    // 1162 if ACP Styles Lib is in Orphans, show it as a top level tab
    const oidOrphans = useEval("HandleFromIdentifier('Orphans')")?.oid;
    const oidAcpStyles = useEval("HandleFromIdentifier('ACP_styles')")?.oid;
    const [isinAcpStyles,] = useObjAtt(oidAcpStyles, "isin");
    const bStyleLibIsinOrphans = oidAcpStyles && (isinAcpStyles === oidOrphans);

    //console.log("bStyleLibIsinOrphans", bStyleLibIsinOrphans, oidOrphans, oidAcpStyles)

    const moduleCloudStyle = useCloudStyles(moduleOid);
    const bHas2Tabs = (nav_style === "two_side_tabs" || nav_style === "two_top_tabs");
    const bHasTabs = nav_style === "top_tabs" || nav_style === "side_tabs" || bHas2Tabs;

    // LDC 9/8/2020 Suan bug 223
    // LDC 8/6/2021 S-933. show_as_tab is on by default at the top level, but off by default at the second level. 
    const bShowAsTab = level === 1 ? (moduleCloudStyle?.show_as_tab !== "no") : (moduleCloudStyle?.show_as_tab === "yes");
    const [moduleIdent,] = useObjAtt(bHasTabs ? moduleOid : null, 'identifier');
    const tabs0 = useModuleKids(moduleIdent);
    if (!tabs0) return tabs0;
    if (level > 1 && !bHas2Tabs) return null;

    const tabs1 = tabs0.map(o => o.oid);
    const tabs2 = bShowAsTab ? [moduleOid, ...tabs1] : tabs1;
    //console.log("[...tabs2, oidAcpStyles]", [...tabs2, oidAcpStyles])
    return bStyleLibIsinOrphans && level === 1 ? [...tabs2, oidAcpStyles] : tabs2;
}

function useModuleKids(modelIdent)
{
    // LDC 8/11/2021 Bug S-938. Added bOk to exclude modules that have show_as_tab:no
    const expr = modelIdent ? String.raw`LocalAlias m := Handle(«m»);
        If IsNull(contains of m) then null else (
            LocalIndex kids := contains of m;
			Local findShowAsTab := FindInText("show_as_tab\s*:\s*no", AcpStyles of (kids),caseInsensitive:true,re:1);
            Local bOk := findShowAsTab=null or findShowAsTab = 0;
            LocalIndex tabs := SubSet(SetContains(\(['Module','LinkModule','Library','LinkLibrary','Form']),class of (kids)) and identifier of (kids) <> 'ACP_option_libra' and bOk);
            LocalIndex XYZ := ['X','Y','Z'];
            LocalIndex YXZ := ['Y','X','Z'];
            CopyIndex(SortIndex(ParseNumber(SplitText(NodeLocation of (tabs),',',resultindex:XYZ))[XYZ=YXZ],tabs,KeyIndex:YXZ))
        )`
        : "null";

    const expr1 = expr.replace("«m»", modelIdent);

    return useQuery({ req: 'eval', expr: expr1 });
}


// This returns an object containing the state of the module tabs, which includes which tabs are shown at both 1st & 2nd levels, which is selected,
// setters for clicking on a tab, and any tab style/layout info.
// You can supply an onSelect that gets called with an Oid when a tab is selected. The tabs take care of themselves, but caller may want additional
// function, such as selecting which diagram will show.

export function useModuleTabsState(onSelect, curDiagNom) {
    const [idCurDiagNom,] = useObjAtt(curDiagNom, "identifier");
    const [topOid,] = useTopLevelOid();
    const cloudStyles = useCloudStyles(topOid);
    const nav_style = cloudStyles?.navigation_style;
    const b2LevelTabs = nav_style === "two_top_tabs" || nav_style === "two_side_tabs";
    const bHasTabs = nav_style === "top_tabs" || nav_style === "side_tabs" || b2LevelTabs;
    const bSide = nav_style === "side_tabs" || nav_style === "two_side_tabs";
    const nav1Tabs = useModuleTabs(topOid, nav_style,/*level*/1);
    const [selectedNav1Tab_nominal, selectNav1Tab] = useState(null);
    const selectedNav1Tab = CurrentlySelectedNavTab(nav1Tabs, selectedNav1Tab_nominal, curDiagNom, idCurDiagNom);
    const nav2Tabs = useModuleTabs(selectedNav1Tab === topOid ? null : selectedNav1Tab, nav_style, /*level*/2);
    const [selectedNav2Tab_nominal, selectNav2Tab] = useState(null);
    const selectedNav2Tab = CurrentlySelectedNavTab(nav2Tabs, selectedNav2Tab_nominal, idCurDiagNom);
    const tabColorScheme = cloudStyles && (cloudStyles.tab_color === "node" || cloudStyles.tab_color === "background") ? cloudStyles.tab_color : "default";
    const selected1color = useTabColor(tabColorScheme, selectedNav1Tab, true);

    //const bShowMainForTopTitle = bHasTabs && /*!b2LevelTabs &&*/ cloudStyles.show_model_title !== "yes";      // This was the logic prior to refactoring
    const bShowMainForTopTitle = !cloudStyles || cloudStyles.show_model_title !== "no";                         // This one matches intended EW 477 - Max, Make first tab "Main" if the model title is displayed in top banner

    const select1 = onSelect ? (oid) => { selectNav1Tab(oid); onSelect(oid); } : selectNav1Tab;
    const select2 = onSelect ? (oid) => { selectNav2Tab(oid); onSelect(oid); } : selectNav2Tab;

    return {
        nav1Tabs, selectedNav1Tab, selectNav1Tab: select1,
        nav2Tabs, selectedNav2Tab, selectNav2Tab: select2,
        bHasTabs, b2LevelTabs, bSide,
        topOid, tabColorScheme, bShowMainForTopTitle,
        selected1color, selectNav1TabOnly: selectNav1Tab /* no onclick */
    };
}

// Maps from the nominal top-tab to the actual one -- i.e., if the nominal one is not possible, then it reverts to one in the possible set.
function CurrentlySelectedNavTab(possibleTabs, nominalSelection, curDiagNom, idCurDiagNom)
{
    const hier = useEval("Local result := [];local loopBreaker := true;local isInNode:= isin of " + idCurDiagNom + "; while (not IsUndef(isInNode)) do (result:= Concat(result, [isInNode]);isInNode:= isin of isInNode;);result")

    if (!possibleTabs || possibleTabs.length === 0)
        return null;
    if (possibleTabs.includes(curDiagNom)) //
        return curDiagNom;

    if (hier && hier.length) {
        for (let i = 0; i < hier.length; i++) {
            console.log("hier loop", i, possibleTabs, hier[i])
            if (possibleTabs.includes(hier[i].oid)) {
                console.log("returning hier ", i, hier[i])
                return hier[i].oid;
            }
        }
    }

    if (possibleTabs.includes(nominalSelection))
        return nominalSelection;

    return possibleTabs[0];
}

// ==============================================================================================
// These routines deal with rendering the tabs. During the act of rendering, it should not alter state (i.e., you should never 
// call useState setters while rendering.  You should also never alter state from within a useState if you are doing so because
// you learned something about the state of the model during rendering -- that info should be discovered before rendering
// starts. useState setters are meant to be called from within event handlers that respond to a user interaction, such as from
// an onClick or onKeyPress handler.


export function ModuleTabs({ navTabs, selNavTab, setNavTab, moduleTabState, level, ...props })
{
    //const [width,] = useObjAtt(props.topOid, 'diagWidth');

    if (!navTabs || navTabs.length === 0 || !moduleTabState?.bHasTabs ) return null;

    const bgStyle = selNavTab && selNavTab===moduleTabState.selectedNav2Tab ? { background: moduleTabState.selected1color } : {};
    const sideStyle = {}; //moduleTabState.bSide ? {} : { width: width };
    const style = { ...bgStyle, ...sideStyle };

    return (
        <div className={"ModelTabs TabColorScheme_" + moduleTabState?.tabColorScheme} style={style}>
            {navTabs.map((oid) => <ModuleTab key={oid} tabOid={oid} setNavTab={setNavTab} bSel={oid === selNavTab} moduleTabState={moduleTabState} />)}
        </div>
    );
}

function useTabColor(tabColorScheme, tabOid, bSel)
{
    const [color, nodeColor] = useObjAtts(tabOid, ['diagramColor', 'nodeColor']);

    return tabColorScheme === "background" ? color                    /* all tabs use their diagram background color */
        : tabColorScheme === "node" ? (bSel ? color : nodeColor)            /* sel uses diag background color, other tabs use module node's color */
           : (bSel ? color : null);                                /* default = non-selected tabs use bluish white (from CSS). Selected tab uses bg color for its diagram */
}

function ModuleTab({ tabOid, bSel, setNavTab, moduleTabState }) {
    const [title, idTab] = useObjAtts(tabOid, ["_title", "identifier"]);
    const tabRef = useRef();
    const { topOid, bShowMainForTopTitle, bSide } = moduleTabState;
    const tabColor = useTabColor(moduleTabState.tabColorScheme, tabOid, bSel);
    const [bShowHelpBalloon, setShowHelpBalloon] = useState(false);
    const [helpBalloonTimer, setHelpBalloonTimer] = useState(0);
    const [nodeInfo,] = useObjAtt(tabOid, "nodeInfo");

    const cloudStyles = useCloudStyles(tabOid);
    if (cloudStyles?.show_object === "no" || cloudStyles?.show_as_tab === "no" || nodeInfo?.hidden === true)
        return null;

    const cls = (bSide ? "ModuleSideTab" : "ModuleTab") + (bSel ? " Selected" : "");

    let style = tabColor ? { background: tabColor } : {};

    if (tabColor) {
        const tabColorsRGB = tabColor.split("(")[1].split(")")[0].split(",");
        const avgColor = (parseInt(tabColorsRGB[0]) + parseInt(tabColorsRGB[1]) + parseInt(tabColorsRGB[2])) / 3;
        if (avgColor < 131) style = {color: "white", ...style}
    }

    const tabTitle = (bShowMainForTopTitle && topOid === tabOid) ? "Main" : title;

    function onClick(ev) {
        ev.stopPropagation();
        setNavTab(tabOid);
    }

    function onMouseEnter(ev) {
        //console.log("onMouseEnter");
        if (helpBalloonTimer === 0) {
            // show help balloon after short delay
            let bTimeId = setTimeout(() => setShowHelpBalloon(true), 500);
            setHelpBalloonTimer(bTimeId);
        }
    }

    function onMouseLeave(ev) {
        //console.log("onMouseLeave");
        setShowHelpBalloon(false);
        if (helpBalloonTimer !== 0) {
            clearTimeout(helpBalloonTimer)
            setHelpBalloonTimer(0);
        }
    }

    return (
        <>
            <div key={tabOid} className={cls} style={style} ref={tabRef} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>{tabTitle}</div>
            {bShowHelpBalloon && <HelpBalloon nodeRef={tabRef} idOrig={idTab} bSide={moduleTabState?.bSide}/>}
        </>
    );
}

export function ModuleTwoLevelTabSeparator({ moduleTabState })
{
    const color = moduleTabState.selected1color;
    const style = color ? { background: color } : {};

    return <div id={moduleTabState.bSide ? "SpacerBetweenSideTabs" : "SpacerBetweenTabs"} style={style} />
}
