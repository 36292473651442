//==============================
// Choice & MultiChoice controls 
//==============================


import React from 'react';
import { useState, useRef } from 'react';
import { useObjAtt /*, useObjAtts*/ } from './serverState.js';
import { ModalPopupControl } from './miscTools.js';

import './styles/choice.scss';

function IsEverythingSelected(options) 
{
    for (const i in options) {
        const opt_i = options[i];
        if (opt_i.disabled || opt_i.sep) continue; // these don't count.
        if (!opt_i.selected) {
            return false;
        }
    }
    return true;
}
function MakeOptionsWithAll(options,bOn,bAllowNone)
{
    let bNeedOneOn = !bAllowNone;
    return options.map(opt_i => {
        if (opt_i.sep || opt_i.disabled) return opt_i;
        const b = bOn || bNeedOneOn;
        if (b) bNeedOneOn = false;
        return { ...opt_i, selected: b };
    });
}
function NumOptionsSelected(options)
{
    return options.reduce((total, opt_i) => (opt_i.selected && !opt_i.sep && !opt_i.disabled) ? (total + 1) : total, 0);
}

export function ChoiceDropdownMenu({ parentRef, bOpen, curSel, setCurSel, isMulti, allowNone, opts, setOpts, setOpen, zoom, bInEditTable, setShowHelpBalloon, bShowHelpBalloon, nestUnder, modalPopupId, setOpenSubMenu, setSubMenuOptions, setSubMenuParentRect, setOpenSubSubMenu, setOptsFiltered, optsFiltered, nestUnderFiltered, setNestUnderFiltered }) 
{
    //console.log("ChoiceDropdownMenu opts", opts)
    const cls = "ChoiceMenu " + (bOpen ? "Open" : "Closed");
    const [optsDisplayed, setOptsDisplayed] = useState(opts);
    const [optsDisplayedSet, setOptsDisplayedSet] = useState(false);
    const [optsDisaplyedUnfiltered, setOptsDisplayedUnfiltered] = useState(opts);
    const [hoverPos, setHoverPos] = useState(-1); // 1363
    const [bFiltering, setFiltering] = useState(false);

    const showAll = isMulti && !nestUnder && opts && !IsEverythingSelected(opts) ? true : false;
    const showNone = isMulti && !nestUnder && opts && allowNone && IsEverythingSelected(opts) ? true : false;
    const showFirstOnly = isMulti && !nestUnder && opts && !allowNone && IsEverythingSelected(opts) ? true : false;

    function SaveMulti() {
        console.log("SaveMulti*", opts)
        const selected = opts.filter(opt_i => opt_i.selected && !opt_i.disabled && !opt_i.sep).map(opt_i => opt_i.pos);
        console.log("SaveMulti selected*", selected)
        setCurSel(selected);
    }

    function SelectAll(bOn) {
        const newOpts = MakeOptionsWithAll(opts, bOn, allowNone);

        if (!allowNone && !bOn && hoverPos !== -1) { // 1363
            newOpts[0].selected = false;
            newOpts[hoverPos - 1].selected = true;
        }

        setOpts(newOpts);
        setOptsDisplayed(newOpts);
    }

    function handleKey(ev) {
        ev.stopPropagation();

        if (ev.key === "Escape") {
            setOpen(false);
            return;
        } else if (ev.key === "Enter") {                // LDC 10/14/2020 Suan bug 300
            setOpen2(false);
        } else if (ev.key.toUpperCase() === "A" && ev.ctrlKey && isMulti) {
            SelectAll(!IsEverythingSelected(opts));
            ev.stopPropagation();                       // LDC 10/14/2020 Suan bug 300
            ev.preventDefault();
        }
        // ** TO DO ** -- can search opts for items starting with ev.key
    }

    if (!opts) return null;

    function IsSelected(opt) {
        //console.log("IsSelected opt", opt)
        if (!opt) return false;
        return opt?.selected || (opt?.pos !== undefined && opt?.pos === curSel);
    }

    function Sel(opt) {

        if (setShowHelpBalloon && bShowHelpBalloon === true) {
            setShowHelpBalloon(false);  // 1457 help balloon lingers
        }

        if (opt?.disabled)
            return () => false;

        console.log("** ", opt, click)

        return click;
    }


    function click(e, opt) {
        console.log("ChoiceDropdownMenu click(), opt", opt)
        e.preventDefault();
        e.stopPropagation();
        if (!isMulti) {
            console.log("click, calling setCurSel", opt.pos)
            setCurSel(opt.pos);
            setOpen(false);
        } else {
            if (!nestUnder) {
                const bCanBeDeselected = allowNone || NumOptionsSelected(opts) > 1;
                const newOptions = opts.map(opt_i => opt_i.pos === opt.pos ? { ...opt_i, selected: !opt_i.sep && !opt_i.disabled && !(opt_i.selected && bCanBeDeselected) } : opt_i);
                const newOptsDisplayed = optsDisplayed.map(opt_i => opt_i.pos === opt.pos ? { ...opt_i, selected: !opt_i.sep && !opt_i.disabled && !(opt_i.selected && bCanBeDeselected) } : opt_i);

                setOpts(newOptions);
                setOptsDisplayed(newOptsDisplayed);
            }
            else {  // has Submenu
                if (opt.pos === undefined) return; // not an option, submenu menu item

                const bCanBeDeselected = allowNone || NumOptionsSelected(opts) > 1;;
                const newOptions = opts.map(opt_i => opt_i.pos === opt.pos ? { ...opt_i, selected: !opt_i.sep && !opt_i.disabled && !(opt_i.selected && bCanBeDeselected) } : opt_i);
                const newOptsDisplayed = optsDisplayed.map(opt_i => opt_i.pos === opt.pos ? { ...opt_i, selected: !opt_i.sep && !opt_i.disabled && !(opt_i.selected && bCanBeDeselected) } : opt_i);

                setOpts(newOptions);
                setOptsDisplayed(newOptsDisplayed);

            }
        }
    }

    function itemCls(opt) {
        let s = "Option";
        if (opt?.pos === curSel)
            s = s + " Selected";
        if (opt?.pos === 0)
            s = s + " AllOption";
        if (opt?.sep)
            s = s + " Sep";
        else if (opt?.bNum)
            s = s + " Num";
        if (opt?.disabled)
            s = s + " Disabled";
        return s;
    }

    function setOpen2(b)
    {
        if (!b && isMulti)
            SaveMulti();

        setOpen(b);
    }

    function onMouseEnter(ev, opt) {
        console.log("ChoiceDropdownMenu, On mouse enter")

        if (opt?.hasSubMenu) {
            const nestUnd = nestUnderFiltered ? nestUnderFiltered : nestUnder;
            const options = optsFiltered ? optsFiltered : opts;
            const choiceSubMenuItems = getSubMenu(splitNestUnder(nestUnd), options, opt.posNum, 1);
            setSubMenuOptions(choiceSubMenuItems);
            setOpenSubMenu(true);

            // target may be the "span" tag, we can the div parent of that span
            if (!ev.target.toString().includes("DivElement")) {
                const offsetParentRect = ev.target.offsetParent.getBoundingClientRect();
                setSubMenuParentRect(offsetParentRect);
            }
            else {
                const targetRect = ev.target.getBoundingClientRect();
                setSubMenuParentRect(targetRect);
            }
        }
        else
            setOpenSubMenu && setOpenSubMenu(false);

        if (opt?.pos && opt.pos !== hoverPos) {
            setHoverPos(opt.pos);
            setOpenSubSubMenu && setOpenSubSubMenu(false);
            return;
        }
        else { 
            if (opt?.posNum && opt.posNum !== hoverPos) {
                setHoverPos(opt.posNum);
                setOpenSubSubMenu && setOpenSubSubMenu(false);
            }
        }
    }

    function onMouseLeave(pos) {

        //if (hoverPos !== -1) setHoverPos(-1); for SubMenus, should stay highlighted even after mouse leave
        //setOpenSubMenu(false);
    }

    function choiceFilterChange(e) {
        console.log("choice filter change");
        if (!bFiltering) setFiltering(true);
        const curText = e.target.value;

        if (!nestUnder) {
            const newOpts = optsDisaplyedUnfiltered.filter((o) => o.text.toLowerCase().includes(curText.toLowerCase()));
            console.log("after filter newOpts", newOpts)
            setOptsDisplayed(newOpts);
        }
        else {
            console.log("filtering hier choice");
            const nestUnderSplit = splitNestUnder(nestUnder);
            //console.log("opts filtered", optsUnfiltered);
            const newOpts = [];
            const newNestUnder = [];
            for (let i = 0; i < opts.length; i++) {
                if (nestUnderSplit[i])
                    console.log("i", nestUnderSplit[i][0])
                if (nestUnderSplit[i]) {
                    if (nestUnderSplit[i][0].toLowerCase().includes(curText.toLowerCase())) {
                        newOpts.push(opts[i]);
                        newNestUnder.push(nestUnder[i])
                    }
                    else if (opts[i].text.toLowerCase().includes(curText.toLowerCase())) {
                        newOpts.push(opts[i]);
                        newNestUnder.push(nestUnder[i]);
                    }
                }
                else if (opts[i].text.toLowerCase().includes(curText.toLowerCase())) {
                    newOpts.push(opts[i]);
                    newNestUnder.push(nestUnder[i]);
                }
            }
            console.log("new filtered hier", newOpts);
            setOptsFiltered(newOpts);
            setNestUnderFiltered(newNestUnder);
            setOptsDisplayedSet(false);
        }
    }
    
    const parentRect = parentRef?.current?.getBoundingClientRect();
    const choiceFilterStyle = { width: parentRect.width - 6, height: parentRect.height - 5 };  // make more or less same size as control, tweaked based on observation
    let choiceFilterText = "";
    if (!isMulti && !nestUnder) {
        for (let x = 0; x < opts?.length; x++) {
            if (opts[x]?.selected) {
                choiceFilterText = opts[x].text;
                break;
            }
        }
    }

    const nestUnd = nestUnderFiltered ? nestUnderFiltered : nestUnder;
    const options = optsFiltered ? optsFiltered : opts;
    const optsFirstMenu = nestUnder && document.acpServerConfig.EW1994_enable_choice_sub_menus !== false ? getFirstMenu(splitNestUnder(nestUnd), options) : optsDisplayed;

    if (nestUnder && document.acpServerConfig.EW1994_enable_choice_sub_menus !== false && optsDisplayedSet === false && optsFirstMenu) {
        console.log("*****setOptsDisplayedSet optsFirstMenu", optsFirstMenu)
        setOptsDisplayedSet(true);
        setOptsDisplayed(optsFirstMenu);
        setOptsDisplayedUnfiltered(optsFirstMenu)
    }

    // LDC 4/19/2021 S-731: Extracted modal popup logic into a re-usable class, ModalPopupControl
    const bChoiceFilterInTable = document.acpServerConfig.EW1405_choice_filtering_in_tables;
    const bChoiceFilterChoiceInputNodes = document.acpServerConfig.EW1463_choice_filter_on_choice_input_nodes;

    //console.log("optsDisplayed", optsDisplayed)

    return (
        <ModalPopupControl className={cls} onKeyDown={handleKey} parentRef={parentRef} setOpen={setOpen2} setOpenSubMenu={setOpenSubMenu} zoom0={zoom} modalPopupId={modalPopupId}>
            {bChoiceFilterInTable && (bInEditTable || bChoiceFilterChoiceInputNodes) && (optsDisaplyedUnfiltered?.length > 10 || bFiltering) && <div><textarea className="ChoiceFilter" style={choiceFilterStyle} onChange={e => choiceFilterChange(e)}>{choiceFilterText}</textarea></div>}
            {showAll && <div key={-1} className="Option AllNoneFirstOnly" onClick={() => SelectAll(true)}><span className="Check"> </span><span className="OptText">All</span></div>}
            {showNone && <div key={-1} className="Option AllNoneFirstOnly" onClick={() => SelectAll(false)}><span className="Check"> </span><span className="OptText">None</span></div>}
            {showFirstOnly && <div key={-1} className="Option AllNoneFirstOnly" onClick={() => SelectAll(false)}><span className="Check"> </span><span className="OptText">First only</span></div>}
            {optsDisplayed.map((opt, i) => (
                <div key={opt?.pos !== undefined ? opt.pos : opt.posNum} className={itemCls(opt)} onClick={(e) => click(e, opt)} onMouseEnter={(e) => { onMouseEnter(e, opt) }} onMouseLeave={() => { onMouseLeave(opt.pos) }}>
                    <span className="Check">{IsSelected(opt) ? '\u2714' : ' '}</span>
                    {!opt?.sep && <span className="OptText">{opt?.text}</span>}
                    {opt?.hasSubMenu && '   >'}
                </div>
            ))}
        </ModalPopupControl>
    );
}

function ChoiceSubMenu({ parentRef, bOpen, curSel, setCurSel, isMulti, allowNone, subMenuOptions, setOpen, zoom, bInEditTable, setShowHelpBalloon, bShowHelpBalloon, nestUnder, subMenuParentRect, setSubMenuOptions, setOpenSubMenu, setSubMenuParentRect, optsAll, setOptsAll }) {
    
    const cls = "ChoiceMenu " + (bOpen ? "Open" : "Closed");
    //const [opts, setOpts] = useState(options);

    const [optsDisplayed, setOptsDisplayed] = useState(subMenuOptions);
    const [hoverPos, setHoverPos] = useState(-1); // 1363
    const [bFiltering, setFiltering] = useState(false);

    //console.log("subMenuOptions", subMenuOptions, optsDisplayed); 

    if (subMenuOptions &&  optsDisplayed && optsDisplayed.length && (subMenuOptions[0].posNum !== undefined && subMenuOptions[0].posNum != optsDisplayed[0].posNum))
        setOptsDisplayed(subMenuOptions);

    function SelectAll(bOn) {
        /*const newOpts = MakeOptionsWithAll(opts, bOn, allowNone);

        if (!allowNone && !bOn && hoverPos !== -1) { // 1363
            newOpts[0].selected = false;
            newOpts[hoverPos - 1].selected = true;
        }

        setOpts(newOpts);
        setOptsDisplayed(newOpts);*/
    }

    function handleKey(ev) {
        ev.stopPropagation();

        if (ev.key === "Escape") {
            setOpen(false);
            return;
        } else if (ev.key === "Enter") {                // LDC 10/14/2020 Suan bug 300
            setOpen2(false);
        } else if (ev.key.toUpperCase() === "A" && ev.ctrlKey && isMulti) {
            //SelectAll(!IsEverythingSelected(opts));
            ev.stopPropagation();                       // LDC 10/14/2020 Suan bug 300
            ev.preventDefault();
        }
        // ** TO DO ** -- can search opts for items starting with ev.key
    }

    if (!subMenuOptions) return null;

    function IsSelected(opt) {
        //console.log("**", opt)
        if (opt === undefined) return false;
        return opt?.selected || (opt.pos !== undefined && opt.pos === curSel);
    }

    function subMenuClickHandler(e) {
        console.log("*** subMenuClickHandler", e)
    }

    function onMouseDown(e, opt) {
        console.log("**On mouse down**", e, opt);
        e.preventDefault();
        e.stopPropagation();

        if (setShowHelpBalloon && bShowHelpBalloon === true) {
            setShowHelpBalloon(false);  // 1457 help balloon lingers
        }

        if (opt?.optObj?.disabled || !opt?.optObj) {
            console.log("return from on mouse down");
            e.stopPropagation(); 
            e.preventDefault();
            return () => false;
        }

        const optObj = opt.optObj;
        if (!isMulti) {
            console.log("Updating choice selection", optObj)
            setCurSel(optObj.pos);
            setOpen(false);
        } else {
            console.log("Submenu, Updating multi choice selection", optObj, optsDisplayed)
            const bCanBeDeselected = allowNone || NumOptionsSelected(optsAll) > 1;

            console.log("optsAll", optsAll)
            console.log(optsDisplayed)

            const newOptions = optsAll.map(opt_i => opt_i.pos === optObj?.pos ? { ...opt_i, selected: opt_i.selected && bCanBeDeselected ? 0 : 1 } : opt_i);
            const newOptsDisplayed = optsDisplayed.map(opt_i => opt_i.optObj.pos === optObj?.pos ? { posNum: opt_i.posNum, optObj: { ...(opt_i.optObj), selected: opt_i.optObj.selected && bCanBeDeselected ? 0 : 1 } } : opt_i);

            console.log("optsAll after", newOptions)
            console.log(newOptsDisplayed)

            setOptsAll(newOptions);
            setOptsDisplayed(newOptsDisplayed);
            
        }
    }

    function itemCls(opt) {
        let s = "Option";
        if (opt.pos === curSel)
            s = s + " Selected";
        if (opt.pos === 0)
            s = s + " AllOption";
        if (opt.sep)
            s = s + " Sep";
        else if (opt.bNum)
            s = s + " Num";
        if (opt.disabled)
            s = s + " Disabled";
        return s;
    }

    function setOpen2(b) {
        console.log("setOpen2")
        /*if (!b && isMulti)
            SaveMulti();*/

        setOpen(b);
    }

    function onMouseEnter(ev, opt) {
        console.log("***On mouse enter ChoiceSubMenu item", opt)

        if (opt?.hasSubMenu) {
            const choiceSubSubMenuItems = getSubMenu(splitNestUnder(nestUnder), optsAll, opt.posNum, 2);
            setSubMenuOptions(choiceSubSubMenuItems);
            setOpenSubMenu(true);
            // target may be the "span" tag, we can the div parent of that span
            if (!ev.target.toString().includes("DivElement")) {
                const offsetParentRect = ev.target.offsetParent.getBoundingClientRect();
                setSubMenuParentRect(offsetParentRect);
            }
            else {
                const targetRect = ev.target.getBoundingClientRect();
                setSubMenuParentRect(targetRect);
            }
        }
        else
            setOpenSubMenu && setOpenSubMenu(false);

        if (opt?.pos && opt.pos !== hoverPos) {
            setHoverPos(opt.pos);
            return;
        }
        else {
            if (opt?.posNum && opt.posNum !== hoverPos) {
                setHoverPos(opt.posNum);
            }
        }
    }

    function onMouseLeave(pos) {
        if (hoverPos !== -1) setHoverPos(-1);
    }

    const parentRect = parentRef?.current?.getBoundingClientRect();
    
    return (
        <ModalPopupControl className={cls} onKeyDown={handleKey} parentRef={parentRef} setOpen={setOpen2} zoom0={zoom} parentRect={subMenuParentRect}>
            {optsDisplayed.map((opt, i) => (
                <div key={opt?.posNum || opt.optObj.pos} className={itemCls(opt)} onClick={(e) => subMenuClickHandler(e)} onMouseEnter={(e) => { onMouseEnter(e, opt) }} onMouseLeave={() => { onMouseLeave(opt.pos) }} onMouseDown={(e) => onMouseDown(e, opt) }>
                    <span className="Check">{IsSelected(opt?.optObj) ? '\u2714' : ' '}</span>
                    {!opt.sep && <span className="OptText">{opt.optObj ? opt?.optObj?.text : opt.text}</span>}
                    {opt.hasSubMenu && '   >'}
                </div>
            ))}
        </ModalPopupControl>
    );
}

// Convert NaN to Undefined.
function NU(n)
{
    return isFinite(n) ? n : undefined;
}

export function DoInputChoice(props) 
{
    //console.log("DoInputChoice", props)
    const [bOpen, setOpen] = useState(false);
    const [bOpenSubMenu, setOpenSubMenu] = useState(false);
    const [subMenuOptions, setSubMenuOptions] = useState(null);
    const [subMenuParentRect, setSubMenuParentRect] = useState(null); // rect of the menu item hovered
    const [bOpenSubSubMenu, setOpenSubSubMenu] = useState(false);
    const [subSubMenuOptions, setSubSubMenuOptions] = useState(null);
    const [subSubMenuParentRect, setSubSubMenuParentRect] = useState(null); // rect of the menu item hovered
    const options = props.options;
    const [opts, setOpts] = useState(options);
    const [optsFiltered, setOptsFiltered] = useState(null);  // used for hierarchical choice
    const [nestUnderFiltered, setNestUnderFiltered] = useState(null);  // used for hierarchical choice
    const setCurSel = props.setCurSel;
    const [controlWidth,] = useObjAtt(props.oid, 'controlWidth');
    const [nodeFontStyle,] = useObjAtt(props.oid, '_nodefont');
    const choiceRef = useRef();
    if (options === undefined || options === null) return "";
    if (!opts || (opts.length === 0 && options?.length)) setOpts(options);
    const bIgnoreControlWidth = props.bIgnoreControlWidth === true;     // LDC 8/7/2020 Suan bug 115
    const bDisabled = props.disabled || props.computing;

    const style0 = bIgnoreControlWidth ? {} : { width: NU(controlWidth) };  // EW50 +6 comes from comparing to DTA, the "controlWidth" in the node info attribute and useObjAtt(props.oid, 'controlWidth');  // LDC 10/29/2020 Suan 308 Removed the +8
    const style1 = props.boxShadow ? { ...style0, boxShadow: props.boxShadow } : style0; // box shadow is for indicating cell selection boundary
    const style = props.colWidth ? { ...style1, width: props.colWidth } : style1;
    const curSelTxt = props.computing ? "\u00abComputing\u00bb" : props.isMulti ? props.curSelText : props.iCurSel < options.length ? options[props.iCurSel].text : "     ";

    const bCurSelIsNum = !props.computing && (props.isMulti ? props.curSelIsNum : (props.iCurSel < options.length && options[props.iCurSel].bNum));
    const cls = (props.cls ? "InputChoice " + props.cls : "InputChoice") + (props.computing ? " Computing" : "") + (bDisabled ? " Disabled" : "");
    const fontStyle = nodeFontStyle === null ? props.fontStyle : nodeFontStyle;
    let style2 = bIgnoreControlWidth ? fontStyle : { width: NU(controlWidth - 30), ...fontStyle };    // For the text part, less the width of the drop-down arrow // LDC 10/29/2020 Suan 308 increased -24 to -30
    if (props?.bInEditTable && props?.cellFormat?.fontSize) style2.fontSize = props?.cellFormat?.fontSize; // 1981

    //console.log("DoInputChoice options opts", options, opts);

    // EW 1382
    //if (!bFiltering) {
    if (opts.length !== opts.length) {
        console.log("setOpts1")
        setOpts(options);
    }
    else {
        for (let i = 0; opts.length > i; i++) {
            if (opts[i]?.text !== options[i]?.text) {
                console.log("setOpts2")
                setOpts(options);
                break;
            }
        }
    }
    //}

    function onClick(ev, clickLoc) {
        console.log("Choice Click") //, props, ev, clickLoc);

        if (optsFiltered !== null) setOptsFiltered(null);
        if (nestUnderFiltered !== null) setNestUnderFiltered(null);
        setOpts(options)
        
        if (props.setShowHelpBalloon) props.setShowHelpBalloon(false); // 1457

        if (props.isToolbar && options.length === 1) {
            console.log("One item in toolbar choice, don't show popup list of choices");
            return
        }

        if (clickLoc === "choiceControlClick" && props.bInEditTable === true && ev.detail === 1) {
            ev.stopPropagation();

            // select the cell if not clicked on down arrow
            if (props.setCellSelectTable) props.setCellSelectTable();

            return false;
        }
        else if (clickLoc === "choiceControlClick" && props.bInEditTable === true && ev.detail === 2) // double click
        {
            if (bDisabled) return;
            ev.stopPropagation();
            props.clearCellSelect();  // deselect cell
            setOpen(!bOpen);
            return false;
        }

        if (bDisabled) return;
        ev.stopPropagation();
        setOpen(!bOpen);
    }

    const clsText = "ChoiceText" + (bCurSelIsNum ? " Num" : "");
    const choiceEditTable = <><div className={clsText} id={props.cellId} style={style2}>{curSelTxt}</div><div className="arrow-container" onClick={(e) => onClick(e, "arrowClick")}><div className="arrow-down" /></div></>;
    const triangle = props.isToolbar ? "img/ChoiceTriangleToolbar.png" : "img/ChoiceArrow.png";
    const showTriangle = props.isToolbar && options.length === 1 ? false : true;
    const choiceNotEditTable = <>
        <span className={clsText} id={props.cellId} style={style2}>{curSelTxt}</span>
        {showTriangle && <img src={triangle} alt="open pulldown" className="ChoiceDropdownArrow" onClick={(e) => onClick(e, "arrowClick")} />}
    </>;

    //console.log("ChoiceInput opts", opts);

    return (
        <button className={cls} style={style} ref={choiceRef} onClick={(e) => onClick(e, "choiceControlClick")}>
            {props.bInEditTable ?  choiceEditTable  : choiceNotEditTable}
            {bOpen && <ChoiceDropdownMenu {...props} options={undefined} opts={opts} setOpts={setOpts} bOpen={bOpen} setOpen={setOpen} setCurSel={setCurSel} parentRef={choiceRef} zoom={props.zoom} setShowHelpBalloon={props.setShowHelpBalloon} modalPopupId={"ChoiceDropdownMenu"} setOpenSubMenu={setOpenSubMenu} setSubMenuOptions={setSubMenuOptions} setSubMenuParentRect={setSubMenuParentRect} setOpenSubSubMenu={setOpenSubSubMenu} setOptsFiltered={setOptsFiltered} optsFiltered={optsFiltered} nestUnderFiltered={nestUnderFiltered} setNestUnderFiltered={setNestUnderFiltered} />}
            {bOpen && bOpenSubMenu && <ChoiceSubMenu {...props} subMenuOptions={subMenuOptions} bOpen={bOpen} setOpen={setOpen} setCurSel={setCurSel} parentRef={choiceRef} zoom={props.zoom} setShowHelpBalloon={props.setShowHelpBalloon} subMenuParentRect={subMenuParentRect} setSubMenuOptions={setSubSubMenuOptions} setSubMenuParentRect={setSubSubMenuParentRect} setOpenSubMenu={setOpenSubSubMenu} setSubMenuParentRect={setSubSubMenuParentRect} optsAll={opts} setOptsAll={setOpts} />}            
            {bOpen && bOpenSubMenu && bOpenSubSubMenu && <ChoiceSubMenu {...props} subMenuOptions={subSubMenuOptions} bOpen={bOpen} setOpen={setOpen} setCurSel={setCurSel} parentRef={choiceRef} zoom={props.zoom} setShowHelpBalloon={props.setShowHelpBalloon} subMenuParentRect={subSubMenuParentRect} optsAll={opts} setOptsAll={setOpts} />}            
        </button>
    );
}

// LDC 10/13/2020 Suan ER 294
function DoInputChoiceList({options,setCurSel,controlWidthNode,bAllowNone,isMulti})
{
    const ref = useRef(null);

    const style = { width: controlWidthNode};  

    function SetSel(pos, bOn, ev)
    {
        ev.stopPropagation();
        if (!isMulti) {                     // LDC 6/23/2021 Bug 878
            if (bOn)
                setCurSel(pos)
        } else {

            if (!bOn && !bAllowNone && NumOptionsSelected(options) <= 1)
                return;
            console.log("choice change")
            const subset = options.filter((s) => (s.pos === pos ? bOn : s.selected));
            const newSel = subset.map((s) => s.pos);
            setCurSel(newSel);
        }
    }

    function SelectAll(bOn)
    {
        const newOpts = MakeOptionsWithAll(options, bOn, bAllowNone);

        const subset = newOpts.filter((s) => s.selected);
        const newSel = subset.map((s) => s.pos);

        setCurSel(newSel);
        if (ref)
            ref.current.focus();
    }

    function handleKey(ev)
    {
        ev.stopPropagation();

        if (ev.key.toUpperCase() === "A" && ev.ctrlKey) {
            ev.preventDefault();
            if (isMulti)
                SelectAll(!IsEverythingSelected(options));
        }
    }

    function Opt(opt)
    {
        if (opt.sep) {
            return <div className="ChoiceListSep" key={opt.pos} />;
        }
        const cls = "ChoiceListOpt " + (opt.selected ? "Selected" : "Unselected") + (opt.bNum ? " Num" : "");

        return (
            <div className={cls} key={opt.pos} onClick={(ev)=>SetSel(opt.pos,!opt.selected, ev)}>{opt.text}</div>
        );
    }

    function onNonOptionClick(ev)
    {
        if (ref)
            ref.current.focus();
        ev.stopPropagation();
    }

    return (
        <button className="ChoiceListCtrl" style={style} onKeyDown={handleKey} ref={ref} >
            <div className="ChoiceListOptions" onClick={onNonOptionClick}>
                {options.map( Opt )}
            </div>
        </button>
    );
}

export function InputChoice(props) {
    //console.log("**InputChoice", props);
    const [options,] = useObjAtt(props.oid, '_choiceOptions');
    const [/*curSel*/, setCurSel] = useObjAtt(props.oid, '_choiceSelection');

    //console.log("InputChoice options", options)

    function setCurSelWrap(o) {
        console.log("setCurSelWrap", o)
        if (props.setPromptToSaveOnClosing !== undefined) props.setPromptToSaveOnClosing(true);
        setCurSel(o);
    }

    if (!options) return null;

    if (props.nodeHeight && props.fontStyle && props.fontStyle.fontSize && props.nodeHeight > 5 * props.fontStyle.fontSize)     // LDC 10/13/2020 Suan ER 294
        return <DoInputChoiceList {...props} {...options} options={options.opts} setCurSel={setCurSelWrap} bAllowNone={options.allowNone} isMulti={props.isMulti} />;

    return <DoInputChoice {...props} allowNone={options.allowNone} curSelText={options.curSelText} iCurSel={options.iCurSel} nestUnder={options.nestUnder} options={options.opts} setCurSel={setCurSelWrap} cls="UserControl" nestUnder={options.nestUnder} />;
}

export function InputMultiChoice(props) 
{
    return InputChoice({ ...props, isMulti: true });
}

function splitNestUnder(nestUnder) {
    if (!nestUnder) return null;
    const nestUnderSplit = new Array();
    for (let i = 0; i < nestUnder.length; i++) {
        //console.log("nestUnder", i, nestUnder[i]);
        if (nestUnder[i]?.split) {
            nestUnderSplit[i] = nestUnder[i].split('>');
            for (let j = 0; j < nestUnderSplit[i].length; j++)
                nestUnderSplit[i][j] = nestUnderSplit[i][j].trim();
        }
        else
            nestUnderSplit[i] = null;
    }

    return nestUnderSplit;
}

function getFirstMenu(nus, opts) {
    try {
        //console.log("getFirstMenu", nus, opts);
        const firstMenu = [];
        const hasAll = nus.length < opts.length ? 1 : 0;
        if (hasAll) firstMenu.push(opts[0]);
        for (let i = 0; i < nus.length; i++) {

            if (opts.length === i)
                break; // happens when filtering

            if (nus[i] === null || !nus[i][0]) {
                firstMenu.push(opts[i + hasAll]);
            }
            else {
                const menuObj = { posNum: i + 1, text: nus[i][0], hasSubMenu: true } // one based, 'all' is zero;
                if (!nus[i - 1])
                    firstMenu.push(menuObj);
                else if (nus[i - 1][0] !== nus[i][0])
                    firstMenu.push(menuObj);
            }
        }

        return firstMenu;

    } catch (err) {
        console.log("Catch getFirstMenu() choice.js")
    }
}

function getSubMenu(nus, opts, startingPos, subMenuLevel) {
    try {
        //console.log("getSubMenu", opts, nus, startingPos, subMenuLevel)
        const labelToMatch = nus[startingPos - 1][subMenuLevel - 1];
        //console.log("getSubMenu() label to match", labelToMatch, nus);
        const hasAll = opts.length !== nus.length ? true : false;
        const subMenu = [];

        for (let i = startingPos - 1; i < nus.length; i++) {
            if (nus[i] === null || nus[i][subMenuLevel - 1] !== labelToMatch)
                break;

            if (nus[i].length === subMenuLevel) {
                const menuItem = { posNum: i + 1, optObj: opts[i + hasAll] };
                subMenu.push(menuItem);
            }
            else {
                const menuItem = { posNum: i + 1, text: nus[i][subMenuLevel], hasSubMenu: true };
                if (subMenu.length === 0 || subMenu[subMenu.length - 1]?.optObj || subMenu[subMenu.length - 1].text !== menuItem.text)
                    subMenu.push(menuItem);
            }
        }

        //console.log("subMenu", subMenu)
        return subMenu;
    }
    catch (err) {
        console.log("catch getSubMenu() choice.js", err);
    }
}
