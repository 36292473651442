// graph.js

import React from 'react';
import { useState,useEffect,useLayoutEffect, useRef } from 'react';

import { useQuery, useObjAtt, /*useCloudStyles, */ useEval } from './serverState.js';
import { WebSocket_Send } from './webSocketClient.js';
import { ResultTitle, Slicer, getCloudStyleSetting, getShowIndexMenus, IsProb } from './resultTable.js';
import { Lineify, useLayoutRect } from './miscTools.js';
import { getTableOrGraphMargin } from './editTable.js';
import { SpinningCubeWithBreakAndProgress } from './Waiting.js';
import './styles/graph.scss';
import './styles/tooltips.scss';

const gEnglishRoles = {
    horiz: "Horizontal", 
    vert : "Vertical", 
    color : "Color", 
    symbol : "Symbol", 
    symbolSize : "Symbol size", 
    stack: "Stack", 
    cluster : "Cluster", 
    origin : "Bar origin", 
    commonIndex: "Common index",
    dash: "Dash style"
};

function GraphFillerOption(props)
{
    const filler = props.filler;
    //const [name,] = useObjAtt(filler.oid, "_title");

    if (!filler) return "";
    return (<option value={JSON.stringify(filler)}>{filler.title}</option> )
}

function GraphRoleSelector(props)
{
    const role = props.role;
    if (!role) return "";
    const filler = props.filler;
    const {opts, ...fillerWithoutOpts} = filler;

    const rolesToHideWhenOnly1opt = ["vert", "origin", "cluster", "stack"];
    
    function onChange(ev)
    {
        ev.stopPropagation();
        const filler = JSON.parse(ev.currentTarget.value);
        WebSocket_Send({ fn: 'Exec', vid: props.vid, cmd: 'pivotTo', role: role, filler: filler });        
    }

    if (opts.length === 0)
        return "";
    if (opts.length === 1 && rolesToHideWhenOnly1opt.includes(role))
        return "";
    if (opts.length === 1 && role === "commonIndex" && !props.bForceCommon)
        return "";

    return (
        <div className="GraphRoleSelector">
            <span className="GraphRoleName">{gEnglishRoles[props.visibleRole]}</span>
            <select value={JSON.stringify(fillerWithoutOpts)} onChange={onChange} onClick={ev=>ev.stopPropagation()} >
                {opts.map( (opt,i)=><GraphFillerOption key={i} filler={opt} /> )}
            </select>
        </div>
    );
}

// LDC 8/3/2021 Bug S-905
function VisRole(role, bSwapXY)
{
    if (!bSwapXY) return role;
    if (role === "horiz") return "vert";
    if (role === "vert" ) return "horiz";
    return role;
}

function GraphPivotSelection({ vid, pivot, isFrameNode, showFlyInPivoters, bShowPivoters, setNumSlicers, zoom })
{
    useEffect(() => {
        if (pivot?.slicers?.length) {
            setNumSlicers(pivot.slicers.length);
        }
    })

    if (!pivot) return "";
    const roles = pivot.roles;
    if (!roles) return "";

    const xDim = roles.horiz;
    if (xDim === undefined) return "";

    const bForceCommon = xDim.type !== "index" && xDim.type !== null; // *** TO DO *** -- and there is more than one value dimension

    function onChangeSlicer(oid,newPos)
    {
        WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'setSlicer', sub: oid, pos: newPos });
    }

    let graphPivotCls = isFrameNode ? "GraphPivot FrameNode" : "GraphPivot";
    
    if (showFlyInPivoters !== "not set" && showFlyInPivoters)
        graphPivotCls += " ShowFlyInPivoters";
    else if (showFlyInPivoters === false)
        graphPivotCls += " HideFlyInPivoters";

    const noIndexMenus = (!bShowPivoters && (pivot.slicers === undefined || pivot.slicers.length === 0)) ? true : false;
    if (noIndexMenus) return "";

    return (
        <div className="GraphPivotWrapper">
        <div className={graphPivotCls}>
                {pivot.slicers && pivot.slicers.map((oid_pos) => <Slicer {...oid_pos} vid={vid} key={oid_pos.oid} changeSlicer={onChangeSlicer} zoom={zoom} />)}
            {bShowPivoters && Object.keys(roles).map( (role,) => <GraphRoleSelector key={role} role={role} visibleRole={VisRole(role,pivot.swapXY)} filler={roles[role]} vid={vid} bForceCommon={bForceCommon}/> )}
        </div>
        </div>
    );
}

function GraphStartingUp({ oid })
{
    const [ident,] = useObjAtt(oid, "identifier");

       return <div>Drawing graph...</div>;
}

function GraphClickHandler({ vid, x, y, setActive })
{
    const q = { req: "whatsAt", x: x, y: y };
    const whatsAt = useQuery(q, vid);
    const customBalloon = useQuery( { req:"customBalloon" }, vid );   // LDC 11/4/2021 ER S-1038
    const customBalloonText1 = customBalloon && customBalloon.balloonText;

    if (!whatsAt || whatsAt.continue===false) 
        return "";


    if (whatsAt.part === "datum") {
        const pos = { left: whatsAt.x, top: whatsAt.y };
        const lines = (customBalloonText1 || whatsAt.description).split('\n');

        return <div className="GraphBalloon tooltip" style={pos}>{lines.map( (txt,i) => <p key={i}>{txt}</p>)}</div>
    } else if (whatsAt.part === "key" && typeof(whatsAt.itemNum)==="number") {
        if (x)
            WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'toggleKeyVis', itemNum:whatsAt.itemNum, key: { color: whatsAt.color, symbol: whatsAt.symbol, symbolSize: whatsAt.symbolSize, dash:whatsAt.dash } } );
        setActive(false);
    } else if (whatsAt.part === "segment") {
        const pt1 = whatsAt.pt1;
        const pos1 = { left: pt1.x, top: pt1.y };
        const lines = (customBalloonText1 || pt1.description).split('\n');

        // ** TO DO ** -- display the 2nd balloon. Custom text, if any, is in customBalloon.balloonText2

        return <div className="GraphBalloon tooltip" style={pos1}>{lines.map((txt, i) => <p key={i}>{txt}</p>)}</div>
    }
    return null; // Or use this for debugging: (<div className="whatsAt">{JSON.stringify(whatsAt)}</div>);   
}

function GraphZoomerAndHoverIcons(props)
{
    //console.log("Graph Zoomer", props)
    const plotArea = useQuery({ req: "plotArea", statusCount:props.statusCount }, props.vid);
    const hAxisInfo = useQuery({ req: "axisInfo", axis: "horiz", statusCount: props.statusCount }, props.vid );
    const vAxisInfo = useQuery({ req: "axisInfo", axis: "vert", statusCount: props.statusCount }, props.vid );
    const [anchor,setAnchor] = useState(null);                  // coordinate system of plotArea
    const [cur, setCur] = useState(null);                       // coordinate system of plotArea
    const [numMoves, setNumMoves] = useState(0);
    const [showIcons, setShowIcons] = useState(false);
    const [graphSetup, setGraphSetup] = useObjAtt(props.oidOrig, 'GraphSetup');
    //console.log("graphSetup", graphSetup?.split("\n"))

    const dragging = anchor && cur && (cur.x !== anchor.x || cur.y !== anchor.y);
    let bSelectFullHoriz = false;
    let bSelectFullVert = false;    

    const bVisHZoomOut = hAxisInfo && !hAxisInfo.autoscale;
    const bVisVZoomOut = vAxisInfo && !vAxisInfo.autoscale;

    let zoomRect = { display: "none" };
    
    if (dragging) {
        zoomRect = {
            display: "block",
            left: Math.min(anchor.x, cur.x), top: Math.min(anchor.y, cur.y),
            width: Math.abs(anchor.x - cur.x), height: Math.abs(anchor.y - cur.y)
        };
        bSelectFullHoriz = (zoomRect.width < 50 && (zoomRect.width * 3 < zoomRect.height));
        bSelectFullVert = (zoomRect.height < 50 && (zoomRect.height * 3 < zoomRect.width));

        if (bSelectFullHoriz) {
            zoomRect.left = 0;
            zoomRect.width = plotArea.width;
        }
        if (bSelectFullVert) {
            zoomRect.top = 0;
            zoomRect.height = plotArea.height;
        }

        // ** TO DO ** SnapSelToCategorical
    }

    function pt(event) {
        //let x = event.clientX; 
        //let y = event.clientY;
        //for (let obj = event.currentTarget; obj; obj = obj.offsetParent) {
        //    x -= obj.offsetLeft;
        //    y -= obj.offsetTop;
        //}

        const zoom = props.zoom === undefined ? 1 : props.zoom;
        
        const r = event.currentTarget.getBoundingClientRect();
        const x = (event.clientX - r.left)/ zoom;
        const y = (event.clientY - r.top)/ zoom;

        return { x: x, y: y };
    }

    function onMouseDown(ev)
    {
        console.log("onMouseDown")
        let p = pt(ev);
        setTimeout(() => setShowIcons(false), 500);  // added setTimeout(), fixes EW 2019
        if (isNaN(p.x) || isNaN(p.y)) return true;
        setAnchor(p);
        return false;
    }
    function onMouseUp(ev)
    {
        if (anchor !== null) {
            if (dragging) {
                const zoomRect2 = { left:zoomRect.left + plotArea.left, top:zoomRect.top + plotArea.top, width:zoomRect.width, height:zoomRect.height };  // Image coords
                WebSocket_Send({ fn: 'Exec', vid:props.vid, cmd: 'zoom', rect: zoomRect2, horiz: !bSelectFullHoriz, vert:!bSelectFullVert } );
                props.setClickActive(false);
                ev.stopPropagation();
            }
            setAnchor(null);
            setCur(null);
        }
    }
    function onMouseMove(ev)
    {
        if (!anchor && !showIcons) {
            setShowIcons(true);
            setNumMoves(numMoves + 1);
        }

        let p = pt(ev);
        if (anchor === null || isNaN(p.x) || isNaN(p.y)) {
            return false;
        }

        setCur(p);
    }

    function zoomOut(bHoriz,bVert)
    {
        console.log("zoom out");
        WebSocket_Send({ fn:'Exec', cmd: 'zoomOut', vid: props.vid, horiz: bHoriz, vert: bVert });
    }

    function swapXYaxes(ev) {
        console.log("Swap XY", graphSetup);
        ev.stopPropagation();
        ev.preventDefault();

        // case 1:  No GraphSetup attribute present
        if (graphSetup === undefined) {
            setGraphSetup("Flip: 8");
        }

        // see if Flip: is present
        let graphSetupArray = graphSetup.split("\n");
        let flipPos = -1;
        for (let i = 0; i < graphSetupArray.length; i++) {
            if (graphSetupArray[i].includes("Flip:")) {
                console.log("Flip found", i);
                flipPos = i;
                break;
            }
        }

        if (flipPos !== -1) { // graph setup has "flip:" setting already
            const flipElementArray = graphSetupArray[flipPos].split(":");
            const flipValue = parseInt(graphSetupArray[flipPos].split(":")[1]);
            console.log("flip value", flipValue);
            if (flipValue === 8) {
                flipElementArray[1] = 0;
            } else if (flipValue === 0) {
                flipElementArray[1] = 8;
            }
            graphSetupArray[flipPos] = flipElementArray.join(":");
            console.log("graphSetupArray", graphSetupArray);
            setGraphSetup(graphSetupArray.join("\n"));
        }
        else {
            console.log("adding flip:8")
            setGraphSetup("Flip:8\n" + graphSetup);
        }
       
    }

    // Make hover icons go away after 5 seconds of no mouse movement
    useEffect(() => {
        if (showIcons) {
            const timer = setTimeout(() => { setShowIcons(false); }, 5000);    
            return () => clearTimeout(timer);
        }
    },[numMoves,setShowIcons,showIcons]);

    if (!plotArea) return "";

    //const { left: paL, top: paT, width: paW, height: paH } = plotArea;
    //const [paR, paB] = [paL + paW, paT + paH];

    const clsVis = showIcons ? " Showing" : " NotShowing";

    const html = (
        <div className="GraphPlotArea" style={plotArea} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseMove={onMouseMove}>
            {plotArea && (<>
                <div className="GraphZoomRect" style={zoomRect}/>
                {bVisHZoomOut && <img className={"GraphHoverIcon GraphZoomOutHorizIcon"+ clsVis} src="img/GraphZoomOutHorizontal.ico" onClick={e=>zoomOut(true, false)} alt="autoscale X"/> }
                {bVisVZoomOut && <img className={"GraphHoverIcon GraphZoomOutVertIcon" + clsVis} src="img/GraphZoomOutVertical.ico"   onClick={e=>zoomOut(false,true)}  alt="autoscale Y"/>}
                {bVisHZoomOut && bVisVZoomOut && <img className={"GraphHoverIcon GraphZoomOutBothIcon" + clsVis} src="img/GraphZoomOutBoth.ico" onClick={e => zoomOut(true, true)}  alt="autoscale" />}
                {false && <img className={"GraphHoverIcon SwapXyIcon" + clsVis} src="img/swapXY.ico" onClick={e => swapXYaxes(e)} alt="swap xy" />}
                <svg className={"GraphHoverIcon SwapXyIcon" + clsVis} style={{ backgroundColor: "none", width: 24, height: 24 }} onClick={e => swapXYaxes(e)}>
                    {/*<path d="M0 8 L8 0 L8 4 Q40 8 44 40 L48 40 L40 48 L32 40 L36 40 Q32 16 8 12 L8 16 Z" style={{fill: "lightgray", stroke: "gray"} }/>*/}
                       <path d="M0 6 L4 0 L4 4 Q20 4 20 20 L24 20 L18 24 L12 20 L16 20 Q16  8 4 8 L4 12 Z" style={{ fill: "lightgray", stroke: "gray" }} />
                </svg>
            </>)}
        </div>
    );

    //return { html: html, onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseMove: onMouseMove };
    return html;
}

function GraphPlot({ vid, loc, width, height, ...props })
{
    //console.log("GraphPlot", props)
    const [cubeTimer, setCubeTimer] = useState(0);
    const [showCube, setShowCube] = useState(false);
    const ht = props.isFrameNode || props.bTallNode === 1 ? (height - 1) : height;
    const statusCount = useQuery({ req: 'ensureGraphed', width: width/props.zoom, height: ht/props.zoom }, vid);   // LDC 5/17/2021 Bug S-738, S-25: ensureGraphed must be called before "image" or "plotArea", and its result passed to those as status parameter.


    //console.log("statusCount, showCube, cubeTimer, document?.acpServerConfig?.EW1411_show_cube_on_graph_rendering", statusCount, showCube, cubeTimer, document?.acpServerConfig?.EW1411_show_cube_on_graph_rendering)
    if (!statusCount) {
        //console.log("status count", statusCount)
        if (showCube === false && cubeTimer === 0 && document?.acpServerConfig?.EW1411_show_cube_on_graph_rendering) {
            //console.log("setting timer")
            setCubeTimer(setTimeout(() => { setShowCube(true) }, 1500));
            return null;
        }
        if (showCube === true)
            return <SpinningCubeWithBreakAndProgress bForceShowCube={true} blockScreenClicks={false} setShowCube={setShowCube}/>
    }
    else {
        //console.log("clear cube and timer")
        if (cubeTimer) {
            clearTimeout(cubeTimer);
            setCubeTimer(0);
        }
        if (showCube === true) setShowCube(false);
    }

    return <GraphPlot2 vid={vid} loc={loc} statusCount={statusCount} {...props}/>;
}

function GraphPlot2({vid,loc,statusCount,...props})
{
    //console.log("GraphPlot2", props)
    const [animating, setAnimating] = [props.animating, props.setAnimating];
    const [clickActive, setClickActive] = [props.clickActive, props.setClickActive];
    const textNodeInfo = useEval("nodeinfo Text");
    const frameNodeInfo = useEval("nodeinfo framenode");
    const formnodeNodeInfo = useEval("nodeinfo formnode");

    // LDC 9/28/2020 Suan bug 267. The parent (PlotHolder) keeps becoming 3 pixels larger than
    // the image. I can't figure out why -- padding=margin=border=0 -- should be same size. 
    // Anyway, I finally hard-coded the 3 here. Would be better to figure out the CSS problem. 
    // But without this, the PlotHolder would start at the desired height, but then kept expanding.
    const plot = useQuery({ req: 'image', statusCount:statusCount }, vid);
    //const plotArea = useQuery({ req: "plotArea" }, vid);

    function getBorderRadius(nodeInfo) {
        let borderRadiusStyle = {};
        const nodeInfoArray = nodeInfo ? nodeInfo.split(',') : [];
        if (nodeInfoArray.length > 17 && nodeInfoArray[17].trim().length)
            borderRadiusStyle = { borderRadius: (nodeInfoArray[17].trim() - 1 + "px") };  // 655 // minus 1 based on observartion
        return borderRadiusStyle;
    }

    let imgStyle = {};
    if (props.isFrameNode && props.nodeClass0 === "Text") imgStyle = getBorderRadius(textNodeInfo);
    if (props.isFrameNode && props.nodeClass0 === "FrameNode") imgStyle = getBorderRadius(frameNodeInfo);
    if (props.bTallNode === 1) imgStyle = getBorderRadius(formnodeNodeInfo);

    //loc = {left: 100, top: 25}

    if (!plot || !plot.pict) {
        return null;
    } else if (plot.status === "OK") {
        const pict = plot.pict;
        return (    
            <>
                <img className="PlotImage" width={pict.width} height={pict.height} alt="" src={"data:" + pict.mime + ";base64," + pict.base64} style={imgStyle}/>
                <div className={animating?"clickEffect":""} style={loc} onAnimationEnd={ev=>setAnimating(false)} />
                {/*<div className="PlotArea" style={plotArea}/>*/}
                {clickActive && <GraphClickHandler vid={vid} x={loc && loc.left} y={loc && loc.top} setActive={setClickActive} />}
                {statusCount && <GraphZoomerAndHoverIcons vid={vid} pict={pict} setClickActive={setClickActive} setAnimating={setAnimating} statusCount={statusCount} zoom={props.zoom} oidOrig={props.oidOrig} />}
            </>
        );
    } else {
        return <div className="plotError">{JSON.stringify(plot)}</div>;
    }
}

function GraphPlotHolder({ vid, nodeClass0, isFrameNode, bTallNode, width, height, topOfPlotHolder, setTopOfPlotHolder, setOverGraph, overGraph, graphId, zoom, ...props }) {
    //console.log("GraphPlotHolder", props)

    // The purpose of this guy is to retrieve the view size before generating the graph, in the case where the containing
    // element determines the graph size.
    const [loc, setLoc] = useState(null);
    const [clickActive, setClickActive] = useState(false);
    const [animating, setAnimating] = useState(false);
    const holderRef = useRef();
    const bSizeToParent = !width || !height;
    const [holderRect, setHolderRect] = useState(null);
    const debounceTimeout = useRef(null);

    const zoomGraph = zoom === undefined ? 1 : zoom;

    function click(ev) {

        console.log("Click on graph");
        
        // Good ref of coords: https://www.jacklmoore.com/notes/mouse-position/
        const rect = ev.currentTarget.getBoundingClientRect();
        const x = (ev.clientX - rect.left)/zoomGraph;
        const y = (ev.clientY - rect.top)/zoomGraph;

        WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'click', x: x, y: y });
        setLoc({ left: x, top: y });
        setAnimating(true);
        setClickActive(true);
        ev.stopPropagation();
    }


    // Resize observer to get the current dimensions of holderRef
    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                // Debounce the state update
                if (debounceTimeout.current) {
                    clearTimeout(debounceTimeout.current);
                }

                debounceTimeout.current = setTimeout(() => {
                    setHolderRect(entry.target.getBoundingClientRect());
                }, 400); // 100ms debounce time
            }
        });

        if (holderRef.current) {
            //console.log("observer")
            resizeObserver.observe(holderRef.current);
        }

        return () => {
            if (holderRef.current) {
                resizeObserver.unobserve(holderRef.current);
            }
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        }
    }, [holderRef]);

    useLayoutEffect(() => {
        //console.log("useLayoutEffect", holderRect?.top, topOfPlotHolder)
        //console.log(holderRef?.current?.getBoundingClientRect())
        if (holderRect && topOfPlotHolder !== undefined && topOfPlotHolder !== holderRect.top) {
            //console.log("***************set top of plot holder", holderRect.top, topOfPlotHolder)
            setTopOfPlotHolder(holderRect.top);
        }
    }, [holderRect, topOfPlotHolder]); // Run this effect whenever 'rect' changes
 
    const bNoDraw = !holderRect;

    const size = bSizeToParent ? holderRect : {};

    if (bNoDraw)
        return <div className="PlotHolder" onClick={click} ref={holderRef} />;

    let plotHolderCls = isFrameNode ? "PlotHolder FrameNode" : "PlotHolder";
    if (bTallNode === 1) plotHolderCls += " TallNode";

    return (
        <div id={graphId} className={plotHolderCls} onClick={click} ref={holderRef} onMouseOver={e => setOverGraph(true)} onMouseOut={e => setOverGraph(false)} >
            <GraphPlot vid={vid} loc={loc} width={size.width} height={size.height}
                isFrameNode={isFrameNode} bTallNode={bTallNode} nodeClass0={nodeClass0}
                setAnimating={setAnimating} animating={animating} zoom={zoom} oidOrig={props.oidOrig}
                setClickActive={setClickActive} clickActive={clickActive} setForceShowCube={props.setForceShowCube} bForceShowCube={props.bForceShowCube}/>
        </div>
    );
}

function GraphForVid0(props) {
    //console.log("GraphForVid0", props)
    const setView = props.setView ? props.setView : props.setViewType;

    function msgBoxReplyHandler() {
        console.log("msgBoxReplyHandler", setView);
        setView("MIDM");
        setTimeout(() => { props.setMsgBoxInfoClient(null) }, 100); // timeout is hack fix for message box showing up twice for tall graphs
    }

    const isProb = props.viewType === "MIDM" ? false : IsProb(props.identifier, props.runOid);

    if (props.viewType !== "MIDM" && isProb === false && props.runOid !== undefined) {
        console.log("Value is not probabilistic.  Mid value will be shown instead.");
        if (props.msgBoxInfoClient === null)
            props.setMsgBoxInfoClient({ body: 'Value is not probabilistic.  Mid value will be shown instead.', buttons: 0, caption: "Explanation", responseHandler: msgBoxReplyHandler });
        return null;
    }

    return <GraphForVid {...props} />
}


function GraphForVid(props) {
    //console.log("GraphForVid", props)
    const pivot = useQuery({ req: 'pivot' }, props.vid);
    const [expand, setExpand] = useState(false);
    const [description,] = useObjAtt(props.oidOrig, "Description");
    const [overGraph, setOverGraph] = useState(false);
    const [cloudStylesOrig/*, setCloudStylesOrig*/] = useObjAtt(props.oidOrig, "_CloudPlatformStyles");
    const textNodeInfo = useEval("nodeinfo Text");
    const frameNodeInfo = useEval("nodeinfo FrameNode");
    const formnodeNodeInfo = useEval("nodeinfo formnode");
    const [togglePivoters, setTogglePivoters] = useState(false);
    const [numSlicers, setNumSlicers] = useState(0);
    //let bShowTitle = props.showTitle === undefined || (props.showTitle !== false && props.showTitle !== "no");
    const showDescription = description && props.showDescription;
    const showHeaderTall = cloudStylesOrig && cloudStylesOrig.show_index_menus ? " ShowHeader_" + cloudStylesOrig.show_index_menus : "";
    const showHeaderFrame = props?.frameNodeCloudStyles?.show_index_menus ? " ShowHeader_" + props.frameNodeCloudStyles.show_index_menus : "";
    const showHeader = props.isFrameNode ? showHeaderFrame : showHeaderTall;
    const cls = "Graph" + (overGraph ? " OverGraph" : "") + showHeader;

    function showHidePivoters() {
        setTogglePivoters(!togglePivoters);

        // hackish way of getting embedded view to update 
        setTimeout(() => { props.onMouseOut(); }, 100);
        setTimeout(() => { props.onMouseOver(); }, 200);
    }

    let [bShowPivoters, bShowSlicers] = getShowIndexMenus(props.topCloudStyles, props.frameNodeCloudStyles, cloudStylesOrig, props);
    if (togglePivoters) bShowPivoters = !bShowPivoters;

    const bShowTitle = getCloudStyleSetting("show_title", props?.topCloudStyles, props?.frameNodeCloudStyles, props.cloudStylesOrig, "yes") === "yes";

    const showUncertaintyView = getCloudStyleSetting("show_uncertainty_view", props?.topCloudStyles, props?.frameNodeCloudStyles, props.cloudStylesOrig, "yes");

    const headerIsEmpty = !bShowTitle && (!showDescription || !description) && !bShowPivoters && (!bShowSlicers || numSlicers === 0) && showUncertaintyView === "no"
    const graphHeaderCls = headerIsEmpty ? "GraphHeader  EmptyHeader" : "GraphHeader";

    // for framenodes and tall nodes, check corner radius
    let graphHeaderMargin = 9; // the default for framenodes and tall nodes, unless set otherwise in the nodeinfo
    let style = {};
    if ((props.isFrameNode || props.bTallNode === 1) && !headerIsEmpty) {
        if (props.isFrameNode && props.nodeClass0 === "Text") graphHeaderMargin = getTableOrGraphMargin(textNodeInfo);
        if (props.isFrameNode && props.nodeClass0 === "FrameNode") graphHeaderMargin = getTableOrGraphMargin(frameNodeInfo);
        if (props.bTallNode === 1) graphHeaderMargin = getTableOrGraphMargin(formnodeNodeInfo);
        style = { paddingBottom: "0px", paddingLeft: 9 /*graphHeaderMargin 1997*/, paddingRight: graphHeaderMargin, paddingTop: graphHeaderMargin, borderTopLeftRadius: graphHeaderMargin, borderTopRightRadius: graphHeaderMargin }
    }

    const showGraphTableIcon = getCloudStyleSetting("show_graph_table_icon", props?.topCloudStyles, props?.frameNodeCloudStyles, cloudStylesOrig, "yes")

    const setView = props.setView ? props.setView : props.setViewType;

    // LDC S-1198 -- While debugging S-1198, I thought that maybe this random number might be causing GraphPlotHolder
    // to invalidate every time it runs through this code, leading to continual invalidations / redraws.  
    // The main culprit turned out to be something else, but since the vid exists already and provides a unique graphId,
    // I changed it to be that, which eliminates the uncertainty about whether it is causing problems.
    //const graphId = Math.floor(Math.random() * 100000000).toString();  // will be used for copy graph
    const graphId = props.vid;

    function mouseEnter(e) {
        //console.log("mouse enter title", props);
        const showDescription = props?.cloudStylesOrig?.show_description ? props?.cloudStylesOrig?.show_description : props?.cloudStyles?.show_description;
        if ((showDescription === "no" || showDescription === "0") && props.onMouseOverNode && !props.isFrameNode) {
            props.onMouseOverNode("embedded node title");
        }
        else if (props.isFrameNode) {
            const showDesc = props?.frameNodeCloudStyles?.show_description ? props?.frameNodeCloudStyles?.show_description : props?.topCloudStyles?.show_description;
            if ((showDesc === "no" || showDesc === "0") && props.onMouseOverNode) {
                props.setDescriptionFrameNode(description);
                props.onMouseOverNode("embedded node title");
            }
        }
    }

    function mouseLeave(e) {
        //console.log("mouse leave title", props)
        if (!props.isFrameNode) {// tall node
            const showDescription = props?.cloudStylesOrig?.show_description ? props?.cloudStylesOrig?.show_description : props?.cloudStyles?.show_description;
            if ((showDescription === "no" || showDescription === "0") && props.onMouseOutNode)
                props.onMouseOutNode("embedded node title");
        } else if (props.isFrameNode) {
            const showDesc = props?.frameNodeCloudStyles?.show_description ? props?.frameNodeCloudStyles?.show_description : props?.topCloudStyles?.show_description;
            if ((showDesc === "no" || showDesc === "0") && props.onMouseOutNode)
                props.onMouseOutNode("embedded node title");
        }
    }

    return (
        <div className={cls}>
            <>
                <div className={graphHeaderCls} onClick={e => e.stopPropagation()} style={style} onMouseLeave={mouseLeave} onMouseEnter={mouseEnter}>
                    <ResultTitle oid={props.oid} view={props.viewType} setView={setView} toggleTableGraphResult={props.toggleTableGraphResult} isGraph="yes" isFrameNode={props.isFrameNode} cloudStyles={props.cloudStyles} cloudStylesOrig={cloudStylesOrig} topCloudStyles={props.topCloudStyles} graphId={graphId} zoom={props.zoom}
                        showHidePivoters={showHidePivoters} showHeader={showHeader} mouseEntered={props.mouseEntered} showGraphTableIcon={showGraphTableIcon} frameNodeCloudStyles={props.frameNodeCloudStyles} bTallNode={props.bTallNode} bShowTitle={bShowTitle} oidOrig={props.oidOrig} hasAcp1Tabs={props.hasAcp1Tabs} setSelectedTab={props.setSelectedTab}
                        frameNodeOid={props.frameNodeOid} setShowObjectView={props.setShowObjectView }
                 />
            </div>
            <div className="GraphHeader2">
                {!!showDescription && <div className="Description" style={{ maxHeight: props.showDescription }}><Lineify text={description} /></div>}
                {expand ?
                    <>
                        <h1 class="PivotDebug"><span className="Expander Collapse" onClick={(ev)=>setExpand(false)}>[-]</span>pivot is</h1>
                        <div>{JSON.stringify(pivot)}</div>
                    </> :
                    <span className="Expander Expand PivotDebug" onClick={(ev)=>setExpand(true)}>[+]</span>
                }
                    {(bShowPivoters || bShowSlicers) && <GraphPivotSelection vid={props.vid} pivot={pivot} isFrameNode={props.isFrameNode} bShowPivoters={bShowPivoters} setNumSlicers={setNumSlicers} zoom={props.zoom} />}
            </div>
            </>
            <GraphPlotHolder pivot={pivot} {...props} setOverGraph={setOverGraph} overGraph={overGraph} graphId={graphId} setForceShowCube={props.setForceShowCube} bForceShowCube={props.bForceShowCube} oidOrig={props.oidOrig}/>
        </div>
    );
}

var prevVid = null;
//var prevCurQ = null;

export function GraphForViewType(props)
{
    //console.log("GraphForViewType", props);
    const oid = props.oid;
    const curQ = { req: 'graph', oid: oid, view: props.viewType, width: props.width, height: props.height, proactive:props.proactive };
    const vid = useQuery(curQ);
    const [orig,] = useObjAtt(props.oid, '_original');
    const [identfier,] = useObjAtt(orig?.oid, "identifier");
    const runOid = useEval("HandleFromIdentifier('run')")?.oid;

    if (vid !== prevVid) {
        //if (prevCurQ && curQ.height !== prevCurQ.height) {
        //    console.log("New vid=",vid," height ", prevCurQ.height, " to ", curQ.height);
        //}
        prevVid = vid;
        //prevCurQ = curQ;
    }
    if (!vid) return <GraphStartingUp {...props} />;

    return <GraphForVid0 vid={vid} {...props} oidOrig={orig.oid} runOid={runOid} identifier={identfier} />;
}

export function Graph(props)
{
    const [viewType, setViewType] = useObjAtt(props.oid, "_resultViewType");
    if (!viewType) return null;

    return <GraphForViewType viewType={viewType} setViewType={setViewType} {...props} width={props.tabContentWidth} height={props.heightOfGraph} />;
}
