// Toolbar.js

import React from 'react';
import { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ServerConnectionButton } from './ServerConnectionButton.js';
import { WebSocket_Send, WebSocket_Close, getWindowsUserId } from './webSocketClient.js';
import { useTopLevelOid, useCloudStyles, useObjAtt, useEval, useEvalOnce } from './serverState.js';
import { ToolbarTip } from './toolTip';
import { useLayoutRect } from './miscTools.js';
import { InputChoice } from './choice.js';
import "./styles/Toolbar.scss";

function RunningInDebug()
{
    // LDC  2/17/2021 Suan 437. I'd like this to detect if we are running under "npm start", and return false if we're running 
    // an image that has been built by "npm run-script build". The __DEV__ variable does not reveal that, despite what docs say.
    // The port is not exactly right, but close enough.
    return window.location.port === "3000";
}

// When we deploy ACP on our main server, we want our users to be able to submit bug reports. 
// But when we give ACP to customers to deploy on their own servers, we want to disable this feature.
// One reason is that it needs to send an email, which requires email credentials, etc., to be set
// (in the ReportIssue.vbs file).  There isn't an easy configuration for client-side features that
// doesn't require recompilation, so we decided to configure this feature by either including the
// ReportIssue.vbs file on the server, or not including it.  This, a simple ping to the file to 
// see if it returns a 200 (OK) or a 404 (Page does not exist) is enough to determine whether the
// feature is available. This function does that ping.

function useServerAcceptsIssueReports()
{
    // We are going to check whether the ReportIssue page exists on the server. This determines whether the 
    // "Report issue" features are available.
    const XHR = new XMLHttpRequest();
    const postUrl = window.location.origin + "/acp-cgi/ReportIssue.vbs";
    const [result, setResult] = useState(window.location.host==="localhost:3000" ? true : null);        // Added localhost case to make it easier to edit/debug the form from debug build

    if (result !== null)
        return result;

    XHR.addEventListener("error", (e) => {
        setResult(null);
    });
    XHR.addEventListener("load", (e) => {
        setResult(e.currentTarget.status === 200);
    });

    XHR.open("POST", postUrl);
    XHR.send("ping");

    return result;
}

function UIStyleSelector(props)
{
    return (
        <select className="UIStyleChoice" value={props.uiStyle} onChange={(ev) => props.setUiStyle(ev.currentTarget.value)}>
            <option value="MDI">MDI</option>
            <option value="ACP1">ACP1</option>
        </select>
    );
}

function ResultTableVersionSelector(props)
{
    return (
        <select className="UIResultTableVersion" value={props.resultTableVersion} onChange={(ev) => props.setResultTableVersion(ev.currentTarget.value)}>
            <option value="value">Value</option>
            <option value="table">Table</option>
            <option value="cells">Cells</option>
        </select>
    );
}

export function TopToolbar(props) {
    //console.log("TopToolbar", props);
    const [topOid,] = useTopLevelOid();
    const [showMsgBox, setShowMsgBox] = useState(true);
    const [acpLibUpdateMsgShown, setAcpLibUpdateMsgShown] = useState(false);

    function connectionClosedHandler() {
        window.location.reload();
    }

    useEffect(() => {
        //console.log("status", props.status)
        if (props.status === "closed" && props.msgBoxInfoClient === null && document.acpServerConfig.EW1444_reconnect_when_web_socket_is_closed && showMsgBox) {
            if (showMsgBox) setShowMsgBox(false); // this line prevents box from popping up again
            props.setMsgBoxInfoClient({ body: 'Your connection to ACP has closed.  Click [Ok] to reconnect.', buttons: 0, caption: "Info", responseHandler: connectionClosedHandler });
        }
    })

    return <TopToolbar1 topOid={topOid} {...props} acpLibUpdateMsgShown={acpLibUpdateMsgShown} setAcpLibUpdateMsgShown={setAcpLibUpdateMsgShown} />;
} 

export function SaveAsDialog({ setShow })
{
    const [topOid,] = useTopLevelOid();
    const [origFilename,] = useObjAtt(topOid, "fileinfo");
    if (!topOid || !origFilename) return null;

    // Get only the file name part.
    const reMatch = origFilename && origFilename.match(/([^\\/]+)$/);
    const startFilename = reMatch ? reMatch[1] : "";

    return <SaveAsDialog2 startFilename={startFilename} setShow={setShow} />
}

function SaveAsDialog2({ startFilename, setShow })
{
    const [filename, setFilename] = useState(startFilename);
    const [bDirty, setDirty] = useState(false);
    const fileListing = useEval("TextLowerCase(FileSystemListing(CurrentModelFolder()))");
    const [showOverwriteMsg, setShowOverwriteMsg] = useState(false);
    const [showOverwriteLabel, setShowOverwriteLabel] = useState(false); 

    useEffect(() => {
        if (!bDirty)
            setFilename(startFilename);
    },[startFilename,setFilename]);

    function onChange(ev)
    {
        const val = ev.target.value.replace(/\/\\/g, "");
        if (!bDirty && val !== ev.target.value)
            setDirty(true);

        setFilename(val);
    }

    function onKey(ev)
    {
        if (ev.key === '/' || ev.code === "Backslash" || ev.key=== ':') {
            ev.preventDefault();
            return false;
        }
        if (ev.key === "Enter") {
            ev.preventDefault();
            onSave();
            return false;
        }

        return true;
    }
    function onSave(overwrite)
    {
        console.log("onSave", overwrite)
        if (filename === startFilename && overwrite !== "yes") {
            console.log("set overwrite, OverWriteCurrent")
            setShowOverwriteMsg("OverwriteCurrent");
            return;
        }

        if (filename !== startFilename && fileListing.includes(filename) && overwrite !== "yes") {
            console.log("set overwrite, OverwriteOtherModel")
            setShowOverwriteMsg("OverwriteOtherModel");
            return;
        }
        
        if (filename === startFilename)
            WebSocket_Send({ fn: 'Exec', cmd: 'Save' });
        else {
            const newFileName = filename.toLowerCase().endsWith('.ana') ? filename : filename + ".ana";
            WebSocket_Send({ fn: 'Exec', cmd: 'Save replace in "' + newFileName + '"' });
        }
        
        setShow(false);
    }

    function onCancel()
    {
        setShow(false);
    }

    function onOverwrite(yesOrNo) {
        console.log("on overwrite", yesOrNo)
        if (yesOrNo === "yes")
            onSave("yes")
        else {
            setShowOverwriteMsg(false);
            setShowOverwriteLabel(true);
            setFilename(startFilename);
        }
    }

    if (showOverwriteMsg !== false) {
        const overWriteMsg = showOverwriteMsg === "OverwriteOtherModel" ? "File '" + filename + "' already exists. Do you want to overwrite?" : 'That is the file name of the current model. Do you want to save the model with the same name?';
        return (<div id="SaveAsDialog">
            <div id="Caption">Save this model as...</div>
            <div id="Body">
                <div className="OverWriteMsg">{overWriteMsg}</div>
                <div id="Buttons">
                    <button name="yes" onClick={() => onOverwrite("yes")}>Yes</button>
                    <button name="no" onClick={() => onOverwrite("no")}>No</button>
                </div>
            </div>
        </div>
        );
    }

    const textInputLabel = showOverwriteLabel ? <><span>Give a different file name: </span><br /><br /></> : <span>Name: </span>;

    return (
        <div id="SaveAsDialog">
            <div id="Caption">Save this model as...</div>
            <div id="Body">
            <div id="FilenameInput">
                    {textInputLabel}
                    <input id="FileName" type="text" name="filename" onChange={onChange} value={filename} onKeyDown={onKey} spellCheck="false" />
            </div>
            <div id="Buttons">
                <button name="ok" onClick={onSave}>Save</button>
                <button name="cancel" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

function TopToolbar1(props)
{
    //console.log("TopToolbar1", props);
    const topOid = props.topOid;
    const [modelTitle0,] = useObjAtt(topOid, "_title");
    const [modelDescription,] = useObjAtt(topOid, "description");
    const [showDescTip, setShowDescTip] = useState(false);
    const [descTimeout, setDescTimeout] = useState(0);
    const [showUserIdTip, setShowUserIdTip] = useState(false);
    const [userIdTimeout, setUserIdTimeout] = useState(0);
    const [showProjectTip, setShowProjectTip] = useState(false);
    const [projectTimeout, setProjectTimeout] = useState(0);
    const [showAddProjectTip, setShowAddProjectTip] = useState(false);
    const [addProjectTimeout, setAddProjectTimeout] = useState(0);
    const [showAccountTip, setShowAccountTip] = useState(false);
    const [accountTimeout, setAccountTimeout] = useState(0);
    const [showCubeTip, setShowCubeTip] = useState(false);
    const acpPlanType = useEval("Selected_plan_type");
    const [cubeTimeout, setCubeTimeout] = useState(0);
    const [curDiagId,] = useObjAtt(props.diagramForAcpStylesLib, "identifier");
    const [modelId,] = useObjAtt(topOid, "identifier");
    const [showRightSideControls, setShowRightSideControls] = useState(false);
    const [showConnectionStatus, setShowConnectionStatus] = useState(false);
    const topToolBarRef = useRef();
    const cloudStyles = useCloudStyles(topOid);
    const [titleRef, titleRect] = useLayoutRect();
    const [userIdRef, userIdRect] = useLayoutRect();
    const [projectRef, projectRect] = useLayoutRect();
    const [accountRef, accountRect] = useLayoutRect();
    const styleLibOid = useEval("HandleFromIdentifier('ACP_Styles')");
    const isInStyleLib = useEval("local styleLib := HandleFromIdentifier('ACP_Styles'); local isInStyleLib := null;if (styleLib <> null) then( isInStyleLib := isin of styleLib;);identifier of isInStyleLib");
    const selectSubscription = useEval("HandleFromIdentifier('Fo822373949')")?.oid;
    const [subChoices,] = useObjAtt(selectSubscription, '_choiceOptions');
    const projOid = useEval("HandleFromIdentifier('Select_project')")?.oid;
    const [projChoices,] = useObjAtt(projOid, '_choiceOptions');
    const browseCls = "ToolbarButton " + (props.browseOnly ? " Selected" : " Unselected");
    const editCls = "ToolbarButton " + (props.browseOnly ? " Unselected" : " Selected");
    const isEvite = props.isEvite;
    const bServerAcceptsSupportIssues = useServerAcceptsIssueReports();
    const setShowSaveAsDialog = props.setShowSaveAsDlg;
    const [origFilename,] = useObjAtt(topOid, "fileinfo");

    if (!topOid || !origFilename) return null;

    // Get only the file name part.
    const reMatch = origFilename && origFilename.match(/([^\\/]+)$/);
    const fileName = reMatch ? reMatch[1] : "";

    const modelTitle = cloudStyles?.show_model_title === "no" || modelTitle0 === "Suan Account" ? "" : modelTitle0;

    let showingPortal = false;
    if (props.whichUiPortal === "acpportal")
        showingPortal = true;

    const bShowSupport = !!props.setRequestTechSupport && bServerAcceptsSupportIssues === true;
    const bHasModel = !!topOid && !showingPortal;      // LDC 6/22/2021 ER S-642

    function LogOff() {
        if (props.signOut === undefined) {
            alert("You skipped sign in, so you can't sign out.");
            return;
        }

        if (props.status === "connected") {
            WebSocket_Close(props.setStatus);
        }
        props.signOut();
    }

    function CloseModel() {
        // next line is for EW 1169 & 1128, can be removed when EW DTA 20497 fix is done
        //WebSocket_Send({ fn: 'Exec', cmd: 'Local m:= HandleFromIdentifier("ACP_Styles"); if not IsNull(m) then EvaluateScript("delete all acp_styles")' });

        console.log("close Model", props, cloudStyles);
        console.log(props.bPromptSaveOnClosing === true && props.isEvite === false && props.userRole !== 3)
        if (props.bPromptSaveOnClosing === true && props.isEvite === false && props.userRole !== 3 && cloudStyles?.hide_save_button !== "yes") {
            console.log("close model, prompt user to save changes");
            props.setMsgBoxInfoClient({ body: 'Save changes to model before closing?', buttons: 3, caption: "Question", responseHandler: closeModelResponseHandler });
        }
        else
            closeModel2();
    }

    function closeModel2() {
        if (document.acpServerConfig.EW1367_show_cube_when_closing === true) {
            console.log("show busy");
            props.setForceShowCube(true);
        }

        WebSocket_Send({ fn: 'Exec', cmd: 'Close Model' });

        if (props.setIsEvite) {
            props.setIsEvite(false);
            if (props.status === "connected") {
                WebSocket_Close(props.setStatus);
            }
        }
    }

    function closeModelResponseHandler(button) {
        console.log("closeModelResponseHandler", button);
        if (button === "Yes") saveModel();
        if (button === "Yes" || button === "No") props.setPromptToSaveOnClosing(false); else return;
        closeModel2();
    }
    
    function saveModel() {
        WebSocket_Send({ fn: 'Exec', cmd: 'Save' });
    }

    function toggleDisplayRightSideControls() {
        //setShowRightSideControls(!showRightSideControls);
        window.open("https://analytica.com/products/analytica-cloud-platform/", "_blank");
    }

    function mouseEnteredTitle(evt) {
        setDescTimeout(setTimeout(() => { setShowDescTip(true) }, 500));
    }

    function mouseLeaveTitle(evt) {
        setShowDescTip(false);
        if (descTimeout !== 0) clearTimeout(descTimeout);
    }

    function mouseEnteredUserId(evt) {
        setUserIdTimeout(setTimeout(() => { setShowUserIdTip(true) }, 500));
    }

    function mouseLeaveUserId(evt) {
        setShowUserIdTip(false);
        if (userIdTimeout !== 0) clearTimeout(userIdTimeout);
    }

    function mouseLeaveProject(evt) {
        console.log("mouseLeaveProject()");
        setShowProjectTip(false);
        if (projectTimeout !== 0) clearTimeout(projectTimeout);
    }

    function mouseEnteredProject(evt) {
        console.log("mouseEnterAddProejct()")
        setProjectTimeout(setTimeout(() => { setShowProjectTip(true) }, 500));
    }

    function mouseLeaveAddProject(evt) {
        console.log("mouseLeaveAddProject()");
        setShowAddProjectTip(false);
        if (addProjectTimeout !== 0) clearTimeout(addProjectTimeout);
    }

    function mouseEnteredAddProject(evt) {
        console.log("mouseEnteredAddProject()")
        setAddProjectTimeout(setTimeout(() => { setShowAddProjectTip(true) }, 500));
    }

    function mouseLeaveAccount(evt) {
        setShowAccountTip(false);
        if (accountTimeout !== 0) clearTimeout(accountTimeout);
    }

    function mouseEnteredAccount(evt) {
        setAccountTimeout(setTimeout(() => { setShowAccountTip(true) }, 500));
    }

    function mouseLeaveCube(evt) {
        setShowCubeTip(false);
        if (cubeTimeout !== 0) clearTimeout(cubeTimeout);
    }

    function mouseEnteredCube(evt) {
        setCubeTimeout(setTimeout(() => { setShowCubeTip(true) }, 500));
    }

    let email = "fbrunton@lumina.com";// "signin skipped";
    if (props.user !== undefined && props.user.email !== undefined)
        email = props.user.email;

    if (document?.acpServerConfig?.authenticationMethod === "apacheWindows")
        email = getWindowsUserId();

    if (props.isEvite)
        email = "";

    function ToolbarMenuBtns(props) {
        //console.log("ToolbarMenuBtns", props);
        const [bShowCloseMenu, setShowCloseMenu] = useState(false);
        const [bShowHelpMenu, setShowHelpMenu] = useState(false);
        const [bShowHamburgerMenu, setShowHamburgerMenu] = useState(false);
        const [showHamburgerTip, setShowHamburgerTip] = useState(false);
        const [hamburgerTimeout, setHamburgerTimeout] = useState(0);
        const [showHelpTip, setShowHelpTip] = useState(false);
        const [helpTimeout, setHelpTimeout] = useState(0);
        const [showCloseTip, setShowCloseTip] = useState(false);
        const [closeTimeout, setCloseTimeout] = useState(0);

        function showCloseMenu() {
            setShowCloseMenu(!bShowCloseMenu);
            setShowHelpMenu(false);
            setShowHamburgerMenu(false);
            props.setShowConnectionStatus(false);
        }

        function showHelpMenu() {
            setShowHelpMenu(!bShowHelpMenu);
            setShowCloseMenu(false);
            setShowHamburgerMenu(false);
            props.setShowConnectionStatus(false);
        }

        function showHamburgerMenu() {
            setShowHamburgerMenu(!bShowHamburgerMenu);
            setShowCloseMenu(false);
            setShowHelpMenu(false);
            props.setShowConnectionStatus(false);
        }

        function mouseEnteredHamburger(evt) {
            setHamburgerTimeout(setTimeout(() => { setShowHamburgerTip(true) }, 500));
        }
        
        function mouseLeaveHamburger(evt) {
            setShowHamburgerTip(false);
            if (hamburgerTimeout !== 0) clearTimeout(hamburgerTimeout);
        }
        
        function mouseEnteredHelp(evt) {
            setHelpTimeout(setTimeout(() => { setShowHelpTip(true) }, 500));
        }

        function mouseLeaveHelp(evt) {
            setShowHelpTip(false);
            if (helpTimeout !== 0) clearTimeout(helpTimeout);
        }

        function mouseEnteredClose(evt) {
            setCloseTimeout(setTimeout(() => { setShowCloseTip(true) }, 500));
        }

        function mouseLeaveClose(evt) {
            setShowCloseTip(false);
            if (closeTimeout !== 0) clearTimeout(closeTimeout);
        }

        const closeMenuButnClass = document?.acpServerConfig?.showSignOutMenuItems === "no" && showingPortal ? "CloseMenuBtn Hide" : "CloseMenuBtn";

        //const bDownloadModelAllowed = bHasModel && document?.acpServerConfig?.EW1518_allow_model_download & cloudStyles?.save_menu_download !== "no"; 
        const bAcpStylesMenuItem = bHasModel && !isEvite && document?.acpServerConfig?.showAddAcpStyleLibrary;
        const bShowHamburgerMenuIcon = bAcpStylesMenuItem; // || bDownloadModelAllowed

        return (
            <>
                {!!bShowHamburgerMenuIcon && <div>
                    <div onClick={showHamburgerMenu}><img id="HamburgerMenuBtn" src="img/HamburgerMenu.png" style={{ height: 24, width: 24 }} alt="Hamburger" onMouseEnter={mouseEnteredHamburger} onMouseLeave={mouseLeaveHamburger} /></div>
                    {bShowHamburgerMenu && <div className="CloseMenuBtnSquare" onClick={showHamburgerMenu}></div>}
                </div>}
                <div>
                    <div onClick={showHelpMenu} onMouseEnter={mouseEnteredHelp} onMouseLeave={mouseLeaveHelp} ><img id="HelpMenuBtn" src="img/QuestionMarkIconTopToolbar.png" style={{ height: 24, width: 24 }} alt="Help" /></div>
                    {bShowHelpMenu && <div className="CloseMenuBtnSquare" onClick={showHelpMenu}></div>}
                </div>
                <div>
                    <div id="closeMenuButtonDiv" onClick={showCloseMenu} onMouseEnter={mouseEnteredClose} onMouseLeave={mouseLeaveClose} ><img className={closeMenuButnClass} src="img/CloseMenuBtn.png" style={{ height: 24, width: 24 }} alt="Close" /></div>
                    {bShowCloseMenu && <div className="CloseMenuBtnSquare" onClick={showCloseMenu}></div>}
                </div>
                {(bShowCloseMenu || bShowHelpMenu || bShowHamburgerMenu) && <ToolbarMenu bShowCloseMenu={bShowCloseMenu} setShowCloseMenu={setShowCloseMenu} bShowHelpMenu={bShowHelpMenu} setShowHelpMenu={setShowHelpMenu} setAcpLibUpdateMsgShown={props.setAcpLibUpdateMsgShown} acpLibUpdateMsgShown={props.acpLibUpdateMsgShown} modelFilePath={props.modelFilePath}
                    bShowHamburgerMenu={bShowHamburgerMenu} setShowHamburgerMenu={setShowHamburgerMenu} whichUiPortal={props.whichUiPortal} setRequestTechSupport={props.setRequestTechSupport} setBusy={props.setBusy} setMsgBoxInfoClient={props.setMsgBoxInfoClient} styleLibOid={styleLibOid} isInStyleLib={isInStyleLib}/>}
                {showHamburgerTip && <ToolbarTip objRect={null} control="Hamburger" tipText="Press to see a menu of options that affect this model." />}
                {showHelpTip && <ToolbarTip objRect={null} control="Help" tipText="Press to see a menu of options for more information about ACP or this model or to report an issue to tech support." />}
                {showCloseTip && <ToolbarTip objRect={null} control="Close" tipText=" Press to see a menu of options to save, close, or restart this ACP model." />}
            </>
        );
    }

    function ToolbarMenuItem(props) {

        function onMouseOver(event) {
            props.setSelectedMenu(props.label);
        }

        function onClick() {
            props.handleClick(props.label);
        }

        let className = "CloseMenuItem";
        if (props.selectedMenu === props.label)
            className += " Selected";

        return (
            <div className={className} onMouseOver={onMouseOver} onClick={onClick}>{props.label}</div>
            );
    }
    
    function ToolbarMenu(props) {
        const closeMenuRef = useRef();
        const [selectedMenu, setSelectedMenu] = useState(null);
        const acpLibUpdateMsgShown = props.acpLibUpdateMsgShown;
        const setAcpLibUpdateMsgShown = props.setAcpLibUpdateMsgShown;
        const bShowCloseMenu = props.bShowCloseMenu;
        const bShowHelpMenu = props.bShowHelpMenu;
        const bShowHamburgerMenu = props.bShowHamburgerMenu;
        const styleLibOid = props.styleLibOid;
        const isInStyleLib = props.isInStyleLib;

        function onMouseLeave(event) {
            props.setShowCloseMenu(false);
            props.setShowHelpMenu(false);
            props.setShowHamburgerMenu(false);
        }

        let customLinkText = "";
        let customLink = "";
        if (cloudStyles) {
            for (let x in cloudStyles) {
                // because of the way the json is returned from the server, 
                // that's why all these gyrations.
                if (x.indexOf("add_help_menu_option") === 0 && x?.indexOf(":") !== -1 && x?.indexOf("|") !== -1) {
                    customLinkText = x.substring(x?.indexOf(":") + 1, x.indexOf("|")).trim();
                    let customLinkSplit = customLinkText.split("");
                    customLinkSplit[0] = customLinkSplit[0].toUpperCase(); // make first letter of text upper case i.e. sentence case
                    customLinkText = customLinkSplit.join("");
                    customLink = x.substring(x.indexOf("|") + 1) + ":" + cloudStyles[x];
                }
            }
        }
        const bHasCustomHelpLink = (customLink !== "" && customLinkText !== "") ? true : false;

        function handleHelpLink() {
            window.open(customLink, "_blank");
        }

        function handleStyleLibMsgBox(button) {
            console.log("handleStyleLibMsgBox", button);
            setAcpLibUpdateMsgShown(true);
            if (button === "No") {
                console.log("deleting acp lib")
                const deleteLibCmd = 'EvaluateScript("delete all acp_styles")';
                WebSocket_Send({ fn: 'Exec', cmd: deleteLibCmd });
            }

            addStyleLib();
        }

        function handleDownloadFile() {
            const downloadCmd = "DownloadFileToClient('" + props.modelFilePath + "')";
            console.log("handleDownloadFile()", downloadCmd);
            WebSocket_Send({ fn: 'Exec', cmd: downloadCmd });
        }

        function addStyleLib() {
            console.log("addStyleLib()");
            let cmdOrphans = 'Local m:= HandleFromIdentifier("ACP_Styles"); if IsNull(m) then m:= AddModuleOrLibrary("[Libraries]\\ACP Style Library.ana", orphans, link: false, merge: true);evaluatescript("definition ACP_Top_model: handle(' + modelId + ')");EvaluateScript("ACP_Run_on_import");';
            cmdOrphans += 'Local a:= HandleFromIdentifier("ACP_styles_added"); if IsNull(a) then ( EvaluateScript("Variable ACP_styles_added"); EvaluateScript("definition ACP_styles_added: true");EvaluateScript("ChangeNodeVisibility(handle(ACP_styles_added), ""hidden"")"));EvaluateScript("ACP_Set_user_inputs_");ShowWindow(m,"Diagram");';
            console.log(cmdOrphans);
            WebSocket_Send({ fn: 'Exec', cmd: cmdOrphans });
            //props.setStyleLibAdded(true);
        }

        function handleClick(menuLabel) {
            switch (menuLabel) {
                case "Close this model":
                    CloseModel();
                    break;
                case "Save this model":
                    saveModel();
                    break;
                case "Save model as...":
                    setShowSaveAsDialog(true);
                    break;
                case "Sign out of ACP":
                    LogOff();
                    break;
                case "Restart ACP":
                    window.location.reload();
                    break;
                /*case "Report issue...":                    // LDC 6/18/2021 ER S-642
                    props.setRequestTechSupport("bugOrDown");
                    break;*/
                case "Report a bug or get tech support...":                    // LDC 6/18/2021 ER S-642
                    props.setRequestTechSupport("support");
                    break;
                case "ACP Online docs":                    // LDC 6/18/2021 ER S-642
                    window.open("https://docs.analytica.com/index.php?title=Analytica_Cloud_Platform", "_blank");
                    break;
                case "Analytica Q&A Forum":                    // LDC 6/18/2021 ER S-642
                    window.open("https://analytica.com/community/", "_blank");
                    break;
                case "Connection status":
                    setShowConnectionStatus(true);
                    break;
                case "ACP Style Library":
                    console.log("ACP Style Library**", acpLibUpdateMsgShown);
                    setAcpLibUpdateMsgShown(true);
                    if (styleLibOid?.oid > 0 && isInStyleLib !== "Orphans" && !acpLibUpdateMsgShown) {
                        console.log("setting mgx box client", setAcpLibUpdateMsgShown);
                        setAcpLibUpdateMsgShown(true);
                        props.setMsgBoxInfoClient({ body: 'This model already contains a version of the ACP Style library. Would you like to show that version [Yes] or replace it with the latest version [No]?', buttons: 4, caption: "Question", responseHandler: handleStyleLibMsgBox });
                    }
                    else {
                        addStyleLib();
                    }
                    break;
                case "Download Analytica model":
                    console.log("download model", props);
                    if (cloudStyles?.save_menu_download === "prompt_to_download_free")
                        props.setMsgBoxInfoClient({ body: 'DownloadMessageToolbar', buttons: 0, caption: "Info", responseHandler: handleDownloadFile });
                    else {
                        const downloadCmd = "DownloadFileToClient('" + props.modelFilePath + "')";
                        console.log(downloadCmd)
                        WebSocket_Send({ fn: 'Exec', cmd: downloadCmd });
                    }
                    break;
                default:
                    break;
       
            }
            props.setShowCloseMenu(false);
        }


        const showAcpOnlineDocs = cloudStyles?.remove_help_menu_options && cloudStyles?.remove_help_menu_options?.indexOf("acp_online_docs") !== -1 ? false : true;
        const showQaForum = cloudStyles?.remove_help_menu_options && cloudStyles?.remove_help_menu_options?.indexOf("analytica_q_a_forum") !== -1 ? false : true;
        const showTechSupport = cloudStyles?.remove_help_menu_options && cloudStyles?.remove_help_menu_options?.indexOf("tech_support") !== -1 ? false : true;
        const bDownloadModelAllowed = bHasModel && document?.acpServerConfig?.EW1518_allow_model_download && (cloudStyles?.save_menu_download === "yes" || cloudStyles?.save_menu_download ==="prompt_to_download_free");
        const bAcpStylesMenuItem = bHasModel && !isEvite && document?.acpServerConfig?.showAddAcpStyleLibrary;

        const html = (
            <div className="CloseMenu" ref={closeMenuRef} onMouseLeave={onMouseLeave}>
                {(bShowCloseMenu && bHasModel) && <ToolbarMenuItem label="Close this model" selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleClick={handleClick} />}
                {(bShowCloseMenu && bHasModel && !isEvite && cloudStyles?.hide_save_button !== "yes") && <ToolbarMenuItem label="Save this model" selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleClick={handleClick} />}
                {(bShowCloseMenu && bHasModel && !isEvite && cloudStyles?.hide_save_button !== "yes") && <ToolbarMenuItem label="Save model as..." selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleClick={handleClick} />}
                {!!(bShowCloseMenu && bDownloadModelAllowed) && <ToolbarMenuItem label="Download Analytica model" selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleClick={handleClick} />}
                {(bShowCloseMenu && !isEvite && document?.acpServerConfig?.showSignOutMenuItems !== "no") && <ToolbarMenuItem label="Sign out of ACP" selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleClick={handleClick} />}
                {(bShowCloseMenu && !showingPortal) && <ToolbarMenuItem label="Restart ACP" selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleClick={handleClick} />}
                {(bShowHelpMenu && bShowSupport && bHasCustomHelpLink) && <ToolbarMenuItem label={customLinkText} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleClick={handleHelpLink} />}
                {(bShowHelpMenu && showAcpOnlineDocs) && <ToolbarMenuItem label="ACP Online docs" selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleClick={handleClick} />}
                {(bShowHelpMenu && showQaForum) && <ToolbarMenuItem label="Analytica Q&A Forum" selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleClick={handleClick} />}
                {(bShowHelpMenu && bShowSupport && showTechSupport) && <ToolbarMenuItem label="Report a bug or get tech support..." selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleClick={handleClick} />}
                {bShowHelpMenu && <ToolbarMenuItem label="Connection status" selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleClick={handleClick} />}
                {(bShowHamburgerMenu && bAcpStylesMenuItem) && <ToolbarMenuItem label="ACP Style Library" selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} handleClick={handleClick} />}
            </div>
        );

        return ReactDOM.createPortal(html, document.getElementById('modal-root'));
    }

    function ShowCreateNewProjectForm() {
        console.log("ShowCreateNewProjectForm()");

        // clear status text
        WebSocket_Send({ fn: 'Exec', cmd: 'description of addProjectStatusText :' });

        // set state variable which says to display the create project ui
        props.setShowCreateProject(true);
        setShowAddProjectTip(false);
    }

    function CancelNewProject() {
        props.setShowCreateProject(false);
    }


    const bDebug = RunningInDebug();

    //if (props.status === "closed") window.location.reload(); // refresh page 
    const analyticaTitleCls = showingPortal ? "AnalyticaTitle Hide" : "AnalyticaTitle Hide";

    const toolbarCls = showingPortal ? "TopToolbar Portal" : "TopToolbar";

    let accountTipText = "Your ACP Account.";   
    if (subChoices?.opts?.length > 1) accountTipText += " Press for a menu to select another account.";
    let projectTipText = "Your ACP Project."
    if (projChoices?.opts?.length > 1) projectTipText += " Press for a menu to select another project.";

    return (
        <div className={toolbarCls} ref={topToolBarRef}>
            {props.uiStyle==="MDI" && (<>
                <img id="OutlineBtn" className="ToolbarButton" src="img/OutlinerIcon.ico" alt="Outline" onClick={(ev)=>props.setShowOutliner(!props.bShowOutliner)}/>
                {false && (<img id="ResultBtn" className="ToolbarButton" alt="Result" src="img/result.ico"/>)}
                <img id="BrowseBtn" className={browseCls} src="img/browseBtn.png" alt="Browse" onClick={(ev)=>props.setBrowseOnly(true)}/>
                <img id="EditBtn" className={editCls}  src="img/editBtn.png" alt="Edit" onClick={(ev)=>props.setBrowseOnly(false)}/>
            </>)}
            <span onMouseEnter={mouseEnteredCube} onMouseLeave={mouseLeaveCube}><img id="SpinningCubeBtn" src="img/appIcon3.png" alt="spin" onMouseLeave={mouseLeaveCube} onClick={toggleDisplayRightSideControls} /*onClick={e => props.setForceShowCube(!props.bForceShowCube)}*/ /></span>
            <div className="ModelTitle" onMouseEnter={mouseEnteredTitle} onMouseLeave={mouseLeaveTitle} ref={titleRef} >{modelTitle}</div>
            <div className={analyticaTitleCls} >Analytica</div>
            <AcpPortalAccount mouseEnteredAccount={mouseEnteredAccount} mouseLeaveAccount={mouseLeaveAccount} />
            {acpPlanType && acpPlanType !== "Individual" && modelId && modelId === "Suan_Account" && <AcpPortalProjectStripe createProject={ShowCreateNewProjectForm} showProjectButtons={props.showProjectButtons} setProjectForToolbar={props.setProjectForToolbar} setAccountNameForToolbar={props.setAccountNameForToolbar} permissions={props.permissions} userRole={props.userRole} setUserRole={props.setUserRole} mouseEnteredProject={mouseEnteredProject} mouseLeaveProject={mouseLeaveProject} mouseEnteredAddProject={mouseEnteredAddProject} mouseLeaveAddProject={mouseLeaveAddProject} />}
            <div id="RightSideButtons" >
                <div id="UserAndProject">
                    <div id="UserId" ref={userIdRef} onMouseEnter={mouseEnteredUserId} onMouseLeave={mouseLeaveUserId} >{email}</div>
                    {/*(!!props.projectForToolbar /*&& props.whichUiPortal !== "acpportal") && <div id="ProjectName" ref={projectRef} onMouseEnter={mouseEnteredProject} onMouseLeave={mouseLeaveProject}>{props.projectForToolbar}</div>*/}
                    {/*(!!props.accountNameForToolbar /*&& props.whichUiPortal !== "acpportal") && <div id="AccountName" ref={accountRef} onMouseEnter={mouseEnteredAccount} onMouseLeave={mouseLeaveAccount} >{props.accountNameForToolbar}</div>*/}
                </div>
                <ToolbarMenuBtns whichUiPortal={props.whichUiPortal} setRequestTechSupport={props.setRequestTechSupport} setShowConnectionStatus={setShowConnectionStatus} setBusy={props.setBusy} setMsgBoxInfoClient={props.setMsgBoxInfoClient} acpLibUpdateMsgShown={props.acpLibUpdateMsgShown} setAcpLibUpdateMsgShown={props.setAcpLibUpdateMsgShown} modelFilePath={props.modelFilePath}/>
                {/*{props.user && <div id="UserIdentity">{props.user.email}</div>}*/}
                {showRightSideControls && <span>{bDebug && (<>RT:<ResultTableVersionSelector {...props} /></>)}
                    {bDebug && (<> UI:<UIStyleSelector {...props} /></>)}</span>}
                {showConnectionStatus && <ServerConnectionButton {...props} setShowConnectionStatus={setShowConnectionStatus}/>}
            </div>
            {showDescTip && <ToolbarTip objRect={titleRect} control="ModelTitle" tipText={modelDescription} fileName={fileName}  />}
            {showUserIdTip && <ToolbarTip objRect={userIdRect} control="UserId" tipText="Your user id as the current ACP user." />}
            {showAccountTip && <ToolbarTip objRect={null} control="Account" tipText={accountTipText} />}
            {showProjectTip && props?.whichUiPortal === "acpportal" && <ToolbarTip objRect={accountRect} control="Project" tipText={projectTipText} />}
            {showAddProjectTip && props?.whichUiPortal === "acpportal" && <ToolbarTip objRect={accountRect} control="AddProject" tipText="Press to add a new project." />}
            {showCubeTip && <ToolbarTip objRect={null} control="Cube" tipText="This web tool is implemented in the Analytica Cloud Platform. Click it for more information" />}
        </div>
    );
}


function AcpPortalAccount(props) {
    const selectSub = useEval("HandleFromIdentifier('Fo822373949')")?.oid;
    const [options,] = useObjAtt(selectSub, '_choiceOptions');


    if (selectSub === undefined) return "";
    if (options?.opts?.length === 1 && options.opts[0].text.includes("@") && options.opts[0].text.includes(".")) {
        console.log("Looks like single individual account", options.opts[0]);
        return "";
    }

    return (
        <div className="AccountContainer" onMouseOver={props.mouseEnteredAccount} onMouseOut={props.mouseLeaveAccount} onMouseDown={props.mouseLeaveAccount}>
            {options && options.opts /*&& options.opts.length > 1*/ && <InputChoice oid={selectSub} isToolbar={true} />}
            {false && options && options.opts && options.opts.length === 1 && <div id="AccountNameStatic"> {options.opts[0].text}</div>}
        </div>
    );
}

function AcpPortalProjectStripe(props) {
    //console.log("AcpPortalProjectStripe", props)
    const projOid = useEval("HandleFromIdentifier('Select_project')")?.oid;
    const permissions = useEval("Permissions", "ACL");
    const selelectedProjectName = useEval("Project_name");
    //const selectedAccountName = useEval("Group_identity");
    const isSubscriptionAdminProject = useEval("is_for_subscription");          // LDC 8/19/2021 S-530. You can't delete the subscription admin project. Its purpose is to track subscription-level permissions.
    const acpPlanType = useEval("Selected_plan_type");

    //const roleId = useEval("Role_Id");

    //console.log("permissions", permissions);

    // super use can create projects amonng other things
    // LDC 8/19/2021 S-530: Changed to a subscription level scheme
    //const isSuperUser = useEval("Is_SuperUser");
    const canCreateProject = permissions && permissions["Create projects"];
    const canDeleteProject = isSubscriptionAdminProject === 0 && permissions && permissions["Delete projects"];

    function DeleteProject() {
        WebSocket_Send({ fn: 'Exec', cmd: "Delete_selected_proj()" });
    }
    /*
    useEffect(() => {
        if (selelectedProjectName !== undefined && props.setProjectForToolbar !== undefined) {
            props.setProjectForToolbar(selelectedProjectName);
        }
        if (selectedAccountName !== undefined && props.setAccountNameForToolbar !== undefined) {
            props.setAccountNameForToolbar(selectedAccountName);
        }
        if (roleId !== undefined && props.setUserRole !== undefined && props.userRole !== roleId) {
            props.setUserRole(roleId);
        }
    });
    */
    if (!projOid || acpPlanType === "Individual") return "";

    return (
        <div className="ProjectContainer">
            <AcpPortalProjectChoice projOid={projOid} selelectedProjectName={selelectedProjectName} {...props} />
            {!!canCreateProject && <button id="CreateProjectButton" onClick={props.createProject} onMouseEnter={props.mouseEnteredAddProject} onMouseLeave={props.mouseLeaveAddProject}>+</button>}
            {!!canDeleteProject && false /*1626 Max: Get delete projects working or remove button*/ && <button id="DeleteProjectButton" onClick={DeleteProject}>-</button>}
        </div>
    );
}

function AcpPortalProjectChoice(props) {
    //const [selProj] = useObjAtt(props.projOid, "definition");
    //console.log("AcpPortalProjectChoice", props)
    return (<span id="Project" onMouseEnter={props.mouseEnteredProject} onMouseLeave={props.mouseLeaveProject} onMouseDown={props.mouseLeaveProject} ><InputChoice oid={props.projOid} bIgnoreControlWidth={true} isToolbar={true} /></span>);      // LDC 8/7/2020 Suan Bug 115
}
