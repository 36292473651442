import React from 'react';
import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import './styles/helpBalloon.scss';
import { useQuery } from './serverState.js';
import { ExpandHyperlinks } from './miscTools.js';

export function HelpBalloon(props) {
    const portalRef = useRef(document.getElementById('root'));
    const helpBalloonRef = useRef();
    const nodeRef = props.nodeRef;
    const [loc, setLoc] = useState(null);
    const [positionAdjusted, setPositionAdjusted] = useState(false);
    const [balloonOnLeft, setBalloonOnLeft] = useState(false);
    const [balloonOnBottom, setBalloonOnBottom] = useState(false);
    const [maxHeightAdjustment, setMaxHeightAdjustment] = useState(0);
    const [closeButtonClicked, setCloseButtonClicked] = useState(false);

    //console.log("HelpBalloon", props)

    const balloonContent = useQuery({ req: 'eval', expr: 'CustomizeBalloonHelp(' + props.idOrig + ', false)' })

    let balloonTitleText = "";
    if (balloonContent !== undefined && balloonContent.length > 0)
        balloonTitleText = balloonContent[0];

    let balloonBodyText = "";
    if (balloonContent !== undefined && balloonContent.length > 1)
        balloonBodyText = balloonContent[1];

    if (props.isFrameNode)
        balloonBodyText = props.descriptionFrameNode;

    // if CustomizeBalloonHelp returns a string rather than an object/array
    // then there's no title e.g. for a module
    if ((typeof balloonContent) == "string") {
        balloonTitleText = "";
        balloonBodyText = balloonContent;
    }

    // EW 314 Max "By default, the help balloon should not show the object title — which is already visible just to the left (or now below)"
    balloonTitleText = "";

    if (loc === null && nodeRef) {
        const r = nodeRef?.current?.getBoundingClientRect();
        if (r) {
            if (props.bIsTallNode === 1 || props.isFrameNode)
                setLoc({ left: (r.x + 3), top: (r.y + 30) });
            else if (props.bSide || props.bSide === undefined) // for nodes and side tabs
                setLoc({ left: (r.x + r.width + 3) , top: r.y  });
            else
                setLoc({ left: r.x, top: r.y + r.height + 5 }) // for top tabs
        }
    }

    useLayoutEffect(() => {

        if (props.bIsTallNode === 1 || props.isFrameNode)  return;

        const portalRect = portalRef?.current?.getBoundingClientRect();
        const hbRect = helpBalloonRef?.current?.getBoundingClientRect();
        const nodeRect = nodeRef?.current?.getBoundingClientRect();
        let newLeft = loc?.left;

        if (positionAdjusted < 4 && balloonBodyText !== "") {

            setPositionAdjusted(positionAdjusted + 1);

            if (hbRect?.right < portalRect?.right - 5 && !balloonOnLeft) {
                //console.log("balloon NOT clipped on right")
            } else {
                setBalloonOnLeft(true);
                newLeft = nodeRect?.left - hbRect?.width - 3;
                setLoc({ left: newLeft, top: loc.top });
            }

            if (hbRect?.bottom > portalRect?.bottom || balloonOnBottom) {
                setBalloonOnBottom(true);
                setLoc({ left: newLeft, top: nodeRect.bottom - hbRect.height })
            }

            if (loc.top < 0) {  //1431
                setMaxHeightAdjustment(loc.top);
                setLoc({ left: loc.left, top: 0 });
            }
            
        }
    }, [helpBalloonRef, portalRef, nodeRef, setLoc, balloonBodyText, positionAdjusted, loc]);

    if (loc === null) return "";

    let style = maxHeightAdjustment < 0 ? { maxHeight: 400 + maxHeightAdjustment, ...loc } : { ...loc }; 

    if (props.showIdInBalloon === "yes" && balloonBodyText !== "")
        balloonBodyText = "(" + props.idOrig + ") " + balloonBodyText;

    if (/\w/.test(balloonBodyText)) balloonBodyText = ExpandHyperlinks(balloonBodyText); // EW 1153 Hyperlink not displaying

    let helpBalloonCls = "HelpBalloonPortal";

    if (props.bIsTallNode === 1 || props.isFrameNode)
        helpBalloonCls += " TallOrFrameNode";
    else if (props.bSide === false)
        helpBalloonCls += " TopTab";
    else {
        helpBalloonCls += balloonOnLeft ? " Left" : " Right";
        helpBalloonCls += balloonOnBottom ? " Bottom" : " Top";
    }

    function closeHelpBalloon(e) {
        console.log("close help balloon");
        e.stopPropagation();
        setCloseButtonClicked(true);
        if (props.onMouseLeave) props?.onMouseLeave();
    }

    const html = (
        <div className={helpBalloonCls} ref={helpBalloonRef} style={style} >
            <div className="HelpBalloonCloseButton" onClick={closeHelpBalloon}>X</div>
            <div className="HelpBalloonTitle">{balloonTitleText}</div>
            <div className="HelpBalloonBody">{balloonBodyText}</div>
        </div>
    );

    const hasWords = /\w/.test(balloonBodyText); // 764

    if (!html || !hasWords || closeButtonClicked) {
        //console.log("don't show help balloon");
        return "";
    };

    return ReactDOM.createPortal(html, document.getElementById('modal-root'));
}
