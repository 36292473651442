// techSupport.js -- A form for reporting issues or requesting tech support
//
// ER S-642
// 

import React from 'react';
import { useState, useRef, useEffect } from 'react';
import "./styles/TechSupport.scss";
import { useEvalOnce, useTopLevelOid, useObjAtts } from './serverState';

function TechSupportIssueTypeRadios({ type, setType })
{
    function select(val)
    {
        setType(val);
    }

    function Radio({ val, text })
    {
        const name = "reportType_" + val;
        return (
            <div className="RadioPair">
                <input type="radio" id={name} name="reportType" value={val} checked={type === val} onChange={(e) => select(val)} />
                <label htmlFor={name}>{text}</label>
            </div>
        );
    }

    return (
        <div className="TechSupportTypeRadios">
            <div className="TypeTitle">I would like to</div>
            <div className="RadioArray">
                <Radio val="support" text="request technical support" />
                <Radio val="bug" text="report a bug or other problem" />
                <Radio val="serverDown" text="report that ACP is not responding or the server is down" />
                <Radio val="ER" text="request an enhancement or new feature" />
                <Radio val="Appreciation" text="send my appreciation to the Analytica team" />
            </div>
        </div>
    );
}

function TextBox({ label, id, val, setVal })
{
    return (
        <div className="LabelTextBoxPair">
            <label htmlFor={id}>{label}</label>
            <input type="text" id={id} name={id} value={val} onChange={(e) => setVal(e.currentTarget.value)} />
        </div>
    );
}

function TextArea({ label, id, val, setVal })
{
    return (
        <div className="LabelTextAreaPair" >
            <label htmlFor={id}>{label}</label>
            <textarea id={id} name={id} value={val} onChange={(e) => setVal(e.currentTarget.value)} />
        </div>
    );
}

function TechSupportUserInfo(props)
{
    const user = props.user;

    return (
        <div className="UserInfo">
            {user && user.email && <input type="hidden" id="login" name="login" value={user.email} />}
            <TextBox label="Your name:" id="displayName" name="displayName" val={props.displayName} setVal={props.setDisplayName} />
            <TextBox label="Email:" id="email" name="email" val={props.email} setVal={props.setEmail}/>
        </div>
    );
}

function ServerDownCaseRadio({ serverDownCase, setServerDownCase })
{
    function Radio({ val, text }) {
        const name = "reportType_" + val;
        return (
            <div className="RadioPair">
                <input type="radio" id={name} name="ServerDownCase" value={val} checked={serverDownCase === val} onChange={(e) => setServerDownCase(val)} />
                <label htmlFor={name}>{text}</label>
            </div>
        );
    }

    return (
        <div id="ServerDownCaseGroup">
            <Radio val="Websocket_unable_to_establish_connection" text="It reported 'Web socket is unable to establish a connection'" />
            <Radio val="Other" text="Other"/>
        </div>
    )
}

/*
function ModelNameEntry({ suggestedModelName })
{
    const [modelName, setModelName] = useState(suggestedModelName);
    return <TextBox label="Model file:" id="model" val={modelName} setVal={setModelName} />;
}
*/

function CurrentModelField({type})
{
    const [topOid,] = useTopLevelOid();
    const [file] = useObjAtts(topOid, ["fileinfo"])

    if (!file) return null;

    const match = (/.*[/\\](.*)/).exec(file);
    const modelName = match ? match[1] : "";

    return (<>
        {/*type === "bug" && <ModelNameEntry suggestedModelName={modelName} />*/}
        <input type="hidden" id="modelpath" name="modelpath" value={file} />
        </>
    );

}

export function TechSupportForm(props)
{
    const user = props.user;
    const [type, setType] = [props.type, props.setType];
    const [email, setEmail] = useState(user && user.email ? user.email : "");
    const [displayName, setDisplayName] = useState(user && user.displayName ? user.displayName : "");
    const [description, setDescription] = useState(null);
    const [steps, setSteps] = useState(null);
    const [serverDownCase, setServerDownCase] = useState("Websocket_unable_to_establish_connection");
    const pid = useEvalOnce('GetProcessInfo("pid")');    
    const formRef = useRef(null);
    
    const status = props.status;
    useEffect(() => {
        if (type === "bugOrDown")
            setType(status === "connected" ? "bug" : "serverDown");
    }, [type, setType, status]);

    if (!type) return null;
    if (type === "bugOrDown") 
        return null; // will rerender after useEffect changes state
    
    function ResetForm()
    {
        setDescription(null);
        setSteps(null);
        setType(null);
    }

    //function urlEncodeFormData(fd)
    //{
    //    let s = '';
    //    for (let pair of fd.entries())
    //    {
    //        if (typeof pair[1] === "string")
    //            s += (s?'&':'') + encodeURIComponent(pair[0]) + '=' + encodeURIComponent(pair[1]);
    //    }
    //    return s;
    //}
    function getURLEncodedForm()
    {
        let s = '';

        const inputs = formRef.current.elements;
        for (let i = 0; i < inputs.length; ++i) {
            const el = inputs[i];
            if (el.type === "radio" && !el.checked) continue;
            if (!el.name) continue;
            s += (s ? '&' : '') + encodeURIComponent(el.name) + '=' + encodeURIComponent(el.value);
        }
        return s;
    }

    function onSend(ev)
    {
        if (!formRef) return;
        ev.preventDefault();
        const XHR = new XMLHttpRequest();
        const postUrl = window.location.origin + "/acp-cgi/ReportIssue.vbs";

        XHR.addEventListener("error", (e) => {
            console.log(e);
            alert("Sorry, something went wrong while submitting the form to the server.")
        });

        XHR.open("POST", postUrl);
        const urlEncodedData = getURLEncodedForm(); // urlEncodeFormData(formData);
        XHR.send(urlEncodedData);    

        ResetForm();

    }
    function onCancel()
    {
        ResetForm();
    }

    const bShowDescription = type !== "serverDown" || serverDownCase === "Other";
    const bShowSteps = bShowDescription && type === "bug";

    return (
        <form className="TechSupport" ref={formRef}>
            <h1>To request tech support or report an issue</h1>
            <input type="hidden" id="webSocketServer" name="webSocketServer" value={props.server} />
            <input type="hidden" id="webSocketStatus" name="webSocketStatus" value={props.status} />
            <input type="hidden" id="isEvite" name="isEvite" value={props.isEvite} />
            {props.isEvite && <input type="hidden" id="invite" name="invite" value={props.invite} />}
            {props.isEvite && <input type="hidden" id="code" name="invite-code" value={props.code} />}
            <input type="hidden" id="pid" name="processId" value={pid} />
            <input type="hidden" id="server" name="server" value={window.location.origin} />
            <input type="hidden" id="account" name="account" value={user && user.email ? user.email : "N/A"} />
            <TechSupportIssueTypeRadios type={type} setType={setType} />
            <TechSupportUserInfo {...props} email={email} setEmail={setEmail} displayName={displayName} setDisplayName={setDisplayName} />
            <CurrentModelField type={type} />
            {type === "serverDown" && <ServerDownCaseRadio serverDownCase={serverDownCase} setServerDownCase={setServerDownCase} />}
            {bShowDescription && <TextArea label="Description:" id="Description" name="Description" val={description === null ? "" : description}
                                            setVal={setDescription} />}
            {bShowSteps && <TextArea label="Steps to reproduce:"
                            sublabel="Please be as specific as possible to enable us to reproduce the problem in at least one case"
                            id="Steps" name="Steps" val={steps===null?"":steps} setVal={setSteps} />}
            <div className="Buttons">
                <button className="btn" onClick={onSend}>Send</button>
                <button className="btn" onClick={onCancel}>Cancel</button>
            </div>
        </form>
    );
}
