import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import firebase from 'firebase/app';        // LDC 3/23/2021 Suan bug 673. Syntactic change req after upgrade to Firebase version 8.0.0
import 'firebase/auth';
import { App } from './index.js';
import './styles/login.scss';


const firebaseConfig = {
    apiKey: "AIzaSyCVqz6_VhjU8b8vQ9iMoU6wyiPfP3O8scI",
    authDomain: "suan-eb18f.firebaseapp.com",
    databaseURL: "https://suan-eb18f.firebaseio.com",
    projectId: "suan-eb18f",
    storageBucket: "suan-eb18f.appspot.com",
    messagingSenderId: "1044711870549",
    appId: "1:1044711870549:web:f0dea8a0e7059819b284dc",
    measurementId: "G-YCS65CJZ87"
};

firebase.initializeApp(firebaseConfig);

var gSentVerificationFor = null;

// Used for auto-login from DTA, Manage published models or publish to cloud.
function AuthDecode(auth)
{
    const len = auth.length;
    if (len < 8) return null;

    const A2N = (ch)=> ch.charCodeAt(0) - 'a'.charCodeAt('a');

    function DecodeNumber(s)
    {
        let result = 0;
        let mult = 1;
        for (let i = 0; i < 4 ; ++i) {
            result += A2N(s[i])* mult;
            mult *= 16;
        }
        return result;
    }
    function PseudoRandomSeq(seed, n)
    {
        const result = [];
        for (let i = 0; i < n ; ++i) {
            seed = (seed * 13 + 127) % 65536;
            result.push(Math.floor(seed / 23 % 256));
        }
        return result;
    }
    function DecodeWord(encoded,n,seed)
    {
        if (n > 256) n = 255;
        const seq = PseudoRandomSeq(seed, n);
        let result = [];

        for (let i = 0; i < n / 2 ; ++i) {
            const c1 = A2N(encoded[2 * i]);
            const c2 = A2N(encoded[2 * i + 1]);
            result.push(String.fromCharCode(c2 * 16 + c1 - seq[i]));
        }
        return result.join('');
    }

    const seed = DecodeNumber(auth);
    if (!Number.isInteger(seed)) return null;
    const loginLen = DecodeNumber(auth.substring(4));
    if (!Number.isInteger(loginLen) || loginLen + 8 > len) return null;
    const userName = DecodeWord(auth.substring(8), loginLen, seed);
    const pwd = DecodeWord(auth.substring(8 + loginLen), len - loginLen - 8, seed + 1);
    return [userName,pwd];

}

export function LoginPanel(props)
{
    const [email, setEmail] = useState(firebase.auth().currentUser ? firebase.auth().currentUser.email : ""); 
    const [pwd, setPwd] = useState("");
    const [showPwd, setShowPwd] = useState(false);
    const [bGroupAcct, /*setGroupAcct*/] = useState(false);
    //const [groupName, setGroupName] = useState("");
    const [emailErr, setEmailErr] = useState(null);        // controls when error message appears
    const [pwdErr, setPwdErr] = useState(null);
    const [signinButtonDisabled, setSigninButtonDisabled] = useState(false);
    const [signupButtonDisabled, setSignupButtonDisabled] = useState(false);
    const [bProcessedQueryStringAuth, setProcessedQueryStringAuth] = useState(false);
    const [bAutoLogin, setAutoLogin] = useState(0);
    const [insertEmailFromCookie, setInsertEmailFromCookie] = useState(true);

    //const [persistence, SetPersistence] = [props.persistence, props.SetPersistence];

    const doLogin = useCallback(() => {
        // make sure email address is filled
        if (email === "") {
            alert("Please enter an email address.");
            return;
        }

        // make sure password field is filled
        if (pwd === "") {
            alert("Please enter your password.");
            return;
        }

        setCookie("email", email, 500);

        // disable sign in button to give feedback something is happening
        // and they don't click it again
        setSigninButtonDisabled(true);
        const bWasAutoLogin = bAutoLogin;
        setAutoLogin(false);            // so that it tries only once

        firebase.auth().signInWithEmailAndPassword(email, pwd).then(() => {
            const user = firebase.auth().currentUser;
            props.setUser(user);

            setSigninButtonDisabled(false); // probably not necessary, but just in case
            //SetPersistence();

        }).catch(function (error) {
            console.log("login error", error)
            if (error.code === 'auth/wrong-password') {
                alert("Wrong password");
            } else if (error.code === 'auth/user-not-found') {
                alert("Sign in credentials for this email address were not found.  If this is your first time signing into ACP3, press 'Sign up' button to continue.")
            } else if (error.message.includes("INVALID_LOGIN_CREDENTIALS")) {
                alert("Invalid sign in credentials.  The password may be wrong.  Or you may need to Sign Up.");
            } else { 
                alert(error.code + "\r" + error.message);
            }

            if (bWasAutoLogin && error.code !== 'auth/user-not-found') setPwd('');

            setSigninButtonDisabled(false);
            return;
        });
    }, [email, pwd, setSigninButtonDisabled, bAutoLogin, props]);

    useEffect( ()=>{
        if (bAutoLogin && email && pwd && doLogin) {
            doLogin();
            setAutoLogin(false);
        }
    }, [bAutoLogin, email, pwd, doLogin, setAutoLogin] );

    if (!bProcessedQueryStringAuth) {
        if (email === "") {
            const urlParams = new URLSearchParams(window.location.search);
            const auth = urlParams.get('auth');
            if (auth) {
                const [authUser, authPwd] = AuthDecode(auth);
                if (authUser) {
                    setEmail(authUser);
                    if (authPwd) {
                        setPwd(authPwd);
                        setAutoLogin(true);
                    }
                }
            }
        }
        setProcessedQueryStringAuth(true);
    }

    const verifying = props.waitingForUserToVerifyEmail;

    function toggleShowPwd() {
        setShowPwd(!showPwd);
    }

    function ToggleRememberMe(ev)
    {
        // LOCAL = remembered, even after window closed. SESSION = this window/tab only, but retained through refreshes.  NONE=dropped after refreshes.
        const b = ev.target.checked;
        props.setRememberMe(b);
    }

    function Validate()
    {
        // https://en.wikipedia.org/wiki/Email_address
        if (!/^[\w!#$%&'*+-/=?^_`{|}~.]+@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)       // LDC 8/20/2021 S-964. Many special characters are allowed in the local part
            || /(^\.)|(\.\.)|(\.@)|(@\.)|(\.$)|(@-)|(-$)/.test(email)                   // dots can't be two-in-a-row nor first nor last of local part of domain, and hyphen can't be first or last of domain
            ) {   // The . in [.-] is a period, not a wildcard.
            setEmailErr("Enter a valid email address");
            return false;
        }
        if (pwd.length === 0) {
            setPwdErr("Enter a password");
            return false;
        }
        if (pwd.length < 6) {
            setPwdErr("Password too short.");
            return false;
        }
        return true;
    }

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function loginViaGoogle()
    {
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider)/*.then(function(){
            SetPersistence();
        })*/.catch(function(error) {
            setEmail(error.email);

            if (error.code === 'auth/wrong-password') {
                setEmailErr("Wrong password");
            } else if (error.code === 'auth/user-not-found') {
                setEmailErr("Sign in credentials for this email address were not found.  If this is your first time signing into ACP3, press 'Sign up' button to continue.")
            } else if (error.code === "auth/popup-closed-by-user") {
                // don't show error message in this case, it's of not use to end user and is tailored
                // for the developer
            } else {
                setEmailErr(error.code + "\r" + error.message);
            }

            console.log(error);
        });
    }
	/*async function loginViaMicrosoft() {
    try {
        console.log('Microsoft Sign-In button clicked');

        const provider = new firebase.auth.OAuthProvider('microsoft.com');

        // Use popup-based sign-in to avoid cross-origin redirect
        const result = await firebase.auth().signInWithPopup(provider);
        console.log('Microsoft sign-in successful:', result.user);

    } catch (error) {
        console.error('Error during Microsoft sign-in:', error);
    }
}*/
async function loginViaMicrosoft() {
    try {
        const provider = new firebase.auth.OAuthProvider('microsoft.com');
        const result = await firebase.auth().signInWithPopup(provider);

        console.log('Microsoft sign-in successful:', result.user);
        props.setUser(result.user);  // Set the user in the state
        //redirectToACP();  // Redirect to ACP portal

    } catch (error) {
        if (error.code === 'auth/account-exists-with-different-credential') {
            // The account already exists with a different provider
            const email = error.email;
            const pendingCred = error.credential;  // Microsoft credential to be linked

            // Get the Google provider to allow sign-in
            firebase.auth().fetchSignInMethodsForEmail(email).then((methods) => {
                if (methods.includes('google.com')) {
                    // Sign in with Google to link accounts
                    const googleProvider = new firebase.auth.GoogleAuthProvider();
                    firebase.auth().signInWithPopup(googleProvider).then((result) => {
                        // Link Microsoft credentials to the existing Google account
                        return result.user.linkWithCredential(pendingCred);
                    }).then(() => {
                        console.log('Accounts successfully linked.');
                        //redirectToACP();  // Redirect to ACP portal
                    }).catch((error) => {
                        console.error('Error linking accounts:', error);
                    });
                }
            });
        } else {
            console.error('Error during Microsoft sign-in:', error);
        }
    }
}


    function forgotPassword()
    {
        if (!email || email==="") {
            setEmailErr("Please enter your email");
            return;
        }
        firebase.auth().sendPasswordResetEmail(email).then(function () {
            alert("Password reset email sent to " + email + "\nPlease check your email.");
        }).catch(function (error) {

            if (error.code === 'auth/user-not-found') {
                alert("Password cannot be reset because a password for this email address was not found.  Press 'Sign up' button to continue.")
            }
            else
                setEmailErr(error.message);

            console.log(error);

        });
    }

    function signUp()
    {
        if (!Validate()) return;

        setSignupButtonDisabled(true);

        firebase.auth().createUserWithEmailAndPassword(email, pwd).catch(function (error) {
            setSignupButtonDisabled(false);
            if (error.code === 'auth.weak-password') {
                setPwdErr(error.message);
            } else {
                alert(error.message);
            }
            console.log(error);
        });
    }

    function testVerification(ev)
    {
        if (!props.user) 
            return cancelVerification();
        props.user.reload().then(() => {
            if (props.user && !props.user.emailVerified)
                alert("Email verification is still pending.");
            else if (props.user) {
                props.refreshStatus();
                //SetPersistence();
             }
        });
    }
    function resendVerification(ev)
    {
        firebase.auth().createUserWithEmailAndPassword(email, pwd).catch(function (error) {
            alert(error.message);
            console.log(error);
        });
    }
    function cancelVerification(ev)
    {
        firebase.auth().signOut();
    }

    function onChangePasswordInput(event) {
        setPwd(event.target.value);
        setPwdErr(null);
    }

    function onKeyUpInput(event) {
        //alert("onKeyUpInput " + event.keyCode);
        if (event.keyCode === 13)
            doLogin();
    }

    // doLogin(); // Remove this line to show the login dialog. For debugging, easier to auto-skip it

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        console.log("cookie " + document.cookie);
        console.log(getCookie("email"));
        if (insertEmailFromCookie) {
            setInsertEmailFromCookie(false);
            setEmail(getCookie("email"));
        }
    }, [insertEmailFromCookie, setInsertEmailFromCookie, setEmail]);

    const microsoftLogin = document?.acpServerConfig?.EW2055_show_microsoft_signin ? "microsoftLogin" : "microsoftLoginHide";
    const otherLoginMethodsCls = document?.acpServerConfig?.EW2055_show_microsoft_signin ? "SignInSignUp microsoftLogin" : "SignInSignUp";

    return (
        <div className="LoginScreen">
            <img id="luminaLogo" src="img/LuminaLogo.jpg" alt="Lumina decision systems"/>
            <hr/>
            <div id="LoginPanelWrapper">
                <form id="loginPanel">
                    <div id="LogoHolder" ><img src="img/AnalyticaCloudPlatform.png" alt="ACP" />				
                        <div id="BetaLabel" ><a id="releaseNumber" href="https://docs.analytica.com/index.php?title=What%27s_new_in_ACP%3F" target="blank">{document.acpServerConfig.BuildNumber}</a></div><div id="WhatIsACP">
                            <a href="https://docs.analytica.com/index.php?title=Analytica_Cloud_Platform" target="blank">What is ACP?</a></div>
                    </div>              
                    <div id="UseChrome">We recommend using Chromium web browsers (Chrome, Edge, Opera, Brave...) for ACP for now</div>
                    <div id="EmailAndRememberMe"><div id="EmailLabel" className="Label">Email</div>
                        <div id="Email">
                        <input className={"TextBox" + (emailErr ? " Inval" : "")} readOnly={verifying} type="email" autoComplete="username" value={email} autoFocus={!bGroupAcct} onChange={(event) => { setEmailErr(null);setEmail(event.target.value)}} onKeyUp={onKeyUpInput}/>
                        {emailErr && <div className="ErrorBadEntry">{emailErr}</div>}
                        </div>
                        <div id="RememberMe" ><div style={{ width: "23px"}}><div style={{ height:"2px" }}></div><input type="checkbox" id="RememberMeCheck" checked={props.rememberMe} onChange={ToggleRememberMe} /></div><span id="RememberMeLabel">Remember me</span></div>
                    </div>
                    {!verifying && (<>
                        <div id="PasswordRow" >
                            <div id="PwdLabel" className="Label">Password</div>
                            <div id="Password">
                                <input className="TextBox" onKeyUp={onKeyUpInput} type={showPwd ? "text" : "password"} value={pwd} onChange={onChangePasswordInput} autoComplete="current-password" />
                                {pwdErr && <div className="ErrorBadEntry">{pwdErr}</div>}
                            </div>
                            <button type="button" className="ShowPwdIcon HyperlinkLike" id={showPwd ? "hideIt" : "showIt"} onClick={toggleShowPwd} >{showPwd ? (<i className="far fa-eye" />) : (<i className="far fa-eye-slash" />)}</button>
                            <button type="button" id="ForgotPassword" className="HyperlinkLike" onClick={forgotPassword}>Get a new password</button>
                        </div>
                        <div className="SignInSignUp" ><button type="button" id="SignInButton" disabled={signinButtonDisabled} onClick={doLogin}>Sign in</button></div>
                        <div >
                            <div id="OtherLoginMethods" className={otherLoginMethodsCls}>
                                Or sign in with 
                                <button type="button" id="googleLogin" onClick={loginViaGoogle}>
                                    <img className="a12n-provider-icon" alt="Google"
                                        ng-src="//www.gstatic.com/mobilesdk/160512_mobilesdk/auth_service_google.svg"
                                        ng-class="{'a12n-provider-icon-transparency': controller.providerId == controller.Provider.APPLE}"
                                        src="//www.gstatic.com/mobilesdk/160512_mobilesdk/auth_service_google.svg"
                                    />
                                    <span className="OtherLoginLabel">oogle</span>
                                </button>
                                <button type="button" id={microsoftLogin} onClick={loginViaMicrosoft}>Microsoft					
								</button>


                            </div>
                            </div>
                        <div style={{ display: "flex", height: "50px" }}><div className="SignInSignUp"><span id="noAcctYet">Don't have an account yet? <button type="button" id="signupButton" onClick={signUp} disabled={signupButtonDisabled}>Sign up</button></span></div></div>
                        <div style={{ display: "flex", height: "40px" }}><div id="loginPrivacyPolicy" ><a href="https://analytica.com/company/privacy-policy/" target="blank">Privacy policy</a></div><div id="logoSpacer" style={{ width: "75px" }} /><div id="bottomLogo"><img id="LogoBottomRightForm" src="img/LuminaLogo.jpg" alt="Lumina"/></div></div>
                    </>)}
                    {verifying && <>
                        <div id="VerifyingMessage">Waiting for email verification. Please check your email and click on the link that was emailed to you. Click <b>Continue</b> after doing so.</div>
                        <div id="VerifyButtons">
                            <button type="button" id="HaveVerified" onClick={testVerification}>Continue</button>
                            <button type="button" id="ResendVerification" onClick={resendVerification}>Resend email</button>
                            <button type="button" id="ChangeEmail" onClick={cancelVerification}>Use different email</button>
                        </div>
                    </>}

                </form>
            </div>
            <footer>
                <span>Powered by </span><img className="AnalyticaLogo" src="img/analyticaLogo.png" alt="Analytica"/>
            </footer>
        </div>
    );
}


export function AppWithSignIn(props) {
    const [user, setUser] = useState(firebase.auth().currentUser);                     // null = not logged in. Otherwise, userID (which is the user email)
    const [userIsAuthenticated, setUserIsAuthenticated0] = useState(firebase.auth().currentUser && firebase.auth().currentUser.emailVerified );
    const [waitingForUserToVerifyEmail, setWaitingForUserToVerifyEmail] = useState(firebase.auth().currentUser !== null && firebase.auth().currentUser.emailVerified);
    const [authCheckTimer, setAuthCheckTimer] = useState(null);
    const [rememberMe, setRememberMe] = useState(firebase.auth().Persistence === firebase.auth.Auth.Persistence.SESSION);
    const [userIdToken, setUserIdToken] = useState(null);           // The token enables the server-side to validate the user is who he says he is.

    function SetPersistence() {
        const persistence = rememberMe ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION;
        firebase.auth().setPersistence(persistence);
    }
    function setUserIsAuthenticated(b) {
        if (!user) return;

        if (b) {
            user.getIdToken().then(function (idToken) {
                setUserIdToken(idToken);
                setUserIsAuthenticated0(b);
                SetPersistence();
            });
        } else {
            setUserIsAuthenticated0(false);
        }
    }

    function resetVerificationTimer()
    {
        if (authCheckTimer) {
            clearInterval(authCheckTimer);
            setAuthCheckTimer(null);
        }
    }

    function refreshStatus()
    {
        const u = firebase.auth().currentUser;
        if (u !== user) setUser(u);

        if (u) {
            u.reload().then(() => {
                setUserIsAuthenticated(u.emailVerified);
                if (u.emailVerified) {
                    resetVerificationTimer();
                    setWaitingForUserToVerifyEmail(false);
                }
            });
        } else {
            resetVerificationTimer();
            setUserIsAuthenticated(false);
            setWaitingForUserToVerifyEmail(false);
        }
    }

    firebase.auth().onAuthStateChanged(function (updatedUser) 
    {

        if (updatedUser && !updatedUser.emailVerified && updatedUser.email && updatedUser.email !== gSentVerificationFor) {

            gSentVerificationFor = updatedUser.email;
            updatedUser.sendEmailVerification().then(function () {
                alert("A verification email has been sent to your email address.\nPlease click the link in that email to continue.");
                setWaitingForUserToVerifyEmail(true);
                setAuthCheckTimer( setInterval(refreshStatus, 5000));
            });

        } else if (waitingForUserToVerifyEmail && updatedUser && updatedUser.emailVerified) {
            resetVerificationTimer();
            setWaitingForUserToVerifyEmail(false);
            setUserIsAuthenticated(true);
        }
        setUser(updatedUser);
        setUserIsAuthenticated(updatedUser && updatedUser.emailVerified);
    });

    
    function signOut() {
        firebase.auth().signOut();
        setUser(null);
        setUserIsAuthenticated(false);
    }

    const eViteProps = { isEvite:props.isEvite, invite:props.invite, code:props.code, setIsEvite:props.setIsEvite };

    if (user===null || !userIsAuthenticated) {

        return <LoginPanel setUser={setUser} user={user} waitingForUserToVerifyEmail={waitingForUserToVerifyEmail} refreshStatus={refreshStatus}  
            rememberMe={rememberMe} setRememberMe={setRememberMe}
            />;
    } else {
        return <App {...eViteProps} user={user} userIdToken={userIdToken} signOut={signOut} authMethod="firebase" />;
    }
}
