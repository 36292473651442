// acp1styleLayout.js -- A ACP1-style top-level appearance
//

import React from 'react';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';

import { Outliner } from './outliner.js';
import { Diagram } from './diagram.js';
import { ObjectView } from './objectWindow.js';
import { EditTable } from './editTable.js';
import { Graph } from './graph.js';
import { Footer } from './acpPortal.js';
import { useTopLevelOid, useObjAtt, useObjAtts, useQuery, useCloudStyles, useEval } from './serverState.js';
import { DistributionPopup } from './distributionPopup.js';
import { SequencePopup, ListEditPopup } from './sequenceAndListPopups.js';

import './styles/acp1.scss';
import { ResultTable } from './resultTable.js';
import { SetShowWindowHooks } from './EvalUIFunctions.js';

import { AcpPortal } from "./acpPortal.js";
import { WebSocket_Send, WebSocket_Close } from './webSocketClient.js';
import { ErrorMessageWindow } from './errorMessages.js';

import { IncReqCalc } from './formNodes.js';

import { ModuleTabs, useModuleTabsState, ModuleTwoLevelTabSeparator } from './ModuleTabs.js';
import { useLayoutRect } from './miscTools.js';

import { HierarchyStripe } from './Hierarchy stripe.js';

//import { HierarchyStripe } from './Hierarchy stripe.js';

function Acp1Tab(props)
{
    const cls = "Acp1Tab " + (props.isSel ? "Selected" : "Unselected");

    let style = {};
    if (props.text === "Diagram" && props.isSel) style = { backgroundColor: props.diagTabColor };

    return (
        <div id={"Tab_" + props.text} className={cls} style={style} onClick={(ev) => props.onClickTab(props.tab, props.isSel)}>
            {props.ico && (<img className="Tab_ico" alt="" src={props.ico}/>)}
            <span className="Tab_Label">{props.text}</span>
        </div>
    );
}

function Acp1Tabs(props) {
    const [curDefType, hasResultTab] = useObjAtts(props.curObj, ["definitionType", "_mightHaveResult"]);
    const [parentDiag,] = useObjAtt(props.curDiag, "IsIn");

    const hasEditTab = curDefType === "Table" || curDefType === "SubTable";
    const hasGraphTab = !!hasResultTab;

    let hasResultTableTab = !!hasResultTab;
    let hasChartTab = hasGraphTab;

    if (hasEditTab) {
        hasResultTableTab = true;
        hasChartTab = true;
    }

    function onClickTab(tabName, isSelected) {
        console.log("on tab click", tabName)
        if (tabName === 'diagram' && isSelected && parentDiag) {
            props.setCurDiag(parentDiag);
            props.setCurObj(parentDiag);
        }
        else
            props.setSelectedTab(tabName);
    }

    return (
        <div className="Acp1Tabs" id='NavTabs'>
            <Acp1Tab tab="diagram" ico='img/Diagram.ico' text="Diagram" isSel={props.selectedTab === 'diagram'} onClickTab={onClickTab} {...props} />
            <Acp1Tab tab="object" ico='img/ObjectWindow.ico' text="Object" isSel={props.selectedTab === 'object'} onClickTab={onClickTab} {...props} />
            {hasEditTab && (<Acp1Tab tab="edit" ico='img/EditTable.ico' text="Edit Table" isSel={props.selectedTab === 'edit'} onClickTab={onClickTab} {...props} />)}
            {hasResultTableTab && (<Acp1Tab tab="result" ico="img/EditTable.ico" text={"Table"} isSel={props.selectedTab === 'result'} onClickTab={onClickTab} {...props} />)}
            {hasChartTab && <Acp1Tab tab="graph" ico="img/GraphBtn.ico" text="Graph" isSel={props.selectedTab === 'graph'} onClickTab={onClickTab} {...props} />}
        </div>
    );
}

/* 913, remove this button and make diagram tab move into parent 
function ToParentButton(props)
{
    const [parentDiag,] = useObjAtt(props.curDiag, "IsIn");

    const show_parent_diagram_button = parentDiag && props.cloudStyles.show_parent_diagram_button !== "no";
    if (!show_parent_diagram_button) return "";

    return (<img src="img/ToParent.png" id="ToParentBtn" alt="Up to parent" onClick={(ev) => { props.setCurDiag(parentDiag); props.setCurObj(null);}} />);

} */

// Unused. If useful, move to miscTools.js
//function ArrayEqual(a,b)
//{
//    if (a.length !== b.length) return false;
//    for (let i = 0; i < a.length; ++i)
//        if (a[i] !== b[i])
//            return false;
//    return true;
//}


// Identifies which diagram should be showing, based on the nominal diagram and the tabs.
// If possible, returns the nominalCurDiag. But if that diagram is supplying a set of tabs, then the diagram to actually show is 
// the one selected by the tab.
function CurrentDiagFromNavsAndNominal(moduleTabState, nominalCurDiag)
{    
    if (!moduleTabState)
        return nominalCurDiag;

    const { nav1Tabs, selectedNav1Tab, nav2Tabs, selectedNav2Tab, topOid } = moduleTabState;

    if (nominalCurDiag === null) {
        if (selectedNav2Tab) return selectedNav2Tab;
        if (selectedNav1Tab) return selectedNav1Tab;
        return moduleTabState.topOid;
    }
        
    if (!nav1Tabs || nav1Tabs.length === 0)
        return nominalCurDiag;

    if (nominalCurDiag === topOid) {
        return nav2Tabs ? selectedNav2Tab : selectedNav1Tab;
    }
    if (nav2Tabs && nav2Tabs.length>0 && nominalCurDiag === selectedNav1Tab) {
        return selectedNav2Tab;
    }

    return nominalCurDiag;
}

// When a module with tabs shows itself in the list of tabs, then when viewing that diagram, modules that are used for tabs
// should not be shown.
function CalcNodesToHide(curDiag, moduleTabState)
{
    if (!curDiag || !moduleTabState) return null;
    if (curDiag === moduleTabState.selectedNav2Tab) 
        return moduleTabState.nav2Tabs;
    if (curDiag === moduleTabState.selectedNav1Tab) 
        return moduleTabState.nav1Tabs;
    return null;
}

function ShouldShowHierarchyStripe(moduleTabState, curDiag)
{
    if (moduleTabState && moduleTabState.selectedNav1Tab != null && moduleTabState.selectedNav1Tab === curDiag) {
        // don't show hierarchy strip if the curDiag is same as selected tab
        return false;
    }

    //
    // if diagram being displayed is one of the nav tabs, then don't show hierarchy strip
    //
    if (moduleTabState && moduleTabState.nav1Tabs && moduleTabState.nav1Tabs.length > 0) {
        for (let i = 0; i < moduleTabState.nav1Tabs.length; i++) {
            if (moduleTabState.nav1Tabs[i] === curDiag)
                return false;
        }
    }
    if (moduleTabState && moduleTabState.nav2Tabs && moduleTabState.nav2Tabs.length > 0) {
        for (let i = 0; i < moduleTabState.nav2Tabs.length; i++) {
            if (moduleTabState.nav2Tabs[i] === curDiag)
                return false;
        }
    }

    return true;
}

// LDC Extracted this into a separate function to shorted ACP2()
// Still a very ugly function, too imperative in style, but at least encapsulated. 

function TabRelatedStyleInfo(cloudStyles, show_tabs, bShowingOutline, ObjectTableGraphDivRect, selectedTab, topDiagSize)
{
    const nav_style = cloudStyles && cloudStyles.navigation_style;

    let tabContentClsName = "TabContent";
    let ObjectTableGraphDiv = "ObjectTableGraphDiv"
    let ObjectTableGraphDivStyle = {}
    if (show_tabs) {
        tabContentClsName += " NoTopSideTabs";
    }
    else
    {

        if (nav_style === "top_tabs" && cloudStyles.use_top_diagram_size !== "yes") {
            tabContentClsName += " TopTabs";
            ObjectTableGraphDiv += " TopTabsFullScreen"
            ObjectTableGraphDivStyle = ObjectTableGraphDivRect ? { height: "calc(100vh - " + (ObjectTableGraphDivRect.top + 49) + "px)" } : {};
        }
        else if (nav_style === "top_tabs") {
            tabContentClsName += " TopTabs";
            ObjectTableGraphDiv += " TopTabsUseTopDiagSize"

            ObjectTableGraphDivStyle.width = topDiagSize?.width;
            ObjectTableGraphDivStyle.height = topDiagSize?.height;
        } else if ((nav_style === "side_tabs" || nav_style === "two_side_tabs") && cloudStyles.use_top_diagram_size !== "yes") {
            tabContentClsName += " SideTabs";
            ObjectTableGraphDiv += " SideTabsFullScreen"
            ObjectTableGraphDivStyle = ObjectTableGraphDivRect ? { height: "calc(100vh - " + (ObjectTableGraphDivRect.top + 41) + "px)", width: "calc(100vw - " + (ObjectTableGraphDivRect.left + 9) + "px"} : {};
        }
        else if (nav_style === "side_tabs") {
            ObjectTableGraphDiv += " SideTabsUseTopDiagSize";
            tabContentClsName += " SideTabs UseTopDiagSize";

            ObjectTableGraphDivStyle.width = topDiagSize?.width;
            ObjectTableGraphDivStyle.height = topDiagSize?.height;
        }
    }

    ObjectTableGraphDiv = selectedTab === "diagram" ? ObjectTableGraphDiv + " Diagram" : ObjectTableGraphDiv;
    tabContentClsName = bShowingOutline ? tabContentClsName + " Outline" : tabContentClsName;

    return [tabContentClsName,ObjectTableGraphDiv,ObjectTableGraphDivStyle]
}

function Acp2(props)
{   
    //console.log("ACP2", props)
    const cloudStyles = useCloudStyles(props.topOid);
    const [curDiag_nominal, setCurDiag] = useState(null);                  // Before user clicks on a different tab. When there are tabs, curDiag is only the nominal diagram, superceded by the selected tab.
    const [selectedTab, setSelectedTab] = useState('diagram');         
    const moduleTabState = useModuleTabsState((o) => { setCurDiag(o); WebSocket_Send({ fn: 'DoOnClick', oid: o, x: 5, y: 5 }); setSelectedTab('diagram')}, curDiag_nominal);            // LDC 8/6/2021 S-933
    const [curObj, setCurObj] = useState(props.topOid);
    //const [contentWidth,] = useObjAtt(props.topOid, "diagWidth");
    const [jumpToNodeInDiagReq, setJumpToNodeInDiagReq] = useState(null);
    const [diagSelection, setDiagSelection] = useState([]);
    const [diagTabColor, setDiagTabColor] = useState("#E5E5E5");
    const [frameOid, setFrameOid] = useState(0);
    const showHier = useEval("showHier");
    const [topDiagSize,] = useObjAtt(props.topOid, "_diagSize");
    const [bShowingOutline, setbShowingOutline] = useState(false);
    const [topOfPlotHolder, setTopOfPlotHolder] = useState(50);
    const [ObjectTableGraphDivRef,ObjectTableGraphDivRect] = useLayoutRect(null,500 /*ms*/);      // LDC 8/20/2021 Cleanup
    const [sideTabsWidth/*, setSideTabsWidth*/] = useState(0);                                          // Not used?
    const [sideTabsRef, sideTabsRect] = useLayoutRect(null, 500 /*ms*/);
    const [fontStyle,] = useObjAtt(props.topOid, "_fontStyle");
    const [bNeedToClearPendingCalcReq, setNeedToClearPendingCalcReq] = useState(false);
    const [diagramZoom, setDiagramZoom] = useState(1);
    const [showUpdateBrowserMsg, setShowUpdateBrowser] = useState(true);
    //const [tabContentRef,tabContentRect] = useLayoutRect(null, 500 /*ms*/);                              // LDC 8/20/2021 Cleanup
    const tabContentRef = useRef();                                                           // FWB 1630
    const tabContentRect = tabContentRef?.current?.getBoundingClientRect();                   // FWB 1630
    //const debounceTimeout = useRef(null);
    //const [tabContentRect, setTabContentRect] = useState(null);

    //console.log(moduleTabState);      // Very useful for debugging anything having to do with module tabs.

    const serifFonts = document?.acpServerConfig?.serifFonts;
    const sansSerifFonts = document?.acpServerConfig?.sansSerifFonts;
    const monospaceFonts = document?.acpServerConfig?.monospaceFonts;
    let fontFound = false;
    for (let i = 0; i < sansSerifFonts.length; i++) {
        if (fontStyle?.fontFamily?.toLowerCase() === sansSerifFonts[i].toLowerCase()) {
            fontStyle.fontFamily += ",'Avant Garde', Arial, sans-serif";
            //console.log("Sans Serif font found");
            fontFound = true;
            break;
        }
    }
    if (fontFound === false) {
        for (let i = 0; i < serifFonts.length; i++) {
            if (fontStyle?.fontFamily?.toLowerCase() === serifFonts[i].toLowerCase()) {
                fontStyle.fontFamily += ',"Times New Roman",Times,serif';
                //console.log("Serif font found");
                fontFound = true;
                break;
            }
        }
    }
    if (fontFound === false) {
        for (let i = 0; i < monospaceFonts.length; i++) {
            if (fontStyle?.fontFamily?.toLowerCase() === monospaceFonts[i].toLowerCase()) {
                fontStyle.fontFamily += ',"Courier New",Courier,monospace';
                console.log("mono font found");
                fontFound = true;
                break;
            }
        }
    }
    const curDiag = CurrentDiagFromNavsAndNominal(moduleTabState,curDiag_nominal); // LDC 8/6/2021 Bug S-933
    const nodesToHide = CalcNodesToHide(curDiag, moduleTabState);
    const nav_style = cloudStyles && cloudStyles.navigation_style;
    const bSideTabs = nav_style==="side_tabs" || nav_style==="two_side_tabs";

    const show_outline = !bSideTabs && cloudStyles && cloudStyles.show_outline !== "no";
    //const show_banner = cloudStyles && cloudStyles.show_banner !== "no";
    const show_diagram_title = cloudStyles && cloudStyles.show_diagram_title === "yes";
    //const show_model_title = show_banner && cloudStyles.show_model_title !== "no";

    const show_tabs_by_default = !bSideTabs;
    const show_tabs = (show_tabs_by_default ? (cloudStyles?.show_tabs !== "no" && cloudStyles?.show_toolbar !== "no") : (cloudStyles?.show_tabs === "yes" || cloudStyles?.show_toolbar === "yes"));            // LDC 8/20/2021 Bug S-956

    const whichUI = cloudStyles && cloudStyles.suanui;

    const setPendingCalcReq = props.setPendingCalcReq;
    
    const heightOfGraph = (tabContentRect && topOfPlotHolder<tabContentRect.bottom) ? (tabContentRect.bottom - topOfPlotHolder) : 450;

    useEffect(() => {
        if (bNeedToClearPendingCalcReq) {
            setPendingCalcReq(null); 
            setNeedToClearPendingCalcReq(false);
        }
    }, [bNeedToClearPendingCalcReq, setNeedToClearPendingCalcReq, setPendingCalcReq]);

    useEffect(() => {
        if (props.whichUiPortal !== whichUI)
            props.setWhichUiPortal(whichUI);

        if (show_outline === false && bShowingOutline === true)
            setbShowingOutline(false);

        if (props.diagramForAcpStylesLib !== curDiag)
            props.setDiagramForAcpStylesLib(curDiag);
    }, [setbShowingOutline, show_outline, bShowingOutline, props, whichUI, curDiag])

    /*
    // Resize observer to get the current dimensions of holderRef
    useLayoutEffect(() => {
        console.log("useLayout", tabContentRef)
        const resizeObserver = new ResizeObserver(entries => {
            console.log("Resize Observer", entries)
            for (let entry of entries) {
                // Debounce the state update
                if (debounceTimeout.current) {
                    clearTimeout(debounceTimeout.current);
                }

                debounceTimeout.current = setTimeout(() => {
                    console.log("***********************setTabContentRect");
                    const r = entry.target.getBoundingClientRect();
                    console.log("r", r)
                    if (r.top !== tabContentRect?.top || r.left !== tabContentRect?.left || r.bottom !== tabContentRect?.bottom || r.right !== tabContentRect?.right) {
                        console.log("setting RECT")
                        setTabContentRect(entry.target.getBoundingClientRect());
                    }
                }, 400); // 100ms debounce time
            }
        });

        if (tabContentRef.current) {
            console.log("observer")
            resizeObserver.observe(tabContentRef.current);
        }

        return () => {
            if (tabContentRef.current) {
                resizeObserver.unobserve(tabContentRef.current);
            }
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        }
    }, );
    */
    if (cloudStyles === undefined)      // LDC 12/11/2020 Suan bugs 411,420. Value not yet available
        return null;

    // LDC 2/19/2021 Bug 420. Don't draw top diagram before the tab has been selected. Doing so causes visual garbage while rendering.
    // LDC 8/4/2021 Bug S-933 -- refactor so that this shouldn't be necessary

    if (whichUI === "acpportal")
        return <AcpPortal user={props.user} setIsEvite={props.setIsEvite} status={props.status} setStatus={props.setStatus} signOut={props.signOut} setProjectForToolbar={props.setProjectForToolbar} setAccountNameForToolbar={props.setAccountNameForToolbar} bModelFullyLoaded={props.bModelFullyLoaded} setModelFullyLoaded={props.setModelFullyLoaded}
            userRole={props.userRole} setUserRole={props.setUserRole} setForceShowCube={props.setForceShowCube} msgBoxInfoClient={props.msgBoxInfoClient} setMsgBoxInfoClient={props.setMsgBoxInfoClient} setShowCreateProject={props.setShowCreateProject} showCreateProjectForm={props.showCreateProjectForm}/>;

    if (jumpToNodeInDiagReq) {
        jumpToNodeInDiagReq.diagOid===curDiag || setCurDiag(jumpToNodeInDiagReq.diagOid);
        selectedTab === 'diagram' || setSelectedTab('diagram');
        setDiagSelection([jumpToNodeInDiagReq.nodeOid]);
        setJumpToNodeInDiagReq(null);
    }

    function JumpToNodeInDiagram(diagOid, nodeOid)
    {
        setJumpToNodeInDiagReq({ diagOid: diagOid, nodeOid: nodeOid });
    }

    function openEditTable(oid)
    {
        setCurObj(oid);
        setSelectedTab('edit');
    }
    function openResultTable(oid)
    {
        setCurObj(oid);
        setSelectedTab('result');
    }
    function openGraph(oid)
    {
        setCurObj(oid);
        setSelectedTab('graph');
    }
    let showWindow = (oid, windowType) => {
        switch (windowType) {
            case "diagram": setCurDiag(oid); setSelectedTab("diagram"); break;
            case "editTable": openEditTable(oid); break;
            case "resultTable": doNodeClick(oid, oid, "Variable", true, frameOid); break;//openResultTable(oid); break;
            case "object": setCurObj(oid); setSelectedTab("object"); break;
            case "definition": setCurObj(oid); setSelectedTab("object"); break;        // LDC 2/13/2023 S-1544. Note: Server-side checks a ShowWindow(o,"definition") call to see if it has a table definition, and sends us "editTable" instead of "definition" if it is.
                                                                                       // So this message needs to just handle the non-table case.  Currently it converts to "editTable" for scalar subtable definitions, which is consistent with
                                                                                       // DTA's behavior, which opens an edit table window in that case.
            case "graph": setCurObj(oid); setSelectedTab("graph"); break;
            case "outline":  break;         // ** TO DO **. Would this make the outline visible if the nagivation style currently does not show it? TBD.
            case "typescript": break        // No access to a console window currently in ACP.
            default:
                break;
        }
    };
    let closeWindow = (oid, windowType) => {

    };
    SetShowWindowHooks(showWindow, closeWindow);

    //function setNodesToHideOnDiagram(moduleOids) {
    //    if (!Array.isArray(nodesToHide) || !Array.isArray(moduleOids))
    //        return;
    //    if (ArrayEqual(nodesToHide, moduleOids))
    //        return;
    //    setNodesToHide(moduleOids);
    //}


    const calcStat = props.pendingCalcStat;
    const calcReq = props.pendingCalcReq;
    if (calcStat && !calcStat.stale && calcReq && calcStat.oid===calcReq.oid && calcStat.status!=="computing") {
        const bGraph = calcStat.graph;
        const bScalar = calcStat.scalar;
        const bFromCalc = calcReq.fromCalc;
        const stat = calcStat.status;
        calcStat.stale = true;                         // Total hack, because react seems to not be setting calcReq to null, and gets in an infinite loop here. Not sure why.

        setNeedToClearPendingCalcReq(true);             // No longer active 

        if (stat === "done" && (!calcReq.frameNode || (bScalar && !calcReq.scalarInFrameNode))) {
            // A calculation just finished. We might want to open a new result window.
            if (bScalar === false || !bFromCalc) {
                if (bGraph)
                    openGraph(calcStat.oid);
                else
                    openResultTable(calcStat.oid);
            }
        }
    }

    function doNodeClick(nodeOid,origOid,nodeClass,bMightHaveResult,frameNodeOid)
    {
        let noid = nodeOid;
        if (nodeOid !== origOid)
            noid = origOid;
 
        setCurObj(noid);

        if (["Module","LinkModule","Library","LinkLibrary","Form","Model","SysLibrary"].indexOf(nodeClass) >= 0) {
            setCurDiag(origOid);
            setSelectedTab("diagram");
        } else if (bMightHaveResult) { // LDC 3/26/2021 Suan bug 691
            if (props.setPendingCalcReq) {
                if (frameNodeOid)
                    props.setPendingCalcReq({ oid: noid, fromCalc: false, reqCount: IncReqCalc(), frameNode:frameNodeOid, scalarInFrameNode:true });
                else
                    props.setPendingCalcReq({ oid: noid, fromCalc: false, reqCount: IncReqCalc() });
            } else
                openGraph(nodeOid);
        } else if (nodeClass !== "InputNode" && nodeClass!=="OutputNode") {
            setSelectedTab("object");
        }
    }
    
    function closeModel()
    {
        WebSocket_Send({ fn: 'Exec', cmd: 'Close Model' });

        if (props.setIsEvite) {
            props.setIsEvite(false);
            if (props.status === "connected") {
                WebSocket_Close(props.setStatus);
            }
        }
    }
    
    function saveModel() {
        WebSocket_Send({ fn: 'Exec', cmd: 'Save' });
    }

    const props2 = { curObj: curObj, setCurObj: setCurObj, nodeClick:doNodeClick, curDiag:curDiag, setCurDiag:setCurDiag, selectedTab:selectedTab, setSelectedTab:setSelectedTab,
        openEditTable: openEditTable, openResult: openGraph, openGraph:openGraph, cloudStyles: cloudStyles, showDiagramTitle: show_diagram_title,
        jumpToNodeInDiagram: JumpToNodeInDiagram, bShowingOutline: bShowingOutline, fontStyle: fontStyle, diagramZoom, setDiagramZoom,
        ...props };

    // LDC 8/20/2021 Cleanup.  Extracted these into subroutine to shorted function
    const [tabContentClsName,ObjectTableGraphDiv, ObjectTableGraphDivStyle] = 
        TabRelatedStyleInfo(cloudStyles, show_tabs, bShowingOutline && show_outline, ObjectTableGraphDivRect, selectedTab, topDiagSize);

    const showHier2 = ShouldShowHierarchyStripe(moduleTabState,curDiag);

    const sideTabWidth = nav_style === "side_tabs" || nav_style === "two_side_tabs" ? sideTabsRect?.width : 0;
    const tabContentWidthForGraphs = tabContentRect && (tabContentRect.width - sideTabWidth);

    const widthForMargin = nav_style !== "top_tabs" ? 16 : 18;
    const heightForMargin = nav_style !== "top_tabs" ? 8 : 9;

    const hasAcp1Tabs = show_tabs;

    return (
        <>
        <div id="Acp1Top">
            {false && (<div id="CloseSaveButtons"><button id="CloseModelBtn" onClick={closeModel}>CLOSE MODEL</button>|<button id="SaveModelBtn" onClick={saveModel}>SAVE</button></div>)}
            <div style={{ display: "flex" }}>
                {show_outline && <Outliner topUnclosable={true} modulesOnly={true} onSelect={(oid) => doNodeClick(oid, oid, "Module")} {...props2} diagTabColor={diagTabColor} setbShowingOutline={setbShowingOutline} navStyle={nav_style}/>}
                <div>{show_tabs && <div style={{ display: "flex" }}><Acp1Tabs {...props2} curObj={curObj} diagTabColor={diagTabColor} /></div>}     {/*curObj just for debugging*/}
                    <div className={tabContentClsName} ref={tabContentRef} style={(cloudStyles?.use_top_diagram_size === "yes" && !moduleTabState.bSide) ? { width: topDiagSize?.width + 2 } : {}}>
                            {moduleTabState.bHasTabs &&
                                <div ref={sideTabsRef} className={moduleTabState.bSide ? "SideTabHolder" : "TopTabHolder"} style={(cloudStyles?.use_top_diagram_size === "yes" && !moduleTabState.bSide) ? { width: topDiagSize?.width+2, zoom: diagramZoom } : {zoom: diagramZoom}}>
                                    <ModuleTabs navTabs={moduleTabState.nav1Tabs} selNavTab={moduleTabState.selectedNav1Tab} setNavTab={moduleTabState.selectNav1Tab} moduleTabState={moduleTabState}
                                        cloudStyles={cloudStyles} 
                                        topOid={props.topOid}
                                        navigation_style={nav_style} /> 
                                    { moduleTabState.b2LevelTabs && <ModuleTwoLevelTabSeparator moduleTabState={moduleTabState} />}
                                    { moduleTabState.b2LevelTabs && <ModuleTabs navTabs={moduleTabState.nav2Tabs} selNavTab={moduleTabState.selectedNav2Tab} setNavTab={moduleTabState.selectNav2Tab} moduleTabState={moduleTabState}
                                        cloudStyles={cloudStyles}
                                        topOid={props.topOid}
                                        navigation_style={nav_style} />}
                                </div>
                            }           
                            {selectedTab === "diagram" && props.bModelFullyLoaded !== false &&  // EW 1273
                                <div className="DiagWithTitleAndHier" >
                                    <Diagram id={curDiag} {...props2} nodesToHide={nodesToHide} jumpToNodeInDiagram={JumpToNodeInDiagram} selection={diagSelection}
                                        setSelection={setDiagSelection} setDiagTabColor={setDiagTabColor} setFrameOid={setFrameOid} showHier={showHier} onSelect={(oid) => doNodeClick(oid, oid, "Module")} showHier2={showHier2}
                                        navTabsFirstLevel={moduleTabState.nav1Tabs} navTabsSecondLevel={moduleTabState.nav2Tabs} sideTabsWidth={sideTabsWidth} setForceShowCube={props.setForceShowCube} bForceShowCube={props.bForceShowCube}
                                        showUpdateBrowserMsg={showUpdateBrowserMsg} setShowUpdateBrowser={setShowUpdateBrowser}
                                    />
                                </div>
                            }
                            <div className={ObjectTableGraphDiv} ref={ObjectTableGraphDivRef} style={ObjectTableGraphDivStyle}>
                                    <HierarchyStripe moduleId={curDiag} onSelect={props.onSelect} cloudStyles={cloudStyles} navTabsFirstLevel={moduleTabState.nav1Tabs} navTabsSecondLevel={moduleTabState.nav2Tabs}  width={"100%"} onSelect={(oid) => doNodeClick(oid, oid, "Module")}/>
                                {selectedTab === "object" && <ObjectView oid={curObj}  {...props2} />}
                                {selectedTab === "edit" && <EditTable oid={curObj}  {...props2} />}
                                {selectedTab === "result" && <ResultTable oid={curObj} proactive={true}  {...props2} leftSideContent={ObjectTableGraphDivRect.left} hasAcp1Tabs={hasAcp1Tabs} />   /* LDC 2/26/2021 Suan bug 516 */ }   
                                {selectedTab === "graph" && <Graph oid={curObj}  {...props2} tabContentWidth={tabContentWidthForGraphs - widthForMargin} heightOfGraph={heightOfGraph - heightForMargin} setTopOfPlotHolder={setTopOfPlotHolder} topOfPlotHolder={topOfPlotHolder} hasAcp1Tabs={hasAcp1Tabs} />}
                            </div>
                    </div>
                </div>

            </div>
            <DistributionPopup />
            <SequencePopup />
            <ListEditPopup />
            <ErrorMessageWindow/>
        </div>
            <Footer />
            </>
    );
}

function Acp1(props)
{
    const pendingCalcStat = useQuery(props.pendingCalcReq && { req: 'requestCalc', ...props.pendingCalcReq });

    //return <AcpMaybeWithModuleTabs {...props} pendingCalcStat={pendingCalcStat} />;
    return <Acp2 {...props} pendingCalcStat={pendingCalcStat} />;
}

function Acp0(props)
{
    // Set pendingCalcRec to { oid:int, view:string, fromCalc:bool }, or null 
    const [pendingCalcReq, setPendingCalcReq] = useState(null); 

    return <Acp1 {...props} pendingCalcReq={pendingCalcReq} setPendingCalcReq={setPendingCalcReq} />;

}

export function Acp1StyleLayout(props)
{
    const [topOid,] = useTopLevelOid();
    return (topOid && topOid > 0) ? (<Acp0 topOid={topOid} {...props} />) : null;
}

function GetModuleHier0(diag) {
    const [parentDiag,] = useObjAtt(diag, "isin");
    return parentDiag;
}

function GetModuleHier(diagId) {
    // when I try looping or recursion, I get an error message 
    // "Error: Should have a queue. This is likely a bug in React."??
    /*let res = [];
    let mod = diagId;
    console.log("xxxxxxxxxxxxxxxxxxxxx")
    for (let j = 1; j < 10; j++) {
        mod = GetModuleHier0(mod);
        if (mod !== undefined) res.push(mod);
        if (mod === undefined) break;
        console.log("***", res)
    }*/

    const modHier2 = GetModuleHier0(diagId);
    const modHier3 = GetModuleHier0(modHier2);
    const modHier4 = GetModuleHier0(modHier3);
    const modHier5 = GetModuleHier0(modHier4);
    const modHier6 = GetModuleHier0(modHier5);
    const modHier7 = GetModuleHier0(modHier6);
    const modHier8 = GetModuleHier0(modHier7);

    let result = [modHier8, modHier7, modHier6, modHier5, modHier4, modHier3, modHier2, diagId];
    for (let i = result.length - 1; i > -1; i--) 
        if (result[i] === undefined) result.shift();

    return result;
}

