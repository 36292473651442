import React from 'react';
import { useState } from 'react';
import { useEvalOnce, useEval, useObjAtt } from './serverState.js';
import { TitleBar } from './titleBar.js';
import './styles/functionEditPopups.scss';

// This one asks user for the parameters of a probability distribution.

var gSetDistributionPopupOid = null;

export function ShowDistributionPopup(oid)
{
    if (gSetDistributionPopupOid) 
        gSetDistributionPopupOid(oid);
}

export function Description(props)
{
    const desc = useEval("Description of " + props.objName);
    if (typeof (desc) !== 'string') return "";

    return (<div id="Description">{desc}</div>);
}

export function DistributionPopup(props)
{
    const [oid, setOid] = useState(null);
    gSetDistributionPopupOid = setOid;
    const distFns0 = useEvalOnce("identifier of #FindObjects('singleSampleMethod', parameters, class: function, sysfunction, caseSensitive: false)");
    const [orig0,] = useObjAtt(oid, "_original");
    const orig = orig0 ? orig0.oid : 0;
    const [def,setDef] = useObjAtt(orig, "definition");
    const [selDist, setDist] = useState(null);
    const [params, setParams] = useState(null);

    if (!oid) return null;

    let distFns = distFns0;

    if (!Array.isArray(distFns))
        return "";

    if (selDist===null || params===null) {
        let def2 = def;
        // Remove comments, newlines
        if (typeof(def)==='string') {
            def2 = def2.replace( String.raw`\{.*?\}`, '');
            def2 = def2.replace( String.raw`/\*.*?\*/`, '');
            def2 = def2.replace( String.raw`\R`, ' ');
        }
        let defParts = /([A-Z][A-Z0-9_]*)\((.*?)\)/i.exec(def2);
        let curFn = null;
        let curParams = "";
        let existing = null;
        if (Array.isArray(defParts)) {
            curFn = defParts[1];
            curParams = defParts[2];

            if (params !== curParams)
                setParams(curParams);

            // Need to make sure that curFn has the same upper/lower case as in the array
            existing = distFns.find((e) => (curFn.toLowerCase() === e.toLowerCase()));
            if (existing) {
                curFn = existing;
            } else {
                distFns = distFns.concat(curFn);
            }
            if (curFn !== selDist) setDist(curFn);
        }
    }
    distFns.sort();

    function onOK()
    {
        // ** TO DO ** -- before closing, test that the new distribution parses successfully.
        // Don't set to a definition that doesn't parse.
        setDef(selDist + '(' + params + ')');
        setOid(null);
        setDist(null);
        setParams(null);
    }
    function onCancel()
    {
        setOid(null);
        setDist(null);
        setParams(null);
    }

    return (
        <div className="DistributionPopup FunctionEditPopup Window">
            <TitleBar type="DistributionPopup" title="Distribution" closer={onCancel} />
            <div className="DistributionSelector">
                <div id="Body">
                    <select value={selDist} onChange={(ev) => setDist(ev.currentTarget.value)}>
                        {distFns.map((fn, ) => (<option value={fn}>{fn}</option>))}
                    </select>
                    <span id="Params">(<input type="text" value={params} onChange={(ev)=>setParams(ev.currentTarget.value)}/>)</span>
                </div>
                {selDist && (<Description objName={selDist}/>)}
                <div id="Buttons">
                    <button className="Btn" id="OK"     onClick={onOK}>OK</button>
                    <button className="Btn" id="Cancel" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );
}