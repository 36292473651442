import React from 'react';
import { useState, useEffect } from 'react';
import { useObjAtt, useTopLevelOid, useCloudStyles } from './serverState.js';
import { isTopSideTabNav } from './diagram.js';
import './styles/outliner.scss';

function OutlinerLevel(props)
{
    const oid = props.oid;
    const [isExpanded,setExpanded] = useState(props.top);
    const [ident,] = useObjAtt(oid, "identifier");
    const [title,] = useObjAtt(oid, "title");
    const [contains,] = useObjAtt(oid, props.modulesOnly ? "_subModules" : "contains");
    const cloudStyles = useCloudStyles(oid);
    if (cloudStyles && cloudStyles.show_object === "no")
        return null;

    if (oid === 0 || oid === undefined) return null;

    let name = (title !== undefined && title !== null) ? title : ident;
    
    const hasChildren = (Array.isArray(contains)&& contains.length > 0);
    const status = hasChildren ? (isExpanded ? (props.topUnclosable ? "AlwaysExpanded" : "Expanded") : "Closed") : (props.modulesOnly ? "LeafModule" : "Leaf");
    const clsSel = (props.curDiag === oid) ? " SelectedDiag" : "";
    const cls = "OutlinerItem Outliner" + status + clsSel;

    function toggleExpanded(ev)
    {
        if (hasChildren && !props.topUnclosable)
            setExpanded(!isExpanded);
        ev.stopPropagation();
    }
    function onClickItem(ev)
    {
        if (props.onSelect) {
            props.onSelect(oid);
            ev.stopPropagation();
        } else {
            toggleExpanded(ev);
        }
    }

    let childVisStyle = { display: (hasChildren && isExpanded) ? "block" : "none" };

    let style = {};
    //if (props.curDiag === oid) style = { backgroundColor: props.diagTabColor };
    style.display = "flex";

    let triangle = "";
    if (status === "Closed") triangle = <img src="img/outliner triangle closed.png" alt="[+]" style={{ paddingLeft: 5 }}/>;
    if (status === "Expanded") triangle = <img src="img/outliner triangle open.png" alt="[-]" />;
    //if (status === "LeafModule") triangle = <img style={{ width: 10 }}src="img/icoModule.png" />;

    if (hasChildren) {
        if (props.topLevelOid === oid) {
            // Top level
            return (
                <div className={cls}><div className="Item" style={style} onClick={onClickItem}><span className="Prefix" onClick={toggleExpanded} /><span className="Oid">[{oid}]</span>&nbsp;&nbsp;{name}</div>
                    <ul style={childVisStyle}>
                        {contains.map((child) => (<OutlinerLevel key={child} {...props} topUnclosable={false} top={false} oid={child} />))}
                    </ul>
                </div>
            );
        } else {
            // Openable level
            return (
                <div className={cls}><div className="Item" style={style} onClick={onClickItem}><div className="Prefix" style={{ height: 18, width: 18}} onClick={toggleExpanded} >{triangle}</div><span className="Oid">[{oid}]</span>{name}</div>
                    <ul style={childVisStyle}>
                        {contains.map((child) => (<OutlinerLevel key={child} {...props} topUnclosable={false} top={false} oid={child} />))}
                    </ul>
                </div>
            );
        }
    }

    // Non-openable level
    return (<div className={cls}><div className="Item" style={style} onClick={onClickItem}><span className="Prefix" onClick={toggleExpanded} /><span className="Oid">[{oid}]</span> {name}</div></div>);
}

export function Outliner(props)
{
    const [oid,] = useTopLevelOid();
    const [contains,] = useObjAtt(oid, "_subModules");
    let showingOutline = false;

    useEffect(() => { props.setbShowingOutline(showingOutline); })

    // Don't show outliner if diagram has no submodule EW 118
    if (contains === undefined)
        return null;
    if (contains.length === 0)
        return null;
    else
        showingOutline = true;

    const outlineContainerClas = isTopSideTabNav(props.navStyle) ? "OutlineContainer TopSideTabNav" : "OutlineContainer";
    const outlinerCls = isTopSideTabNav(props.navStyle) ? "Outliner TopSideTabNav" : "Outliner";

    return (
        <div className={outlineContainerClas} >
            <ul className={outlinerCls}>
                <OutlinerLevel oid={oid} topLevelOid={oid} {...props} top={true} />
            </ul>
        </div>
	);
}
