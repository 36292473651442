import React from 'react';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useObjAtt } from './serverState.js';
import ReactDOM from 'react-dom';

export function ExpandHyperlinks(text) {
    const re = /^(.*)<a\s+href="(https?:.*?)"\s*>(.*?)<\/a>(.*)$/i;
    const m = re.exec(text);
    if (m === null) return <>{text}</>;
    return <>{ExpandHyperlinks(m[1])}<a href={m[2]} target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()}>{m[3]}</a>{(m[4])}</>;
}

export function Lineify(props)
{
    const text = props.text;
    const perLineStyle = props.perLineStyle;

    const colorIndex = props.colorIndex; // Optional. One character between '0' and '6' for each character in text. The 
                                         // char identifies the token type in the SysVar Sys_SyntaxColors.
    const colorTable = props.colorTable; // Optional The 7 colors in Sys_SyntaxColors.

    const bColorize = colorTable && colorIndex; // If caller provides color info, then we colorize the text.

    if (typeof(text) !== "string")
        return <>{JSON.stringify(text)}</>;

    function NumLeadingTabsAndSpaces(s)
    {
        if (!s) return [0,0];
        let nTabs = 0, nSpaces = 0;
        for (let i = 0; i < s.length && (s[i] === '\t' || s[i] === ' '); ++i) {
            if (s[i] === '\t') {
                ++nTabs;
                nSpaces = 0;
            } else {
                ++nSpaces;
            }
        }

        return [nTabs,nSpaces];
    }

    function oneLine(item, key) {
        // Replace any <a href...> hyperlinks so they show up as hyperlinks
        //if (item === "")
        //    return (<br key={key} />);

        const [indent,nSpaces] = NumLeadingTabsAndSpaces(item);
        const html = item==="" ? <>&nbsp;</> : ExpandHyperlinks(item);
        const perLineStyle2 = indent > 0 ? { marginLeft: (indent * 3) + "ch", ...perLineStyle } : perLineStyle;

        // Note: '\xa0' comes out as &nbsp;
        return (<div key={key} thekey={key} style={perLineStyle2}>{'\xa0'.repeat(nSpaces)}{html}</div>);
    }

    function ColorRanges(txt, indent, start)
    {
        let ranges = [];
        let prev = '';
        let prevStart = indent;

        for (let i = indent; i <= txt.length; ++i) {
            let c_i = (i < txt.length) ? colorIndex[i + start] : '!'; // use ! for end
            if (c_i !== prev) {
                if (prev !== '') ranges.push([prevStart, i, colorTable[prev-'0'] ]);
                prevStart = i;
            }
            prev = c_i;
        }
        return ranges;
    }

    function ToCssColorStyle(x) {
        const b = x & 255;
        const g = (x >>= 8) & 255;
        const r = (x >>= 8) & 255;
        return { color: `rgb(${r},${g},${b})` };
    }

    function oneColorizedLine(txt, key, start) {
        // Replace any <a href...> hyperlinks so they show up as hyperlinks
        //if (item === "")
        //    return (<br key={key} />);

        const [indent,nSpaces] = NumLeadingTabsAndSpaces(txt);
        const perLineStyle2 = indent > 0 ? { marginLeft: (indent * 3) + "ch", ...perLineStyle } : perLineStyle;
        const ranges = ColorRanges(txt, indent+nSpaces, start);
        
        // Note: '\xa0' comes out as &nbsp;
        return (<div key={key} thekey={key} style={perLineStyle2}>
            {ranges.map((range, j) => (
                <span key={j} style={ToCssColorStyle(range[2])}>
                    {'\xa0'.repeat(nSpaces)}
                    {txt.substring(range[0], range[1])}
                </span>
            ))}
        </div>);
    }


    const lines = text.split(/[\r\n]/);

    if (!bColorize) 
        return (<>{lines.map(oneLine)}</>);

    let cum = 0;
    return (<>{lines.map((s, key) => {
        const st = cum;
        cum += s.length + 1;
        return oneColorizedLine(s,key,st);
    })}</>);
    
}

// Ignores arguments that are not arrays, so they can be null or undefined, for example.

export function MergeArrays(/*variable number of arguments*/) {
    let a = [];
    for (let i = 0; i < arguments.length; ++i) {
        let ai = arguments[i];
        if (Array.isArray(ai)) 
            a = a.concat(ai);
    }

    return a;
}


export function paddingDiff(col) {

    if (getStyleVal(col, 'box-sizing') === 'border-box') {
        return 0;
    }

    var padLeft = getStyleVal(col, 'padding-left');
    var padRight = getStyleVal(col, 'padding-right');
    return (parseInt(padLeft) + parseInt(padRight));

}

function getStyleVal(elm, css) {
    return (window.getComputedStyle(elm, null).getPropertyValue(css))
}

// How to use:
//
// In a component that will process keyDown events, do this:
// function MyComponent(pros)
// {
//      const getKeyDown = useKeyDown(false);
//      let keyEvent=getKeyDown();                   // Note that this clears the key press so you don't process it twice

//      if (keyEvent.key==='Escape') ....
//      else if (keyEvent.key==='h') ....   etc.
// }

export function useKeyDown(bIncludeShiftlikeKeyEvents = false)
{
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(null);

    function get()
    {
        let key = keyPressed;
        if (key !== null)
            setKeyPressed(null);        // So you don't process it twice
        return key;
    }

    // Add event listeners
    useEffect(() => {

        // If pressed key is our target key then set to true
        function downHandler(ev) {
            if (!bIncludeShiftlikeKeyEvents) {
                if (ev.key === "Shift" || ev.key === "Control" || ev.key === "Alt" || ev.key === "CapsLock")
                    return;
            }
            setKeyPressed(ev);
        }

        // If released key is our target key then set to false
        // Note: Not sure if this really belongs here. Could it cause some key events to get missed?
        const upHandler = () => {
            setKeyPressed(null);
        }



        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [bIncludeShiftlikeKeyEvents]); // Empty array ensures that effect is only run on mount and unmount

    return get;
}

export function useMouseDownCapture()
{
    const [event, setEvent] = useState(null);

    function onDown(e) {
        setEvent(e);
    }

    useEffect(() => {
        window.addEventListener("mousedown", onDown);                       // LDC 10/14/2020 Suan Bug 300. When it was document.addEventListener, a react-hooks handler co-opted it.  Has to be window.addEventListener
        return () => window.removeEventListener("mousedown", onDown);
    }, []);

    function get()
    {
        const e = event;
        if (e !== null)
            setEvent(null);         // So you don't process it twice
        return e;
    }

    return get;
}

function useInitialFocus(ref) {
    useEffect(() => ref.current && ref.current.focus(), [ref]);
}


// Popup menus or other pop-up controls need appear above everything else, which means they need to be 
// connected to the model-root.
// LDC 4/19/2021 S-731 -- extracted this from function ChoiceDropdownMenu() in order to make it re-usable.

export function ModalPopupControl({ className, parentRef, onKeyDown, setOpen, children, zoom0, modalPopupId, parentRect, setOpenSubMenu })
{
    const popupRef = useRef();
    const [loc, setLoc] = useState(null);
    const portalRef = useRef(document.getElementById('modal-root'));
    const parentDom = parentRef && parentRef.current;
    const zoom = zoom0 === undefined ? 1 : zoom0;

    useInitialFocus(popupRef);

    //if (loc === null && parentRef && ) {
    //    const r = parentRef.current.getBoundingClientRect();
    //    setLoc({ left: r.x, top: r.y });        // Just a guess initially -- refined below in a useEffect
    //}

    function handleClickOutside(ev) {
        console.log("miscTools.js handleClickOutside", ev.target);

        if (!popupRef?.current?.contains(ev.target)) {
            setOpen(false);
            setOpenSubMenu && setOpenSubMenu(false);
        }
    }

    // This is to close the menu when the user clicks outside of it
    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);                       // LDC 10/14/2020 Suan Bug 300. When it was document.addEventListener, a react-hooks handler co-opted it.  Has to be window.addEventListener
        return () => window.removeEventListener("mousedown", handleClickOutside);
    });

    useLayoutEffect(() => {
        if (!parentDom && !parentRect) return;
        const rect = parentRect ? parentRect : parentDom?.getBoundingClientRect();
        //console.log("parentRect", parentRect, rect);
        const curRect = popupRef.current.getBoundingClientRect();
        let x, y, maxHt;
        if (modalPopupId === "ChoiceDropdownMenu") {
            x = loc ? loc.left + (rect.x - curRect.x) : rect.x;
            y = loc ? loc.top + (rect.y - curRect.y) : rect.y;
            maxHt = {};
        }
        else {
            x = loc ? loc.left + (rect.x + rect.width - curRect.x ) : rect.x + rect.width;
            y = loc ? loc.top + (rect.y - curRect.y) : rect.y;
            maxHt = {};
            //console.log("sub menu location", x, y)
        }

        // But make sure it fits on the screen
        const doc = portalRef.current.parentElement;
        if (doc) {
            const boundRect = doc.getBoundingClientRect();
            const brTop = boundRect.top + 0.05 * boundRect.height;
            const brHeight = boundRect.height * 0.9;
            const brBottom = brTop + brHeight;

            x = Math.max(boundRect.left, Math.min(x, boundRect.right/*/zoom*/ - curRect.width));
            y = Math.max(boundRect.top,  Math.min(y, brBottom/*/zoom*/ - curRect.height));
            if (curRect.height /* * zoom */ > brHeight)
                maxHt = { height: brHeight };
        }
        setLoc({ left: x, top: y, ...maxHt, /*zoom: zoom*/ });
    }, [parentDom, portalRef, popupRef, portalRef, setLoc, parentRect]);           // Beware about including loc here. Doing so will put it into an infinite recursion.

    function noBubbleUp(ev) {
        console.log("noBubbleUp")
        ev.stopPropagation();
    }

    const html = (
        <div className={className} id={modalPopupId} onMouseOver={ev => ev.stopPropagation()} ref={popupRef} onClick={noBubbleUp} onKeyDown={onKeyDown} tabIndex="0" style={loc} >
                {children}
        </div>
    );

    if (!html) return null;

    return ReactDOM.createPortal(html, document.getElementById('modal-root'));
}

function OidHyperlink(props) {
    const [title,] = useObjAtt(props.oid, "_title");
    const [isin,] = useObjAtt(props.oid, "isin");

    function OnClick(ev) {
        props.jumpToNodeInDiagram(isin, props.oid);
    }

    return (<span className="OidHyperLink" onClick={OnClick}>{title}</span>);
}

export function ValueToHTML({val,jumpToNodeInDiagram}) 
{
    //console.log("value to hmtl, val", val, typeof(val), )
    if (typeof (val) !== 'object')
        return (<>{val}</>);

    if (val === null)  // 1748 - oddly the 'typeof null' is 'object'
        return <>Null</>;

    if ('oid' in val) 
        return (<OidHyperlink {...val} jumpToNodeInDiagram={jumpToNodeInDiagram}/>);
    if (val.type === 'date')                      // LDC 1/12/2021 Bug S490
        return (<>{val.val}</>);

    return JSON.stringify(val);
}


// LDC 4/29/2021 bug S-741
// This is similar to ValueToHTML, except oid's are shown by identifier and not as hyperlinks

export function ExprToHTML({ val }) {
    if (typeof (val) !== 'object')
        return (<>{val}</>);

    if ('oid' in val) {
        function ShowIdent({ val }) {
            const [ident,] = useObjAtt(val.oid, "identifier");
            return <>{ident}</>;
            //return <span className="IdentiferExpr">{ident}</span>;
        }
        return (<ShowIdent val={val} />);
    }
    if (val.type === 'date')                      // LDC 1/12/2021 Bug S490
        return (<>{val.val}</>);

    return JSON.stringify(val);

}

function debounce(fn, ms) 
{
    if (ms===0) return fn;

    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

// LDC 8/20/2021 Cleanup. Added a resizeMs parameter. If set to a number >=0, then 
// it catches resize events and updates the rect after a resize. A non-zero resizeMs
// prevents it from doing through an excessive call while dragging the size.

export function useLayoutRect(sanityTest, resizeMs=null)
{
    const ref = useRef();
    const [rect, setRect] = useState(null);

    const br = ref.current && ref.current.getBoundingClientRect();
    const [h, w, t, l] = [br?.height, br?.width, br?.top, br?.left];

    useLayoutEffect(() => {
        if (!ref.current) return;

        function doit(bTestForChange) 
        {
            const r = ref.current.getBoundingClientRect();
            const bChanged = bTestForChange && (r.height !== h || r.width !== w || r.top !== t || r.left !== l);
            if (bChanged || !sanityTest || sanityTest(r))
                setRect(r.toJSON());
        }
        doit(false);

        if (resizeMs === null) return;

        const resizeHandler = debounce( ()=>doit(true), resizeMs );
        window.addEventListener('resize', resizeHandler);

        return ()=>window.removeEventListener('resize', resizeHandler);

    }, [ref, h, w, t,l, sanityTest, setRect,resizeMs]);

    return [ref, rect];
}
