import React from 'react';
import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import './styles/helpBalloon.scss';
import { useQuery } from './serverState.js';
import { ExpandHyperlinks } from './miscTools.js';

export function ToolTip(props) {
    //console.log("ToolTip", props)
    const portalRef = useRef(document.getElementById('root'));
    const helpBalloonRef = useRef();
    const nodeRef = props.nodeRef;
    const [loc, setLoc] = useState(null);
    const [positionAdjusted, setPositionAdjusted] = useState(false);
    const [balloonOnLeft, setBalloonOnLeft] = useState(false);

    const balloonContent = props.tipText; 

    let balloonTitleText = "";
    if (balloonContent !== undefined && balloonContent.length > 0)
        balloonTitleText = balloonContent[0];

    let balloonBodyText = "";

    switch (props.control) {
        case "copy download":
            if (props.tableOrGraph === "table")
                balloonBodyText = "Press for a menu to copy this table so you can paste it into another application, or download it as a CSV file (with comma separated values).";
            break;
        case "copy download":
            balloonBodyText = "Copy this graph so you can paste it into another application.";
            break;
        case "pivot":
            if (props.tableOrGraph === "table")
                balloonBodyText = "Show menu(s) of indexes to let you switch rows, columns, and slicer indexes (if any).";
            else
                balloonBodyText = "Show menus of indexes to let you switch which index to show on vertical axis, color key etc.";
            break;
        case "toggle":
            if (props.tableOrGraph === "table")
                balloonBodyText = "Show this data as a graph";
            else
                balloonBodyText = "Show this data as a table";
            break;
        case "ViewSelector":
            balloonBodyText = "Select Mid to show the deterministic value of this variable, or another option to view its uncertain (i.e. probabilistic) value if it has one with the selected view.";
            break;
        case "object view":
            balloonBodyText = "Show object view";
            break;
        default:
            break;
    }


    // EW 314 Max "By default, the help balloon should not show the object title — which is already visible just to the left (or now below)"
    balloonTitleText = "";

    const zoom = props.zoom === undefined ? 1 : props.zoom; 
    if (loc === null) {
        const r = props.objRect; //nodeRef?.current?.getBoundingClientRect();
        if (r) 
            setLoc({ left: zoom * (r.x + r.width) + 3 , top: zoom * r.y });   
    }

    useLayoutEffect(() => {
        
        const portalRect = portalRef?.current?.getBoundingClientRect();
        const hbRect = helpBalloonRef?.current?.getBoundingClientRect();
        const nodeRect = props.objRect; //nodeRef?.current?.getBoundingClientRect();
        let newLeft = loc?.left;

        if (positionAdjusted < 4 && balloonBodyText !== "") {
            setPositionAdjusted(positionAdjusted + 1);
            if (hbRect?.right < portalRect?.right - 5 && !balloonOnLeft) {
                //console.log("balloon NOT clipped on right")
            } else {
                setBalloonOnLeft(true);
                newLeft = nodeRect?.left * zoom - hbRect?.width - 3;
                setLoc({ left: newLeft, top: loc.top });
            }
            if (hbRect?.bottom > portalRect?.bottom) {
                let newTop = loc?.top - (hbRect?.bottom - portalRect?.bottom) - 5;
                if (newTop < 0) newTop = 0;
                setLoc({ left: newLeft, top: newTop })
            }  
        }
    }, [helpBalloonRef, portalRef, nodeRef, setLoc, balloonBodyText, positionAdjusted, loc, zoom]);


    if (loc === null) return "";

    let style = { ...loc};
    
    if (props.showIdInBalloon === "yes" && balloonBodyText !== "")
        balloonBodyText = "(" + props.idOrig + ") " + balloonBodyText;
    
    if (/\w/.test(balloonBodyText)) balloonBodyText = ExpandHyperlinks(balloonBodyText); // EW 1153 Hyperlink not displaying

    const html = (
        <div className="HelpBalloonPortal" ref={helpBalloonRef} style={style} >
            <div className="HelpBalloonTitle">{balloonTitleText}</div>
            <div className="HelpBalloonBody">{balloonBodyText}</div>
        </div>
    );

    const hasWords = /\w/.test(balloonBodyText); // 764

    if (!html || !hasWords) return "";  

    return ReactDOM.createPortal(html, document.getElementById('modal-root'));
}


// tool bar tip to be used for toolbar

export function ToolbarTip(props) {
    console.log("ToolbarTip", props)
    const helpBalloonRef = useRef();
    const [loc, setLoc] = useState(null);
    const balloonContent = props.tipText;

    let balloonTitleText = "";
    if (balloonContent !== undefined && balloonContent.length > 0)
        balloonTitleText = balloonContent[0];

    let balloonBodyText = props.tipText;

    if (props.tipText === undefined) return "";

    // EW 314 Max "By default, the help balloon should not show the object title — which is already visible just to the left (or now below)"
    balloonTitleText = "";

    if (loc === null && props.objRect !== null && props.objRect.x) {
        const r = props.objRect; //nodeRef?.current?.getBoundingClientRect();
        if (r) {
            let xAdjustment = 0;
            switch (props.control) {
                case "UserId": xAdjustment = -62; break;
                case "Project": xAdjustment = -100; break;
                case "Account": xAdjustment = -160; break;
                default: xAdjustment = 0;
            }
            setLoc({ left: r.x + xAdjustment, top: 35 });
        }
    }

    if ((props.control === "Hamburger" || props.control === "Help" || props.control === "Close") && loc === null) {setLoc({ right: 5, top: 35 });}
    if (props.control === "Cube" && loc === null) setLoc({ left: 5, top: 35 });
    if (props.control === "AnaLogo" && loc === null) setLoc({ left: 5, bottom: 50 });
    if (props.control === "LumLogo" && loc === null) setLoc({ right: 5, bottom: 50 });
    if (props.control === "Account" && loc === null) setLoc({ left: 35, top: 35 });
    if (props.control === "Project" && loc === null) setLoc({ left: 325, top: 35 });
    if (props.control === "AddProject" && loc === null) setLoc({ left: 612, top: 35 });

    if (loc === null) return "";

    let style = { ...loc };

    if (props.showIdInBalloon === "yes" && balloonBodyText !== "")
        balloonBodyText = "(" + props.idOrig + ") " + balloonBodyText;

    if (/\w/.test(balloonBodyText)) balloonBodyText = ExpandHyperlinks(balloonBodyText); // EW 1153 Hyperlink not displaying

    // 1567 Show file name in Balloon on model name
    if (props.fileName) balloonBodyText = <> {balloonBodyText} <br /><br /> {"File name:  " + props.fileName} </>;

    const html = (
        <div className="HelpBalloonPortal" ref={helpBalloonRef} style={style} >
            <div className="HelpBalloonTitle">{balloonTitleText}</div>
            <div className="HelpBalloonBody">{balloonBodyText}</div>
        </div>
    );

    const hasWords = /\w/.test(balloonBodyText); // 764

    if (!html || !hasWords) return "";

    return ReactDOM.createPortal(html, document.getElementById('modal-root'));
}
