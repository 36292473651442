import React from 'react';
import {useState} from 'react';
import { Diagram } from './diagram.js';
import { TitleBar } from './titleBar.js';
import { Outliner } from './outliner.js';
import { PeekAttribute } from './peekAttribute.js';
import { TypescriptConsole } from './TypescriptConsole.js';
//import { WebSocketTester } from './webSocketClient.js';
import { useTopLevelOid } from './serverState.js';
import { ResultTable } from './resultTable.js';
import { EditTable } from './editTable.js';
import { ClientCacheBrowser } from "./cacheBrowser.js";
import { SetShowWindowHooks } from './EvalUIFunctions.js';
import { DistributionPopup } from './distributionPopup.js';

import './styles/WindowManager.scss';

export function WindowManager(props)
{
    const [topOid,] = useTopLevelOid();
	const [diagramWindows0,setOpenDiagrams] = useState(undefined)
    const [resultTables0, setOpenResultTables] = useState(undefined);
    const [editTables0, setOpenEditTables] = useState(undefined);
    const [bShowPeekAttribute, setShowPeekAttribute] = useState(true);
    const [bShowCacheBrowser, setShowCacheBrowser] = useState(true);
    const [bShowConsole, setShowConsole] = useState(true);
    const [prevTop,setPrevTop] = useState(topOid);

    if ((topOid !== 0 && diagramWindows0 === undefined) || prevTop!==topOid) {
        if (prevTop !== topOid) setPrevTop(topOid);
        setOpenDiagrams([topOid]);
        return "";
    }

    let diagramWindows = diagramWindows0 ? diagramWindows0 : [];
    let resultTables = resultTables0 ? resultTables0 : [];
    let editTables = editTables0 ? editTables0 : [];

    let openDiagram = (moduleOid) => {
        if (moduleOid in diagramWindows) return;
        let newOpenDiags = [].concat(diagramWindows, [moduleOid]);
        setOpenDiagrams(newOpenDiags);
    };
    let openResultTable = (variableOid) => {
        if (variableOid in resultTables) return;
        let newOpenResTabs = [].concat(resultTables, [variableOid]);
        setOpenResultTables(newOpenResTabs);
    };
    let openEditTable = (variableOid) => {
        if (variableOid in editTables) return;
        let newOpenEditTabs = [].concat(editTables, [variableOid]);
        setOpenEditTables(newOpenEditTabs);
    };
    let openGraph = (variableOid) => {
        openResultTable(variableOid);           // ** TO DO **
    };
    let showWindow = (oid, windowType) => {
        switch (windowType) {
            case "diagram": openDiagram(oid); break;
            case "editTable": openEditTable(oid); break;
            case "resultTable": openResultTable(oid); break;
            case "graph": openResultTable(oid); break;          /*** TO DO ***/
            case "object": break;                              /*** TO DO ***/
            case "outline": props.setShowOutliner(true); break;
            case "typescript": setShowConsole(true); break;
            default: break;
        }
    };
    let closeWindow = (oid, windowType) => {
        switch (windowType) {
            case "diagram": if (Array.isArray(diagramWindows)) setOpenDiagrams(diagramWindows.filter((val, ind) => (val !== oid))); break;
            case "editTable": if (Array.isArray(editTables)) setOpenEditTables(editTables.filter((val,ind)=> (val !== oid))); break;
            case "resultTable": if (Array.isArray(resultTables)) setOpenResultTables(resultTables.filter((val, ind) => (val !== oid))); break;
            case "outline": props.setShowOutliner(false); break;
            case "typescript": setShowConsole(false); break;
            default: break;
        }
    }
    SetShowWindowHooks(showWindow, closeWindow); 

	function drawDiagramWindow(oid)
    {
        if (oid === 0 || oid===undefined) return "";

        let closer = () => {
            let newOpenDiags = diagramWindows.filter((x) => (x !== oid))
            setOpenDiagrams(newOpenDiags);
        };

		return (
			<div key={"diagram "+oid} className="DiagramWindow Window">
                <TitleBar oid={oid} ico="img/Diagram.ico" type="Diagram" closer={closer} />
                <Diagram id={oid} openDiagram={openDiagram} openResult={openResultTable} openEditTable={openEditTable} openGraph={openGraph} enableHoverIcons={true} {...props} />
			</div>
		);
	}
	

    function drawResultTableWindow(oid) {
        if (oid === 0 || oid === undefined) return "";

        let closer = () => {
            let newOpenDiags = resultTables.filter((x) => (x !== oid))
            setOpenResultTables(newOpenDiags);
        };

        return (
            <div key={"result"+oid} className="ResultWindow Window">
                <TitleBar oid={oid} ico="img/Result.ico" type="Result" closer={closer} />
                <ResultTable oid={oid} resultTableVersion={props.resultTableVersion} proactive={true} />       { /* LDC 2/26/2021 Suan bug 516 */}
            </div>
        );
    }
    function drawEditTableWindow(oid) {
        if (oid === 0 || oid === undefined) return "";

        let closer = () => {
            let newOpenDiags = editTables.filter((x) => (x !== oid))
            setOpenEditTables(newOpenDiags);
        };

        return (
            <div key={"editTable" + oid} className="EditTableWindow Window">
                <TitleBar oid={oid} ico="img/EditTable.ico" type="EditTable" closer={closer} />
                <EditTable oid={oid} />
            </div>
        );
    }

	function drawOutlinerWindow()
    {
        //const [oid,] = useTopLevelOid();
        const oid = topOid;
		if (!props.bShowOutliner || oid===0) return "";

        const closer = () => props.setShowOutliner(false);

        // <div key="outliner" className="OutlinerWindow Window"> 

		return (
            <>
                <TitleBar oid={oid} type="Outliner" ico="img/OutlinerIcon.ico" closer={closer}/>
				<Outliner oid={oid} />				
            </>
		);
    }

    function drawCacheBrowserWindow()
    {
        if (!bShowCacheBrowser) return "";

        return (<div key="cacheBrowser" className="CacheBrowserWindow Window">
            <TitleBar type="CacheBrowser" ico="img/PeekAttribute.svg" closer={() => setShowCacheBrowser(false)} />
            <ClientCacheBrowser/>
        </div>);
    }

    function drawPeekAttributeWindow()
    {
        if (!bShowPeekAttribute) return "";
        return (
            <div key="peekAttribute" className="PeekAttributeWindow Window">
                <TitleBar type="PeekAttribute" ico="img/PeekAttribute.svg" closer={() => setShowPeekAttribute(false)}/>
                <PeekAttribute />
            </div>
        );
    }

    function drawConsoleWindow()
    {
        if (!bShowConsole) return "";
        return (
            <div key="typescriptConsole" className="TypescriptConsoleWindow Window">
                <TitleBar type="TypescriptConsole" title={"Server console - " + props.status} ico="img/ConsoleIcon.png" closer={() => setShowConsole(false)} />
                <TypescriptConsole {...props} />
            </div>
        );
    }

	return (
        <div className="WindowMgr">
            <div className="WindowMgrSplitter SplitLR">
                <div className="WindowMgrSplitter SplitLR">
                    <div className="WindowPane">
 			            { drawOutlinerWindow() }
                    </div>
                    <div className="WindowPane">
                        {editTables.map(drawEditTableWindow)}
                        { resultTables.map( drawResultTableWindow ) }
			            { diagramWindows.map( drawDiagramWindow ) }
                    </div>
                </div>
                <div className="WindowPane">
                    {drawConsoleWindow()}
                    {drawCacheBrowserWindow()}
                    {drawPeekAttributeWindow()}
                    <DistributionPopup/>
                </div>
            </div>
		</div>
	);	

}
