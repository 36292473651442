// Slider.js -- slider controls
// LDC 10/31/2022 ER S-1468

import React, { useLayoutEffect } from 'react';
import { useState, useRef } from 'react';
import { useObjAtt /*, useObjAtts*/ } from './serverState.js';
import { useLayoutRect } from './miscTools.js';

// Note: Sliders will probably have to be implemented as SVG controls in order to get the control
// over appearance that we have in DTA. I'm sure there are probably many react implementations
// already on the web.

function ContinuousSlider({ state, setVal }) {
    const range = state.ub - state.lb;
    const step = range / 1000;
    const [dragVal, setDragVal] = useState(state.val[0]);

    const styles = {
        height: state.trackHt,
        borderRadius: state.roundEnds ? state.trackHt / 2 : 0,
        backgroundColor: state.trackColor
    };

    function onChange(ev) {
        const val = ev.target.valueAsNumber;
        console.log("ev=", JSON.stringify(val));
        setVal(val);
    }
    function onDrag(ev) {
        // ** TO DO ** -- I haven't gotten it to drag continuously. I would like onChange to fire once, when it is
        //                released.  But the visual appearance should follow the drag.
        const x = ev.target.valueAsNumber;
        if (x !== dragVal) {
            setDragVal(ev.target.valueAsNumber);
        }
    }

    return <input type="range" className="Slider ContinuousSlider"
        min={state.lb} max={state.ub} value={dragVal} step={step} onChange={onChange} onInput={onDrag} style={styles}
    />;
}

function DiscreteSlider({ state, setVal }) {
    // ** TO DO **
    return <input type="range" className="Slider DiscreteSlider" />;
}

// sliders in tables use ths
export function Slider({ state, setVal, zoom}) {
    //console.log("Slider zoom", state);
    const [sliderWrapperRef, sliderWrapperRect] = useLayoutRect();

    const controlWidth = sliderWrapperRect ? sliderWrapperRect.width : 30;

    return <SliderInputControl state={state} setVal={setVal} controlWidth={controlWidth} isDiscrete={state.pos !== undefined} isEditTableCell={true} sliderWrapperRef={sliderWrapperRef} sliderWrapperRect={sliderWrapperRect} zoom={zoom} isEditTableCell={true}/>;
    /*
    if (state && state.val) {
        return <ContinuousSlider state={state} setVal={setVal} />;
    } else if (state && state.pos) {
        return <DiscreteSlider state={state} setVal={setVal} />;
    } else {
        return null;
    }
    */
}

// This is for a slider in a formnode control
export function InputSlider(props) {
    console.log("InputSlider zoom", props.zoom)
    const [state, setVal] = useObjAtt(props.oid, "_sliderState");
    const [controlWidth,] = useObjAtt(props.oid, "controlWidth");

    if (!state || !controlWidth) return "";

    return <SliderInputControl state={state} setVal={setVal} controlWidth={controlWidth} isDiscrete={state.pos !== undefined} zoom={props.zoom}/>;
}

function SliderInputControl(props) {
    //console.log("SliderInputControl", props.zoom, props.zoom);
    const sliderState = props.state;
    const controlWidth = props.controlWidth;
    const isDiscrete = props.isDiscrete;
    const [sliderDivWidth, setSliderDivWidth] = useState(controlWidth);
    const [sliderLbRef, sliderLbRect] = useLayoutRect();
    const [sliderUbRef, sliderUbRect] = useLayoutRect();
    const [leftLabelBelowRef, leftLabelBelowRect] = useLayoutRect();
    const [rightLabelBelowRef, rightLabelBelowRect] = useLayoutRect();

    //console.log("controlWidth", controlWidth)

    const sliderContinerDiv = useRef();
    const [thumb1Down, setThumb1Down] = useState(false);
    const [thumbX1Dragging, setDraggingThumbX1] = useState(-1);
    //console.log("thumbX1Dragging", thumbX1Dragging)
    const [thumb2Down, setThumb2Down] = useState(false);
    const [thumbX2Dragging, setDraggingThumbX2] = useState(-1);
    const [sliderPosNew, setSliderPosNew] = useState(null); // used to stop "bounce back" and also fix 
    const [newPosTime, setNewPosTime] = useState(0);
    const sliderValue1 = sliderState.val !== undefined ? sliderState.val[0] : null;
    const sliderValue2 = (sliderState.val !== undefined && sliderState.val.length > 1) ? sliderState.val[1] : null;
    let sliderPos1 = sliderState.pos !== undefined ? (sliderPosNew !== null && Date.now() - newPosTime < 2000 ? sliderPosNew : sliderState.pos[0]) : null;
    const sliderPos2 = sliderState.pos !== undefined && sliderState.pos.length > 1 ? sliderState.pos[1] : null;
    const sliderLowerBound = sliderState.lb;
    const sliderUpperBound = sliderState.ub;
    const trackMarginX = props.isEditTableCell ? 2 : 10;
    const trackWidth = sliderDivWidth - 2 * trackMarginX;
    const numPositions = isDiscrete ? sliderState.options.length : 0;
    const lengthTrackInterval = trackWidth / (numPositions - 1);
    const thumbX1NotDragging = !isDiscrete ? ((sliderValue1 - sliderLowerBound) / (sliderUpperBound - sliderLowerBound)) * trackWidth : lengthTrackInterval * (sliderPos1 - 1);
    const thumbX1 = thumb1Down && thumbX1Dragging !== -1 ? thumbX1Dragging : thumbX1NotDragging + trackMarginX;
    const thumbX2NotDragging = !isDiscrete ? (((sliderValue2 - sliderLowerBound) / (sliderUpperBound - sliderLowerBound)) * trackWidth) : lengthTrackInterval * (sliderPos2 - 1);
    const thumbX2 = thumb2Down && thumbX2Dragging !== -1 ? thumbX2Dragging : thumbX2NotDragging + trackMarginX;
    const thumbXs = [thumbX1, thumbX2];
    const [trackFillLeftSideX, trackFillWidth] = getTrackFillInfo();
    const zoom = props.zoom ? props.zoom : 1;

    function getTrackFillInfo() {
        if ((sliderState.val && sliderState.val.length === 1) || (sliderState.pos && sliderState.pos.length === 1))
            return [trackMarginX, thumbX1 - trackMarginX];
        else {
            return [thumbX1, thumbX2 - thumbX1]
        }
    }

    function mouseDownThumb(ev) {
        console.log("slider thumb mouse DOWN", ev);
        console.log(ev.target.id)
        ev.stopPropagation();
        ev.preventDefault();
        if (ev.target.id === "thumb1")
            setThumb1Down(true);
        else if (ev.target.id === "thumb2")
            setThumb2Down(true);
    }

    function mouseUpThumb(ev) {
        console.log("slider thumb mouse UP");
        ev.stopPropagation();
        ev.preventDefault();
        if (!isDiscrete)
            setNewSliderPosContinuous(ev, true);
        else
            setNewSliderPosDiscrete(ev, true);

        if (thumb1Down) setThumb1Down(false);
        if (thumb2Down) setThumb2Down(false);
    }

    function mouseMoveThumb(ev) {
        //console.log("mouse move thumb", ev);
        ev.stopPropagation();
        ev.preventDefault();
        if (!isDiscrete)
            setNewSliderPosContinuous(ev);
        else {
            //console.log("setNewSliderPosDiscrete")
            setNewSliderPosDiscrete(ev);
        }
    }

    function trackClick(ev) {
        console.log("TRACK CLICK", ev, props);
        ev.stopPropagation();
        ev.preventDefault();

        if (!isDiscrete)
            setNewSliderPosContinuous(ev, true);
        else
            setNewSliderPosDiscrete(ev, true);
    }

    function setNewSliderPosContinuous(ev, sendToServer) {
        console.log("setNewSliderPosContinuous", zoom);
        const sliderContainerRect = sliderContinerDiv?.current?.getBoundingClientRect();

        const clientX = ev.clientX;
        let sliderXPos = (clientX - sliderContainerRect.left)/zoom;
        console.log("sliderXPos", sliderXPos)
        if (sliderXPos < trackMarginX) sliderXPos = trackMarginX;
        if (sliderXPos > trackMarginX + trackWidth) sliderXPos = trackMarginX + trackWidth;

        if (thumb1Down) {
            if (sliderState.val.length > 1 && sliderXPos > thumbX2) sliderXPos = thumbX2;
            setDraggingThumbX1(sliderXPos);
        } else if (thumb2Down) {
            if (sliderXPos < thumbX1) sliderXPos = thumbX1;
            setDraggingThumbX2(sliderXPos);
        }

        if (sendToServer) {
            const newVal0 = sliderLowerBound + (((sliderXPos - trackMarginX) / trackWidth) * (sliderUpperBound - sliderLowerBound));
            //console.log(sliderLowerBound, sliderXPos, trackMarginX, trackWidth, sliderUpperBound, sliderLowerBound)
            const newVal = newVal0;
            console.log("NEW VAL1 SENT TO SERVER", newVal, thumb2Down)

            // LDC 1/20/2023 Bug S-1529. Not valid to destructively change something deep within a data structure that comes from useObjAtt.
            // Doing so changes the JS-cached value also, making it look to setVal as if the new value is the same as the old value, thus
            // causing it to ignore the change and not send it to the server.  So never do this:
            //newSliderState.val[0] = newVal1;
            // Instead do this, which is non-destructive (makes a copy):

            let newSliderState;
            if (thumb1Down || sliderState.val.length === 1) {
                const [val0, ...restVals] = sliderState.val;
                newSliderState = { ...sliderState, val: [newVal, ...restVals] };
            }
            else if (thumb2Down) {
                const newValArr = [...(sliderState.val)];
                newValArr[1] = newVal;
                console.log("newValArr", newValArr);
                newSliderState = { ...sliderState, val: [...newValArr] };
                console.log("newSliderStaete", newSliderState)
            }
            else {
                //track click, more than 1 thumb
                if (sliderState.val.length === 2) {
                    console.log("moving one of two thumbs");
                    const distThumb1 = Math.abs(sliderXPos - thumbX1);
                    const distThumb2 = Math.abs(sliderXPos - thumbX2);
                    if (distThumb1 < distThumb2) {
                        console.log("MOVE thumb 1");
                        const [val0, ...restVals] = sliderState.val;
                        newSliderState = { ...sliderState, val: [newVal, ...restVals] };
                    }
                    else {
                        console.log("MOVE thumb 2");
                        const newValArr = [...(sliderState.val)];
                        newValArr[1] = newVal;
                        console.log("newValArr", newValArr);
                        newSliderState = { ...sliderState, val: [...newValArr] };
                        console.log("newSliderStaete", newSliderState)
                    }
                }
            }

            // However, this payload isn't what set _sliderState expects. Instead, it expects just the list.
            if (!props.isEditTableCell)
                props.setVal(newSliderState);
            else
                props.setVal([newVal]);
        }
    }

    function setNewSliderPosDiscrete(ev, sendToServer) {
        //console.log("setNewSliderPosDiscrete", zoom, sliderState)
        const sliderContainerRect = sliderContinerDiv?.current?.getBoundingClientRect();
        const clientX = ev.clientX;
        let sliderXPos = clientX - sliderContainerRect.left;

        //console.log("clientX w/zoom, sliderXPos", ev.clientX, sliderContainerRect.left, sliderXPos);
        let newSliderPos;
        if (sliderXPos < trackMarginX)
            newSliderPos = 1;
        else if (sliderXPos/zoom > trackMarginX + trackWidth)
            newSliderPos = sliderState.options.length;
        else {
            const trackClickLoc = (clientX - sliderContainerRect.left - trackMarginX)/zoom;
            //console.log("trackClickLoc", trackClickLoc, lengthTrackInterval, trackMarginX)
            newSliderPos = Math.round(trackClickLoc / lengthTrackInterval) + 1;
            //console.log("new slider pos", newSliderPos)
        }

        if (thumb1Down && sliderState.pos.length === 2 && newSliderPos > sliderState.pos[1])
            newSliderPos = sliderState.pos[1];
        else if (thumb2Down && newSliderPos < sliderState.pos[0])
            newSliderPos = sliderState.pos[0];

        //console.log("newSliderPos", newSliderPos)

        let newSliderX = (newSliderPos - 1) * lengthTrackInterval + trackMarginX;
        if (thumb1Down) {
            //console.log("newSliderPos", newSliderPos)
            setDraggingThumbX1(newSliderX);
        } else if (thumb2Down) {
            if (sliderXPos < thumbX1) sliderXPos = thumbX1;
            setDraggingThumbX2(newSliderX);
        }

        if (sendToServer) {
            console.log("NEW Pos1 SENT TO SERVER", newSliderPos)

            let newSliderState;
            if (thumb1Down || sliderState.pos.length === 1) {
                if (thumb1Down && sliderState.pos.length === 2 && newSliderPos > sliderState.pos[1])
                    newSliderPos = sliderState.pos[1];
                const [pos0, ...restVals] = sliderState.pos;
                newSliderState = { ...sliderState, pos: [newSliderPos, ...restVals] };
            }
            else if (thumb2Down) {
                const newPosArr = [...(sliderState.pos)];
                newPosArr[1] = newSliderPos;
                newSliderState = { ...sliderState, pos: [...newPosArr] };
            }
            else {
                //track click, more than 1 thumb
                if (sliderState.pos.length === 2) {
                    console.log("moving one of two thumbs");
                    const distThumb1 = Math.abs(newSliderX - thumbX1);
                    const distThumb2 = Math.abs(newSliderX - thumbX2);
                    if (distThumb1 < distThumb2) {
                        console.log("MOVE thumb 1");
                        const [pos0, ...restVals] = sliderState.pos;
                        newSliderState = { ...sliderState, pos: [newSliderPos, ...restVals] };
                    }
                    else {
                        console.log("MOVE thumb 2");
                        const newPosArr = [...(sliderState.pos)];
                        newPosArr[1] = newSliderPos;
                        newSliderState = { ...sliderState, pos: [...newPosArr] };
                    }
                }
            }
            console.log("newSliderState being sent to server", props.isEditTableCell, newSliderState);
            if (!props.isEditTableCell)
                props.setVal(newSliderState);
            else
            {
                console.log("new slider value", newSliderPos, sliderState.options[newSliderPos - 1]);
                setSliderPosNew(newSliderPos); // client side, stop "bounce back"
                setNewPosTime(new Date());
                props.setVal(sliderState.options[newSliderPos - 1]); // this gets sent to suan.exe
                //props.setVal(newSliderPos);
            }
        }
    }

    function stageClick(ev) {
        console.log("stage click", ev)
        ev.stopPropagation();
        ev.preventDefault();
        // do nothing for now i.e. don't select the node
    }

    if (!props.isEditTableCell) {
        if (sliderState.labels === "atEnds" && sliderLbRect && sliderUbRect) {
            const newSliderDivWidth = controlWidth - sliderLbRect.width - sliderUbRect.width;
            if (sliderDivWidth !== newSliderDivWidth)
                setSliderDivWidth(newSliderDivWidth);
        }
        else if (sliderState.labels === "none" && sliderDivWidth !== controlWidth)
            setSliderDivWidth(controlWidth);
        else if (sliderState.labels === "below" && leftLabelBelowRect && rightLabelBelowRect) {
            const sliderDivWidthLabelsBelow = controlWidth - leftLabelBelowRect.width / 2 - rightLabelBelowRect.width / 2;
            //console.log("sliderDivWidthLabelsBelow", sliderDivWidthLabelsBelow, controlWidth, leftLabelBelowRect.width, rightLabelBelowRect.width, sliderDivWidth);
            if (sliderDivWidth !== sliderDivWidthLabelsBelow && Math.abs(sliderDivWidth - sliderDivWidthLabelsBelow) > .2) {
                //console.log("**setSliderDivWidth", sliderDivWidth, sliderDivWidthLabelsBelow, sliderDivWidth - sliderDivWidthLabelsBelow);
                if (sliderDivWidthLabelsBelow) setSliderDivWidth(sliderDivWidthLabelsBelow);
            }
        }
    } else {
        if (sliderState.labels === "atEnds" && sliderLbRect && sliderUbRect) {
            const newSliderDivWidth = (controlWidth - sliderLbRect.width - sliderUbRect.width)/zoom;
            if (sliderDivWidth !== newSliderDivWidth)
                setSliderDivWidth(newSliderDivWidth);
        }
        else if (sliderState.labels === "none" && sliderDivWidth !== controlWidth/zoom)
            setSliderDivWidth(controlWidth/zoom);
        else if (sliderState.labels === "below" && leftLabelBelowRect && rightLabelBelowRect) {
            const sliderDivWidthLabelsBelow = (controlWidth - leftLabelBelowRect.width / 2 - rightLabelBelowRect.width / 2)/zoom;
            //console.log("sliderDivWidthLabelsBelow", sliderDivWidthLabelsBelow, controlWidth, leftLabelBelowRect.width, rightLabelBelowRect.width, sliderDivWidth);
            if (sliderDivWidth !== sliderDivWidthLabelsBelow && Math.abs(sliderDivWidth - sliderDivWidthLabelsBelow) > .2) {
                //console.log("**setSliderDivWidth", sliderDivWidth, sliderDivWidthLabelsBelow, sliderDivWidth - sliderDivWidthLabelsBelow);
                if (sliderDivWidthLabelsBelow) setSliderDivWidth(sliderDivWidthLabelsBelow);
            }
        }
    }

    const cornerRadiusTrack = sliderState.roundEnds ? sliderState.trackHt / 2 : 0;
    const sliderHeight = sliderState.trackHt + 10

    let sliderDivCls = "Slider ContinuousSlider UserControl";
    if (sliderState.labels === "atEnds") sliderDivCls += " LabelsAtEnds";
    const sliderLabelsWrapperCls = "SliderLabelsWrapper " + sliderState.labels;

    const leftSliderSpace = sliderState.labels === "below" && leftLabelBelowRect ? leftLabelBelowRect?.width / 2 : 0;
    const rightSliderSpace = sliderState.labels === "below" && rightLabelBelowRect ? rightLabelBelowRect?.width / 2 : 0;

    const labelsBelowSliderHeight = isNaN(leftLabelBelowRect?.height + sliderHeight + 5) ? 0 : leftLabelBelowRect?.height + sliderHeight + 5;
    let labelsBelowSliderStyle = sliderState.labels === "below" ? { height: labelsBelowSliderHeight } : {};
    if (sliderState.labels === "below" && !isDiscrete) labelsBelowSliderStyle = { height: sliderHeight }; // won't need this when continuous labels below is implemented
    //console.log("labelsBelowSliderStyle", labelsBelowSliderStyle)
    return (
        <div className={sliderLabelsWrapperCls} ref={props.sliderWrapperRef} >
            {sliderState.labels === "atEnds" && <div className="LbSliderLabel" ref={sliderLbRef}>{sliderState.lb !== undefined ? sliderState.lb : sliderState.options[0]}</div>}
            {sliderState.labels === "below" && <div className="LabelBelowSpacer" style={{ background: "yellow", width: leftSliderSpace }}></div>}
            <div className="LabelsBelowAndSliderWrapper" style={labelsBelowSliderStyle}>
            <div className={sliderDivCls} style={{ width: sliderDivWidth, height: sliderHeight }} ref={sliderContinerDiv}>
                <svg id="stage" style={{ height: sliderHeight, width: sliderDivWidth }} onClick={stageClick} onMouseMove={mouseMoveThumb} onMouseUp={mouseUpThumb}>
                    <rect id="track" height={sliderState.trackHt} width={sliderDivWidth - 2 * trackMarginX} y={6} x={trackMarginX} ry={cornerRadiusTrack} rx={cornerRadiusTrack} style={{ fill: sliderState.trackColor, stroke: sliderState.trackOutlineColor }} onClick={trackClick}></rect>
                    {sliderState?.showSelect === "true" && <rect id="track-fill" height={sliderState.trackHt} width={trackFillWidth} y={6} x={trackFillLeftSideX} ry={cornerRadiusTrack} rx={cornerRadiusTrack} style={{ fill: sliderState?.selectColor }} onClick={trackClick}></rect>}
                    {sliderState.val && sliderState.val.map((v, i) => <SliderThumb sliderState={sliderState} i={i} key={i} value={v} thumbId={"thumb" + (i + 1).toString()} thumbXs={thumbXs} mouseDownThumb={mouseDownThumb} mouseUpThumb={mouseUpThumb} />)}
                    {sliderState.pos && sliderState.pos.map((v, i) => <SliderThumb sliderState={sliderState} i={i} key={i} value={v} thumbId={"thumb" + (i + 1).toString()} thumbXs={thumbXs} mouseDownThumb={mouseDownThumb} mouseUpThumb={mouseUpThumb} />)}
                    {isDiscrete && sliderState.labels === "below" && sliderState.options.map((v, i) => <SliderTick sliderState={sliderState} i={i} key={i} value={v} trackMarginX={trackMarginX} sliderTrackWidth={sliderDivWidth - 2 * trackMarginX} />)}
                </svg>
            </div>
                {isDiscrete && <LabelsBelow sliderState={sliderState} trackMarginX={trackMarginX} sliderTrackWidth={sliderDivWidth - 2 * trackMarginX} numOptions={sliderState.options.length} leftLabelBelowRef={leftLabelBelowRef} rightLabelBelowRef={rightLabelBelowRef} sliderDivWidth={sliderDivWidth} isDiscrete={isDiscrete} />}
            </div>
            {sliderState.labels === "atEnds" && <div className="UbSliderLabel" ref={sliderUbRef}>{sliderState.ub !== undefined ? sliderState.ub : sliderState.options[sliderState.options.length - 1]}</div>}
            {/*sliderState.labels === "below" && <div className="LabelBelowSpacer" style={{ background: "yellow", width: rightSliderSpace }}></div>*/}
        </div>
    );
}

function SliderTick(props) {
    //console.log("slider tick line", props)
    const lineX1 = props.trackMarginX + (props.i * (props.sliderTrackWidth / (props.sliderState.options.length -1)));
    const lineY1 = props.sliderState.trackHt + (2 * 6) - 1;
    const lineX2 = lineX1;
    const lineY2 = lineY1 + 4;

    return <line x1={lineX1} y1={lineY1} x2={lineX2} y2={lineY2} style={{stroke:"rgb(0,0,0)"}} />
}

function LabelsBelow({ sliderState, trackMarginX, leftLabelBelowRef, rightLabelBelowRef, sliderDivWidth, isDiscrete }) {
    // labels below, hiding
    const [skipSomeLabels, setSkipSomeLabels] = useState(false);
    const [numTicksPerLabel, setNumTicksPerLabel] = useState(0);

    //console.log("LabelsBelow", skipSomeLabels, numTicksPerLabel);

    const sliderTrackWidth = sliderDivWidth - 2 * trackMarginX;
    const numOptions = sliderState?.options?.length

    useLayoutEffect(() => {
        //if (skipSomeLabels) {
        //console.log("rightLabelBelowRef", rightLabelBelowRef);
        const rightLabelRect = rightLabelBelowRef?.current?.getBoundingClientRect();
        //console.log(rightLabelRect);
        const widthBetweenTicks = sliderTrackWidth / (numOptions - 1);
        //console.log("width between ticks", widthBetweenTicks, sliderTrackWidth, rightLabelRect?.width);
        if (rightLabelRect?.width + 4 > widthBetweenTicks) {
            //console.log("labels collide, hide some", rightLabelRect?.width + 4, widthBetweenTicks);
            const test = (rightLabelRect?.width + 4) / widthBetweenTicks;
            setSkipSomeLabels(true);
            setNumTicksPerLabel(Math.ceil(test - 1) + 1);
        }
        else
            setSkipSomeLabels(false);
        //}
    })


    return (
        <div className="LabelsBelowWrap">
            {isDiscrete && sliderState.labels === "below" && sliderState.options.map((v, i) => <LabelBelow sliderState={sliderState} i={i} key={i} label={v} trackMarginX={trackMarginX} sliderTrackWidth={sliderTrackWidth} numOptions={numOptions} leftLabelBelowRef={leftLabelBelowRef} rightLabelBelowRef={rightLabelBelowRef} skipSomeLabels={skipSomeLabels} numTicksPerLabel={numTicksPerLabel} />)}
        </div>
    );
}

function LabelBelow({ i, label, numOptions, sliderTrackWidth, trackMarginX, leftLabelBelowRef, rightLabelBelowRef, skipSomeLabels, numTicksPerLabel}) {
    //console.log("Label Below");
    const left = (sliderTrackWidth * i / (numOptions - 1) + trackMarginX)
    let cls = "LabelBelow";

    //const numBlankLabels = 4;
    //console.log("i", i, i % numBlankLabels)
    //let hideLabel = false;
    if (skipSomeLabels && i % numTicksPerLabel !== 0) {
        //console.log("***********************skip some labels", skipSomeLabels, i, numTicksPerLabel)
        cls += " Hide";
    }
    else {
        //console.log("don't skip this label", numTicksPerLabel, i , i % numTicksPerLabel)
    }

    //console.log("labels below cls", cls);

    //if (hideLabel)
    //    return null;

    if (i === 0)
        return <div className={cls} style={{ left: left }} ref={leftLabelBelowRef}>{label}</div>;

    if (i === numOptions - 1)
        return <div className={cls} style={{ left: left }} ref={rightLabelBelowRef}>{label}</div>;

    return <div className={cls} style={{ left: left }} >{label}</div>;
}

function SliderThumb(props) {
    //console.log("SliderThumb", props)
    const sliderState = props.sliderState;
    const i = props.i;

    switch (sliderState.thumbShape) {

        case "cirlce":
            return <circle id={props.thumbId} r={sliderState.trackHt / 2 + 4} cy={5 + sliderState.trackHt / 2 + 1} cx={props.thumbXs[i]} style={{ fill: sliderState.thumbColor[i], stroke: sliderState.thumbOutlineColor }} onMouseDown={props.mouseDownThumb} onMouseUp={props.mouseUpThumb} ></circle>;
            break;
        case "rectangle":
            const height = sliderState.trackHt + 8;//16; // min height 20
            const width = 8;
            return <rect id={props.thumbId} width={width} height={height} y={2} x={props.thumbXs[i] - width / 2} style={{ fill: sliderState.thumbColor[i], stroke: sliderState.thumbOutlineColor }} onMouseDown={props.mouseDownThumb} onMouseUp={props.mouseUpThumb} ></rect>;
            break;
        case "needle":
            const heightNeedle = sliderState.trackHt + 8; // sliderState.trackHt + 8 > 16 ? sliderState.trackHt + 8 : 16;//16;
            const widthNeedle = 8;
            const x1 = props.thumbXs[i] - widthNeedle / 2;
            const y1 = 2;
            const x2 = Math.round(x1 + widthNeedle).toString();
            const y2 = 2;
            const x3 = x2;
            const y3 = 2 + heightNeedle - 3
            const x4 = x3 - 4;
            const y4 = 2 + heightNeedle;
            const x5 = x1;
            const y5 = y3;

            let points = x1.toString() + "," + y1.toString() + " " + x2.toString() + "," + y2.toString() + " " + x3.toString() + "," + y3.toString();
            points += " " + x4.toString() + "," + y4.toString() + " " + x5.toString() + "," + y5.toString();

            return <polygon id={props.thumbId} points={points} style={{ fill: sliderState.thumbColor[i], stroke: sliderState.thumbOutlineColor }} onMouseDown={props.mouseDownThumb} onMouseUp={props.mouseUpThumb} ></polygon>;
            break;
        case "diamond":

            const widthDiamond = sliderState.trackHt > 8 ? sliderState.trackHt + 8 : 20;
            const heightDiamond = widthDiamond;
            const dx1 = props.thumbXs[i];
            const dy1 = sliderState.trackHt > 8 ? 2 : 0;
            const dx2 = Math.round(dx1 + widthDiamond / 2).toString();
            const dy2 = dy1 + widthDiamond / 2;
            const dx3 = dx1;
            const dy3 = dy1 + heightDiamond;
            const dx4 = dx1 - widthDiamond / 2;
            const dy4 = dy1 + heightDiamond / 2

            const pointsDiamond = dx1.toString() + "," + dy1.toString() + " " + dx2.toString() + "," + dy2.toString() + " " + dx3.toString() + "," + dy3.toString() + " " + dx4.toString() + "," + dy4.toString()

            return <polygon id={props.thumbId} points={pointsDiamond} style={{ fill: sliderState.thumbColor[i], stroke: sliderState.thumbOutlineColor }} onMouseDown={props.mouseDownThumb} onMouseUp={props.mouseUpThumb} ></polygon>;
            break;
        case "block":
            const blockSize = sliderState.trackHt;
            return <rect x={props.thumbXs[i] - blockSize / 2} y={6} width={blockSize} height={blockSize} style={{ fill: sliderState.thumbColor[i], stroke: sliderState.thumbOutlineColor }} onMouseDown={props.mouseDownThumb} onMouseUp={props.mouseUpThumb} />
            break;
        case "line":
            const lineHeight = sliderState.trackHt + 8;

            return <rect x={props.thumbXs[i] - 1} y={2} width={2} height={lineHeight} style={{ stroke: sliderState.thumbOutlineColor }} onMouseDown={props.mouseDownThumb} onMouseUp={props.mouseUpThumb} />
            break;
        case "none":
            return "";
            break;
    }
    // should not get here...
    return <circle id={props.thumbId} r={sliderState.trackHt / 2 + 4} cy={5 + sliderState.trackHt / 2 + 1} cx={props.thumbXs[i]} style={{ fill: sliderState.thumbColor[i], stroke: sliderState.thumbOutlineColor }} onMouseDown={props.mouseDownThumb} onMouseUp={props.mouseUpThumb} ></circle>;
}
