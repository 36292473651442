// formNode.js

import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useObjAtt, useObjAtts, useQuery } from './serverState.js';
import { WebSocket_Send } from './webSocketClient.js';
import { ShowDistributionPopup } from './distributionPopup.js';
import { ShowListPopup, ShowSequencePopup } from './sequenceAndListPopups.js';
import { InputChoice, InputMultiChoice, DoInputChoice } from './choice.js';
import { InputSlider } from './slider.js';
import { EmbeddedEditTable, getDescriptionHeightPercentage } from './frameNode.js';
import { GraphForViewType } from './graph.js';
import { ResultTable } from './resultTable.js';
import { ObjectView } from './objectWindow.js';

function IsQuotedText(t)
{
    if (typeof (t) !== "string") return false;
    if (t.length < 2) return false;
    const quote = t.charAt(0);
    if (quote !== '\'' && quote !== '"') return false;
    if (t.charAt(t.length - 1) !== quote) return false;

    // Now worry about an expression like: 'hello' & 'world'
    // which would not qualify as a single quoted string.  We can have doubled quotes,
    // like 'hello ''there'' world'
    for (let i = 1; i + 1 < t.length; ++i) {
        if (t.charAt(i) === quote) {
            if (t.charAt(i + 1) !== quote) return false;  // The quote wasn't doubled
            ++i;
            if (i + 1 === t.length) return false;  // this case, such as 'a '', would actually be an invalid expression.
        }
    }

    return true;
}

function RemoveQuotes(t)
{
    // Assumes t is valid fully-quoted text string.
    const quote = t.charAt(0);    
    const t1 = t.substring(1, t.length - 1);
    if (quote === '\'') return t1.replace(/''/g, quote);
    else if (quote === '"') return t1.replace(/""/g, quote);
    else return t1;
}
//function AddQuotes(t)
//{
//    if (t.indexOf("'") < 0)
//        return "'" + t + "'";

//    return '"' + t.replace(/"/g, '""') + '"';
//}

function InputNodeTextBox(props)
{
    const [orig, bgColor, controlWidth, entry, cue, nodeFontStyle] = useObjAtts(props.oid, ["original", "nodeColor", "controlWidth", "_entryType", "_editBoxCueText", "_nodeFont"]);
    const [dispText,] = useObjAtt(orig, "_userInputDispText");       // LDC 3/19/2021 Suan bug 15
    const [def0, setDef0] = useObjAtt(orig, "_userInputText");       // Formerly aid="definition"
    const [defType,] = useObjAtt(orig, "definitionType"); 
    const [dirty, setDirty] = useState(false);
    const def = props.def !== undefined ? props.def : def0;
    const setDef = props.setDef || setDef0;
    const [displayedText, setDisplayedText] = useState(dispText===undefined ?"":dispText);
    const [origDisplayedText, setOrigDisplayedText] = useState(def === undefined ? "" : def);
    //const [textAreaHeight, setTextAreaHeight] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const textAreaRef = useRef();
    const [multilineInput, setMultilineInput] = [props.multilineInput, props.setMultilineInput];

    const entryType = props.entry || (entry && entry.entry);
    const bSingleLine = (entry && entry.singleLine) || entryType === "number";
    const txt = isEditing || defType === "SubTable" ? def : dispText;  // 723 - Added-> || defType === "SubTable"    
    const toDisplay = entryType === 'expr' && !isEditing && IsQuotedText(txt) ? RemoveQuotes(txt) : txt;

    const align = entryType === "text" ? { textAlign: "left" } : {};
    //const ht = !bSingleLine && textAreaHeight ? { height: textAreaHeight + 4 } : {};                 // LDC 10/29/2020 Suan 308. The +6 matches the padding + border, since it is now box-sized by border rather than content. 
    let fontInfo = nodeFontStyle === null ? props.fontStyle : nodeFontStyle;
    const style = { backgroundColor: bgColor, width: controlWidth, ...align, /*...ht,*/ ...fontInfo }; //, ...props.fontStyle }; //...nodeFontStyle } //, ...props.fontStyle};
    const bShouldBeMultiline = !bSingleLine && props.nodeHeight > 80;           // LDC 6/24/2021 S-874. Was >60, but to match when Analytica switches over at default font, I changed it to 80.
    const cls1 = "InputNodeTextBox UserControl" + (isEditing ? " Editing" : "");
    const cls2 = bShouldBeMultiline ? " Multiline" : "";
    const cls = cls1 + cls2;

    if (props?.cloudStyles?.text_inputs_white !== "no" && style) delete style.backgroundColor  // EW 1320, make textarea background white

    useEffect(() => {                // LDC 6/24/2021 Eliminate react warning.
        if (!dirty && displayedText !== toDisplay && toDisplay !== undefined) {
            setDisplayedText(toDisplay);
            setOrigDisplayedText(toDisplay);
        }
    }, [toDisplay, dirty, displayedText, setDisplayedText, setOrigDisplayedText]);

    //useEffect(() => {
    //    if (!bSingleLine && textAreaRef && !multilineInput)
    //        AdjustTextBoxHeight(textAreaRef.current);
    //}, [bSingleLine, textAreaRef, displayedText, multilineInput]);

    useEffect(() => {
        if (multilineInput !== bShouldBeMultiline) {
            setMultilineInput(bShouldBeMultiline);
        }
    },[bShouldBeMultiline,multilineInput,setMultilineInput]);

    // These next if statements are cases where the above useEffect will kick in and change a state variable,
    // causing a re-render. There is no reason to render prior to those re-renders.
    if (!dirty && displayedText !== toDisplay && toDisplay !== undefined) {
        return null;
    }
    if (multilineInput !== bShouldBeMultiline)
        return null;


    function onFocus(ev)
    {
        if (!ev.target.value.endsWith("%")) {
            ev.target.select();
            setIsEditing(true);
            setDisplayedText(def);
            setTimeout((ev) => (ev.target.select()), 50, ev) // EW 870
        }
        else {
            // 1598 - When clicking on input node with numeric % format, select only the digits as DTA does.
            console.log("Looks like percent")
            ev.target.setSelectionRange(0, ev.target.value.length - 1);
            setTimeout((ev) => (ev.target.setSelectionRange(0, ev.target.value.length - 1)), 50, ev) // EW 870
        }
    }

    //function AdjustTextBoxHeight(box)
    //{
    //    // Hack to get the height of the content
    //    if (!box || !box.style) return;
    //    const origHt = box.style.height;
    //    box.style.height = 0;
    //    const contentHt = box.scrollHeight;
    //    box.style.height = origHt;
    //    const newHt = contentHt; 

    //    setTextAreaHeight(newHt);
    //}

    function onClick(ev) {
        ev.stopPropagation();
    }

    function onChange(ev) {
        setDirty(true);
        setDisplayedText(ev.currentTarget.value);
    }
    function onChangeTextArea(ev) {
        onChange(ev);
        //AdjustTextBoxHeight(ev.currentTarget);
    }
    function onDone(ev) {
        setIsEditing(false);
        if (displayedText !== def) {
            if (entryType==="number") {
                // Validate that it is a valid numeric entry
                if (!displayedText.match(/^[+-]?\d+(\.\d*)?([QTGBMK%munpfqtgbkUNPF]|e[+-]?\d+)?$/u)      // \x0956 is micro (greek mu). Not sure how to include it in javascript regex
                    && !displayedText.match(/^0x[0123456789abcdefABCDEF]+$/)
                    && !displayedText.match(/^0b[01]+$/))
                {
                    // Note: I haven't matched on date here, which Analytica would accept.
                    alert("Number required. Restoring original value " + origDisplayedText);
                    setDisplayedText(origDisplayedText);
                    ev.currentTarget.focus();
                    return;
                }
            }
            setDef(displayedText);
            setDirty(false);
            if (props.setPromptToSaveOnClosing) props.setPromptToSaveOnClosing(true);
        }
        setDisplayedText(dispText);
    }
    function keyPress(ev) {
        if (ev.charCode === 13 && (bSingleLine || (!ev.shiftKey && !ev.altKey))) {
            ev.preventDefault();
            ev.currentTarget.blur();
        }
    }

    // ** TO DO **: Add placeholder (cue) text, when present
    // ** TO DO **: Text-only, number-only entry

    // LDC 6/24/2021 S-874 -- the next two lines should be handled in CSS, not here.
    //if (!bSingleLine && props.nodeHeight > 80)
    //    style.height = "100%";//(props.nodeHeight - 14) + "px";

    

    return bSingleLine ?
        (
            <input className={cls} type="text" value={displayedText} onClick={onClick} onChange={onChange} onBlur={onDone}
                onKeyPress={keyPress} style={style} onFocus={onFocus} placeholder={cue} />
        ) :
        (

            <textarea className={cls} value={displayedText} onClick={onClick} onChange={onChangeTextArea} onBlur={onDone}
                onKeyPress={keyPress} style={style} ref={textAreaRef} onFocus={onFocus} placeholder={cue} />
        );

}

function EditTableButton(props)
{
    const [color,controlWidth,buttonStyle,nodefont] = useObjAtts(props.oid, ['nodeColor','controlWidth','_buttonStyle','_nodefont']);

    let font = nodefont === null ? props.fontStyle : nodefont;
    let style = { ...font };
    if (buttonStyle === undefined) return null;  // 1616
    if (buttonStyle !== "nakedIcon") style.backgroundColor = color;
    if (buttonStyle === "flat") {
        if (props?.nodeInfoOrig?.border === 0)
            style.borderColor = color;  // flat buttons have no border when original has no border, just make border same color as node so the size doesn't change
        else
            style.borderColor = props?.nodeBorderColorOrig;
    }
    if (buttonStyle !== "nakedIcon" && buttonStyle !== "icon") style.width = controlWidth;
    const label = buttonStyle === "icon" || buttonStyle === "nakedIcon" ? (<div className="IconContainer"><img src="img/EditTable.ico" alt="Table" /></div>) : "Table";

    function OpenEditTable(event) {
        console.log("formNode.js OpenEditTable");
        event.stopPropagation();
        event.preventDefault();
        console.log(props.openEditTable)
        props.openEditTable(props.oid);
    }

    if (props.pending) {
        return (<div className="editTableButton UserControl FormButton Pending" style={style} onClick={OpenEditTable}><div className="Progress" style={{backgroundColor:color}}>{label}</div></div>);
    } else {
        return (<button className={"editTableButton UserControl FormButton ButtonStyle_"+buttonStyle} style={style} onClick={OpenEditTable}>{label}</button>);
    }
}

function HiddenDefinitionButton(props)
{
    const [color, controlWidth, buttonStyle] = useObjAtts(props.oid, ['nodeColor', 'controlWidth', '_buttonStyle']);
    const style = { backgroundColor: color, width: controlWidth };

    if (buttonStyle === "flat") {
        if (props?.nodeInfoOrig?.border === 0)
            style.borderColor = color;  // flat buttons have no border when original has no border, just make border same color as node so the size doesn't change
        else
            style.borderColor = props?.nodeBorderColorOrig;
    }

    return (<button className={"UserControl HiddenDefn FormButton ButtonStyle_"+buttonStyle} style={style}>Hidden</button>);
}


//======================================================================================================================================
// Checkbox controls
//====================

function DoInputCheckbox(props)
{
    function onClick(ev) {
        ev.stopPropagation();
    }
    function onChange(ev) {
        props.setIsChecked(props.isChecked ? 0 : 1);
    }
    return (<input className="UserControl FormCheckbox" type="checkbox" checked={props.isChecked?1:0} onClick={onClick} onChange={onChange} />);
}

function InputCheckbox(props)
{
    const [isChecked, setIsChecked] = useObjAtt(props.oid, "_isChecked");
    return <DoInputCheckbox isChecked={isChecked} setIsChecked={setIsChecked} />;
}
//======================================================================================================================================

function InputListButton(props)
{
    const [ color, controlWidth, buttonStyle, nodeFontStyle] = useObjAtts(props.oid, ["nodeColor", "controlWidth", "_buttonStyle", "_nodefont"]);
    const fontStyle = nodeFontStyle === null ? props.fontStyle : nodeFontStyle;
    let style = { ...fontStyle };
    if (buttonStyle === undefined) return null; // 1616
    if (buttonStyle !== "nakedIcon") style.backgroundColor = color;
    if (buttonStyle === "flat") {
        if (props?.nodeInfoOrig?.border === 0)
            style.borderColor = color;  // flat buttons have no border when original has no border, just make border same color as node so the size doesn't change
        else
            style.borderColor = props?.nodeBorderColorOrig;
    }
    if (buttonStyle !== "nakedIcon" && buttonStyle !== "icon") style.width = controlWidth;
    const label = buttonStyle === "icon" || buttonStyle === "nakedIcon" ? (<div className="IconContainer"><img src="img/ListIcon.png" alt="List" /></div>) : "List";

    function onClick(ev) {
        ev.stopPropagation();
        ShowListPopup(props.oid);
    }

    return (<button className={"ListButton UserControl FormButton ButtonStyle_" + buttonStyle} style={style} onClick={onClick}>{label}</button>);
}

function InputDistributionFunctionButton(props) {

    const [fnName, color, controlWidth, buttonStyle, nodeFontStyle] = useObjAtts(props.oid, ["_topFnInDefn", "nodeColor", "controlWidth", "_buttonStyle", "_nodefont"]);

    const fontStyle = nodeFontStyle === null ? props.fontStyle : nodeFontStyle;
    let style = { ...fontStyle };
    if (buttonStyle === undefined) return null; // 1616
    if (buttonStyle !== "nakedIcon") style.backgroundColor = color;
    //if (props?.nodeInfoOrig?.border === 0 && buttonStyle === "flat") style.borderColor = color; 
    if (buttonStyle === "flat") {
        if (props?.nodeInfoOrig?.border === 0)
            style.borderColor = color;  // flat buttons have no border when original has no border, just make border same color as node so the size doesn't change
        else
            style.borderColor = props?.nodeBorderColorOrig;
    }


    if (buttonStyle !== "nakedIcon" && buttonStyle !== "icon") style.width = controlWidth;
    const label = buttonStyle === "icon" || buttonStyle === "nakedIcon" ? (<div className="IconContainer"><img src="img/Distribution.png" alt="Distribution" /></div>) : fnName;

    function onClick(ev) {
        ev.stopPropagation();
        ShowDistributionPopup(props.oid);
    }

    return (<button className={"FnButton UserControl FormButton ButtonStyle_" + buttonStyle} style={style} onClick={onClick}>{label}</button>);
}
function InputSpecialFunctionButton(props)
{
    const [fnName, color, controlWidth, nodeFontStyle, buttonStyle] = useObjAtts(props.oid, ["_topFnInDefn", "nodeColor", "controlWidth", "_nodefont", '_buttonStyle']);
    const fontStyle = nodeFontStyle === null ? props.fontStyle : nodeFontStyle;

    let font = nodeFontStyle === null ? props.fontStyle : nodeFontStyle;
    if (buttonStyle === undefined) return null;  // 1616
    let style = { ...font };
    if (buttonStyle !== "nakedIcon") style.backgroundColor = color;
    if (buttonStyle === "flat") {
        if (props?.nodeInfoOrig?.border === 0)
            style.borderColor = color;  // flat buttons have no border when original has no border, just make border same color as node so the size doesn't change
        else
            style.borderColor = props?.nodeBorderColorOrig;
    }

    function onClick(ev) 
    {
        ev.stopPropagation();
        if (props.fn==="sequence")
            ShowSequencePopup(props.oid);
    }
    if (buttonStyle !== "nakedIcon" && buttonStyle !== "icon") style.width = controlWidth;
    const label = buttonStyle === "icon" || buttonStyle === "nakedIcon" ? (<div className="IconContainer"><img src="img/SequenceIcon.ico" alt="Table" /></div>) : "Sequence";
    //return (<button className="FnButton UserControl FormButton" style={style} onClick={onClick}>{fnName}</button>);
    return (<button className={"editTableButton UserControl FormButton ButtonStyle_" + buttonStyle} style={style} onClick={onClick}>{label}</button>);
}

function ScalarSubTableControl(props)
{
    const contents = useQuery({ req: 'cell', cellInfo: 'VRE1', col: 0, row: 0 }, props.vid);
    if (contents === undefined)
        return "";//<EditTableButton {...props} pending={true} />;
    const options = contents.val;           // Only exists for choice case
    const entryType = contents.entry || "";

    function setCellDef(newText)
    {
        WebSocket_Send({ fn: 'Exec', vid: props.vid, cmd: 'setCellText', col: 0, row: 0, val: newText, entry:entryType, update:true });
    }

    function setChoicePos(pos)
    {
        console.log("setChoicePos()", { fn: 'Exec', vid: props.vid, cmd: 'choiceSelect', col: 0, row: 0, pos: pos, update: true })
        WebSocket_Send({ fn: 'Exec', vid: props.vid, cmd: 'choiceSelect', col: 0, row: 0, pos:pos, update:true });        
    }
    function setMultiChoiceSel(sel)
    {
        console.log("setMultiChoiceSel", sel)
        WebSocket_Send({ fn: 'Exec', vid: props.vid, cmd: 'multiChoiceSelect', col: 0, row: 0, pos: sel, update: true });        
    }
    function setCellCheck(bChecked)
    {
        WebSocket_Send({ fn: 'Exec', vid: props.vid, cmd: 'setCellCheck', col: 0, row: 0, val: bChecked, update:true });
    }

    switch (contents.type) {
        case "checkbox":
            return <DoInputCheckbox isChecked={contents.val} setIsChecked={setCellCheck} />;
        case "choice":
            return <DoInputChoice oid={props.oid} {...options} setCurSel={setChoicePos} options={options.opts} />;
        case "multichoice":
            return <DoInputChoice oid={props.oid} {...options} setCurSel={setMultiChoiceSel} options={options.opts} isMulti={true} />;
        case "text":
        default:
            return <InputNodeTextBox {...props} def={contents.val} entry={contents.entry} setDef={setCellDef} />;
    }
}

function SubTableControlWithVid(props)
{
    const isScalar = useQuery({ req: 'isScalar' }, props.vid);

    useEffect(() => {
        if (isScalar === true) props.setBAtomicSubTable(true);
        if (isScalar === false) props.setBAtomicSubTable(false);
    });

    if (!isScalar) {
        return <EditTableButton {...props} pending={isScalar===undefined} />;
    }
    
    return <ScalarSubTableControl {...props} vid={props.vid} />;
}

function SubTableControl(props)
{
    // It might be a scalar input, or it might be table that needs a button.
    const [color, controlWidth] = useObjAtts(props.oid, ['nodeColor', 'controlWidth']);
    const vid = useQuery({ req: 'editTable', oid: props.oid });

    if (!vid) return null;

    return <SubTableControlWithVid {...props} vid={vid} controlWidth={controlWidth} color={color} />;
}


export function InputNodeControl(props) 
{
    //console.log("InputNodeControl", props)
    const [defType/*, nodeHeight, font*/, bEmbedded, formnodeSize] = useObjAtts(props.oid, ["definitionType"/*,"nodeHeight","_nodeFont"*/,"_embedded", "NodeSize"]);
    const [orig,] = useObjAtt(props.oid, "original");
    const [nodeInfoOrig, nodeBorderColorOrig] = useObjAtts(orig, ["nodeInfo", "nodeBorderColor"]);
    const [showObjectView, setShowObjectView] = useState(false);

    var ctrl = "";

    function objectViewHoverIconClick(showThis) {
        console.log("objectViewHoverIconClick", showThis);
        setShowObjectView(false);
    }

    if (showObjectView) {
        const isTallNode = true;
        return (<ObjectView oid={props.oid} isTallNode={true} formnodeSize={formnodeSize} nodeClass0={props.nodeClass0} mouseEntered={props.mouseEntered} objectViewHoverIconClick={objectViewHoverIconClick} isEditTable={true}/>);
    }

    switch (defType) {
        case "Expr":        ctrl = (<InputNodeTextBox {...props} />); break;
        case "Undef":       ctrl = (<InputNodeTextBox {...props} bad={true} />); break;                                     // LDC 8/18/2020 Bug 175
        case "Table":
            // LDC 2/25/2021 Bug 366
            if (bEmbedded || (/* bEmbedded !== 0 && 1931 */ props.bTallNode)) {             // LDC 8/14/2020 ER 75
                const w = props.controlWidthNode && props.bShowLabel !== false ? props.controlWidthNode : undefined;
                return (<div className="controlHolder hasEditTable"><EmbeddedEditTable {...props} noTitle={true} frameNodeOid={props.oid} minWidth={w} setShowObjectView={setShowObjectView} /></div>);
            } else if (defType === "SubTable") {
                ctrl = (<SubTableControl {...props} nodeInfoOrig={nodeInfoOrig} nodeBorderColorOrig={nodeBorderColorOrig} />);
            } else {
                ctrl = (<EditTableButton {...props} nodeInfoOrig={nodeInfoOrig} nodeBorderColorOrig={nodeBorderColorOrig} />);
            }
            break;
        case "SubTable": 
            if (bEmbedded || ((bEmbedded !== 0 && props.bTallNode) && props.bAtomicSubTable === false)) {
                const w = props.controlWidthNode && props.bShowLabel !== false ? props.controlWidthNode : undefined;
                return (<div className="controlHolder hasEditTable"><EmbeddedEditTable {...props} noTitle={true} frameNodeOid={props.oid} minWidth={w} setShowObjectView={setShowObjectView} /></div>);
            } else { 
                ctrl = (<SubTableControl {...props} nodeInfoOrig={nodeInfoOrig} nodeBorderColorOrig={nodeBorderColorOrig} />);
            }
            break;
        case "Choice":      ctrl = (<InputChoice {...props} />); break;
        case "MultiChoice": ctrl = (<InputMultiChoice {...props} />); break;
        case "Checkbox":    ctrl = (<InputCheckbox {...props} />); break;
        case "List": ctrl = (<InputListButton {...props} listOfText={false} nodeInfoOrig={nodeInfoOrig} nodeBorderColorOrig={nodeBorderColorOrig} />); break;
        case "ListOfText": ctrl = (<InputListButton {...props} listOfText={true} nodeInfoOrig={nodeInfoOrig} nodeBorderColorOrig={nodeBorderColorOrig} />); break;
        case "Distribution": ctrl = (<InputDistributionFunctionButton {...props} nodeInfoOrig={nodeInfoOrig} nodeBorderColorOrig={nodeBorderColorOrig} />); break;
        case "Sequence": ctrl = (<InputSpecialFunctionButton {...props} fn="sequence" nodeInfoOrig={nodeInfoOrig} nodeBorderColorOrig={nodeBorderColorOrig} />); break;
        case "Hidden": ctrl = (<HiddenDefinitionButton {...props} nodeInfoOrig={nodeInfoOrig} nodeBorderColorOrig={nodeBorderColorOrig} />); break;
        case "Slider":      ctrl = (<InputSlider {...props} />); break;
        default:
            ctrl = "";
    }
    const sicn = (!!props.bShowSICN) && <div className="SICN" />;
    return (<div className="controlHolder">{ctrl}{sicn}</div>);
}

var gReqCalcCount = 0;        // This hack is a kick that seems like it shouldn't be necessary. Purpose is to force
                                     // this request to look different from the previous one.
export function IncReqCalc() {
    return ++gReqCalcCount; 
}

export function OutputNodeControl(props) 
{
    //console.log("Output node control", props);
    const [color, result, orig, buttonStyle, embeddedContent, nodeFont, formnodeSize] = useObjAtts(props.oid, ['nodeColor', '_curResultState', '_original', '_buttonStyle', '_embeddedContent', '_nodefont', 'nodesize']);
    const [toggleTableGraph, setToggleTableGraph] = useState(false);
    const [viewType, setViewType] = useObjAtt(props.oid, "_resultViewType");
    const [nodeInfoOrig, nodeBorderColorOrig] = useObjAtts(orig?.oid, ["nodeInfo", "NodeBorderColor"]);
    const [showObjectView, setShowObjectView] = useState(false);

    if (result === undefined) return (<div className="controlHolder" />);

    if (result?.type === "other" && result?.val?.mime?.includes("image")) { // EW S-1286
        result.type = "text";
        result.val = <>&#171;Picture(png)&#187;</>;
    }

    const font = nodeFont === null ? props.fontStyle : nodeFont;
    let style = { ...font };
    if (buttonStyle !== "nakedIcon") style.backgroundColor = color;
    if (buttonStyle !== "nakedIcon" && buttonStyle !== "icon") style.width = props.controlWidthNode;
    if (buttonStyle === "flat") {
        if (nodeInfoOrig?.border === 0)
            style.borderColor = color;  // make formnode button appear to have no border 
        else
            style.borderColor = nodeBorderColorOrig; 
    }
    let resultStyle = { width: props.controlWidthNode, maxHeight: props.nodeHeight };

    const sicn = props.bShowSICN ? (<SICN {...props} />) : null;

    function OpenResult(event) {
        console.log("OpenResult")
        event?.stopPropagation();
        event?.preventDefault();
        if (!orig) return;

        if (props.disabled) return;
        if (embeddedContent) {
            console.log("OpenResult embedded")
            props.setPendingCalcReq({ oid: orig.oid, fromCalc: true, reqCount: ++gReqCalcCount, frameNode: props.oid });
        } else {
            console.log("click Calc")
            //setViewType("MIDM");
            props.setPendingCalcReq({ oid: orig.oid, fromCalc: true, reqCount: ++gReqCalcCount });
            props.setCurObj(props.oid);
        }
    }

    function toggleTableGraphResult() {
        setToggleTableGraph(!toggleTableGraph);
    }

    var ctrl;
    const w = props.controlWidthNode && props.bShowLabel !== false ? props.controlWidthNode : undefined;

    function objectViewHoverIconClick(showThis) {
        console.log("objectViewHoverIconClick", showThis);
        const showGraph = toggleTableGraph ? !embeddedContent.graph : !!embeddedContent.graph;
        if (showThis === "ShowGraph" && showGraph === false) setToggleTableGraph(!toggleTableGraph);
        if (showThis === "ShowTable" && showGraph) setToggleTableGraph(!toggleTableGraph);
        setShowObjectView(false);
    }

    if (showObjectView) {
        return (<ObjectView oid={props.oid} isTallNode={true} formnodeSize={formnodeSize} nodeClass0={props.nodeClass0} mouseEntered={props.mouseEntered} objectViewHoverIconClick={objectViewHoverIconClick} />);
    }
    else if (result.computed && embeddedContent) {           // LDC 8/14/2020 Suan ER 75
        const show_index_menus = true;      // TO DO 
        const show_title = true;
        const show_description = getDescriptionHeightPercentage(null, props.nodeHeight / 2, props?.cloudStyles?.show_description, props?.cloudStylesOrig?.show_description);

        const style = props.controlWidthNode ? { minWidth: props.controlWidthNode } : {};

        const showGraph = toggleTableGraph ? !embeddedContent.graph : !!embeddedContent.graph;

        if (showGraph) {
            if (!embeddedContent.view) return null;
            return (
                <div className="controlHolder hasGraph" style={style}>
                    <GraphForViewType {...props} viewType={embeddedContent.view} oid={embeddedContent.obj.oid} showPivoters={show_index_menus} showTitle={show_title} showDescription={show_description} minWidth={w} toggleTableGraphResult={toggleTableGraphResult} setView={setViewType} setShowObjectView={setShowObjectView} />
                </div>
            );
        } else {
            return (
                <div className="controlHolder hasResultTable" style={style}>
                    <ResultTable {...props} oidOrig={orig?.oid} oid={embeddedContent.obj.oid} bEmbeddedOutput={true} showPivoters={show_index_menus} showTitle={props.bShowLabel} showDescription={show_description} minWidth={w} toggleTableGraphResult={toggleTableGraphResult} setView={setViewType}
                        showObjectView={showObjectView} setShowObjectView={setShowObjectView}/>
                </div>
            );
        }
    } else if (!result.computed) {
        if (buttonStyle === undefined) return null;  // 1616
        const label = buttonStyle === 'icon' || buttonStyle === 'nakedIcon' ? (<div className="IconContainer"><img src="img/VirtButtonCalc.ico" alt="Calc" /></div>) : "Calc";
        ctrl = (<button className={"CalcButton UserControl FormButton ButtonStyle_" + buttonStyle} style={style} onClick={OpenResult}>{label}</button>);
    } else if (result.type === "number"  || result.type === "date") {
        ctrl = (<div className="OutputResult" style={resultStyle}><span className="OutputResultText" style={props.nodeFontStyle}>{result.txtval}</span></div>);
    } else if (result.type === "text" || result.type === "other") {
        ctrl = (<div className="OutputResult" style={resultStyle}><span className="OutputResultText" style={props.nodeFontStyle}>{result.val}</span></div>);
    } else if (result.type==="binaryData") {
        ctrl = (<div className="OutputResult" style={resultStyle}><span className="OutputResultText" style={props.nodeFontStyle}>&#xab;BinaryData {result.nbytes} byte{result.nbytes!==1?"s":""}&#xbb;</span></div>);
    } else {
        if (buttonStyle === undefined) return null;  // 1616
        const label = buttonStyle === 'icon' || buttonStyle === 'nakedIcon' ? (<div className="IconContainer"><img src="img/Result.ico" alt="Result" /></div>) : "Result";
        ctrl = (<button className={"ResultButton UserControl FormButton ButtonStyle_" + buttonStyle} style={style} onClick={OpenResult}>{label}</button>);
    }

    if (props.bBusy && (props.proactiveEvalModule & 4 || props.proactiveEvalModule & 8) && props.bTallNode === 1) return "";

    return (<div className="controlHolder">{ctrl}{sicn}</div>);
}

export function SICN(props)
{
    const [view,bShowSICN] = useObjAtts(props.oid, ["_resultViewType","_showSICN"]);

    if (bShowSICN && view)
        return (<img className="SICN" src={"img/SICN_" + view + ".png"} alt="" />);        
    else
        return "";
}
