// TypescriptConsole.js
//
// Lonnie Chrisman, 4/4/2019
//

import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { WebSocket_Send } from './webSocketClient.js';
import { AddServerMessageReceiver } from './serverState.js';

import './styles/TypescriptConsole.scss';

var gSetSvrResp = null;

function Multiline(props)
{
    const lines = props.txt.split('\n');
    if (lines.length <= 1) 
        return lines;
    return (
        <>{lines.map((line,i) => (<div key={i}>{line}</div>)) }</>
    );
}

export function TypescriptConsole(props)
{
    const [history, setHistory] = useState([]);     // Each item is itself a 2-item list: [bInput, text]. 
    const [command, setCommand] = useState("");
    const [svrResp, setSvrResp] = useState(null);
    const prompt = ">> ";
    let scrollRef = useRef(null);
    let inputRef = useRef(null);

    // temporary simulation of a server response
    if (svrResp!==null) {
        appendToHistory(false, svrResp);
        setSvrResp(null);
    }

    // Any time the history updates, scroll to the bottom
    useEffect(() => {
        let el = scrollRef.current;
        el.scrollTo(0, el.scrollHeight);
    }, [history]);

    useEffect(() => {
        gSetSvrResp = setSvrResp;
        return ()=>{ gSetSvrResp = null; }
    }, [setSvrResp]);

    function appendToHistory( bInput, text )
    {
        setHistory([].concat(history, [[bInput, text]]));
    }
    // To do -- set up listener to receive server responses

    function keyPress(ev)
    {
        if (ev.key === "Enter" && !ev.altKey && !ev.ctrlKey) {
            if (command !== "") {                
                WebSocket_Send({ fn: 'Exec', cmd: command });
            }
            appendToHistory(true, command);
            setCommand("");
        }
    }

    function putFocusOnInput(ev)
    {
        inputRef.current.focus();
    }

    let buttonLabel = props.status === "closed" ? "Reconnect" : "Connect";
    let buttonStyle = { display: props.status === "connected" ? "none" : "inline-block" };
    let inputLineStyle = { display: props.status === "connected" ? "block" : "none" };

    const addlBtnText = props.status === "connected" ? "" : (" to " + props.server + " group=" + props.group);

    return (
        <div id="RequestConnect" className="TypescriptConsole" ref={scrollRef} onClick={putFocusOnInput} >
            <div className="Input-Output">
                {history.map(([bInput, txt],i) => (<div key={i}>{bInput?prompt:""}<Multiline txt={txt}/></div>))}
            </div>
            <div className="ConsoleInputLine" style={inputLineStyle}>
                <span className="prompt">{prompt}</span>
                <input id="ConsoleInput" value={command} onChange={(ev) => setCommand(ev.target.value)} onKeyDown={keyPress} ref={inputRef} />
            </div>
            <span><button style={buttonStyle} onClick={() => props.setStatus("request connect")}>{buttonLabel}</button>{addlBtnText}</span>
        </div>
    );
}

function onConsoleOutFromServer(json)
{
    if (gSetSvrResp) 
        gSetSvrResp(json.txt);
}

AddServerMessageReceiver('ConsoleOut', onConsoleOutFromServer);
