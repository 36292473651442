import React from 'react';
import { useEffect } from 'react';
import { AddServerMessageReceiver } from './serverState.js';
import { BreakComputation } from './serverState.js';
import { TitleBarBase } from './titleBar.js';

import './styles/SpinningCube.scss';

var gSetBusyState = null;
var gSetProgressBarState = null;

export function SpinningCube(props)
{
    const style = { display: props.bBusy? "block" : "none" };
    const size = 75;                           // This needs to be set both here and in the style sheet (SVG can't obtain its coordinates from the CSS)

    const r = 3 * size / 10;
    const m = size / 2;

    useEffect(() => {
        gSetBusyState = props.setBusy;
        return () => { gSetBusyState = null; };
    },[props.setBusy]);

    return (
        <div className="SpinningCube" style={style} >
            <div className="SpinningCubeSides">
                <svg className="SpinningCubeFront SpinningCubeSide">
                    <line x1={m+r} y1={m-r} x2={m+r} y2={m+r}/>
                    <circle cx={m} cy={m} r={r}/>
               
                </svg>
                <div className="SpinningCubeTop SpinningCubeSide"/>
                <div className="SpinningCubeRight SpinningCubeSide"/>
                <svg className="SpinningCubeBack SpinningCubeSide">
                    <line x1={m-r} y1={m-r} x2={m-r} y2={m+r} />
                    <circle cx={m} cy={m} r={r} />
                </svg>
                <div className="SpinningCubeBottom SpinningCubeSide" />
                <div className="SpinningCubeLeft SpinningCubeSide" />
            </div>
        </div>
    );
}

function ProgressBar({ p })
{
    const progressW = { width: (100 * p) + "%" };

    return (
        <div className="ProgressBar" >
            <div className="Progress" style={progressW} />
            <div className="ProgressPct">{Math.round(p * 100) + "%"}</div>
        </div>
    );
}

export function SpinningCubeWithBreakAndProgress(props)
{
    //console.log("SpinningCubeWithBreakAndProgress", props.showCube, props.bForceShowCube)
    const bBusy = props.bBusy;
    const style0 = { display: bBusy || props.bForceShowCube ? "block" : "none" };
    const style1 = { display: bBusy ? "block" : "none" };
    const buttonText = props.bForceShowCube ? "cancel" : "Cancel";

    const bHasProg = props.progress !== null && props.progress !== undefined;

    //console.log("document?.acpServerConfig?.EW_1835_spinning_cube_block_clicks", document?.acpServerConfig?.EW_1835_spinning_cube_block_clicks)

    const cls = "SpinningCubeWithBreak";
    const cls2 = bHasProg ? " HasProgressBar" : " HasProgressBar";
    const cls3 = document?.acpServerConfig?.EW_1835_spinning_cube_block_clicks === false || props.blockScreenClicks === false ? "" : " BlockClicks";

    function mouseHandler(e) {
        console.log("cube mouse handler", e);
        e.stopPropagation();
        e.preventDefault();
    }

    function breakComp() {
        console.log("break comp()", props)
        BreakComputation();
        if (props.setShowCube)
            props.setShowCube(false);
    }

    return (
        <div className={cls + cls2 + cls3} style={style0} onClick={e => mouseHandler(e)} onMouseDown={e => mouseHandler(e)} onMouseUp={e => mouseHandler(e)}>
            <SpinningCube {...props} bBusy={bBusy || props.bForceShowCube} />
            {bHasProg && <ProgressBar p={props.progress} />}
            <button className="BreakButton" style={style1} onClick={breakComp}>{buttonText}</button>
        </div>
    );
}

function OnServerBusy(json)
{
    if (gSetBusyState)
        gSetBusyState(json.state);
}

AddServerMessageReceiver('Busy', OnServerBusy);

//-----------------------------------------------------------------------------
// LDC 2/17/2021 ER 413


export function ProgressBarDialog({ state, setState })
{
    useEffect(() => {
        gSetProgressBarState = setState;
        return () => { gSetProgressBarState = null; };
    }, [setState]);

    if (!state) return null;

    let { caption, text, p } = state; // { caption: "caption", text: "text", p: .5 }; //state;

    // next few blocks are ERs from max for the progress bar 
    // when opening a model e.g. 1618 combine Reading File and Checking Definitions progress bars into one progress bar
    if (caption?.includes("Reading from file") && text?.startsWith("File:")) {
        text = text.substr(5, 1000000);
        p = p / 2
        if (p === 0) p = .5; // fixes oddity where Reading from file's p value goes back to zero after opening the model
        caption = "Loading...";
        text = "";
    }
    else if (caption?.includes("Checking Definitions") && text?.startsWith("Checking:")) {
        text = text.replace("Checking:", "Variable: ");
        p = .5 + (p / 2);
        caption = "Loading...";
        text = "";
    } else if (caption.includes("Checking Domains")) {
        caption = "Loading...";
        text = "";
    }

    return (
        <div className="ProgressBarDialog">
            <TitleBarBase caption={caption} />
            <div className="ProgressText">{text}</div>
            <ProgressBar p={p}/>
            <button className="BreakButton" onClick={BreakComputation}>Cancel</button>

        </div>
    );
}

function OnShowProgressBar(json)
{
    if (gSetProgressBarState)
        gSetProgressBarState(json);
}

function OnHideProgressBar(json)
{
    if (gSetProgressBarState)
        gSetProgressBarState(null);
}

AddServerMessageReceiver('ShowProgressBar', OnShowProgressBar);
AddServerMessageReceiver('HideProgressBar', OnHideProgressBar);
