// acpPortal.js

import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useEval, useObjAtt, useQuery } from './serverState.js';
import { InputChoice } from './choice.js';
import { WebSocket_Send } from './webSocketClient.js';
import { ErrorMessageWindow } from './errorMessages.js';
import firebase from 'firebase/app';            // LDC 3/23/2021 Suan bug 673. Syntactic change req after upgrade to Firebase version 8.0.0
import 'firebase/auth';
import './styles/AcpPortal.scss';
import { ToolbarTip } from './toolTip';
//import { WebSocket_Close } from './webSocketClient.js';

function OidFromName(name)
{
    const obj = useEval("HandleFromIdentifier('" + name + "')");
    return obj ? obj.oid : undefined;
}

function AcpPortalTabBar(props)
{
    //console.log("AcpPortalTabBar", props);
    const userEmail = useEval("Mid(User_Identity)");
    const whatsNewRef = useRef();
    //const bViewACL = props.permissions && props.permissions['View ACL'];
    const clsM = props.selTab === "Models" ? "Tab Selected" : props.isAdminProj ? "Tab Unselected Hidden" : "Tab Unselected";
    const clsU = props.selTab === "Users" ? "Tab Selected" : "Tab Unselected";
    const clsA = props.selTab === "Accounts" ? "Tab Selected" : "Tab Unselected";
    const clsMB = props.selTab === "Models" ? "SelTabBase" : "UnselTabBase";
    const clsMU = props.selTab === "Users" ? "SelTabBase" : "UnselTabBase";
    const clsMA = props.selTab === "Accounts" ? "SelTabBase" : "UnselTabBase";
    // let all group members see Users portal, but controls disabled
    // for users without 'Manager user' permission (typically reviewers and authors)
    const bUsers = props.isGroup; // && bViewACL;
    const userVis = props.isGroup /* && bViewACL */ ? null : { display: "none" };
    const [whatsNewHeight, setWhatsNewHeight] = useState(null);

    const cls = "TabBar " + (bUsers ? "WithUserTab" : "WithoutUserTab");

    useEffect(() => {
        //console.log("use effect", whatsNewRef?.current?.clientHeight);
        if (whatsNewHeight !== whatsNewRef?.current?.clientHeight)
            setWhatsNewHeight(whatsNewRef?.current?.clientHeight)
    })

    function onClickUsersTab() {
        // the initially view should always be 'Only show members'
        WebSocket_Send({ fn: 'Exec', cmd: 'definition Only_show_members: checkbox(1)' });

        props.setSelTab("Users")
    }

    const whatsNewTextFront = document?.acpServerConfig?.EW_1782_whats_new_text_before_link;
    const whatsNewLinkText = document?.acpServerConfig?.EW_1782_whats_new_text_link_text;
    let whatsNewLink = document?.acpServerConfig?.EW_1782_whats_new_link;

    if (!userEmail)
        return "";

    if (whatsNewLink.includes("--email--"))
        whatsNewLink = whatsNewLink.replace("--email--", userEmail);

    let whatsNewCls = whatsNewRef?.current?.clientHeight < 30 ? "WhatsNew" : "WhatsNew WhatsNewTall";

    //console.log("whatsNewRef?.current?.clientHeight", whatsNewRef?.current?.clientHeight);

    const opacity = whatsNewHeight ? 1 : .01;  // fixes shift in text when loading


    return (
        <div className={cls} id="AcpPortalTabBar" style={{ opacity: opacity }}>
                <div className={clsM} id="ModelTab" onClick={ev=>props.setSelTab("Models")} >Models</div>
                <div className={clsMB} id="ModelTabBase"/>
                {(!!bUsers) && <div className={clsU} id="UserTab" onClick={onClickUsersTab} style={userVis} >Users</div> }
                { (!!bUsers) && <div className={clsMU} id="UserTabBase" style={userVis} /> }
                <div className={clsA} id="AccountTab" onClick={ev => props.setSelTab("Accounts")} >Account</div>
                <div className={clsMA} id="AccountTabBase" />
            <div className="UnselTabBase" id="RemainderTabBase" />
            <div className={whatsNewCls} ref={whatsNewRef}>{whatsNewTextFront} <a className="WhatsNewLink" href={whatsNewLink} target="_blank">{whatsNewLinkText}</a></div>
            </div>
    );
}

function AcpPortalAccount(props)
{
    const [options,] = useObjAtt(props.oid, '_choiceOptions');

    return (
        <span id="Account">
            {options && options.opts && options.opts.length > 1 && <InputChoice oid={props.oid} />}
            {options && options.opts && options.opts.length === 1 && <span id="AccountNameStatic"> {options.opts[0].text}</span>}
        </span>
    );
}

function AcpPortalAcctStripe(props)
{

    return (
        <div id="AccountStripe">
            <span id="AccountLabel">Account:</span>
            <AcpPortalAccount oid={props.oid}/>
        </div>
    );
}

function AcpPortalProjectChoice(props)
{
    //const [selProj] = useObjAtt(props.projOid, "definition");
    return (<span id="Project"><InputChoice oid={props.projOid} bIgnoreControlWidth={true}  /></span>);      // LDC 8/7/2020 Suan Bug 115
}

function AcpPortalProjectStripe(props)
{
    //console.log("AcpPortalProjectStripe", props)
    const projOid = OidFromName("Select_project");
    const selelectedProjectName = useEval("Project_name");
    const selectedAccountName = useEval("Group_identity");
    const isSubscriptionAdminProject = useEval("is_for_subscription");          // LDC 8/19/2021 S-530. You can't delete the subscription admin project. Its purpose is to track subscription-level permissions.
    const roleId = useEval("Role_Id");
    
    // super use can create projects amonng other things
    // LDC 8/19/2021 S-530: Changed to a subscription level scheme
    //const isSuperUser = useEval("Is_SuperUser");
    const canCreateProject = props.permissions && props.permissions["Create projects"];
    const canDeleteProject = isSubscriptionAdminProject===0 && props.permissions && props.permissions["Delete projects"];

    function DeleteProject() {
        WebSocket_Send({ fn: 'Exec', cmd: "Delete_selected_proj()" });
    }

    useEffect(() => {
        if (selelectedProjectName !== undefined && props.setProjectForToolbar !== undefined) {
            props.setProjectForToolbar(selelectedProjectName);
        }
        if (selectedAccountName !== undefined && props.setAccountNameForToolbar !== undefined) {
            props.setAccountNameForToolbar(selectedAccountName);
        }
        if (roleId !== undefined && props.setUserRole !== undefined && props.userRole !== roleId) {
            props.setUserRole(roleId);
        }
    });

    if (!projOid) return "";

    return (
        <div id="ProjectStripe">
            <span id="ProjectLabel">Project:</span>
            <AcpPortalProjectChoice projOid={projOid} selelectedProjectName={selelectedProjectName}/>
            {((!!canCreateProject) && props.showProjectButtons) && <button id="CreateProjectButton" onClick={props.createProject}>+</button>}
            {((!!canDeleteProject) && props.showProjectButtons && false /*1626 Max: Get delete projects working or remove button*/) && <button id="DeleteProjectButton" onClick={DeleteProject}>-</button>}
        </div>
    );
}

function AcpPortalFileRow(props)
{
    //console.log("AcpPortalFileRow", props.acpPlanType)
    const acpPlanType = props.acpPlanType;
    const numberOfModelRunsThisMonth = props.numberOfModelRunsThisMonth;
    const purchasedCreditsLeft = props.purchasedCreditsLeft;
    const f = props.file;
    const userIdentity = useEval("User_identity");
    const account = useEval("Group_identity");
    const countSessionCredits = useEval("Count_session_credit");
    const maxModelRunsGroup = useEval("ModelRunsGroup");
    const maxModelRunsGroupPremium = useEval("ModelRunsPremiumGrp");
    const maxModelRunsIndividual = useEval("ModelRunsIndividual");
    const acctType = props.acpPlanType;
    const isGroup = acctType && acctType !== "Individual";

    let buyUrl = "https://analytica.com/purchase-analytica/?email=" + encodeURIComponent(userIdentity)
        + "&accountType=" + encodeURIComponent(acctType);

    if (isGroup && account) {
        buyUrl += "&account=" + encodeURIComponent(account);
    }

    if (!f) return "";

    const cls = props.canRun ? "CellFileName Runnable" : "CellFileName";
    const trCls = f.fileName.toLowerCase().indexOf(props.filter) === -1 ? "FilterNoMatch" : "FilterMatch";

    function LaunchModel(ev)
    {
        console.log("LaunchModel()", acpPlanType, purchasedCreditsLeft, f);

        if (!f.fileName.toLowerCase().endsWith(".ana")) { 
            console.log("not a model file, don't launch");
            return;
        }
        
        // Out of Credits message
        if (account !== 'Lumina' && countSessionCredits === 1) {

            if (purchasedCreditsLeft <= 0) {
                console.log("Purchased credits zero (or less than zero)")
                if (acpPlanType.toLowerCase() === "premium group" && numberOfModelRunsThisMonth >= maxModelRunsGroupPremium) {
                    props.setMsgBoxInfoClient({ body: 'Sorry, your ACP Premium Group Plan has used all the ' + maxModelRunsGroupPremium.toString() + ' model runs for this month. You can buy more model runs at', buttons: 0, caption: "Out of sessions", responseHandler: null, urlBuySessions: buyUrl });
                    return;
                } else if (acpPlanType.toLowerCase() === "group" && numberOfModelRunsThisMonth >= maxModelRunsGroup) {
                    props.setMsgBoxInfoClient({
                        body: 'Sorry, your ACP Basic Group Plan has used all the ' + maxModelRunsGroup.toString() + ' model runs for this month. You can buy more model runs or upgrade to a Premium Group Plan at', buttons: 0, caption: "Out of sessions", responseHandler: null, urlBuySessions: buyUrl
                    });
                    return;
                } else if (acpPlanType.toLowerCase() === "individual" && numberOfModelRunsThisMonth >= maxModelRunsIndividual && !userIdentity.includes("@lumina.com")) {
                    props.setMsgBoxInfoClient({
                        body: 'Sorry, you have used up all ' + maxModelRunsIndividual.toString() + ' model runs from your ACP Individual Account for this month. You can buy more model runs or upgrade to a Group Plan with many more sessions at', buttons: 0, caption: "Out of sessions", responseHandler: null, urlBuySessions: buyUrl
                    });
                    return;
                }
            }
            else
                console.log("Purchased credits left is greater than 0");

            function warningMsgBoxHandler() {
                console.log("warningMsgBoxHandler", f)
                const cmd = 'Launch_Model(' + f.idx + ')';
                WebSocket_Send({ fn: 'Exec', cmd: cmd });
            }

            if (purchasedCreditsLeft <= 0) {
                if (acpPlanType.toLowerCase() === "premium group") {
                    const percentOfCreditsUsed = numberOfModelRunsThisMonth / maxModelRunsGroupPremium;
                    if (percentOfCreditsUsed === 0.8 || percentOfCreditsUsed === 0.9 || percentOfCreditsUsed >= 0.95) {
                        props.setMsgBoxInfoClient({
                            body: 'You have used ' + numberOfModelRunsThisMonth + ' of the total ' + maxModelRunsGroupPremium + ' model runs for this ACP Premium Group Plan for this month.You can buy more model runs at', buttons: 0, caption: "Warning", responseHandler: warningMsgBoxHandler, urlBuySessions: buyUrl
                        });
                        return;
                    }
                } else if (acpPlanType.toLowerCase() === "group") {
                    const percentOfCreditsUsed = numberOfModelRunsThisMonth / maxModelRunsGroup;
                    if (percentOfCreditsUsed === 0.8 || percentOfCreditsUsed === 0.9 || percentOfCreditsUsed >= 0.95) {
                        props.setMsgBoxInfoClient({
                            body: 'You have used ' + numberOfModelRunsThisMonth + ' of the total ' + maxModelRunsGroup + ' model runs for this ACP Basic Group Plan for this month. You can buy more model runs or upgrade to a Premium Group Plan at', buttons: 0, caption: "Warning", responseHandler: warningMsgBoxHandler, urlBuySessions: buyUrl
                        });
                        return;
                    }
                } else if (acpPlanType.toLowerCase() === "individual" && !userIdentity.includes("@lumina.com")) {
                    const percentOfCreditsUsed = numberOfModelRunsThisMonth / maxModelRunsIndividual;
                    if (percentOfCreditsUsed >= 0.8) {
                        props.setMsgBoxInfoClient({
                            body: 'You have used ' + numberOfModelRunsThisMonth + ' of the total ' + maxModelRunsIndividual + ' model runs from your ACP Individual Account for this month. You can buy more model runs or upgrade to a Group Plan with many more sessions at', buttons: 0, caption: "Warning", responseHandler: warningMsgBoxHandler, urlBuySessions: buyUrl
                        });
                        return;
                    }
                }
            }
        }

        const cmd = 'Launch_Model(' + f.idx + ')';
        WebSocket_Send({ fn: 'Exec', cmd:cmd});
        if (!props.canRun) return;
    }

    return (
        <tr key={f.idx} className={trCls} >
            <td className="CellSelect"><input type="radio" onChange={ev => props.setSel(f.idx)} checked={props.sel === f.idx} /></td>
            <td className={cls} onClick={LaunchModel}>{f.fileName}</td>
            <td className="CellDate">{f.modified}</td>
            <td className="CellSize">{Math.round(f.fileSize/1000).toLocaleString()} KB</td>
        </tr>
    );
}

function AcpFilter(props)
{
    function onClick(evt) {
        //alert("filter " + evt)
        evt.stopPropagation();
    }

    return (
        <span className="TextFilter" onClick={onClick}>
            <input id="FilterText" type="text" value={props.filter} onChange={e=>props.setFilter(e.currentTarget.value)} /><i className="fas fa-search"></i>
        </span>
    );
}

function AcpPortalFileListing(props) {
    //console.log("AcpPortalFileListing", props);
    const [sel0, setSel] = useObjAtt(props.selFileNum, "Definition");
    const [filter, setFilter] = useState("");
    const canRun = props.permissions ? props.permissions["Run models"] : false;
    const [selectedColumn, setSelectedColumn] = useState("date");
    const [ascending, setAscending] = useState(false);
    const [nameHover, setNameHover] = useState(false);
    const [dateHover, setDateHover] = useState(false);
    const [sizeHover, setSizeHover] = useState(false);
    const numberOfModelRunsThisMonth = useEval('ModelRunsThisMonth');
    const purchasedCreditsLeft = useEval('PurchasedCreditsLeft');
    const acpPlanType = useEval("Selected_plan_type");

    const sel = typeof (sel0) === "string" ? parseInt(sel0) : sel0;

    if (sel > props.files.length && props.files.length > 0) {
        setSel(1);
    }

    const filterLower = filter.toLowerCase();

    let noFiles = false;
    if (props.files.length === 0) {
        noFiles = true;
    }

    function insertFileRow(f) {
        // don't show Analytica recovery files
        let bSkipFile = false;
        if (f.fileName.indexOf(".ana~") !== -1)
            bSkipFile = true;

        return (
            bSkipFile ? "" : <AcpPortalFileRow key={f.idx} sel={sel} setSel={setSel} file={f} canRun={canRun && f.launchable} filter={filterLower} numberOfModelRunsThisMonth={numberOfModelRunsThisMonth} purchasedCreditsLeft={purchasedCreditsLeft} acpPlanType={acpPlanType} msgBoxInfoClient={props.msgBoxInfoClient} setMsgBoxInfoClient={props.setMsgBoxInfoClient} />
        );
    }

    function fileNameClick() {
        if (selectedColumn !== "name") {
            WebSocket_Send({ fn: 'Exec', cmd: 'SortNameAscend' });
            setSelectedColumn("name");
            setAscending(true);
        } else {
            if (ascending)
                WebSocket_Send({ fn: 'Exec', cmd: 'SortNameDescend' });
            else
                WebSocket_Send({ fn: 'Exec', cmd: 'SortNameAscend' });
            setAscending(!ascending);
        }
    }

    function fileDateClick() {
        if (selectedColumn !== "date") {
            WebSocket_Send({ fn: 'Exec', cmd: 'SortDateDescend' });
            setSelectedColumn("date");
            setAscending(false);
        } else {
            if (ascending)
                WebSocket_Send({ fn: 'Exec', cmd: 'SortDateDescend' });
            else
                WebSocket_Send({ fn: 'Exec', cmd: 'SortDateAscend' });
            setAscending(!ascending);
        }
    }

    function fileSizeClick() {
        if (selectedColumn !== "size") {
            WebSocket_Send({ fn: 'Exec', cmd: 'SortSizeDescend' });
            setSelectedColumn("size");
            setAscending(false);
        } else {
            if (ascending)
                WebSocket_Send({ fn: 'Exec', cmd: 'SortSizeDescend' });
            else
                WebSocket_Send({ fn: 'Exec', cmd: 'SortSizeAscend' });
            setAscending(!ascending);
        }
    }

    //
    // mouse over and mouse leave handlers for showing arrowhead on hover
    //
    function onNameHover() { setNameHover(true); }
    function onNameMouseLeave() { setNameHover(false); }
    function onDateHover() { setDateHover(true); }
    function onDateLeave() { setDateHover(false); }
    function onSizeHover() { setSizeHover(true); }
    function onSizeLeave() { setSizeHover(false); }

    const descendingSvg = <svg className="descendingSvgIcon" style={{ height: 15, width: 20 }}>
        <rect x="0" y="0" width="18" height="2" rx="2" ry="2" style={{ fill: 'white', stroke: 'white' }} />
        <rect x="5" y="4" width="13" height="2" rx="2" ry="2" style={{ fill: 'white', stroke: 'white' }} />
        <rect x="10" y="8" width="8" height="2" rx="2" ry="2" style={{ fill: 'white', stroke: 'white' }} />
    </svg>;

    const ascendingSvg = <svg className="ascendingSvgIcon" style={{ height: 15, width: 20 }}>
        <rect x="10" y="0" width="8" height="2" rx="2" ry="2" style={{ fill: 'white', stroke: 'white' }} />
        <rect x="5" y="4" width="13" height="2" rx="2" ry="2" style={{ fill: 'white', stroke: 'white' }} />
        <rect x="0" y="8" width="18" height="2" rx="2" ry="2" style={{ fill: 'white', stroke: 'white' }} />
    </svg>;

    const hoverSvg = <svg className="hoverSvgIcon" style={{ height: 15, width: 20 }}>
        <rect x="5" y="0" width="8" height="2" rx="2" ry="2" style={{ fill: 'white', stroke: 'white' }} />
        <rect x="0" y="4" width="18" height="2" rx="2" ry="2" style={{ fill: 'white', stroke: 'white' }} />
        <rect x="5" y="8" width="8" height="2" rx="2" ry="2" style={{ fill: 'white', stroke: 'white' }} />
    </svg>;    

    let fileNameSortArrow = <svg className="noSvgIcon" style={{ height: 15, width: 20 }}></svg>;

    if (selectedColumn === "name" && ascending === true)
        fileNameSortArrow = ascendingSvg; // <img style={{ width: "20px" }} src="/img/ascendingArrowHead.png" alt="arrowHead" />;
    if (selectedColumn === "name" && ascending === false)
        fileNameSortArrow = descendingSvg; // <img style={{ width: "20px" }} src="/img/descendingArrowHead.png" alt="arrowHead" />;
    if (selectedColumn !== "name" && nameHover)
        fileNameSortArrow = hoverSvg; // <img style={{ width: "20px" }} src="/img/ascendingArrowHead.png" alt="arrowHead" />;

    let fileDateSortArrow;
    if (selectedColumn === "date" && ascending === true)
        fileDateSortArrow = ascendingSvg; // <img style={{ width: "20px" }} src="/img/ascendingArrowHead.png" alt="arrowHead" />;
    if (selectedColumn === "date" && ascending === false)
        fileDateSortArrow = descendingSvg; // <img style={{ width: "20px" }} src="/img/descendingArrowHead.png" alt="arrowHead" />;
    if (selectedColumn !== "date" && dateHover)
        fileDateSortArrow = hoverSvg; // <img style={{ width: "20px" }} src="/img/descendingArrowHead.png" alt="arrowHead" />;

    let fileSizeSortArrow = "";
    if (selectedColumn === "size" && ascending === true)
        fileSizeSortArrow = ascendingSvg; // <img style={{ width: "20px" }} src="/img/ascendingArrowHead.png" alt="arrowHead" />;
    if (selectedColumn === "size" && ascending === false)
        fileSizeSortArrow = descendingSvg; // <img style={{ width: "20px" }} src="/img/descendingArrowHead.png" alt="arrowHead" />;
    if (selectedColumn !== "size" && sizeHover)
        fileSizeSortArrow = hoverSvg; // <img style={{ width: "20px" }} src="/img/descendingArrowHead.png" alt="arrowHead" />;

    if (acpPlanType === undefined) return null; // FWB EW 1673
    if (numberOfModelRunsThisMonth === undefined || purchasedCreditsLeft === undefined) return null;

    //console.log("models view ref", props?.modelsViewHeight)

    const tableBodyHeightAdjustment = props.fileUploading ? 210 : 92; 

    return (
        <table className="ModelListing">
            <thead>
                <tr>
                    <th className="CellSelect" onClick={fileNameClick}>Select</th>
                    <th className="CellFileName" onClick={fileNameClick} onMouseOver={onNameHover} onMouseLeave={onNameMouseLeave}><span>Model&nbsp;{fileNameSortArrow}</span><AcpFilter filter={filter} setFilter={setFilter}/></th>
                    <th className="CellDate" onClick={fileDateClick} onMouseOver={onDateHover} onMouseLeave={onDateLeave}>Save date&nbsp;{fileDateSortArrow}</th>
                    <th className="CellSize Header" onClick={fileSizeClick} onMouseOver={onSizeHover} onMouseLeave={onSizeLeave}>File size&nbsp;{fileSizeSortArrow}</th></tr>
                </thead>
            <tbody style={{ maxHeight: props.modelsViewHeight - tableBodyHeightAdjustment }}>
                {props.files.map((f) =>insertFileRow(f))}
                {noFiles && <tr><td>No files present.  upload...</td></tr>}
            </tbody>
        </table>
    );
}

function UploadProgressOneFile(props)
{
    console.log("UploadProgressOneFile()", props)
    const file = props.file;
    const vid = useQuery({ req: "uploadFile", filename: file.name, size: file.size });

    if (!vid) return null;

    return <UploadProgressOneFileVid vid={vid} {...props} />;
}

function UploadProgressOneFileVid(props)
{
    console.log("UploadProgressOneFileVid", props)
    const vid = props.vid;
    const file = props.file;
    const progress = useQuery({ req: 'progress'}, vid );
    const [done, setDone] = useState(false);
    const [bScheduled, setScheduled] = useState(false);         // Have we scheduled the upload with setTimeout yet. Flag to prevent scheduling it twice. Suan Bug 390
    const [reader, setReader] = useState(null);
    const [enc64Data, setEnc64Data] = useState(null);
    const [curPos, setCurPos] = useState(0);                    // The position in enc64Data of the next chunk to be uploaded.

    useEffect(() => {
        if (reader && !done) {
            reader.readAsDataURL(file);
            return ()=>{
                if (!done) {
                    setDone(true);
                    console.log("setDone(true)");
                    // reader abort....
                }
            }
        }
    },[reader,done,setDone,file]);

    if (!file || !vid) return null;
    if (!reader) {
        const r = new FileReader();
        r.onload = function (evt) {
            const result = evt.target.result;
            // First cut, sends all in one chunk. In a future incarnation, for very large files, we might break this into
            // blocks, sending in a sequence of messages, and give some sort of progress feedback.

            const startPos = result.indexOf("base64,") + 7;
            WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'startChunked', length: result.length - startPos });
            setCurPos(startPos);
            setEnc64Data(result);

            //WebSocket_Send({ fn: 'Exec', vid:vid, cmd: 'base64encAll', data: result });
            //setDone(true);
            //props.reportDone();
        };
        setReader(r);
        return null;
    }

    if (enc64Data && !done && !bScheduled) {
        const chunkSize = 4096;           // Important: This must be a multiple of 4.
        setScheduled(true);
        setTimeout(() => {               // Do this on a thread
            //console.log("Starting upload of ", file.name, ", size=", file.size, ", vid=", vid);
            //let nSent = 0;              // This is only to assist in debugging
            for (let pos = curPos; pos < enc64Data.length; pos += chunkSize) {
                const nBytesToSend = Math.min(enc64Data.length - curPos, chunkSize);
                WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'oneChunk', chunk: enc64Data.substring(pos, pos + nBytesToSend) });
                //++nSent;
            }
            WebSocket_Send({ fn: 'Exec', vid: vid, cmd: 'chunkFinalize' });
            //console.log("Finalized upload of ", file.name, ", vid = ", vid, ", nChunks sent=", nSent);
            setDone(true);
        },0);
    }

    if (progress>=1.0) {
        props.reportDone();
        props.setFileUploading(false);
    }

    const progressW = { width: (100 * progress) + "%" };

    //console.log("progress ", progress);

    return (
        <li key={file.name} className="UploadFileProgress">
            <span className="UploadFileName">{file.name}</span>
            <span className="UploadFileSize">{file.size.toLocaleString()} bytes</span>
            {!!progress && <div><span className="ProgressBar"><div className="Progress" style={progressW} /></span>
            <span className="ProgressPercent">{Math.floor(100 * progress)}%</span>
                <span className="ProgressCancelButton" ><button style={{ marginLeft: "20px" }} onClick={ev => props.setFiles(null)}>Cancel</button></span></div>}
        </li>
    );
}

function UploadProgress(props)
{
    console.log("UploadProgress", props)
    const files = props.files; // [{lastModified: 1703662003898, name: "table copy paste (2) (2).ana", size: 192841, type: ""}];  
    const setFiles = props.setFiles;
    console.log(files)
    if (!files || files.length===0) return null;

    function onDone(file)
    {
        //alert(file.name + " has completed");
        let filesAfterRemove = files.filter((val, i) => val !== file)
        setFiles(filesAfterRemove);
        
    }

    return (
        <div className="UploadProgress">
            <h1 style={{ paddingLeft: "20px", margin: 0 }}>Uploading ...</h1>
            <ul>
                {files.map(file => <UploadProgressOneFile key={file.name} file={file} setFileUploading={props.setFileUploading} reportDone={() => onDone(file)} setFiles={setFiles} /> ) }
            </ul>
            {/*<button style={{ marginLeft: "20px" }} onClick={ev=>setFiles(null)}>Cancel</button>*/}
        </div>
    );
}

function AcpModelViewButtons(props) {
    //console.log("AcpModelViewButtons", props.files);
    const filesInFolder = props.files;
    document.acpServerConfig.filesInFolder = filesInFolder; // there's probably a better way... 
    const perm = props.permissions;
    const fileChooserRef = useRef();
    const [filesBeingUploaded, setFilesBeingUploaded] = useState(null);
    const [fileBeingDownloaded, setFileBeingDownloaded] = useState(false);
    const [orphansUnits, setOrphanUnits] = useObjAtt(props.orphansOid, "units");
    const accountType = useEval("Selected_plan_type");
    const reviewersCanUploadDataFiles = document?.acpServerConfig?.reviewersCanUploadDataFiles;
 
    useEffect(() => {
        if (orphansUnits === "download done") {
            console.log("use effect setFileBeingDownloaded false");
            setFileBeingDownloaded(false);
            setOrphanUnits("");
        }
    })

    if (!perm) return "";

    const clsUpload = perm["Upload models"] || reviewersCanUploadDataFiles ? "BtnEnabled" : "BtnDisabled";
    const clsDownload = perm["Download models"]     ? "BtnEnabled" : "BtnDisabled";
    const clsDelete   = perm["Delete models"]       ? "BtnEnabled" : "BtnDisabled";
    const clsInvite   = perm["Send Email Invites"]  ? "BtnEnabled" : "BtnDisabled";

    function Upload(ev) {
        console.log("function Upload()");
        if (!perm["Upload models"] && !reviewersCanUploadDataFiles) return; // now allowing reviewers to upload spreadsheets, csv, txt...
        console.log("fileChooserRef.current", fileChooserRef.current);
        if (!fileChooserRef.current) { 
            console.log("fileChooserRef.current return")
            return;
        }

        fileChooserRef.current.click();

        //alert("Upload model is not implemented yet");
    }
    let src;
    let event;
    function DoUpload(ev) {
        console.log("DoUpload()", ev);
        if (!perm["Upload models"] && !reviewersCanUploadDataFiles) return; // now allowing reviewers to upload spreadsheets, csv, txt...
        if (!fileChooserRef.current) return;
        src = fileChooserRef.current.files;
        event = ev;

        console.log("src", src);
        for (let i = 0; i < filesInFolder.length; ++i) {
            if (filesInFolder[i].fileName.toLowerCase() === src[0].name.toLowerCase()) {
                props.setMsgBoxInfoClient({ body: src[0].name + " already exists.  Do you want to replace it?", buttons: 4, caption: "Confirm file update", responseHandler: handleUploadFileExists });
                return;
            }
        }

        DoUploadPart2(ev, src);
    }
    function handleUploadFileExists(button) {
        console.log("handleUploadFileExists", button);
        if (button === "Yes") {
            // Delete existing file before uploading, attempting to fix 1732 (actually the portal version of the bug)
            const cmd = 'Delete_model_file("' + src[0].name + '")';
            console.log('cmd', cmd);
            WebSocket_Send({ fn: 'Exec', cmd: cmd });
            setTimeout(() => {completeUploadAfterDeletion()}, 1500);
        }
    }
    function completeUploadAfterDeletion() {
        console.log("file in folder", filesInFolder);
        const fileListing = document.acpServerConfig.filesInFolder;
        let fileDeletionWorked = true;
        for (let i = 0; i < fileListing.length; ++i) {
            if (fileListing[i].fileName.toLowerCase() === src[0].name.toLowerCase()) {
                //props.setMsgBoxInfoClient({ body: src[0].name + " already exists.  Do you want to replace it?", buttons: 4, caption: "Confirm file update", responseHandler: handleUploadFileExists });
                console.log("File still present, deletion didn't work");
                fileDeletionWorked = false;
                break;
            }
        }
        console.log("****************file deletion worked", fileDeletionWorked);
                if (fileDeletionWorked)
                    DoUploadPart2(event, src);
    }
    function DoUploadPart2(ev, src) { 
        // src is array-like, but not a JS array. Copy to a JS array so .map() can be used.
        const whiteList = document?.acpServerConfig?.EW1474_uploadable_files;
        let files = [];

        for (let i = 0; i < src.length; ++i) {

            const fileSizeMB = src[i].size / (1024 * 1024);
            
            const fileName = src[i].name.toLowerCase();
            let allowUpload = false;
            for (let j = 0; j < whiteList.length; j++) {
                if (fileName.endsWith(whiteList[j])) {
                    allowUpload = true;
                    break;
                }
            }

            if (accountType.toLowerCase() === "individual" && fileSizeMB > document?.acpServerConfig?.Max_file_upload_size_individual) {
                alert("The file " + fileName + " cannot be uploaded into an ACP " + accountType + " account because it exceeds the maximum allowed file size of " + document?.acpServerConfig?.Max_file_upload_size_individual + "MB.")
                continue;
            } else if (accountType.toLowerCase() === "group" && fileSizeMB > document?.acpServerConfig?.Max_file_upload_size_group_basic) {
                alert("The file " + fileName + " cannot be uploaded into an ACP " + accountType + " account because it exceeds the maximum allowed file size of " + document?.acpServerConfig?.Max_file_upload_size_group_basic + "MB.")
                continue;
            } else if (accountType.toLowerCase() === "premium group" && fileSizeMB > document?.acpServerConfig?.Max_file_upload_size_group_premium) {
                alert("The file " + fileName + " cannot be uploaded into an ACP " + accountType + " account because it exceeds the maximum allowed file size of " + document?.acpServerConfig?.Max_file_upload_size_group_premium + "MB.")
                continue;
            }


            if (allowUpload)
                files.push(src[i]);
            else {
                let notAllowedMsg = "File '" + src[i].name + "' is not allowed.  Only files of type";
                for (let j = 0; j < whiteList.length; j++) {
                    if (j < whiteList.length - 1)
                        notAllowedMsg += " *." + whiteList[j] + ",";
                    else
                        notAllowedMsg += " and *." + whiteList[j];
                }
                notAllowedMsg += " are allowed to be uploaded.";
                alert(notAllowedMsg);
             }
        }

        if (files.length === 0) return;

        setFilesBeingUploaded(files);
        props.setFileUploading(true);

        ev.target.value = ''
    }
    function Download(ev)
    {
        if (!perm["Download models"]) return;

        if (props.selFileNum) {
            console.log("download()", props.selFileNum)
            setFileBeingDownloaded(true);
            setOrphanUnits("portal download in progress"); 
            WebSocket_Send({ fn: 'Exec', cmd: 'Download_sel_file' });
        }
    }
    function Delete(ev)
    {
        if (!perm["Delete models"]) return;
        if (props.selFileNum) {
            WebSocket_Send({ fn: 'Exec', cmd: 'Delete_selected_file' });
        }
    }
    function Invite(ev)
    {
        if (!perm["Send Email Invites"]) return;
        props.initEvite();
    }

    const filesTypesForUpload = perm["Upload models"] ? ".ana, .xlsx, .txt, .csv" : ".xlsx, .txt, .csv";

    const downloadButtonContents = fileBeingDownloaded && orphansUnits !== "download done"? <progress className="DownloadProgress"></progress> : "Download";

    return (
        <>
            <div className="ModelViewButtonStripe">
                <button id="Upload" className={clsUpload} onClick={Upload}>Upload</button>
                <input type="file" id="fileChooser" accept={filesTypesForUpload} ref={fileChooserRef} multiple onChange={DoUpload} />
                <button id="Download" className={clsDownload} onClick={Download}>{downloadButtonContents}</button>
                <button id="Delete"   className={clsDelete}   onClick={Delete}>Delete</button>
                {document?.acpServerConfig?.emailInvitesEnabled !== "no" && <button id="Invite" className={clsInvite} onClick={Invite}>Email invitation</button>}
            </div>
            {(filesBeingUploaded && filesBeingUploaded.length > 0) && <UploadProgress files={filesBeingUploaded} setFiles={setFilesBeingUploaded} setFileUploading={props.setFileUploading}/> }
        </>
    );
}

function AcpUserViewButtons(props)
{
    //console.log("AcpUserViewButtons", props)
    const onlyShowMembers = useEval("Only_show_members");
    const [showUsersButtonText, setShowUsersButtonText] = useState("Show all users");
    const numUsersSubscription = useEval("indexlength(sub_user_ids)");
    const groupPlanType = useEval("Selected_plan_type");
    const subscriptionName = useEval("Group_identity");

    function toggleUsersShown() {
        if (onlyShowMembers) {
            setShowUsersButtonText("Only show members");
            WebSocket_Send({ fn: 'Exec', cmd: 'definition Only_show_members: checkbox(0)' });
        }
        else
        {
            setShowUsersButtonText("Show all users");
            WebSocket_Send({ fn: 'Exec', cmd: 'definition Only_show_members: checkbox(1)' });
        }
    }


    /// The click handler for the "Add users" button below the list of users.
    /// Clear the status text in the Suan account model,
    /// enables the "Invite and Add Users"  button if it is disabled
    /// and displays the Add Users form to the right of the list of users.
    function addUsers() {

        if (subscriptionName != 'Lumina') {

            
            if (groupPlanType.toLowerCase() === "premium group" && numUsersSubscription >= document?.acpServerConfig?.Max_users_allowed_group_premium) {
                alert("You have reached the maximum number of Users allowed for a Premium Group plan, " + document?.acpServerConfig?.Max_users_allowed_group_premium.toString() + " Users. You cannot add any more users.");
                return;
            }

            const maxGroupBasicUsers = subscriptionName != 'Gravelroad' ? document?.acpServerConfig?.Max_users_allowed_group_basic : document?.acpServerConfig?.Max_users_allowed_gravelroad;
            if (groupPlanType.toLowerCase() === "group" && numUsersSubscription >= maxGroupBasicUsers) {
                alert("You have reached the maximum number of Users allowed for a Basic Group plan, " + maxGroupBasicUsers.toString() + " Users. You cannot add any more users unless you upgrade your plan.");
                return;
            }
        }
        
        // clear status text
        WebSocket_Send({ fn: 'Exec', cmd: 'description of Add_users_status :' });

        props.setbShowAddUsersForm(true);
        props.setAddUsersButtonDisabled(false);
    }

    return (
        <>
            <div className="ModelViewButtonStripe">
                <button id="ToggleUsersListing" className={props.bCanModify ? "BtnEnabled" : "BtnDisabled"} disabled={!props.bCanModify} onClick={toggleUsersShown} style={{ width: "200px" }}>{showUsersButtonText}</button>
                <button id="AddUsers" className="BtnEnabled" disabled={!props.bCanModify} onClick={addUsers} >+ Add users</button>
            </div>
            </>
        );
}

function AcpAddUsersButtons(props) {

    const inviteAndAddUsersBtnText = document?.acpServerConfig?.userIdsAreEmailAddresses !== "no" ? "Invite and add users" : "Add users";

    return (
        <>
            <div className="AddUsersButtonStripe">
                <button id="InviteAndAddUsers" class="BtnEnabled" disabled={props.addUsersButtonDisabled} onClick={props.inviteAndAddUsers} style={{ width: "200px" }}>{inviteAndAddUsersBtnText}</button>
                <button id="AddUsersCancel" class="BtnEnabled" onClick={props.addUsersCancel} >Cancel</button>
            </div>
        </>
    );
}

function IsValidToEmailAddr( toAddrs )
{
    const emailRegExp = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}\s*(,\s*[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}\s*)*$/;
    return emailRegExp.test(toAddrs)
}

function EmailInviteView(props)
{
    const initialEmailBody = useEval("Email_body_suggested");


    if (!props.anyBodyEdit && props.body !== initialEmailBody && typeof (initialEmailBody) === "string")
        props.setBody(initialEmailBody);

    const sendBtnCls = IsValidToEmailAddr(props.to) ? "BtnEnabled" : "BtnDisabled";

    function SendEmail(ev)
    {
        const expr = "Send_email_invite(toAddresses:'" + props.to + "',subject:'"
            + props.subj.replace("'", "''") + "',body:'"
            + props.body.replace("'", "''") + "')";

        WebSocket_Send({ fn: 'Exec', cmd: expr });

        props.setEViteVisible(false);
    }

    return (
        <div className="EmailInviteView">
            <div id="ToStripe">
                <div id="ToLabel">To email address(es):</div>
                <input id="To" type="text" placeholder="Email address(es) (separated by commas)" value={props.to} onChange={e=>props.setTo(e.currentTarget.value)} />
            </div>
            <div id="SubjectStripe">
                <div id="SubjectLabel">Subject:</div>
                <input id="Subject" type="text" value={props.subj} onChange={e=>props.setSubj(e.currentTarget.value)} />
            </div>
            <textarea id="EmailInviteBody" value={props.body} onChange={e => { props.setAnyBodyEdit(true);props.setBody(e.currentTarget.value)}} />
            <div className="EviteBtnStripe">
                <button id="SendEmail" className={sendBtnCls} onClick={SendEmail}>Send email</button>
                <button id="Cancel" className="BtnEnabled" onClick={e=>props.setEViteVisible(false)}>Cancel</button>
            </div>
        </div>
    );
}

function AcpPortalModelView(props)
{
    //console.log("AcpPortalModelView", props)
    const fileListingJSON = useEval("File_listing_JSON");
    const [fileUploading, setFileUploading] = useState(false);
    const modelsViewRef = useRef();
    const [modelsViewHeight, setModelsViewHieght] = useState(300);
    const selFileNum = OidFromName("Selected_file_num");
    const acctChoice = props.acctChoice;
    const orphansOidObj = useEval("HandleFromIdentifier('orphans')");

    useEffect(() => {
        if (!props.isGroup) {
            props.setProjectForToolbar(undefined);
            props.setAccountNameForToolbar(undefined);
        }

        // there's client side code for showing cube when closing model, so this will make the cube go away
        props.setForceShowCube(false);
    })


    // Start observing the element when the component is mounted
    useEffect(() => {
        const element = modelsViewRef?.current;

        if (!element) return;

        const observer = new ResizeObserver(() => {
            // Do something when the element is resized
            console.log("resize event");
            if (modelsViewRef && modelsViewHeight != modelsViewRef?.current?.clientHeight)
                setModelsViewHieght(modelsViewRef?.current?.clientHeight);
        });

        observer.observe(element);
        return () => {
            // Cleanup the observer by unobserving all elements
            observer.disconnect();
        };
    })

    if (!acctChoice || !fileListingJSON) return "";
 
    const files = JSON.parse(fileListingJSON);

    const orphansOid = orphansOidObj?.oid;

    const portalModelViewCls = props.eViteVisible ? "AcpPortalModelView WithEmailInvite" : "AcpPortalModelView"; 

    return (
        <div className={portalModelViewCls} ref={modelsViewRef}>
            {/* <AcpPortalAcctStripe oid={acctChoice}/> */}
            {false && props.isGroup && <AcpPortalProjectStripe createProject={props.createProject} showProjectButtons={props.showProjectButtons} setProjectForToolbar={props.setProjectForToolbar} setAccountNameForToolbar={props.setAccountNameForToolbar} permissions={props.permissions} userRole={props.userRole} setUserRole={props.setUserRole}/>}
            <AcpPortalFileListing files={files} {...props} selFileNum={selFileNum} modelsViewHeight={modelsViewHeight} fileUploading={fileUploading}/>
            {(props.eViteVisible && !!orphansOid) || <AcpModelViewButtons permissions={props.permissions} initEvite={props.initEvite} selFileNum={selFileNum} orphansOid={orphansOid} files={files} setMsgBoxInfoClient={props.setMsgBoxInfoClient} msgBoxInfoClient={props.msgBoxInfoClient} setFileUploading={setFileUploading}/>}
        </div>
    );
}

function RolePermissionTable(props)
{
    const permTableJSON = useEval('PermissionTable_JSON');
    if (!permTableJSON) return null;
    const perms = JSON.parse(permTableJSON);
    const roles = perms.Roles;
    const allow = perms.Allow;

    function permRow(aclItem,i)
    {
        // remove items we don't actually support yet
        if (aclItem === "Delete folders" || aclItem === "Create folders" || aclItem === "Save snapshots" || aclItem === "Modify ACL" || aclItem === "See other users' snapshots" || aclItem === "View subscription info" || aclItem === "List" || aclItem  === "Delete projects")
            return "";

        let aclName = (aclItem === "View ACL") ? "View privileges" : aclItem;

        return (
            <tr key={i} id={aclItem}>
                <td>{aclName}</td>
                {roles.map(r => {
                    const allow_i = allow[aclItem][r];
                    return <td key={r}>{allow_i?'\u2714':' '}</td>;
                })}
            </tr>
        );
    }

    return (
        <table className="PermissionsTable">
            <thead>
                <tr>
                    <th key="Priv">Privileges</th>
                    {roles.map( r=><th key={r}>{r}</th> )}
                </tr>
            </thead>
            <tbody>
                {perms.ACL.map( permRow ) }
            </tbody>
        </table>
    );
}

function UserRoleChoice(props)
{
    const roleListJSON = useEval("Role_List_JSON");
    const user = props.user;
    if (!roleListJSON) return user.Username;
    const roleList = JSON.parse(roleListJSON);

    function changeRole(ev)
    {
        const u = user.User_ID;
        const v = ev.currentTarget.value;
        let cmd = "Change_user_role(" + u + ',' + v + ")";
        if (v === "100") cmd = "Delete_user(" + u + ")";
        WebSocket_Send({ fn: 'Exec', cmd: cmd });
    }

    // EW 1757 Reorder Users/Role in project menu
    if (roleList?.length > 2) {
        const manager = roleList[0];
        roleList[0] = roleList[2];
        roleList[2] = manager;
    }
    
    return (
        <select style={{ opacity: 1 }} className="UserRoleChoice" value={user.Role_ID} onChange={changeRole} disabled={!props.bCanModify}>
            { roleList.map( role=>
                role.Role_Name !== "eVite Reviewer" &&
                    <option key={role.Role_ID} value={role.Role_ID}>{role.Role_Name}</option>
            )}
            <option key={0} value={0}>Remove</option>
            {false && // LDC 8/20/2021 Bug S-863. Do not re-enable this option! It was ill-conceived and is extremely problematic. Read S-863 to understand why.
                !!props.isSuperUser && <option key={100} value={100}>Delete user</option>}
        </select>
    );
}

function AcpPortalUserViewRow(props)
{
    const user = props.user;

    const trCls = user.Username.toLowerCase().indexOf(props.filter) === -1 ? "FilterNoMatch" : "FilterMatch";

    return (
        <tr key={user.User_ID} className={trCls}>
            <td><div className="UserName" >{user.Username}</div></td>
            <td><div className="UserRole" ><UserRoleChoice user={user} isSuperUser={props.isSuperUser} bCanModify={props.bCanModify}/></div></td>
        </tr>
    );
}

function AcpPortalUsersView(props)
{
    const [bPermsVis, setPermsVis] = useState(false);
    const usersJSON = useEval("Project_User_JSON");
    const [filter, setFilter] = useState("");
    const [addUsersButtonDisabled, setAddUsersButtonDisabled] = useState(false);
    const [bShowAddUsersForm, setbShowAddUsersForm] = useState(false);
    const isSuperUser = useEval("Is_SuperUser");
    if (!usersJSON) return "";
    const users = JSON.parse(usersJSON);
    const bCanModify = props.permissions['Manage users'];
    const permStyle = { display: bPermsVis ? 'block' : 'none' };
    const filterLower = filter.toLowerCase();
    const addUsersInitialText = document?.acpServerConfig?. userIdsAreEmailAddresses !== "no" ? "To add user(s), type (or paste) their email address(es)..." : "To add user(s), type (or paste) their user name(s)...";

    return (
        <div className="AcpPortalUsersView">
            <div className="AcpPortalUsersListing">
                {false && <AcpPortalAcctStripe oid={props.acctChoice} />}
                {false && props.isGroup && <AcpPortalProjectStripe createProject={props.createProject} showProjectButtons={props.showProjectButtons} setProjectForToolbar={props.setProjectForToolbar} permissions={props.permissions}  />}
                <table className="UserTable">
                    <thead>
                        <tr>
                        <th><span>User</span><AcpFilter filter={filter} setFilter={setFilter}/></th>
                        <th>
                            <span id="RoleText">Role in project</span>
                            <img id="RoleInfo" src="img/Blue_question_mark_icon.svg" alt="?" onMouseOver={e=>setPermsVis(true)} onMouseOut={e=>setPermsVis(false)} />
                            <span id="RolePermHolder" ><div className="PermissionTableContainer" style={permStyle}><RolePermissionTable/></div></span>
                        </th>
                        </tr></thead>
                    <tbody>
                        {users.map(user => <AcpPortalUserViewRow key={user.User_ID} user={user} filter={filterLower} bCanModify={bCanModify} isSuperUser={isSuperUser} />) }
                    </tbody>
                </table>
                <AcpUserViewButtons setbShowAddUsersForm={setbShowAddUsersForm} setAddUsersButtonDisabled={setAddUsersButtonDisabled} bCanModify={bCanModify} />
            </div>
            {bShowAddUsersForm && <AddUsersForm addUsersButtonDisabled={addUsersButtonDisabled} setAddUsersButtonDisabled={setAddUsersButtonDisabled}
                setbShowAddUsersForm={setbShowAddUsersForm} addUsersInitialText={addUsersInitialText}/>}
        </div>
    );
}

function AddUsersForm(props) {
    //console.log("AddUsersForm", props)
    const [emailAddressesToAdd, setEmailAddressesToAdd] = useState(props.addUsersInitialText);
    const [textColorAddressesToAdd, setTextColorAddressesToAdd] = useState({ color: "darkgray" });
    const [userRoleId, setUserRoleId] = useState(1);
    const projectName = useEval("project_name");
    const [userRoleName, setUserRoleName] = useState("Manager");
    //const emailContentFromServer = useEval("EmailContentAddUser");
    const userIdentity = useEval('User_Identity');
    const accountName = useEval('Group_identity');
    const numUsersSubscription = useEval("indexlength(sub_user_ids)");
    const groupPlanType = useEval("Selected_plan_type");
    const subscriptionName = useEval("Group_identity");

    const link = window.location.toString().split('?')[0]; // EW 1595 - remove auth info if present

    let emailContent = "Dear :\n \nI invite you to be a " + userRoleName + " in Analytica Cloud Platform (ACP) for project '" + projectName + "' in account " + accountName + ". Click here to access it:\n \n" + link + " " +
        "\n \nLOGIN INFO\n \nUser name: <email address of user> \n \nThanks,\n" + userIdentity;

    // Results e.g. 'Project added' or 'A project with that name already exists' etc below form
    const oidAddProjectStatusText = OidFromName("Add_users_status");
    const [addUsersStatusText] = useObjAtt(oidAddProjectStatusText, "description");

    if (emailContent.indexOf("undefined") !== -1) return "";

    // after adding users successfully, hide the form
    if (addUsersStatusText === "Users added")
        props.setbShowAddUsersForm(false);

    /**
     * inviteAndAddUsers
     *
     * The click handler for when the someone clicks the "Invite and add user(s)" button.
     *
     * Calls the udf Add_users_to_DB in Suan Account.ana
     *
     * Diables the button to give feedback that click worked and something is happening.
     *
     * Makes sure the email addresses textarea is not blank.
     * */
    function inviteAndAddUsers() {

        if (emailAddressesToAdd === "" || emailAddressesToAdd === props.addUsersInitialText)
        {
            alert("You must fill in the email address(es) field.");
            return;
        }

        let usersToAdd = emailAddressesToAdd.trim().replace(/[\s,;]+/g, ",");
        const numUsersToAdd = usersToAdd.split(',').length;

        if (subscriptionName != 'Lumina') {
            const maxGroupBasicUsers = subscriptionName != 'Gravelroad' ? document?.acpServerConfig?.Max_users_allowed_group_basic : document?.acpServerConfig?.Max_users_allowed_gravelroad;
            if (groupPlanType.toLowerCase() === "group" && numUsersToAdd + numUsersSubscription > maxGroupBasicUsers) {
                alert("You currently have " + numUsersSubscription.toString() + " users.  You cannot add " + numUsersToAdd.toString() + " more users.  You are limited to " + maxGroupBasicUsers + " users with a Basic Group plan.  Reduce the number of users you are adding or upgrade to a Premium Group plan.")
                return;
            }
            if (groupPlanType.toLowerCase() === "premium group" && numUsersToAdd + numUsersSubscription > document?.acpServerConfig?.Max_users_allowed_group_premium) {
                alert("You currently have " + numUsersSubscription.toString() + " users.  You cannot add " + numUsersToAdd.toString() + " more users.  You are limited to " + document?.acpServerConfig?.Max_users_allowed_group_premium + " users with a Premium Group plan.  Reduce the number of users you are adding to " + (document?.acpServerConfig?.Max_users_allowed_group_premium - numUsersSubscription).toString() + " new users.");
                return;
            }
        }

        props.setAddUsersButtonDisabled(true);

        const userIdsAreEmailAds = document?.acpServerConfig?.userIdsAreEmailAddresses === "no" ? false : true;

        let emailBody = "";
        if (userIdsAreEmailAds) {
            emailBody = document.getElementById("EmailNewUserTextArea").value;
            emailBody = emailBody.replace(/'/g, "''");
        }
        let cmd = "Add_users_to_DB('" + emailAddressesToAdd + "'," + userRoleId + ", '" + emailBody + "', " + userIdsAreEmailAds + ")";
        WebSocket_Send({ fn: 'Exec', cmd: cmd });
    }

    /**
     * The onChange handler for the Role pulldown
     *
     * Sets the state variables 'userRoleId' and 'userRoleName' to match
     * the new value in the Role pulldown.
     *
     * @param {any} event
     */
    function onChangeRole(event) {
        setUserRoleId(event.target.value);

        switch (event.target.value) {
            case "1":
                setUserRoleName("Manager");
                break;
            case "2":
                setUserRoleName("Author");
                break;
            case "3":
                setUserRoleName("Reviewer");
                break;
            default:
                break;
        }
    }

    /// click handler for when some one presses the 'Cancel' button
    /// in the Add Users form.
    function addUsersCancel() {
        props.setbShowAddUsersForm(false);
    }

    // onChange handler for text are containing the addresses for the new users.
    function onChangeAddressesTextArea(event) {
        setEmailAddressesToAdd(event.target.value);
    }

    // onFocus handler for text area contains the email address.
    // Clears gray instruction text when the text area comes into focus.
    function onFocusEmailTextArea(ev) {

        if (emailAddressesToAdd === props.addUsersInitialText) {
            setEmailAddressesToAdd("");
            setTextColorAddressesToAdd({ color: "black" });
        } else { /*alert("not clearing text");*/ }
    }

    return (
        <div className="AddUsersForm">
            <div id="AddUsersStripe"><span className="AddUsersLabel">Add users</span></div>
            <div id="AddEmailsAndRole">
                <div>
                    <textarea id="EmailAddressToAddTextArea" onChange={onChangeAddressesTextArea} onFocus={onFocusEmailTextArea} style={textColorAddressesToAdd} spellCheck="false" value={emailAddressesToAdd}></textarea>
                </div>
                <div id="AddUsersRolePulldown" onChange={onChangeRole}>
                    Role<br />
                    <select name="cars" id="cars">
                        <option value="1">Manager</option>
                        <option value="2">Author</option>
                        <option value="3">Reviewer</option>
                    </select>
                </div>
            </div>
            {document?.acpServerConfig?.userIdsAreEmailAddresses !== "no" && <><div id="AddUsersStripe"><span className="AddUsersLabel">Invitation email (which you can edit)</span></div>
                <EmailContentArea emailContent={emailContent} userRoleName={userRoleName} /></>}
            <AcpAddUsersButtons inviteAndAddUsers={inviteAndAddUsers} addUsersCancel={addUsersCancel} addUsersButtonDisabled={props.addUsersButtonDisabled} />
        </div >
    );

}

//<div>
//    <textarea id="EmailNewUserTextArea" onChange={onChangeEmailMessageTextArea} spellCheck="false" value={testState} ></textarea>
//</div>

function EmailContentArea(props) {

    const [emailContent2, setEmailContent2] = useState(props.emailContent);
    //const [roleName, setRoleName] = useState(props.roleId);
    const [userRoleName2, setUserRoleName2] = useState(props.userRoleName);

    if (props.userRoleName !== userRoleName2) updateEmail();

    function updateEmail() {
        const newEMail = emailContent2.replace(userRoleName2, props.userRoleName);
        setUserRoleName2(props.userRoleName);
        setEmailContent2(newEMail);
    }

    function onChangeEmailMessageTextArea(event) {
        //alert("onChangeEmailMessageTextArea");
        setEmailContent2(event.target.value);
    }

    return (
            <div>
                <textarea id="EmailNewUserTextArea" onChange={onChangeEmailMessageTextArea} spellCheck="false" value={emailContent2} ></textarea>
            </div>
        );
}

function AcpPortalAccountView(props)
{
    const isSuperUser = useEval("Is_SuperUser");
    const userIdentity = useEval("User_identity");
    const account = useEval("Group_identity");
    const numberOfSessionsThisMonth = useEval('ModelRunsThisMonth');
    const countSessionCredits = useEval('Count_session_credit');
    const maxModelRunsGroup = useEval("ModelRunsGroup");
    const maxModelRunsGroupPremium = useEval("ModelRunsPremiumGrp");
    const maxModelRunsIndividual = useEval("ModelRunsIndividual");
    const purchasedCreditsRemaining = useEval("PurchasedCreditsLeft");
    const numberOfCreditsPurchased = useEval("NumCreditsBought");
    const acctType = props.acctType;
    const isGroup = acctType && acctType!=="Individual";

    let buyUrl = "https://analytica.com/purchase-analytica/?email=" + encodeURIComponent(userIdentity)
           + "&accountType=" + encodeURIComponent(acctType);

    if (isGroup && account) {
        buyUrl += "&account=" + encodeURIComponent(account);
    }

    function ChangePassword(ev)
    {
        //alert("Sorry, change password isn't yet implemented");

        props.setShowChangePasswordForm(true);
    }

    let numCreditsPerMonth;
    switch (acctType.toLowerCase()) {
        case "premium group":
            numCreditsPerMonth = maxModelRunsGroupPremium; break;
        case "group": 
            numCreditsPerMonth = maxModelRunsGroup; break;
        case "individual":
            numCreditsPerMonth = maxModelRunsIndividual; break;
    }


    let numCreditsRemaining = numCreditsPerMonth !== undefined && numberOfSessionsThisMonth !== undefined ? numCreditsPerMonth - numberOfSessionsThisMonth : "";
    if (numCreditsRemaining < 0) numCreditsRemaining = 0; // this line should not be true, but just in case, we don't want to display a negative number

    console.log("numCreditsRemaining", numCreditsRemaining, numCreditsPerMonth, numberOfSessionsThisMonth)

    const creditsRemaining = account === "Lumina" || (userIdentity?.includes("@lumina.com") && !isGroup)? "Unlimited for Lumina" : numCreditsRemaining;

    if (creditsRemaining === undefined || numberOfCreditsPurchased === undefined || purchasedCreditsRemaining === undefined) return null;

    console.log("numberOfCreditsPurchased", numberOfCreditsPurchased)

    return (
        <div className="AcpPortalAccountView">
            <div className="PortalAccountFields">
                <div id="UserLabel" className="Label">User:</div>
                <div id="UserIdentity">{userIdentity}</div>
                { !!isSuperUser && <span id="IsSuperuser">Super User</span>}
                {document?.acpServerConfig?.usersCanResetPasswords !== "no" && <div id="ChangePwd" style={{ cursor: "pointer" }} onClick={ChangePassword}>Change password</div>}
                {document?.acpServerConfig?.usersCanResetPasswords === "no" && <div style={{ cursor: "pointer" }} >To reset your password,<br/> please contact your ACP Administrator</div>}
                <div id="AccountLabel" className="Label" style={{ marginLeft: "0px" }}>Account:</div>
                <AcpPortalAccount oid={props.acctChoice}/>
                <div id="AccountTypeLabel" className="Label">Account type:</div>
                <div id="AccountType">{acctType}</div>
                {!!countSessionCredits && <div id="SessionCreditsLabel" className="Label">Model run credits<br/>remaining (monthly):</div>}
                {!!countSessionCredits && <div id="SessionCredits"><br/>{creditsRemaining}</div>}
                {!!countSessionCredits && <a href={buyUrl} id="BuyMore" target="_blank" rel="noopener noreferrer"><br/>Buy more</a>}
                {!!countSessionCredits && numberOfCreditsPurchased > 0 && <><div id="CreditsPurchasedLabel" class="Label">Model run credits<br />remaining (purchased):</div><div id="PurchasedCredits"><br/>{purchasedCreditsRemaining}/{numberOfCreditsPurchased}</div></> }
            </div>
        </div>
    );
}

function CreateProjectForm(props) {
    // used to store state of text input for project name, and then send to DTA
    // when someone hits submit
    const [newProjectName, setNewProjectName] = useState("");
    const inputRef = useRef();

    // Results e.g. 'Project added' or 'A project with that name already exists' etc below form
    const oidAddProjectStatusText = OidFromName("AddProjectStatusText");
    const [addProjectStatusText] = useObjAtt(oidAddProjectStatusText, "description");

    // function used when Cancel button is pressed, clears create project ui
    const cancelNewProject = props.cancelNewProject;

    function onChangeProjectName(event) {
        setNewProjectName(event.target.value);
    }

    function CreateNewProject() {
        //alert("CreateNewProject() " + "Add_new_project('" + newProjectName + "')");
        WebSocket_Send({ fn: 'Exec', cmd: "Add_new_project('" + newProjectName + "')" });
        setTimeout(gotoModelsListing, 2000);
    }

    function gotoModelsListing() {
        // I suspect accessing the dom this way is probably a react js no no, but
        // I've wasted a lot of time, and this works, so fine for now. Moving on.
        if (document.getElementById("createProjectStatus").innerHTML === "Project added.")
            cancelNewProject();
    }

    useEffect(() => {
        inputRef.current.focus(); // useEffect hook with an empty dependency array[] is used to run the effect after the component mounts, so it will set focus on the input field as soon as the form is displayed.
    }, []);

    return (
        <div>
            <ErrorMessageWindow />
            <img id="LuminaLogo2" alt="Lumina" src="img/LuminaLogo2.png" />
            <div id="CreateProjectSpacer"></div>
            <div id="CreateProjectFormDiv">
                <span id="CreateProjectTitle">Create a project</span>
                <div className="SuanFormDiv1">
                    <div className="SuanFormLabel" >Project Name</div>
                    <div className="SuanFormControl"><input ref={inputRef} type="text" className="SuanProjectInput" name="NewProject" value={newProjectName} onChange={onChangeProjectName} /></div>
                </div>
                <div className="SuanFormDiv2">
                    <button className="SuanProjectButton" onClick={CreateNewProject}>Submit</button>  <button className="SuanProjectButton" onClick={cancelNewProject}>Cancel</button>
                </div>
                <div id="createProjectStatus">{addProjectStatusText}</div>
            </div>
        </div>
    );
}

function ChangePasswordForm(props) {

    // used to store state of text input for password field, and then send to firebase
    // when someone hits submit
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");

    // function used when Cancel button is pressed, clears change password ui
    const cancelChangePassword = props.cancelChangePassword;

    function onChangePassword1(event) {
        setNewPassword1(event.target.value);
    }

    function onChangePassword2(event) {
        setNewPassword2(event.target.value);
    }

    function onChangeCurrentPassword(event) {
        setCurrentPassword(event.target.value);
    }

    function ChangePassword() {
        //alert("ChangePassword");

        if (newPassword1 !== newPassword2) {
            alert("Passwords do not match.");
            return;
        }

        if (currentPassword === "" || newPassword1 === "" | newPassword2 === "") {
            alert("Please fill in all password fields first.");
            return;
        }

        //
        // You generally need to reauthenticate to update the password
        //
        const user = firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            currentPassword
        );

        // Now you can use that credential to reauthenticate
        user.reauthenticateWithCredential(credential).then(() => {
            //alert("reauth worked.");
            user.updatePassword(newPassword1).then(() => {
                alert("Password updated.");
                cancelChangePassword();
                }).catch((error) => { alert(error); });
            }).catch ((error) => {
            if (error.code === 'auth/wrong-password') {
                alert("Wrong password");
            } else if (error.message.includes("INVALID_LOGIN_CREDENTIALS")) {
                alert("Wrong password.");
            } else {
                alert(error.code + "\r" + error.message);
            }
        });
    }

    return (
        <div>
            <ErrorMessageWindow />
            <img id="LuminaLogo2" alt="Lumina" src="img/LuminaLogo2.png" />
            <div id="ChangePasswordSpacer"></div>
            <div id="ChangePasswordFormDiv">
                <span id="ChangePasswordTitle">Change password</span>
                <div class="SuanFormDiv1">
                    <div class="SuanFormLabelWider" >Current password</div>
                    <div class="SuanFormControl"><input type="password" class="PasswordInput" name="CurrentPassword" value={currentPassword} onChange={onChangeCurrentPassword} /></div>
                </div>
                <div class="SuanFormDiv1">
                    <div class="SuanFormLabelWider" >New password</div>
                    <div class="SuanFormControl"><input type="password" class="PasswordInput" name="NewPassword1" value={newPassword1} onChange={onChangePassword1} /></div>
                </div>
                <div class="SuanFormDiv1">
                    <div class="SuanFormLabelWider" >New password again</div>
                    <div class="SuanFormControl"><input type="password" class="PasswordInput" name="NewPassword2" value={newPassword2} onChange={onChangePassword2} /></div>
                </div>
                <div class="SuanFormDiv2">
                    <button class="ChangePasswordButton" onClick={ChangePassword}>Submit</button>  <button class="ChangePasswordButton" onClick={cancelChangePassword}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

// This flag isn't a react state var. We want to select the subscription when "manage published models" from DTA is used
// only once, not after model are run and closed.
var gManagePublishedModelsSubscriptionHasBeenSelectedPreviously = false;

export function AcpPortal(props) {
    //console.log("AcpPortal", props)
    const userPresentInDB = useEval("User_present_in_data");
    const createIndividSubOnSignin = useEval("CreateSubOnSignin");
    const bModelFullyLoaded = props.bModelFullyLoaded;
    const setModelFullyLoaded = props.setModelFullyLoaded;

    useEffect(() => {
        if (bModelFullyLoaded === false) setModelFullyLoaded(null);
    }, [bModelFullyLoaded, setModelFullyLoaded]);

    if (userPresentInDB === undefined || createIndividSubOnSignin === undefined)
        return "";

    if (userPresentInDB === 0 && createIndividSubOnSignin === 0)
        return <h3>&nbsp;&nbsp;&nbsp;&nbsp;This email address is not a member of any ACP account, contact your ACP administrator.</h3>;

    return <AcpPortal1 {...props} />
}

function AcpPortal1(props)
{
    //const [ , ] = useState(WebSocket_Send({ fn: 'Exec', cmd: 'RefreshUserIdentity' }));    // This is to ensure that it has the right user identity is singleInstance mode
    // LDC 6/7/2021 ER 153: The above line messed up session timeouts, because it execute when the dialog warning that your session was about to time out appeared.
    // LDC 6/22/2021 singleInstance conf wasn't synching user identity correctly. I moved the above RefreshUserIdentity to a useState below.

    const isAdminProj = useEval("Is_For_Subscription");                    // LDC 8/19/2021 S-530. Are we viewing the subscription administration permissions?
    const [selTab0, setSelTab] = useState("Models");
    const selTab = isAdminProj && selTab0==="Models" ? "Users" : selTab0;
    const httpOriginOid = OidFromName("HTTP_Origin");
    const [/*httpOrigin*/, setHttpOrigin] = useObjAtt(httpOriginOid, "Definition");
    const permissions = useEval("Permissions","ACL");
    const acctOid = OidFromName("Select_subscription");
    const acctType = useEval("Selected_plan_type");
    const isGroup = acctType && acctType!=="Individual";
    // Evite info:
    const [to, setTo] = useState("");
    const [subj, setSubj] = useState("Invitation to review Analytica cloud model");
    const [body, setBody] = useState("");
    const [eViteVisible, setEViteVisible] = useState(false);
    const [anyBodyEdit, setAnyBodyEdit] = useState(false);
    const eVite = { to, setTo, subj, setSubj, body, setBody, eViteVisible, setEViteVisible, anyBodyEdit, setAnyBodyEdit };

    // Project Creation/Deletion
    //const [showCreateProjectForm, setShowCreateProject] = useState(false);

    // change password
    const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

    useState(() => {
        WebSocket_Send({ fn: 'Exec', cmd: 'RefreshUserIdentity' });
    });

    function InitEvite()
    {
        console.log("InitEvite")
        setHttpOrigin("'" + window.location.origin + "'");
        WebSocket_Send({ fn: "Exec", cmd: "Make_invite"});
        setTo("");
        setSubj("Invitation to review Analytica cloud model");
        setBody("");
        setAnyBodyEdit(false);
        setEViteVisible(true);
    }

    function ShowCreateNewProjectForm() {
        //alert(setShowCreateProject);

        // clear status text
        WebSocket_Send({ fn: 'Exec', cmd: 'description of addProjectStatusText :' });

        // set state variable which says to display the create project ui
        props.setShowCreateProject(true);
    }

    function CancelNewProject() {
        props.setShowCreateProject(false);
    }

    function CancelChangePassword() {
        setShowChangePasswordForm(false);
    }

    //function LogOff() {
    //    if (props.signOut === undefined) {
    //        alert("You skipped sign in, so you can't sign out.");
    //        return;
    //    }

    //    if (props.status === "connected") {
    //        WebSocket_Close(props.setStatus);
    //    }
    //    props.signOut();
    //}

    //let email = "signin skipped";
    //if (props.user !== undefined && props.user.email !== undefined)
    //    email = props.user.email;

    if (!gManagePublishedModelsSubscriptionHasBeenSelectedPreviously && acctOid) {
        gManagePublishedModelsSubscriptionHasBeenSelectedPreviously = true;
        WebSocket_Send({fn: 'Exec', cmd: 'Set_Subscription_fro'});
    }

    const acpPortalDefault = (<div id="AcpPortalContainer"><div className="AcpPortal">
        <ErrorMessageWindow />
        <AcpPortalTabBar selTab={selTab} setSelTab={setSelTab} isGroup={isGroup} permissions={permissions} isAdminProj={isAdminProj} />
        {(selTab === "Models") && <AcpPortalModelView {...props} acctChoice={acctOid} isGroup={isGroup} permissions={permissions}
            eViteVisible={eViteVisible} initEvite={InitEvite} showProjectButtons={true} createProject={ShowCreateNewProjectForm} />}
        {(selTab === "Users") && <AcpPortalUsersView {...props} showProjectButtons={true} createProject={ShowCreateNewProjectForm}  permissions={permissions} isGroup={isGroup} acctChoice={acctOid} acctType={acctType}/>}
        {(selTab === "Accounts") && <AcpPortalAccountView {...props} acctChoice={acctOid} acctType={acctType} setShowChangePasswordForm={setShowChangePasswordForm} />}
        {eViteVisible && <EmailInviteView {...eVite} />}
        {false && <div>Permissions={JSON.stringify(permissions)}</div>}
    </div>
        <Footer isPortal={true} />
    </div>);

    let showDefaultPortal = true;
    if (showChangePasswordForm === true || props.showCreateProjectForm === true)
        showDefaultPortal = false;

    return (
        <>
            {(showDefaultPortal === true) && acpPortalDefault}
            {(props.showCreateProjectForm === true) && <CreateProjectForm cancelNewProject={CancelNewProject} />}
            {(showChangePasswordForm === true) && <ChangePasswordForm cancelChangePassword={CancelChangePassword} />}
        </>
    );
}


export function Footer(props) {
    const [showAnaLogoTip, setShowAnaLogoTip] = useState(false);
    const [showAnaTimeout, setShowAnaTimeout] = useState(false);
    const [showLumLogoTip, setShowLumLogoTip] = useState(false);
    const [showLumTimeout, setShowLumTimeout] = useState(false);

    const footerWrapperId = props.isPortal ? "FooterWrapper Portal" : "FooterWrapper";

    function enterAnaLogo() {
        //console.log("Enter ana logo")
        setShowAnaTimeout(setTimeout(() => { setShowAnaLogoTip(true) }, 500));
    }

    function leaveAnaLogo() {
        //console.log("Leave ana logo")
        setShowAnaLogoTip(false);
        if (showAnaTimeout !== 0) clearTimeout(showAnaTimeout);
    }

    function anaLogoClick() {
        window.open("https://analytica.com/products/analytica-cloud-platform/", "_blank");
    }

    function enterLumLogo() {
        //console.log("Enter lum logo")
        setShowLumTimeout(setTimeout(() => { setShowLumLogoTip(true) }, 500));
    }

    function leaveLumLogo() {
        //console.log("Leave lum logo")
        setShowLumLogoTip(false);
        if (showLumTimeout !== 0) clearTimeout(showLumTimeout);
    }

    function lumLogoClick() {
        window.open("https://analytica.com", "_blank");
    }

    return (
        <div className={footerWrapperId} >
            <div className="Footer">
                <div className="LeftLogoHolder"><img src="img/footerAnalyticaLogo2.png" alt="Analytica" height="30" onMouseEnter={enterAnaLogo} onMouseLeave={leaveAnaLogo} onClick={anaLogoClick} /></div>
                <div className="RightLogoHolder"><img src="img/footerLuminaLogo.png" alt="Lumina" height="42" onMouseEnter={enterLumLogo} onMouseLeave={leaveLumLogo} onClick={lumLogoClick} /></div>
            </div>
            {showAnaLogoTip && <ToolbarTip objRect={null} control="AnaLogo" tipText="This web tool is implemented in the Analytica Cloud Platform. Click for more about ACP." />}
            {showLumLogoTip && <ToolbarTip objRect={null} control="LumLogo" tipText="This web tool is implemented in the Analytica Cloud Platform from Lumina Decision Systems. Click for more about Lumina." />}
        </div>
    );
}
