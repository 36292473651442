import React from 'react';
import { useState } from 'react';
import { CacheEntries,RawTopOid } from './serverState.js';
import './styles/cacheBrowser.scss';

function ClientCacheAttVal(props)
{
    const node = props.att;
    const attName = node[0];
    const valObj = node[1];
    const val = valObj === undefined ? "�undefined�" : valObj === null ? "�null�" : valObj.val_;
    
    const valTxt = val===undefined ? "" : JSON.stringify(val);

    return (<li className="AttVal"><span className="AttName">{attName}</span> = <span className="Val">{valTxt}</span></li>);
}

function ClientCacheOidEntry(props)
{
    const [bExpanded,setExpanded] = useState(false);
    const oid = props.oid;

    const cls = "CacheOidEntry " + (bExpanded ? "Expanded" : "Collapsed");
    //let childVisStyle = { display: bExpanded ? "block" : "none" };

    let ident = "";
    if (Array.isArray(props.att)) ident = props.att["identifier"];

    function toggleExpanded(ev)
    {
        setExpanded(!bExpanded);
        ev.stopPropagation();
    }

    if (bExpanded) {
        return (<div className={cls} onClick={toggleExpanded}><span>[{oid}]</span>
            <ul>
                { Array.from(props.atts).map( (att)=>( <ClientCacheAttVal att={att} key={att}/> ) ) }
            </ul>
        </div>);
    } else {
        return (<div className={cls} onClick={toggleExpanded}><span>[{oid}] <span className="Ident">{ident}</span></span>
        </div>);
    }
 }
            
export function ClientCacheBrowser(props)
{
    const [count, inval] = useState(0);
    const cache = CacheEntries();
    
    function refresh(ev)
    {
        inval(count + 1);
    }


    return (
        <div className="CacheBrowser">
            <button onClick={refresh}>Refresh</button>
            Top-Level Oid = {RawTopOid()}
            <h1>Oid-Att values:</h1>
            <ul>
                {Array.from(count===0?[]:cache).map( ([i,k])=>
                    (<ClientCacheOidEntry key={i!==undefined?i:-1} oid={i} atts={k}/>)
                 )}
            </ul>
        </div>
    );
}
