// ObjectWindow.js
//

import React from 'react';
import { useState, useRef, useLayoutEffect } from 'react';
import { useObjAtt, useObjAtts, useQuery, useEval } from './serverState.js';
import { Lineify } from './miscTools.js';
import { getTableOrGraphMargin } from './editTable.js';
import './styles/objectWindow.scss';
import { WebSocket_Send } from './webSocketClient.js';

function ObjectListItemView(props) {
    const [cls, ident, title] = useObjAtts(props.oid, ['class', 'identifier', '_title']);
    const idRef = useRef();
    const titleRef = useRef();
    const iconRef = useRef();

    if (!cls || !ident) return null;
    const objClass = "ObjClass ObjClass_" + cls;

    //const shape = NodeShape(cls, { width: 20, height: 20 }, 'transparent', 'black', null, null);
    const onClick = !props.setCurObj ? null : (ev) => {
        props.setCurObj(props.oid);
        ev.stopPropagation();
    };

    function mouseEnter(e) {
        //console.log("mouse enter input output", idRef.current.style)
        idRef.current.style.backgroundColor = "#FAFAFA";
        titleRef.current.style.backgroundColor = "#FAFAFA";
        iconRef.current.style.backgroundColor = "#FAFAFA";
    }

    function mouseLeave(e) {
        //console.log("mouse leave")
        idRef.current.style.backgroundColor = "";
        titleRef.current.style.backgroundColor = "";
        iconRef.current.style.backgroundColor = "";
    }

    function mouseDown(e) {
        //console.log("mouse down");
        idRef.current.style.backgroundColor = "#AAAAAA";
        titleRef.current.style.backgroundColor = "#AAAAAA";
        iconRef.current.style.backgroundColor = "#AAAAAA";
    }

    function mouseUp(e) {
        //console.log("mouse up");
    }

    if (ident === "object_view_btn_acp") return null; // 2053

    return (<>
        {/*<div className="ObjListItem" key={props.i}>*/}
        <div className="ObjClass" ref={iconRef}><div className={objClass} key={"C" + props.i} onClick={onClick} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onMouseDown={mouseDown}>{cls}</div></div>
        <div className="ObjIdent" key={"I" + props.i} onClick={onClick} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onMouseDown={mouseDown} ref={idRef}>{ident}</div>
        <div className="ObjTitle" key={"T" + props.i} onClick={onClick} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onMouseDown={mouseDown} ref={titleRef}>{title}</div>
        {/*</div>*/}
    </>
    );
}

// LDC 8/27/2020 ER 205
function ObjectListView(props) {
    if (!props.oids || !Array.isArray(props.oids)) return null;

    return (
        <div className="ObjList">
            {props.oids.map((oid, i) => <ObjectListItemView oid={oid} i={i} key={i} setCurObj={props.setCurObj} />)}
        </div>
    );
}

function SyntaxColoredValue({ exprText, oid, att })
{
    const colorsByChar = useQuery({ req: "colorize", txt: exprText, oid: oid, att: att });
    const sysColors = useQuery({ req: "eval", expr: "CopyIndex(Sys_SyntaxColors)" });

    if (!colorsByChar || !sysColors)
        return <Lineify text={exprText} />;

    return (<Lineify text={exprText} colorIndex={colorsByChar} colorTable={sysColors} />);
}

// LDC 6/7/2021 Bug S-498

function AttValTable({ oid,openEditTable })
{
    const [inds,] = useObjAtt(oid, "_TableIndexTitles");    

    function JumpToEditTable(event)
    {
        event.stopPropagation();
        event.preventDefault();
        openEditTable(oid);
    }

    return (<div className="TableButtonHolder"><button className="EditTableButton" onClick={JumpToEditTable}>Table</button>
        {Array.isArray(inds) && <span className="IndexedBy">indexed by {inds.join(",")}</span>}
    </div>);
}

function ObjectViewDefn({ value, oid, openEditTable })
{
    const [defType,] = useObjAtt(oid, "DefinitionType");

    switch (defType) {
        case "Hidden":
            return <Lineify text="[Definition is hidden]" />;

        case "Table":
        case "SubTable":
            return (<AttValTable oid={oid} openEditTable={openEditTable} />);

        case "Choice":
        case "MultiChoice":
        case "Checkbox":
        case "List":
        case "ListOfText":
            // ** TO DO ** 
            break;

        default:
            break;
    }

    if (typeof (value) === "string")
        return <SyntaxColoredValue exprText={value} oid={oid} att="Definition" />;

    return (<>{JSON.stringify(value)}</>);
}

function ObjectViewValue(props)
{
    const [value,] = useObjAtt(props.nodeId, props.att);

    if (props.att === "Inputs" || props.att === "Outputs")
        return <ObjectListView oids={value} {...props} />;

    if (props.att.toLowerCase() === "definition") {           // LDC 6/7/2021 Bug S-498
        return <ObjectViewDefn value={value} oid={props.nodeId} openEditTable={props.openEditTable} />;
    }

    if (typeof (value) === "string") {
        const bExprAtt = "!definition!onclick!onchange!tablecelldefault!cellFormatExpression!att_cellformat!onGraphDraw!onGraphClick!graphTitleExpr!optinitialguess!".indexOf(props.att.toLowerCase()) >= 0;
        if (bExprAtt)
            return <SyntaxColoredValue exprText={value} oid={props.nodeId} att={props.att} />;

        return <Lineify text={value} />;
    }

    return (<>{JSON.stringify(value)}</>);
}

function ObjectViewAttHolder(props) {
    const attOid = props.attOid;
    const [attTitle, attIdent] = useObjAtts(attOid, ["_title", "identifier"]);
    if (!attIdent) return null;

    if (attTitle === "Attribute" || attTitle === "Object") // 1002
        return "";

    return (
        <>
            <div className="AttName" key={attOid} id={"AttL_" + attIdent}>{attTitle}</div>
            <div className="AttValue" key={"V" + attOid} id={"Att_" + attIdent}>
                <ObjectViewValue att={attIdent} oid={props.oid} setCurObj={props.setCurObj} nodeId={props.nodeId} openEditTable={props.openEditTable} />
            </div>
        </>);
}

export function ObjectView(props) {
    //console.log("ObjectView", props);
    const [origOid] = useObjAtt(props.oid, 'original');

    //console.log("formnodeSize", props.formnodeSize);

    // for tall nodes, set width and height
    let width, height;
    if (props.isTallNode && props.formnodeSize) {
        const splitRes = props?.formnodeSize?.split(","); // props?.formnodeSize ? props.formnodeSize.split(",") : [100, 100];
        width = parseInt(splitRes[0]) * 2; // -16 padding?
        height = parseInt(splitRes[1]) * 2;
    }

    let nodeId = props.oid;
    if (origOid !== undefined)
        nodeId = origOid;

    return <ObjectView2 {...props} nodeId={nodeId} width={width} height={height}/>
}

function ObjectView2(props) {
    //console.log("ObjectView2", props)
    const [atts,] = useObjAtt(props.nodeId, "_ShowAskAttribute");
    const objectViewRef = useRef();
    const [topObjectView, setTopObjectView] = useState(10);
    const textNodeInfo = useEval("nodeinfo Text");
    const frameNodeInfo = useEval("nodeinfo FrameNode");

    useLayoutEffect(() => {
        if (objectViewRef && objectViewRef.current) {               // LDC 4/6/2021 S-704
            const curRect = objectViewRef.current.getBoundingClientRect();
            if (topObjectView !== curRect.top) setTopObjectView(curRect.top);
        }
    })

    if (!Array.isArray(atts)) return "";

    let style = props.isFrameNode || props.isTallNode ? {} : { maxHeight: "calc(100vh - " + (topObjectView + 40) + "px)"};
    if (props.isTallNode) {
        style.width = props.width;
        style.height = props.maxHeight;
    }

    if (props.isFrameNode) {
        let objMargin = props.nodeClass0 === "FrameNode" ? getTableOrGraphMargin(frameNodeInfo) : getTableOrGraphMargin(textNodeInfo);
        style.margin = objMargin;
        style.maxHeight = "calc(100% - " + (2 * objMargin) + "px)";
    }

    return (
        <div className="ObjectView" ref={objectViewRef} style={style}>
            {props.mouseEntered && <HoverIcons objectContentOid={props.nodeId} frameNodeOid={props.frameNodeOid} objectViewHoverIconClick={props.objectViewHoverIconClick} isTallNode={props.isTallNode} isEditTable={props.isEditTable} />}
            {atts.map((attOid, i) => (<ObjectViewAttHolder key={attOid} attOid={attOid} {...props}  />))}
        </div>
    );
}

function HoverIcons(props) {
    //console.log("Object Hover Icons", props);
    const [frameNodeId,] = useObjAtt(props?.frameNodeOid, "identifier");
    const [objContentId,] = useObjAtt(props?.objectContentOid, "identifier");
    const objViewBtnOid = useEval("HandleFromIdentifier('object_view_btn_acp')")?.oid; // object_view_btn_acp resides in orphans at this point 
    const [onClickObjViewBtnNode, setOnClick] = useObjAtt(objViewBtnOid, "OnClick");
    const [frameNodeShowingAtt, setFrameNodeShowing] = useObjAtt(props?.frameNodeOid, "Att_FrameNodeShowing");
    const [embeddedContentAtt, setEmbeddedContent] = useObjAtt(props?.frameNodeOid, "_embeddedContent");

    function onClick(e, action) {
        console.log("on click hover icon object view", action, props)
        e.preventDefault();
        e.stopPropagation();

        if (props.isTallNode) {
            console.log("tall node hover icon clicked");
            props.objectViewHoverIconClick(action);
        }

        //const newVal = { object: { oid: props?.frameNodeOid }, view: 'MIDM', graph: 1, computed: null };
        //console.log("new val", newVal);
        //setEmbeddedContent(newVal);
        //setFrameNodeShowing(newVal;

        // create a button in orphans for showing object window in frame nodes (hackish, I hope to find a better way)
        let objViewBtnCmd = 'local objViewBtn:= HandleFromIdentifier("object_view_btn_acp");';
        objViewBtnCmd += 'if (isNull(objViewBtn)) then(';
        objViewBtnCmd += 'objViewBtn := CreateNewObject(Button, orphans, "object view button", "object_view_btn_acp");';
        objViewBtnCmd += 'onclick of objViewBtn:= "msgbox(4+4)")';
        WebSocket_Send({ fn: 'Exec', cmd: objViewBtnCmd });

        if (props.isEditTable) {
            //setOnClick("Att_FrameNodeShowing of " + frameNodeId + ":= Array( Sys_FrameNodeShowIdx,[ handle(" + objContentId + "), 'DFNM',0 ])");
            const onClickCmd = 'onclick of object_view_btn_acp:=Att_FrameNodeShowing of ' + frameNodeId + ':= Array( Sys_FrameNodeShowIdx,[ handle(' + objContentId + '), \'DFNM\',0 ])';
            WebSocket_Send({ fn: 'Exec', cmd: onClickCmd });
            WebSocket_Send({ fn: 'DoOnClick', oid: objViewBtnOid, x: 10, y: 10 });
            return;
        }

        const graphOrTable = action === "ShowGraph" ? "1" : "0";
        let onClickCmd = "onclick of object_view_btn_acp:=Att_FrameNodeShowing of " + frameNodeId + ":= Array( Sys_FrameNodeShowIdx,[ handle(" + objContentId + "), 'MIDM', " + graphOrTable + ", Null ])";
        WebSocket_Send({ fn: 'Exec', cmd: onClickCmd });
        WebSocket_Send({ fn: 'DoOnClick', oid: objViewBtnOid, x: 10, y: 10 });
    }

    return (
        <div className="ObjHoverIcons">
            <img className="HoverButton ShowTableBtn" src="img/toggleToTableButton.png" onClick={(e) => onClick(e, "ShowTable")} />
            {!props.isEditTable && <img className="HoverButton ShowGraphBtn" src="img/toggleToGraphButton.png" onClick={(e) => onClick(e, "ShowGraph")} />}
        </div>
    );
}
