// diagram.js

import React from 'react';
import { useState, useEffect, useLayoutEffect } from 'react';
import { Node } from './node.js';
import { useObjAtt, useObjAtts, rawSetObjAtts, useTopLevelOid, useEval, useCloudStyles } from './serverState.js';
import { WebSocket_Send } from './webSocketClient.js';
import { HierarchyStripe } from './Hierarchy stripe.js';
import './styles/diagram.scss';
import { useLayoutRect } from 'W:/ui/src/miscTools.js';

function SVGDashFor(arrow)
{
    let dash;
    switch (arrow.dash) {
        case "Dash": dash = [3, 1]; break;
        case "Dot": dash = [1]; break;
        case "DashDot": dash = [3, 1, 1, 1]; break;
        case "DashDashDot": dash = [3, 1, 3, 1, 1, 1]; break;
        case "DashDotDot": dash = [3, 1, 1, 1, 1, 1]; break;
        default:
            return null; // includes "Solid"
    }
    for (let i = 0; i < dash.length; ++i)
        dash[i] *= arrow.thickness;

    return dash.join(" ");
}
function HeadMarkerFor(arrow)
{
    switch (arrow.headCap) {
        case "Arrow":   return "url(#arrowHead)";
        case "Diamond": return "url(#diamondHead)";
        case "Round":   return "url(#roundHead)";
        case "Square":  return "url(#squareHead)";
        case "None":    return null;
        default:
            return "url(#arrowHead)";
    }
}
function TailMarkerFor(arrow) {
    switch (arrow.tailCap) {
        case "Arrow": return "url(#arrowTail)";
        case "Diamond": return "url(#diamondTail)";
        case "Round": return "url(#roundTail)";
        case "Square": return "url(#squareTail)";
        default:
            return null;
    }
}

function CardinalSplineCurve(pts)
{
    // Returns an SVG path for a cardinal spline through the pts. Does not include the starting point
    const n = pts.length;
    let L = new Array(n);       // Left control point for ith pt
    let R = new Array(n);       // Right control point

    const add = (p1, p2) => [p1[0] + p2[0], p1[1] + p2[1]];
    const sub = (p1, p2) => [p1[0] - p2[0], p1[1] - p2[1]];
    const multc = (p, c) => [p[0] * c, p[1] * c];

    const c = 1.0/6;

    // Interior pts control points
    for (let i = 0; i < n; ++i ) {
        const tribase = sub(pts[Math.min(i + 1,n-1)], pts[Math.max(i - 1,0)]);
        const v = multc(tribase, c);
        L[i] = sub(pts[i], v);
        R[i] = add(pts[i], v);
    }
    var path = "C" + R[0] + ' ' + L[1] + ' ' + pts[1];
    for (let i = 2; i < n; ++i) {
        path += " S" + L[i] + ' ' + pts[i];
    }
    return path;
}

function InfluenceArrows(props)
{ 
    const navTabsFirstLevel = props.navTabsFirstLevel;
    const oid = props.module_oid;
    const [arrows0,] = useObjAtt(oid, "_arrows");
    const colorOfArrowClass = useEval("NodeColor of ArrowClass");
    
    if (!Array.isArray(arrows0)) return null;

    //EW 794
    function filterArrows(arrow) {  
        if (navTabsFirstLevel && (navTabsFirstLevel.includes(arrow.head) || navTabsFirstLevel.includes(arrow.tail)))
            return false
        return true;
    }
    const arrows = navTabsFirstLevel && props.module_oid === navTabsFirstLevel[0] ?  arrows0.filter(filterArrows) : arrows0;

    function drawArrow(arrow)
    {
        //console.log("draw arrow", arrow)
        const key = arrow.head.toString() + "_" + arrow.tail.toString();
        const pts = arrow.pts;
        const pt1 = pts[0];
        const pt2 = pts[1];
        const W = arrow.thickness;
        let color = arrow.color;

        if (arrow.color === 'rgba(0,0,0,1)' && colorOfArrowClass !== undefined)
            color = colorOfArrowClass.replace("0xff", "#"); // EW 1998 - not perfect, that would require suan.exe fix, good enough for now

        if (arrow.head===0 && arrow.tail!==0) {
            return (
                <line key={key} x1={pt2[0]} y1={pt2[1]} x2={pt2[0]} y2={pt2[1]} stroke="#000" strokeWidth={W} markerEnd="url(#remoteHead)" />
            );
        } else if (arrow.head!==0 && arrow.tail===0) {
            return (
                <line key={key} x1={pt1[0]} y1={pt1[1]} x2={pt1[0]} y2={pt1[1]} stroke="#000" strokeWidth={W} markerEnd="url(#remoteHead)" />
            );
        }

        var path = "M" + pts[0].toString();
        if (arrow.curved) {
            path += ' ' + CardinalSplineCurve(pts);
        } else {
            for (var i = 1; i < pts.length; ++i) {
                path += " L";
                path += " " + pts[i].toString();
            }
        }

        const headCap = HeadMarkerFor(arrow);// "url(#arrowHead)";
        const tailCap = TailMarkerFor(arrow);
        const filter = arrow.double ? "url(#doubleLine)" : null;

        return (
            //<line key={key} x1={pt1[0]} y1={pt1[1]} x2={pt2[0]} y2={pt2[1]} stroke="#000" strokeWidth={1} markerEnd="url(#arrowHead)" />
            <path key={key} d={path} stroke={color} strokeWidth={W} markerStart={tailCap} markerEnd={headCap} fill="transparent"
                strokeDasharray={SVGDashFor(arrow)} filter={filter} />
        );
    }

    // I can't figure out with CSS how to get the <svg> element to adopt the full size of the diagram content, rather than just
    // the initally visible scroll area. Hence, I need to set the height and width directly.
    let width = 0, height = 0;
    arrows.forEach(function (arrow) {
        const arrowHeadSize = 3 + 3*arrow.thickness;
        for (var i = 0; i < arrow.pts.length; ++i) {
            width  = Math.max(width,  arrow.pts[i][0]+1+arrowHeadSize);
            height = Math.max(height, arrow.pts[i][1]+1+arrowHeadSize);
        }
    });
    const svgStyle = { width: width, height: height };      // LDC 1/18/2023 Bug S-1532.

    return (
        <svg id="arrowLayer" style={svgStyle}>
            <defs>
                <marker id="arrowHead" markerWidth={10} markerHeight={10} refX={10} refY={4} orient="auto" markerUnits="strokeWidth" fill="context-stroke">
                    <path d="M0,0 L0,8 L10,4 z" />
                </marker>
                <marker id="arrowTail" markerWidth={10} markerHeight={10} refX={0} refY={4} orient="auto" markerUnits="strokeWidth" fill="context-stroke">
                    <path d="M0,0 L0,8 L10,4 z" />
                </marker>
                <marker id="remoteHead" markerWidth={6} markerHeight={6} refX={6} refY={3} orient="auto" markerUnits="strokeWidth" fill="context-stroke">
                    <path d="M0,0 L0,6 L6,3 z" />
                </marker>
                <marker id="diamondHead" markerWidth={6} markerHeight={6} refX={6} refY={3} orient="auto" markerUnits="strokeWidth" fill="context-stroke">
                    <path d="M0,3 L3,6 L6,3 L3,0 z" />
                </marker>
                <marker id="diamondTail" markerWidth={6} markerHeight={6} refX={0} refY={3} orient="auto" markerUnits="strokeWidth" fill="context-stroke">
                    <path d="M0,3 L3,6 L6,3 L3,0 z" />
                </marker>
                <marker id="squareHead" markerWidth={6} markerHeight={6} refX={6} refY={3} orient="auto" markerUnits="strokeWidth" fill="context-stroke">
                    <rect x={0} y={0} width={6} height={6} fill="context-fill" />
                </marker>
                <marker id="squareTail" markerWidth={6} markerHeight={6} refX={0} refY={3} orient="auto" markerUnits="strokeWidth" fill="context-stroke">
                    <rect x={0} y={0} width={6} height={6} />
                </marker>
                <marker id="roundHead" markerWidth={6} markerHeight={6} refX={6} refY={3} orient="auto" markerUnits="strokeWidth" fill="context-stroke">
                    <circle cx={3} cy={3} r={3} />
                </marker>
                <marker id="roundTail" markerWidth={6} markerHeight={6} refX={0} refY={3} orient="auto" markerUnits="strokeWidth" fill="context-stroke">
                    <circle cx={3} cy={3} r={3} />
                </marker>
                <filter id="doubleLine">
                    <feMorphology in="SourceGraphic" result="a" operator="dilate" radius="1" />
                    <feComposite in="SourceGraphic" in2="a" result="xx" operator="xor" />
                </filter>
            </defs>
            {arrows.map(drawArrow)}
        </svg>
    );
}



function RubberBand(selectNodes, svg)
{
	const [anchor,setAnchor] = useState(null);
	const [cur,setCur] = useState(null);
	let currentlyBandedIds = new Set();

	function pt(event) {
        // Good ref or positions: https://www.jacklmoore.com/notes/mouse-position/
        const r = event.currentTarget.getBoundingClientRect();
		return { x: event.clientX - r.left,
				 y:event.clientY - r.top };
	}
	function onDown(event:SyntheticEvent) {
		let p = pt(event);
		if (!isNaN(p.x) && !isNaN(p.y)) {
			if (!event.shiftKey)
				selectNodes(currentlyBandedIds,false);
			setAnchor( p );
			currentlyBandedIds = {};
		}
	}
	function onUp(event:SyntheticEvent) {
		if (anchor != null) {
			selectNodes(currentlyBandedIds,true);
			setAnchor( null );
			setCur(null);
		}
	}
	function onMove(event:SyntheticEvent) {
		if (anchor==null) return;
		let p = pt(event);
		if (!isNaN(p.x) && !isNaN(p.y))
			setCur(p);
	}
	function isIn(rect) {		// is rect contained in selection?
		if (anchor==null || cur==null) return false;
		if (rect.x < Math.min(anchor.x,cur.x)) return false;
		if (rect.y < Math.min(anchor.y,cur.y)) return false;
		if (rect.x+rect.width  >= Math.max(anchor.x,cur.x)) return false;
		if (rect.y+rect.height >= Math.max(anchor.y,cur.y)) return false;
		return true;
	}

	let bActive = (anchor!=null && cur!=null);
	let res = { js:{onMouseDown:onDown, onMouseUp:onUp, onMouseMove:onMove}, rendered:"", isIn:isIn, currentlyBandedIds:currentlyBandedIds, bActive:bActive };

	if (!bActive)
		return res;

	let rect = { left:Math.min(anchor.x,cur.x), top:Math.min(anchor.y,cur.y), width:Math.abs(cur.x - anchor.x), height:Math.abs(cur.y - anchor.y) };
	res.rendered = (<div className="RubberBandSelector" style={rect} />);

	return res;
}

function AcpDiagramTitleStripe(props)
{
    const [title,] = useObjAtt(props.id, "_title");
    //const oidForW = props.uiStyle === "ACP1" ? props.topOid : props.id;
    //const [width,] = useObjAtt(oidForW,"diagWidth");

    let className = "DiagramTitle";

    if (props.cloudStyles.show_tabs !== "no" && props.cloudStyles.show_toobar !== "no") {
        //style.width = "2500px"; // = { width: "2500px", height: "2000px", backgroundColor: bgColor };}
        className += " NoTopSideTabs";
    }
    else {

    }

    return (<div className={className}>{title}</div>);
    //return (<div className="DiagramTitle" style={{ width: width }}>{title} moduleId={props.id} oidForW={oidForW}</div>);
}

//=================================================================================================

function BevelDef(props)
{
    return (
        <svg id="svg_bevel_def" width={0} height={0}>
            <defs>
                <filter id="innerbevel" x0="-50%" y0="-50%" width="200%" height="200%">
                    <feGaussianBlur in="SourceAlpha" stdDeviation="2" result="blur" />
                    <feOffset dy="-2" dx="-2" />
                    <feComposite in2="SourceAlpha" operator="arithmetic"
                        k2="-2" k3="2" result="hlDiff" />
                    <feFlood floodColor="black" floodOpacity=".6" />
                    <feComposite in2="hlDiff" operator="in" />
                    <feComposite in2="SourceGraphic" operator="over" result="withGlow" />

                    <feOffset in="blur" dy="3" dx="3" />
                    <feComposite in2="SourceAlpha" operator="arithmetic"
                        k2="-2" k3="2" result="shadowDiff" />
                    <feFlood floodColor="white" floodOpacity=".3" />
                    <feComposite in2="shadowDiff" operator="in" />
                    <feComposite in2="withGlow" operator="over" />
                </filter>
            </defs>
        </svg>
    );
}

//function ActiveFrameNodeIdents(cloudStyles)
//{
//    if (!cloudStyles) return null;
//    const nodes = cloudStyles.framenode;
//    if (!nodes) return null;
//    return nodes.toLowerCase().split('&');
//}

// Returns a list of frame nodes in a module.
function frameNodeExpr(moduleIdent)
{
    if (!moduleIdent) return null;

    // LDC 11/11/2020 ER 367. Added class FrameNode objects
    // LDC 3/11/2021 Bug S663 tweak
    const expr = String.raw`LocalAlias m := Handle(«m»);
        if IsNull(contains of m) then null else
	        #SetIntersection([
	            \contains of m,
		        SetUnion([
		            \HandleFromIdentifier(TextTrim(Split(FindInText("FrameNode[ \t]*:[\t ]*([a-z0-9_\t &]+)",
		                CloudPlayerStyles of m, re: 1,
		                caseInsensitive: true, return: "S", subpattern: 1), "&"))),
			        FindObjects(class:FrameNode) ]) ])`;

    return expr.replace(/«m»/g, moduleIdent);
}

export function isTopSideTabNav(navStyle) {
    if (navStyle?.includes("_tabs"))
        return true;
    else
        return false;
}

function GetZoom(props, diagramRect) {
    //console.log("GetZoom()", props.moduleIdentifier);
    const navStyle = props?.cloudStyles?.navigation_style === undefined ? "" : props?.cloudStyles?.navigation_style;
    let getDiagWidthAndHeight = "local module_id:= handle(" + props.moduleIdentifier + ");\n";
    getDiagWidthAndHeight += "local is_model_id:= if ((isin of mid(module_id)) = null) then true else false;\n";
    getDiagWidthAndHeight += 'local navigation_style:= "' + navStyle + '";\n';
    getDiagWidthAndHeight += 'local Has_top_or_side_tabs:= navigation_style = "top_tabs" or navigation_style = "side_tabs";\n';
    getDiagWidthAndHeight += 'local hide_modules:= is_model_id and Has_top_or_side_tabs;\n';
    getDiagWidthAndHeight += 'local nodes_on_diagram:= contains of mid(module_id);\n';
    getDiagWidthAndHeight += 'local class_of_nodes:= class of mid(nodes_on_diagram);\n';
    getDiagWidthAndHeight += 'nodes_on_diagram := subset(nodes_on_diagram <> HandleFromIdentifier("acp_styles"));\n';
    getDiagWidthAndHeight += "local is_node_a_module:= class_of_nodes <> 'Module' and class_of_nodes <> 'Library';\n";
    getDiagWidthAndHeight += "local nodes_to_display_1:= if (hide_modules) then subset(is_node_a_module) else nodes_on_diagram;";
    getDiagWidthAndHeight += "local visibility_of_nodes:= ChangeNodeVisibility(mid(nodes_to_display_1), 'Is visible?');";
    getDiagWidthAndHeight += "local nodes_to_display:= subset(visibility_of_nodes);";
    getDiagWidthAndHeight += 'localindex resInd:= [1, 2, 3];\n ';
    getDiagWidthAndHeight += 'local nodeSizes:= parsenumber(SplitText(array(nodes_to_display, nodesize of mid(nodes_to_display)), ",", resultindex: resInd));\n';
    getDiagWidthAndHeight += 'local nodeLocs:= parsenumber(SplitText(array(nodes_to_display, NodeLocation of mid(nodes_to_display)), ",", resultindex: resInd));\n';
    getDiagWidthAndHeight += 'local diagSizeNeeded := max(nodeSizes + nodeLocs, nodes_to_display);'
    getDiagWidthAndHeight += 'diagSizeNeeded[.resInd = 1] & "|" & diagSizeNeeded[.resInd = 2]';
    let spaceNeededToShowAllNodes = useEval("try(" + getDiagWidthAndHeight + ", -1)"); // 1423 - add Try/catch to suppress unimportant error message
    spaceNeededToShowAllNodes = spaceNeededToShowAllNodes === -1 ? undefined : spaceNeededToShowAllNodes; // 1423
    const widthToShowAllNodes = Number(spaceNeededToShowAllNodes?.split("|")[0]);
    const heightToShowAllNodes = Number(spaceNeededToShowAllNodes?.split("|")[1]);
    const zoomWidth = Math.ceil(diagramRect?.width / (widthToShowAllNodes + 11) * 1000) / 1000;
    const zoomHeight = Math.ceil(diagramRect?.height / (heightToShowAllNodes + 11) * 1000) / 1000;

    return [zoomWidth, zoomHeight];
}

function getFlexBoxesExpr(diagId, diagStyles) {

    /*
     
    local nodes := contains of diag;
    localindex nodesindex := contains of diag;
    local nodeclass := class of nodes;
    local nodesByClass = array(nodesindex, nodeclass);
    local isFrameNode = nodesByClass = "FrameNode";
    local cps := AcpStyles of nodes;
    local cpsNodes := if (array(nodesindex, cps) = null) then "" else array(nodesindex, cps);
    local flexBoxItemNo = FindInText("flex_box_item: *no", cpsNodes, caseInsensitive: true, re: true);
    local flexBoxItemYes = FindInText("flex_box_item: *yes", cpsNodes, caseInsensitive: true, re: true);

    {find tall nodes }
    local isFormNode:= nodesByClass = "FormNode";
    local nodeSz:= nodesize of nodes;
    localindex widthHeight:= ["width", "height"];
    local nodeHeightTall:= ParseNumber(splittext(nodeSz, ",", resultindex: widthHeight)[@widthHeight = 2] ) > 70;
    local nodesTall:= array(nodesindex, nodeHeightTall);
    local isTallFormNode:= nodesTall and isFormNode;

    { and the result }
    localindex flexBoxes:= subset((isTallFormNode and not flexBoxItemNo) or flexBoxItemYes or(isFrameNode and not flexBoxItemNo));

    [flexBoxes, NodeLocation of mid(flexBoxes)]

    */

    if (diagStyles?.display != "flex") return "";

    let res = "local nodes:= contains of " + diagId + ";\n";
    res += "localindex nodesindex:= contains of " + diagId + ";\n";
    res += "local nodeclass:= class of nodes;\n";
    res += "local nodesByClass = array(nodesindex, nodeclass);\n";
    res += 'local isFrameNode = nodesByClass = "FrameNode";\n';
    res += "local cps:= AcpStyles of nodes;\n";
    res += 'local cpsNodes:= if (array(nodesindex, cps) = null) then "" else array(nodesindex, cps);\n';
    res += 'local flexBoxItemNo = FindInText("flex_box_item: *no", cpsNodes, caseInsensitive: true, re: true);\n';
    res += 'local flexBoxItemYes = FindInText("flex_box_item: *yes", cpsNodes, caseInsensitive: true, re: true);\n';

    /* find tall nodes */
    res += 'local isFormNode:= nodesByClass = "FormNode";\n';
    res += "local nodeSz:= nodesize of nodes;\n";
    res += 'localindex widthHeight:= ["width", "height"];\n';
    res += 'local nodeHeightTall:= ParseNumber(splittext(nodeSz, ",", resultindex: widthHeight)[@widthHeight = 2] ) > 70;\n';
    res += "local nodesTall:= array(nodesindex, nodeHeightTall);\n";
    res += "local isTallFormNode:= nodesTall and isFormNode;\n";

    /* and the result */
    res += "localindex flexBoxes:= subset((isTallFormNode and not flexBoxItemNo) or flexBoxItemYes or (isFrameNode and not flexBoxItemNo));\n";
    res += "[flexBoxes, NodeLocation of mid(flexBoxes)]";

    return res;
}

function sortFlexBoxes(flexBoxes) {
    //console.log("sort flex boxes(), unsorted", flexBoxes)
    if (flexBoxes?.length != 2) return null;
    //console.log("before sort", flexBoxes)
    const flexBoxOids = flexBoxes[0];
    const flexBoxLocs = flexBoxes[1];
    for (let i = 0; i < flexBoxLocs.length; i++) {
        for (let j = 0; j < flexBoxLocs.length - i - 1; j++) {
            let [x1, y1] = flexBoxLocs[j].split(',').map(Number);
            let [x2, y2] = flexBoxLocs[j + 1].split(',').map(Number);
            if (y1 > y2) {
                //console.log("y1 > y2 swap", y1, y2)
                const tempLoc = flexBoxLocs[j];
                flexBoxLocs[j] = flexBoxLocs[j + 1];
                flexBoxLocs[j + 1] = tempLoc;
                const tempOid = flexBoxOids[j];
                flexBoxOids[j] = flexBoxOids[j + 1];
                flexBoxOids[j + 1] = tempOid;
            }
            else if (y1 === y2) {
                //console.log("Ys are equal, check Xs")
                if (x1 > x2) {
                    //console.log("x1 > x2, swap");
                    const tempLoc = flexBoxLocs[j];
                    flexBoxLocs[j] = flexBoxLocs[j + 1];
                    flexBoxLocs[j + 1] = tempLoc;


                    const tempOid = flexBoxOids[j];
                    flexBoxOids[j] = flexBoxOids[j + 1];
                    flexBoxOids[j + 1] = tempOid;
                }
            }
        }
    }

    //console.log("sorted flex boxes", flexBoxOids, flexBoxLocs);

    return flexBoxOids.map(x => x.oid);
}

export function Diagram(props) {
    //console.log("Diagram", props)

    const [moduleIdentifier,] = useObjAtt(props.id, "identifier");
    const [fileInfoAtt,] = useObjAtt(props?.topOid, "FileInfo");
    const cloudStylesDiag = useCloudStyles(props.id);

    useEffect(() => {
        if (props.modelFilePath !== fileInfoAtt && fileInfoAtt !== undefined) 
            props.setModelFilePath(fileInfoAtt);
    }, [fileInfoAtt, props.modelFilePath]);

    if (moduleIdentifier === undefined) return "";

    const flexBoxesExpr = getFlexBoxesExpr(moduleIdentifier, cloudStylesDiag);

    return <Diagram0 {...props} moduleIdentifier={moduleIdentifier} flexBoxesExpr={flexBoxesExpr}/>
}

function Diagram0(props) {
    //console.log("Diagram0", props)
    const moduleOid = props.id;
    const [contains0, bgColor, moduleIdent/*,fontStyle*/] = useObjAtts(moduleOid, ["contains", "diagramColor", "identifier"/*,"_fontStyle"*/]);
    const cloudStylesDiag = useCloudStyles(moduleOid);
    const [diagSize,] = useObjAtt(props.uiStyle === "ACP1" ? props.topOid : moduleOid, "_diagSize");
    const [selection0, setSelection0] = useState([]);
    const [hoveringOid, setHovering] = useState(0);         // 0=which node's hover icons are showing. Only one at a time.
    const [hoverTimer, setHoverTimer] = useState(null);
    const topOid = useTopLevelOid();
    const frameNodes = useEval(frameNodeExpr(moduleIdent));
    const [nextFrameNode, setNextFrameNode] = useState(0);      // 0-based position, indicates which frame node (if there are any) will recieve the result
    const [frameNodeContents, setFrameNodeContents] = useState(null);
    const [curDiagFrameContents, setCurDiagFrameContents] = useState(props.curDiag);
    const [diagramRef, diagramRect] = useLayoutRect();
    const [proactiveEvalModule,] = useObjAtt(moduleOid, "ProactivelyEvaluate");
    const [helpBalloonTime, setHelpBalloonTime] = useState(null); // time last balloon was shown, 1341
    const [zoom, setZoom] = useState(1);
    const flexBoxes = useEval(props.flexBoxesExpr);
    const flexBoxesSorted = sortFlexBoxes(flexBoxes);
    const [zoomWidth, zoomHeight] = GetZoom(props, diagramRect);

    //console.log("curDiagFrameContents", curDiagFrameContents);
    if (props.curDiag !== curDiagFrameContents) {
        if (frameNodeContents) setFrameNodeContents(null);
        setCurDiagFrameContents(props.curDiag);
    }

    const selection = props.selection && props.setSelection ? props.selection : selection0;
    const setSelection = props.selection && props.setSelection ? props.setSelection : setSelection0;
    const contains = !Array.isArray(contains0) ? [] : (Array.isArray(props.nodesToHide) && props.nodesToHide.length > 0) ? contains0.filter(o => (props.nodesToHide.indexOf(o) === -1)) : contains0;
    if (frameNodes && !frameNodes.map)
        console.log(frameNodes);                // Debugging. There is an intermitent javascript crash here
    const frameNodeOids = frameNodes && frameNodes.map((o) => o.oid);
    const nFrameNodes = frameNodeOids ? frameNodeOids.length : 0;
    const setFrameOid = props.setFrameOid;

    useEffect(() => {
        if (nFrameNodes !== undefined && nFrameNodes > 0) {
            // for showWindow callbacks
            setFrameOid(frameNodeOids[nextFrameNode]);
        } else if (nFrameNodes !== undefined && nFrameNodes === 0)
            setFrameOid(0);
    }, [setFrameOid, nFrameNodes, frameNodeOids, nextFrameNode]);

    useLayoutEffect(() => {
        // props.cloudStyles is top level diagram cloud styles
        if (props?.cloudStyles?.auto_zoom_diagrams !== "yes"
            || cloudStylesDiag?.display === "flex"
            || document?.acpServerConfig?.autoZoomDiagramsEnabled === false
        )
            return;
        let zoomLevel = zoomWidth < zoomHeight ? zoomWidth : zoomHeight;
        zoomLevel = zoomLevel > 2 ? 2 : zoomLevel;
        zoomLevel = zoomLevel < .5 ? .5 : zoomLevel;
        //console.log("zoom", zoom)
        if (zoom !== zoomLevel) setZoom(zoomLevel);
        if (props.diagramZoom !== zoomLevel) {
            if (zoomLevel <= 1)
                props.setDiagramZoom(zoomLevel);
            else
                props.setDiagramZoom(1);
        }
    });

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const chromeMatch = userAgent.match(/Chrome\/(\d+)/);
        if (chromeMatch && chromeMatch.length > 1 && props?.cloudStyles?.auto_zoom_diagrams === "yes") {
            const chromeVer = parseInt(chromeMatch[1])
            if (chromeVer < 128 && props.showUpdateBrowserMsg) {
                props.setMsgBoxInfoClient({ body: 'This model uses zooming features that will not work well in this version of Chromium, please update your browser.', buttons: 0, caption: "Upgrade browser", responseHandler: null });
                props.setShowUpdateBrowser(false);
            }
        }
    }, [cloudStylesDiag])

    function selectNodes(idSet, bAdd) {
        let newSel = Array.from(idSet);
        if (newSel.length === 0) {
            // perhaps deselection by clicking diagram background
            props.setCurObj(props.curDiag);
        }
        if (bAdd) {
            let others = selection.filter((el) => !(el in idSet));
            newSel = newSel.concat(others);
        }
        setSelection(newSel);
    }

    function selectNode(id, orig, nodeClass, bMightHaveResult, bAdd) {
        if (id === 0)
            setSelection([]);
        else if (bAdd) {
            let others = selection.filter((el) => (el !== id));
            if (selection.indexOf(id) !== -1)
                setSelection(others)
            else
                setSelection([id].concat(others));
        } else {
            setSelection([id]);
            if (props.nodeClick) {
                let frameNodeOid = bMightHaveResult && nFrameNodes > 0 ?
                    (nextFrameNode < nFrameNodes ? frameNodeOids[nextFrameNode] : frameNodeOids[0])
                    : undefined;
                if (nFrameNodes > 1)
                    setNextFrameNode((nextFrameNode + 1) % nFrameNodes);
                props.nodeClick(id, orig, nodeClass, bMightHaveResult, frameNodeOid);
            } else if (props.setCurObj) {
                props.setCurObj(id);
            }
        }

    }
    function selectState(id) {
        let pos = selection.indexOf(id);
        return pos === 0 ? 1 : pos === -1 ? 0 : 2;
    }

    const rubber = RubberBand(selectNodes);

    // LDC 8/11/2021 S-933
    const bUseSavedSize = props.cloudStyles.use_top_diagram_size === "yes";
    const rhsMargin = 8; 
    //const szStyle = bUseSavedSize ? { width: diagSize?.width, height: diagSize?.height } :
    //    diagramRect ? { width: "calc(100vw - " + (diagramRect.left + rhsMargin) + "px)", height:"calc(100vh - " + (diagramRect.top+48) + "px)" }
    //        : {};

    const szStyle = bUseSavedSize ? { width: diagSize?.width, height: diagSize?.height } :
        diagramRect ? { width: "auto", height: "calc(100vh - " + (diagramRect.top + 48) + "px)" }
            : {};

    const styleDiag = { ...szStyle, backgroundColor: bgColor, ...props.fontStyle, boxSizing: "border-box" };


    const setDiagTabColor = props.setDiagTabColor;

    // ** TO DO ** -- tabColor should be calculated in advance. No reason it should be set in a useEffect().
    useEffect(() => {
        // diagram tab should match background unless the background is white
        if (bgColor !== "rgb(255,255,255)")
            setDiagTabColor(bgColor);
        else
            setDiagTabColor("#E5E5E5");
    }, [setDiagTabColor,bgColor])

    

    function onDragOver(event) {
        event.preventDefault();
    }
    function onDrop(event) {
        console.log("Diagram.drop( )");
        event.preventDefault();
        let nodeId = parseInt(event.dataTransfer.getData("nodeId"));
        let offsetX = parseInt(event.dataTransfer.getData("offsetX"));
        let offsetY = parseInt(event.dataTransfer.getData("offsetY"));
        let nodeX = parseInt(event.dataTransfer.getData("nodeX"));
        let nodeY = parseInt(event.dataTransfer.getData("nodeY"));
        if (isNaN(nodeId) || isNaN(offsetX) || isNaN(offsetY) || isNaN(nodeX) || isNaN(nodeY)) return;
        const r = event.currentTarget.getBoundingClientRect();
        let dropX = event.clientX - r.left, dropY = event.clientY - r.top;

        rawSetObjAtts(nodeId, { isIn:moduleOid, nodeX: dropX /*- offsetX*/, nodeY: dropY /*- offsetY*/ });
    }

    function setPendingReqToFrameNode(req)
    {
        if (!nFrameNodes)
            return props.setPendingCalcReq(req);

        const frameNodeOid = nextFrameNode < nFrameNodes ? frameNodeOids[nextFrameNode] : frameNodeOids[0];
        if (nFrameNodes > 1)
            setNextFrameNode((nextFrameNode + 1) % nFrameNodes);
        // ** TO DO ** -- logic off slightly. If this request returns a scalar, we shouldn't increment nextFrameNode.
        //             -- The increment should be done after the result is received.
        return props.setPendingCalcReq({ ...req, frameNode: frameNodeOid });
    }
    function IsFrameNode(oid)
    {
        return nFrameNodes > 0 && frameNodeOids.includes(oid);
    }
    function OpenEditTableToFrameNode(oid)
    {
        console.log("OpenEditTableToFrameNode()")
        if (!nFrameNodes)
            return props.openEditTable(oid);

        const frameNodeOid = nextFrameNode < nFrameNodes ? frameNodeOids[nextFrameNode] : frameNodeOids[0];

        WebSocket_Send({ fn: 'SetFrameNodeSrc', frameNode: frameNodeOid, srcOid: oid, view: "edit" });

        if(nFrameNodes > 1)
            setNextFrameNode((nextFrameNode + 1) % nFrameNodes);

    }
    function getHoverHighlightColor() {
        if (bgColor !== undefined) {
            const bgColorsArray = bgColor.split("(")[1].split(")")[0].split(",");
            let allNumbersSame = false;
            if (bgColorsArray[0] === bgColorsArray[1] && bgColorsArray[1] === bgColorsArray[2])
                allNumbersSame = true;
            if (allNumbersSame && parseInt(bgColorsArray[0]) < 184) //184 is B8 in hex
            {
                return "white"; //"rgb(255,255,255)"; // white
            }

            if (!allNumbersSame) {
                const avgColor = (parseInt(bgColorsArray[0]) + parseInt(bgColorsArray[1]) + parseInt(bgColorsArray[2]))/3;
                if (avgColor < 131)
                    return "white"; //"rgb(255,255,255)"; // white
            }
        }
        return "black"; //"rgb(0,0,0)"; // becomes gray when transparency is added
    }
    

    const hoverHighlightColor = getHoverHighlightColor();

    const openEditTable = nFrameNodes > 0 ? OpenEditTableToFrameNode : props.openEditTable;

    // Properties that are passed on to all nodes
    //const constNodeProps = { selectNode: selectNode, rubberBand: rubber, openDiagram: props.openDiagram, openResult:props.openResult, openEditTable:props.openEditTable };
    const constNodeProps = { selectNode: selectNode, rubberBand: rubber, setHovering:setHovering, setHoverTimer:setHoverTimer, hoverTimer:hoverTimer, setPendingCalc:props.setPendingCalc,
                            ...props /*openDiagram: props.openDiagram, openResult: props.openResult, openEditTable: props.openEditTable*/,
                            openEditTable: openEditTable, hoverHighlightColor: hoverHighlightColor,
        setPendingCalcReq: nFrameNodes ? setPendingReqToFrameNode : props.setPendingCalcReq, zoom: zoom, frameNodeContents, setFrameNodeContents
                            };
    const cloudStyles = props.cloudStyles;
    const bDropShadow = cloudStyles && cloudStyles.node_drop_shadow === "yes";
    let cls = "Diagram" + (props.browseOnly ? " BrowseOnly" : "") + (moduleOid === topOid ? " TopLevelDiagram" : "") + (bDropShadow?" DropShadows":"");

    if ((!!props.showHier && props.showHier2) === false && props.cloudStyles.navigation_style === "top_diagram_only")
        cls += " TopDiagOnlyNoHH";

    

    if (props.cloudStyles.show_tabs !== "no" && props.cloudStyles.show_toobar !== "no")
        cls += " NoTopSideTabs";

    if (props.cloudStyles !== undefined && (props.cloudStyles.navigation_style === "side_tabs" || props.cloudStyles.navigation_style === "two_side_tabs")) {
        cls += " SideTabs";
        if (!!props.showHier && props.showHier2)
            cls += " ShowHier";
    }

    

    if (props.moduleIdentifier === "Launch_eVite") // 1720 - don't show hand pointer
        cls = cls.replace("BrowseOnly", "");

    //const zoomDivStyle = props?.cloudStyles?.display === "flex" ? { zoom: zoom, display: "flex", flexWrap: "wrap"} : {zoom : zoom}



    const nodes = cloudStylesDiag?.display === "flex" ? flexBoxesSorted : contains;

    if (!nodes) return null;

    const flex = cloudStylesDiag?.display;
    let zoomDivCls = "";
    if (flex === "flex") {
        //console.log("setting flex css styles", props?.cloudStyles)
        const flex_direction = cloudStylesDiag.flex_direction;

        zoomDivCls = cloudStylesDiag.display === "flex" ? "ZoomDiv Flex" : "ZoomDiv";
        zoomDivCls = cloudStylesDiag.flex_wrap === "no_wrap" ? zoomDivCls + " NoWrap" : zoomDivCls + " Wrap";
        zoomDivCls = flex_direction === "column" ? zoomDivCls + " FlexDirectionColumn" : zoomDivCls + " FlexDirectionRow";

        const flex_h_align = cloudStylesDiag?.flex_h_align;
        zoomDivCls = flex_h_align === "center" ? zoomDivCls + " FlexHAlignCenter" : zoomDivCls;
        zoomDivCls = flex_h_align === "left" ? zoomDivCls + " FlexHAlignLeft" : zoomDivCls;
        zoomDivCls = flex_h_align === "right" ? zoomDivCls + " FlexHAlignRight" : zoomDivCls;

        const flex_v_align = cloudStylesDiag?.flex_v_align;
        zoomDivCls = flex_v_align === "center" ? zoomDivCls + " FlexVAlignCenter" : zoomDivCls;
        zoomDivCls = flex_v_align === "bottom" ? zoomDivCls + " FlexVAlignBottom" : zoomDivCls;
    }

    //console.log("zoom div cls", zoomDivCls )

    return (
        <>
            {(!!props.showHier && props.showHier2) && <HierarchyStripe moduleId={moduleOid} bgColor={bgColor} onSelect={props.onSelect} cloudStyles={cloudStyles} navTabsFirstLevel={props.navTabsFirstLevel} navTabsSecondLevel={props.navTabsSecondLevel} width={szStyle?.width}/>}
            {props.showDiagramTitle && (<AcpDiagramTitleStripe {...props}/>)}
            <div ref={diagramRef} className={cls} module_oid={moduleOid} style={styleDiag} {...rubber.js} onDragOver={onDragOver} onDrop={onDrop} >
                <div className={zoomDivCls} style={{ zoom: zoom }}>
                <BevelDef />
                    {cloudStylesDiag?.display !== "flex" && <InfluenceArrows module_oid={moduleOid} navTabsFirstLevel={props.navTabsFirstLevel} />}
                    {nodes.map((oid) => <Node key={oid} oid={oid} hovering={hoveringOid === oid} selectState={selectState(oid)} isFrameNode={IsFrameNode(oid)} {...constNodeProps} proactiveEvalModule={proactiveEvalModule} setHelpBalloonTime={setHelpBalloonTime} helpBalloonTime={helpBalloonTime} setForceShowCube={props.setForceShowCube} bForceShowCube={props.bForceShowCube} cloudStylesDiag={cloudStylesDiag}/>)}
                    {!props.browseOnly && rubber.rendered}
                </div>
            </div>
        </>
    );
}

// A diagram that appears inside a frame node, or otherwise embedded.
export function EmbeddedDiagram({ id })
{
    const [frameOid, setFrameOid] = useState(0);
    const cloudStyles = useCloudStyles(id);
    const [, setCurObj] = useState(id);
    if (cloudStyles === undefined) return null;

    return (
        <div className="EmbeddedDiagram">
            <Diagram id={id} cloudStyles={cloudStyles} showHier={false} onSelect={() => null} frameOid={frameOid} setFrameOid={setFrameOid}
                setDiagTabColor={() => null} setCurObj={setCurObj} />
        </div>
    );
}
