import React from 'react';
import { useObjAtt } from './serverState.js';

export function TitleBarBase({ caption, ico, closer })
{
    if (caption.startsWith("Edit List of Text")) closer = false; // 1498

    return (
        <header className="TitleBar" draggable="true" >
            <img src={ico} className="WindowTypeIcon" alt="" />
            {caption}
            {/*closer ? (<div className="CloseWindowIcon" onClick={closer}>x</div>) : ""*/}
        </header>
    );
}

export function TitleBar(props)
{
    const [title,] = useObjAtt(props.oid, "title");
    const [ident,] = useObjAtt(props.oid, "identifier");

    let titleBarText = props.title ? props.title : props.type;
    if (props.oid) {
        titleBarText += " - " + (title ? title : ident);
    }

    return <TitleBarBase caption={titleBarText} ico={props.ico} closer={props.closer} />;
}
