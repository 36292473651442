import React, { useEffect, useLayoutEffect } from 'react';
import { useObjAtt, useObjAtts, useCloudStyles, useEval } from './serverState.js';
import { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { InputNodeControl, OutputNodeControl /*, SICN*/ } from './formNodes.js';
import { WebSocket_Send } from './webSocketClient.js';
import { FrameNodeContent } from './frameNode.js';
import { Lineify, useLayoutRect } from './miscTools.js';
import { ParentsPopupMenu, KidsPopupMenu } from './parentKidPopups.js';
import { HelpBalloon } from './helpBalloon.js';
import { IncReqCalc } from './formNodes.js';

import 'animate.css';

function IsModuleClass(className)
{
    return className === "Module" || className === "LinkModule" || className === "Library" || className === "LinkLibrary" || className === "Form" || className === "Model";
}
function ClassHasResult(className)
{
    return className === "Decision" || className === "Variable" || className === "Chance" || className === "Determ" || className === "Constant" || className === "Constraint" || className === "GradientClass" || className === "Index" || className === "Objective" || className === "sysVar";
}

function makeCrack(x,w,y,h)
{
    let crack = [];
    const xm = x + w / 2;
    const nZags = 3, crackTilt = w / 21, zag = 6;
    for (let i = 0; i < nZags; ++i) {
        const xi = xm + (i % 2 ? -zag : +zag) - crackTilt * (nZags - i) / nZags;
        const yi = y + i * h / nZags;
        crack.push([xi, yi]);
    }
    for (let i = nZags ; i >= 0; --i) {
        const xi = xm + (i % 2 ? -zag : +zag) + crackTilt * (nZags - i) / nZags;
        const yi = y + i * h / nZags;
        crack.push([xi, yi]);
    }
    return crack;
}
function ptsToLine(pts, bFirstIsMove)
{
    let res = "";
    let ch = bFirstIsMove ? 'M' : 'L', sp = '';
    for (let i = 0; i < pts.length; ++i) {
        const [xi, yi] = pts[i];
        res += `${sp}${ch}${xi},${yi}`;
        ch = 'L';
        sp = ' ';
    }
    return res;
}

function roundedRectPath(rect,r)
{
	const x = rect.x, y=rect.y, w=rect.width, h=rect.height;
    if (2 * r > h) r = h / 2;
    if (2 * r > w) r = w / 2;
	return `M${x+r},${y} L${x+w-r},${y} a${r},${r},${90},0,1,${r},${r} L${x+w},${y+h-r} a${r},${r},${90},0,1,${-r},${r} L${x+r},${y+h} a${r},${r},${90},0,1,${-r},${-r} L${x},${y+r} a${r},${r},${90},0,1,${r},${-r}`;
}
function objectivePath(rect)
{
	const x = rect.x, y=rect.y, w=rect.width, h=rect.height, c=10;
	const r=x+w, b=y+h, m=y+h/2;
	return `M${x+c},${y} L${r-c},${y} L${x+w},${m} L${r-c},${b} L${x+c},${b} L${x},${m} L${x+c},${y}`;
}
function functionShapePath(rect) {
    const x = rect.x, y = rect.y, w = rect.width, h = rect.height, c = 10;
    const r = x + w, b = y + h, m = y + h / 2;
    return `M${x},${y} L${r - c},${y} L${x + w},${m} L${r - c},${b} L${x},${b} L${x+c},${m} L${x},${y}`;
}
function crackedLibraryShapePath(rect,ignore,bSkipCrack=false) {
    const x = rect.x, y = rect.y, w = rect.width, h = rect.height, c = 10;
    const r = x + w, b = y + h, m = y + h / 2;
    const pts = makeCrack(x, w, y, h);
    const n = pts.length - 1;
    const crackShape = bSkipCrack ? `L${pts[0][0]},${pts[0][1]} M${pts[n][0]},${pts[n][1]}` : ptsToLine(pts, false);

    return `M${x},${y} ${crackShape} L${r - c},${y} L${x + w},${m} L${r - c},${b} L${x},${b} L${x + c},${m} L${x},${y}`;
}
function crackedRoundedRectPath(rect,r,bSkipCrack=false)
{
    const x = rect.x, y = rect.y, w = rect.width, h = rect.height;
    if (2 * r > h) r = h / 2;
    if (2 * r > w) r = w / 2;
    const pts = makeCrack(x, w, y, h);
    const n = pts.length - 1;
    const crackShape = bSkipCrack ? `L${pts[0][0]},${pts[0][1]} M${pts[n][0]},${pts[n][1]}` : ptsToLine(pts, false);
    return `M${x + r},${y} ${crackShape} L${x + w - r},${y} a${r},${r},${90},0,1,${r},${r} L${x + w},${y + h - r} a${r},${r},${90},0,1,${-r},${r} L${x + r},${y + h} a${r},${r},${90},0,1,${-r},${-r} L${x},${y + r} a${r},${r},${90},0,1,${r},${-r}`;
}
function crackOnlyPath(rect)
{
    const x = rect.x, y = rect.y, w = rect.width, h = rect.height;
    return ptsToLine(makeCrack(x, w, y, h), true);
}

function parallelogramPath(rect,hshift)
{
    const x = rect.x, y = rect.y, w = rect.width, h = rect.height;
    return `M${x + hshift},${y} L${x + w},${y} L${x + w - hshift},${y + h} L${x},${y+h} L${x + hshift},${y}`;
}
function hourglassPath(rect,dx)
{
    const x = rect.x, y = rect.y, w = rect.width, h = rect.height;
    const r = x + w, b = y + h, m = y + h / 2;
    return `M${x},${y} L${r},${y} L${r - dx},${m} L${r},${b} L${x},${b} L${x + dx},${m} L${x},${y}`;
}
function trapezoidPath(rect,dx)
{
    const x = rect.x, y = rect.y, w = rect.width, h = rect.height;
    const r = x + w, b = y + h;
    return `M${x + dx},${y} L${r - dx},${y} L${r},${b} L${x},${b} L${x + dx},${y}`;
}

function CrackedModulePaths({bLibrary,rect,style})
{
    const fillStyle = { ...style, stroke: "None", filter:undefined };
    const borderStyle = { ...style, fill: "None" };
    const crackStyle = { ...borderStyle, filter: undefined };
    const r = 18;
    const fn = bLibrary ? crackedLibraryShapePath : crackedRoundedRectPath;

    return (
        <>
            <path className="shapeFill" d={fn(rect, r, false)} style={fillStyle} />
            <path className="shapeBorder" d={fn(rect, r, true)} style={borderStyle} />
            <path className="shapeCrack" d={crackOnlyPath(rect)} style={crackStyle} />
        </>
    );
}

function NodeShape0(nodeClass,rect,fillColor,borderColor,nodeInfo,cloudStyles)
{
    let style = {};
    style.fill = (fillColor !== undefined) ? fillColor : 'White';
    let cornerR = 0;

    if (nodeInfo!==undefined && nodeInfo !== null) {
        if (nodeInfo.border!==undefined) 
            style.stroke= nodeInfo.border ? (borderColor?borderColor:"Black") : "None";        
        if (nodeInfo.fill===0) 
            style.fill = 'None';
        if (nodeInfo.bevel === 1 || (cloudStyles && cloudStyles.bevel_node_border==="yes"))
            style.filter = "url(#innerbevel)";
        if (nodeInfo.cornerR) cornerR = nodeInfo.cornerR;
    }

	switch (nodeClass) {
	case 'Decision':
	case 'Variable':
    case 'Module':
    case 'LinkModule':
    case 'Form':
    case 'Model':
        return cornerR === 0 ? (<rect className="shape" {...rect} style={style}/>) : 
                            (<path className="shape" d={roundedRectPath(rect, cornerR)} style={style} />);
    case 'Chance':
        return (<ellipse className="shape" cx={rect.x + rect.width / 2} cy={rect.y + rect.height / 2} rx={rect.width / 2} ry={rect.height / 2} style={style} />);
    case 'Determ':
        return (<><ellipse className="shape" cx={rect.x + rect.width / 2} cy={rect.y + rect.height / 2} rx={rect.width / 2} ry={rect.height / 2} style={style} />
                <ellipse className="shape" cx={rect.x + rect.width / 2} cy={rect.y + rect.height / 2} rx={rect.width / 2 - 3} ry={rect.height / 2 - 3} style={style} />
            </>);
	case 'Objective':
        return (<path className="shape" d={objectivePath(rect)} style={style} />);
    case 'Library':
    case 'LinkLibrary':
        return (<path className="shape" d={functionShapePath(rect)} style={style} />);
    case 'MissingLinkLibrary':              // LDC 9/4/2020 ER 19147, see Suan 220
        return <CrackedModulePaths bLibrary={true} rect={rect} style={style} />;
    case 'MissingLinkModule':               // LDC 9/4/2020 ER 19147, see Suan 220
        return <CrackedModulePaths bLibrary={false} rect={rect} style={style} />;
    case 'Function':
        return (<path className="shape" d={functionShapePath(rect)} style={style} />);
    case 'Index':
        return (<path className="shape" d={parallelogramPath(rect, 8)} style={style} />);
    case 'Constraint':
        return (<path className="shape" d={hourglassPath(rect, 8)} style={style} />);
    case 'Constant':
        return (<path className="shape" d={trapezoidPath(rect, 8)} style={style} />);
    case 'GradientClass':
        return (<path className="shape" d={trapezoidPath(rect, 8)} style={style} />);
    case 'Picture':
        return null; //(<rect className="shape" {...rect} style={style} />);
	default:
        return cornerR === 0 ? (<rect className="shape" {...rect} style={style} />) :
                (<path className="shape" d={roundedRectPath(rect, cornerR)} style={style} />);
	}
}
export function NodeShape(nodeClass,size,fillColor,borderColor,nodeInfo,cloudStyles)
{
    // EW 631 - temp fix until DTA catches up
    if ((nodeClass === "FrameNode" || nodeClass === "Frame")  && borderColor === "rgb(170,170,170)") borderColor = undefined;		

    // LDC 8/12/2020 Bug 148 - added {...size}
    // LDC 8/17/2020 Bug 164 - needed to enlarge for module nodes to allow for wider border. 2px in all directions.
    const sz = ['Module', 'Library', 'Form', 'LinkModule', 'LinkLibrary', 'MissingLinkLibrary', 'MissingLinkModule'].indexOf(nodeClass) < 0 ? size : { width: size.width + 4, height: size.height + 4 };

    return (<svg style={sz}>{NodeShape0(nodeClass, { x: 0, y: 0, ...size }, fillColor, borderColor, nodeInfo, cloudStyles)}</svg>);
}

function SelectionHandle(x,y,selState) 
{
	if (selState===0) return null;
	const prefix = " SelectionHandle";
	const vPos = prefix + ((y===0)?"Top":"Bottom");
	const hPos = prefix + ((x===0)?"Left":"Right");
//	const s=3;
	const cls = selState===1 ? "SelectionHandle SelectionHandlePrimary" : "SelectionHandle";
	
//	const rect = {x:x-s,y:y-s,width:2*s+1,height:2*s+1};
	return (<div className={cls+hPos+vPos} />);
}

function HoverIcon( props ) // imageSrc, name, action )
{
	const cls = 'HoverIcon Hover'+props.name+" animated fadeIn";

	return (<img className={cls} src={props.imageSrc} style={props.vis} onClick={props.action} alt=""/>);
}

function Units(props)
{
    const [units,] = useObjAtt(props.oid, "units");
    const [result,] = useObjAtt(props.oid, "_curResultState");

    if (props.nodeClass !== "InputNode" && props.nodeClass !== "OutputNode")
        return null;
    if (units === undefined || units === "")
        return null;

    let unitsStyle = {};
    if (props.multilineInput)
        unitsStyle.top = "12px";
    
	/* 880 - Lonnie doesn't like 593, wants it implemented differently
	    // 593 - no units for array inputs/outputs
	    if (props.nodeClass === "OutputNode" && result !== undefined && result.computed === 0) return "";
	    if (props.nodeClass === "OutputNode" && result !== undefined && result.type === "array") return "";
	    if (props.nodeClass === "InputNode" && (props.defType === "Table" || props.defType === "List")) return "";
	    */

    return (
        <div className="UnitsHolder">
            <div className="Units" style={unitsStyle}>
                <span className="UnitsText" style={props.fontStyle}>({units})</span>
            </div>
        </div>
    );
}

function Acp1HoverRect(props) {
    let bShow = props.mouseIsOver && props.cloudStyles.show_hover_highlight !== "no";
    if (props.selState === 1) bShow = true;      // Use hoverhighlight to indicate selection, per max
    const glow = props.cloudStyles.glow_hover_highlight === "yes";
    let opacity = 1;
    if (props.hoverHighlightColor === "black")  opacity = props.selState === 1 ? .5 : .2;
    else /* white */ opacity = props.selState === 1 ? .7 : .3;

    const hoverRectStyle = { display: bShow ? "block" : "none", color: props.glowColor, backgroundColor: glow ? props.glowColor : props.hoverHighlightColor, opacity: opacity };
    const cls = ((props.nodeClass === "OutputNode" || props.nodeClass === "InputNode") && (props.nodeInfo && props.nodeInfo.fill === 0)) ? "HoverRect FormNode HoverRect_" : "HoverRect HoverRect_";
    const cls2 = bShow ? glow ? "glow" : "outline" : "none";

    return (
        <div className={cls + cls2} style={hoverRectStyle} />
    );
}

function ControlOnStyle(nodeInfo,nodeClass, bTall)
{
    // LDC 10/22/2020 Suan ER 308
    if (nodeClass !== "OutputNode" && nodeClass !== "InputNode") return "";
    if (!nodeInfo) return "";

    let ctrlOn = " CtrlOnRight";

    if (nodeInfo.controlOn === "left") {
        ctrlOn = " CtrlOnLeft"; 
    } else if (nodeInfo.controlOn === "center" && bTall && nodeInfo.labelVertAlign !== "middle") {
        ctrlOn = " CtrlOnCenter";
    }

    let vertLabel = "", ctrlLabel = "";

    if (bTall) {
        const bCtrlMiddle = (nodeInfo.ctrlVertAlign === "middle" || nodeInfo.labelVertAlign === "middle");
        vertLabel = nodeInfo.labelVertAlign !== "middle" ?" VertTop" : " VertMiddle";
        ctrlLabel = bCtrlMiddle ? " CtrlMiddle" : " CtrlTop";
    }

    let alignText = " LabelLeft";
    if (nodeInfo.alignText === "right") alignText = " LabelRight";
    else if (nodeInfo.alignText === "center") alignText = " LabelCenter";

    return ctrlOn + alignText + vertLabel + ctrlLabel;
}

function getFlexBoxNodes(id, cls, props) {
    //console.log("getFlexBoxNodes", id, props?.cloudStylesDiag)
    
    if (props?.cloudStylesDiag?.display !== "flex") return "";
    if (props?.isIsFlexBox) return ""; 
    if (cls !== "Text") return "";

    //console.log("Getting flex box kids!!")

    /*
     * (node: handle)
     * 
    local nodeLoc := NodeLocation of node;
    local nodeSz := NodeSize of node;
    local nodeIsin := isin of node;
    local nodeId := Identifier of node;
    local nodesInDiag := Contains of nodeIsIn;
    localindex nodesIndex := contains of nodeIsin;
    local nodeLocs := Array(nodesIndex, NodeLocation of nodesInDiag);
    local nodeIds := Array(nodesIndex, Identifier of nodesInDiag);
    localindex locIndex := ["x", "y", "z"];
    localindex sizeIndex := ["width", "height"];
    local nodeLocArray := Parsenumber( SplitText(nodeLoc, ",", resultindex: locIndex) );
    local nodeSzArray := Parsenumber( SplitText(nodeSz, ",", resultindex: sizeIndex) );
    local left := nodeLocArray[locIndex="x"] - nodeSzArray[sizeIndex = "width"];
    local right := nodeLocArray[locIndex="x"] + nodeSzArray[sizeIndex = "width"];
    local top := nodeLocArray[locIndex="y"] - nodeSzArray[sizeIndex = "height"];
    local bottom := nodeLocArray[locIndex="y"] + nodeSzArray[sizeIndex = "height"];
    local nodesLocArray := Parsenumber( SplitText(nodeLocs, ",", resultindex: locIndex) );
    subset( nodesLocArray[locIndex = "x"] >= left and nodesLocArray[locIndex = "x"] <= right and nodesLocArray[locIndex = "y"] >= top and nodesLocArray[locIndex = "y"] <= bottom and nodeId <> nodeIds ) >= top and nodesLocArray[locIndex = "y"] <= bottom )
    */
   

    let getFlexBoxesExpr = 'local nodeLoc:= NodeLocation of ' + id + ';\n';
    getFlexBoxesExpr += 'local nodeSz:= NodeSize of ' + id +';\n';
    getFlexBoxesExpr += 'local nodeIsin:= isin of ' + id + ';\n';
    getFlexBoxesExpr += 'local nodeId:= Identifier of ' + id + ';\n';
    getFlexBoxesExpr += 'local nodesInDiag:= Contains of nodeIsIn;\n';
    getFlexBoxesExpr += 'localindex nodesIndex:= contains of nodeIsin;\n';
    getFlexBoxesExpr += 'local nodeLocs:= Array(nodesIndex, NodeLocation of nodesInDiag);\n';
    getFlexBoxesExpr += 'local nodeIds := Array(nodesIndex, Identifier of nodesInDiag);\n';
    getFlexBoxesExpr += 'localindex locIndex:= ["x", "y", "z"];\n';
    getFlexBoxesExpr += 'localindex sizeIndex:= ["width", "height"];\n';
    getFlexBoxesExpr += 'local nodeLocArray:= Parsenumber(SplitText(nodeLoc, ",", resultindex: locIndex));\n';
    getFlexBoxesExpr += 'local nodeSzArray:= Parsenumber(SplitText(nodeSz, ",", resultindex: sizeIndex));\n';
    getFlexBoxesExpr += 'local left:= nodeLocArray[locIndex = "x"] - nodeSzArray[sizeIndex = "width"];\n';
    getFlexBoxesExpr += 'local right:= nodeLocArray[locIndex = "x"] + nodeSzArray[sizeIndex = "width"];\n';
    getFlexBoxesExpr += 'local top:= nodeLocArray[locIndex = "y"] - nodeSzArray[sizeIndex = "height"];\n';
    getFlexBoxesExpr += 'local bottom:= nodeLocArray[locIndex = "y"] + nodeSzArray[sizeIndex = "height"];\n';
    getFlexBoxesExpr += 'local nodesLocArray:= Parsenumber(SplitText(nodeLocs, ",", resultindex: locIndex));\n';
    getFlexBoxesExpr += 'subset( nodesLocArray[locIndex = "x"] >= left and nodesLocArray[locIndex = "x"] <= right and nodesLocArray[locIndex = "y"] >= top and nodesLocArray[locIndex = "y"] <= bottom and nodeId <> nodeIds )'

    //console.log("getFlexBoxesExpr", getFlexBoxesExpr);

    return getFlexBoxesExpr;
}

export function Node(props) {
    //console.log("Node", props)

    const [nodeIdentifier,] = useObjAtt(props.oid, "identifier");
    const [nodeClass,] = useObjAtt(props.oid, "class");


    if (nodeIdentifier === undefined || nodeClass === undefined) return "";

    const getFlexBoxNodesExpr = getFlexBoxNodes(nodeIdentifier, nodeClass, props);
    //console.log("Node", nodeIdentifier, nodeClass, props);

    return <Node0 {...props} getFlexBoxNodesExpr={getFlexBoxNodesExpr}/>
}

function Node0(props) {
    //console.log("Node", props)
    const oid = props.oid;
    const [nodeClass0, bAlias, x, w, y, h, identifier, orig0, fillColor, borderColor] = useObjAtts(oid, ['Class', 'isAlias', 'nodeX', 'nodeWidth', 'nodeY', 'nodeHeight', 'identifier', 'original', 'nodeColor', 'nodeBorderColor']);
    const [fontColor, pict, nodeInfo, bHasOnClick, visibility, defType] = useObjAtts(oid, ['nodeFontColor', 'pict', 'nodeInfo', '_hasOnClick', '_visibility', 'definitionType']);
    let [nodeFontStyle,] = useObjAtt(oid, "_nodeFont");
    if (nodeFontStyle?.fontFamily === 'Century Gothic') {
        nodeFontStyle.fontFamily = "'Century Gothic', 'Avant Garde', Arial, sans-serif"
    }

    const [bShowSICN, bMightHaveResult, bIsTallNode, resultState] = useObjAtts(oid, ['_showSICN', '_mightHaveResult', '_isTallNode', '_curResultState']);
    const [zIndex, embeddedContent, bScalarSubTable] = /*[undefined,undefined]; */ useObjAtts(oid, ['_nodeZindex', '_embeddedContent', '_IsSubTableCellInput']);
    //const bEmbeddedOutputNode = nodeClass0==="OutputNode" && embeddedContent?true:false;          // Suan ER 20, bug 29
    const [bAtomicSubTable, setBAtomicSubTable] = useState(null);
    const orig = (orig0 !== null && orig0 !== undefined /*&& !bEmbeddedOutputNode*/) ? orig0 : oid;
    const [title, setTitle] = useObjAtt(oid, '_title');
    const [mouseIsOver, setMouseIsOver] = useState(false);
    const [mouseEntered, setMouseEntered] = useState(false);
    const [helpBalloonTimer, setHelpBalloonTimer] = useState(0);
    const [bShowHelpBalloon, setShowHelpBalloon] = useState(false);
    const [hideHelpBalloonTimer, setHideHelpBalloonTimer] = useState(0);
    //const title = (title0 && nodeClass!=="Text") ? title0 : identifier;
    const [floatText, setFloatText] = useState(null);       // Text in the floating edit, or null indicated floating edit is not active.
    const [description0,] = useObjAtt(orig, "description");
    const [descriptionFrameNode, setDescriptionFrameNode] = useState("");
    const [multilineInput, setMultilineInput] = useState(false);
    const flexBoxItemChildren = useEval(props.getFlexBoxNodesExpr);
    //console.log("flexBoxItemChildren", flexBoxItemChildren);
    const cloudStyles = useCloudStyles(orig);
    // EW 27 start
    const [controlWidthNode,] = useObjAtt(props.oid, 'controlWidth');
    //const [units,] = useObjAtt(orig, "units");
    // EW 27 end
    const [bOrigMightHaveResult,] = useObjAtt(orig, '_mightHaveResult'); // EW 247 // LDC 3/26/2021 S-691
    const nodeRef = useRef();
    const contextMenuRef = useRef();
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuX, setContextMenuX] = useState(0);
    const [contextMenuY, setContextMenuY] = useState(0);
    const [idOrig,] = useObjAtt(orig, "identifier");
    const bDisabled = visibility === "Disabled" || (props.browseOnly && visibility === "Disabled in browse");
    const bHidden = (visibility !== "Visible" && (!props.browseOnly || visibility === "Hidden in browse")) || (cloudStyles && cloudStyles.show_object === "no");

    if (visibility === undefined || visibility === "Hidden") return null; // 1943
    if (bHidden) return null;
    if (nodeInfo !== undefined && nodeInfo.hidden) return null;

    const nodeClass = props.isFrameNode ? "Frame" : nodeClass0;

    const description = nodeClass === "Text" ? description0 : undefined;
    const bHasHoverRect = props.uiStyle === "ACP1" && nodeClass !== "Picture" && nodeClass !== "Text" && nodeClass !== "Frame" && bIsTallNode !== 1;        // LDC 7/17/2020 Suan bug 98 // LDC 3/11/2021 bug S663

    const editingTitle = floatText !== null;

    const l = x - w, t = y - h/*, r=x+w, b=y+h*/;
    const size = { width: 2 * w, height: 2 * h };
    const rect = { x: l, y: t, left: l, top: t, width: 2 * w, height: 2 * h };
    //if (nodeClass0 === "InputNode") console.log("*rect 0000", rect)
    if (props.flexBoxContainerRect) {
        //console.log("flexBoxContainerRect", props.flexBoxContainerRect);
        const cRect = props.flexBoxContainerRect;
        rect.x -= cRect.x;
        rect.left -= cRect.left;
        rect.y -= cRect.y;
        rect.top -= cRect.top;
    }
    //if (nodeClass0 === "InputNode") console.log("*rect 1111", rect)  

    const br = (<br />);
    let displayedTitle = nodeClass === "Text" ? description : title; //title.replace('\n', (<br/>));

    let titleStyle = nodeInfo !== undefined ? { textAlign: nodeInfo.alignText } : {};
    //if (multilineInput)
    //    titleStyle.marginTop = "12px";              // LDC 6/24/2021 Bug S-874. (marginTop gets counted as space used by enclosing grid. top does not).

    let selState = props.selectState;
    if (selState === 0) {
        if (props.rubberBand.isIn(rect)) {
            selState = 2;
            props.rubberBand.currentlyBandedIds.add(oid);
        } else {
            props.rubberBand.currentlyBandedIds.delete(oid);
        }
    }
    if (props.rubber && props.rubber.bActive) props.setHovering(0);


    if (selState !== undefined && selState !== 1 && floatText !== null) {
        // Use has clicked out of the floating edit.
        setTitle(floatText);
        setFloatText(null);
    }


    function onClick(event) {

        console.log("node onClick", defType, props);

        event.stopPropagation();
        event.preventDefault();

        if ((defType === "Table" || defType ==="SubTable") && nodeClass0 === "InputNode" && !bIsTallNode && props.openEditTable)
            props.openEditTable(oid);

        if (nodeClass0 === "OutputNode")
            props.setPendingCalcReq({ oid: orig, fromCalc: true, reqCount: 1000 });

        // a click on a typical text node should do nothing, EW 126
        if (bHasOnClick === 0 && (nodeClass === "Text" || nodeClass === "Frame")) return;

        if (props.browseOnly && (bHasOnClick || nodeClass === "Button" || nodeClass === "Picture")) {
            WebSocket_Send({ fn: 'DoOnClick', oid: oid, x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY });        // LDC 6/27/2023 ER 20804. Pass the alias's oid in the bAlias case
        } else {
            if (bAlias === 1) {
                props.selectNode(oid, orig, nodeClass, bOrigMightHaveResult, event.shiftKey);
            }
            else {
                props.selectNode(oid, orig, nodeClass, bMightHaveResult, event.shiftKey);
            }
        }
    }
    function startDrag(event) {
        if (props.browseOnly) return;
        console.log("start drag");
        let startX = event.clientX /*- event.currentTarget.offsetLeft*/, startY = event.clientY /*- event.currentTarget.offsetTop*/;
        props.selectNode(oid, orig, nodeClass, bMightHaveResult, false);
        event.dataTransfer.dropEffect = "move";
        event.dataTransfer.setData("nodeId", oid);
        event.dataTransfer.setData("offsetX", startX - x);
        event.dataTransfer.setData("offsetY", startY - y);
        event.dataTransfer.setData("nodeX", x);
        event.dataTransfer.setData("nodeY", y);
    }

    function hoverIcons() {
        if (!props.enableHoverIcons) return null;
        const vis = { display: props.hovering ? "block" : "none" };
        // Call all possible hoverIcon()s without any conditionality, for Hook's setState() requirement.
        return (<>
            {IsModuleClass(nodeClass) && (<HoverIcon imageSrc="img/HoverOpenModule.ico" name="OpenModule" vis={vis} action={(event) => props.openDiagram(orig)} />)}
            {ClassHasResult(nodeClass) && (<HoverIcon imageSrc="img/Result.ico" name="OpenResult" vis={vis} action={(event) => props.openResult(oid)} />)}
            {defType === "Table" && (<HoverIcon imageSrc="img/EditTable.ico" name="OpenEditTable" vis={vis} action={(event) => props.openEditTable(oid)} />)}
        </>);;
    }
    function onMouseOver(event) {
        //console.log("onMouseOver", props)
        //console.log("milli secs", Date.now() - props.helpBalloonTime);
        if (bIsTallNode === 1 && event !== "embedded node title") return;

        if (props.hoverTimer !== null && props.hovering) clearTimeout(props.hoverTimer);
        setMouseIsOver(true);
        props.setHovering(oid);

        if (helpBalloonTimer === 0) {
            // show help balloon after short delay
            let bTimeId = setTimeout(() => { console.log("calling displayHelp"); displayHelpBalloon() }, 1000);
            setHelpBalloonTimer(bTimeId);
        }

        if (hideHelpBalloonTimer !== 0) {
            clearTimeout(hideHelpBalloonTimer);
            setHideHelpBalloonTimer(0);
        }
    }

    function displayHelpBalloon(event) {
        //console.log("setDisplayHlepBalloon")
        setShowHelpBalloon(true);
    }
    function onMouseOut(event) {
        console.log("onMouseOut", props);
        if ((bIsTallNode === 1 || props.isFrameNode) && event !== "embedded node title") return;
        setHideHelpBalloonTimer(setTimeout(hideHelpBalloon, 300));

        if (helpBalloonTimer !== 0) {
            clearTimeout(helpBalloonTimer)
            setHelpBalloonTimer(0);
        }
        setMouseIsOver(false);

        props.setHelpBalloonTime(Date.now());
    }
    function hideHelpBalloon(event) {
        //console.log("**hideHelpBalloon");
        setShowHelpBalloon(false);
    }
    function onMouseEnter(event) {
        //console.log("onMouseEnter", props);

        setMouseEntered(true);

        if ((bIsTallNode === 1 || props.isFrameNode) && event !== "embedded node title") return;

        if (props.hoverTimer !== null && props.hovering) clearTimeout(props.hoverTimer);
        setMouseIsOver(true);
        props.setHovering(oid);

        const helpBalloonDelay = (Date.now() - props.helpBalloonTime) > 1000 ? 1000 : 250;

        if (helpBalloonTimer === 0) {
            // show help balloon after short delay
            let bTimeId = setTimeout(() => { displayHelpBalloon() }, helpBalloonDelay);
            setHelpBalloonTimer(bTimeId);
        }

        if (hideHelpBalloonTimer !== 0) {
            clearTimeout(hideHelpBalloonTimer);
            setHideHelpBalloonTimer(0);
        }
    }
    function onMouseLeave(event) {
        //console.log("onMouseLeave", event, event.target.toString());

        if (event?.relatedTarget?.className?.includes && event?.relatedTarget?.className.includes("SpinningCube")) // 1951 spinning cube causes unwanted mouse events
            return;

        setMouseEntered(false);

        if ((bIsTallNode === 1 || props.isFrameNode) && event !== "embedded node title") return;
        setHideHelpBalloonTimer(setTimeout(hideHelpBalloon, 300));

        if (helpBalloonTimer !== 0) {
            clearTimeout(helpBalloonTimer)
            setHelpBalloonTimer(0);
        }
        setMouseIsOver(false);

        props.setHelpBalloonTime(Date.now());

    }
    function onClickTitle(ev) {
        if (selState === 1 && !props.browseOnly)
            setFloatText(title);
    }
    function onFloatKey(ev) {
        if (ev.key === "Enter") {
            if (!ev.altKey && !ev.ctrlKey && floatText !== null) {
                setTitle(floatText);
                setFloatText(null);
            }
        }
    }
    function handleOnMouseDown(event) {
        //console.log("Node handleOnMouseDown")
        if (nodeClass !== "Text" && nodeClass !== "Frame" && nodeClass !== "InputNode" && nodeClass !== "OutputNode")  // EW 512 & 754 & 1053 - Close multichoice popup
            event.stopPropagation()  // stop prop to prevent diagram from unselecting
    }
    function getTitleStyle() {
        let titleStyle = { display: editingTitle ? "none" : "block" };          // LDC 12/1/2020 Bug 404
        if (bIsTallNode && nodeClass === "InputNode" && defType === "Table") titleStyle = { display: "none" };
        if (bIsTallNode && nodeClass === "OutputNode" && (resultState && resultState.computed) /*&& (embeddedContent && embeddedContent.graph !== 1)*/) titleStyle = { display: "none" };
        if (bIsTallNode && defType === "SubTable" && bAtomicSubTable === false) titleStyle = { display: "none" };
        return titleStyle;
    }

    const nodeAliasCls = bAlias ? " NodeAlias" : "";
    const clickableCls = !bDisabled && bHasOnClick && props.browseOnly ? " Clickable" : "";
    const disabledCls = bDisabled ? " Disabled" : "";
    let bShowLabel = nodeInfo === undefined || nodeInfo === null || !!nodeInfo.label;
    const titleTxtStyle = getTitleStyle();
    const titleClick = (selState === 1) ? { onClick: onClickTitle, ...titleTxtStyle } : {};
    const titleEditStyle = { display: editingTitle ? "block" : "none" };
    const displayedTitleClass0 = (bIsTallNode ? "titleText Tall" : "titleText") + (multilineInput ? " Multiline" : "");
    const displayedTitleClass = nodeClass === "Text" ? displayedTitleClass0 + " TextDescription" : displayedTitleClass0;
    //console.log("node class", nodeClass, nodeClass === "Text", displayedTitleClass);

    if (!bShowLabel) displayedTitle = "";  // EW 27 - still include titleText div when not showing label so control location is correct
    displayedTitle = (nodeClass === "OutputNode" || nodeClass === "InputNode") && displayedTitle !== undefined ? displayedTitle.replaceAll("\n", " ") : displayedTitle;
    displayedTitle = (<div className={displayedTitleClass} style={titleStyle}><Lineify text={displayedTitle} perLineStyle={nodeFontStyle} /></div>);
    const titleTxtHtml = (<span className="title" style={titleTxtStyle} {...titleClick}>{displayedTitle}</span>);
    const titleEditHtml = (<input className="floatEdit" value={floatText !== null ? floatText : ""} style={titleEditStyle} onChange={(ev) => setFloatText(ev.target.value)} onKeyDown={onFloatKey} />);
    const controlHolderCls = multilineInput ? "controlHolder withMultiline" : "controlHolder";
    let control = (<div className={controlHolderCls} />);
    if (nodeClass === "InputNode")
        control = (<InputNodeControl {...props} bTallNode={bIsTallNode} disabled={bDisabled} bShowLabel={bShowLabel} controlWidthNode={controlWidthNode} nodeHeight={h * 2} setMultilineInput={setMultilineInput} multilineInput={multilineInput} setShowHelpBalloon={setShowHelpBalloon} bShowHelpBalloon={bShowHelpBalloon} onMouseLeaveNode={onMouseLeave}
            bShowSICN={bShowSICN} cloudStylesOrig={cloudStyles} topCloudStyles={props.cloudStyles} setBAtomicSubTable={setBAtomicSubTable} bAtomicSubTable={bAtomicSubTable} onMouseOverNode={onMouseOver} onMouseOutNode={onMouseOut} mouseEntered={mouseEntered} onMouseOut={onMouseOut} onMouseOver={onMouseOver} />);
    else if (nodeClass === "OutputNode")
        control = (<OutputNodeControl {...props} bTallNode={bIsTallNode} controlWidthNode={controlWidthNode} disabled={bDisabled} bShowSICN={bShowSICN} nodeHeight={h * 2} cloudStylesOrig={cloudStyles} topCloudStyles={props.cloudStyles} bShowLabel={bShowLabel} onMouseOut={onMouseOut} onMouseOver={onMouseOver} mouseEntered={mouseEntered} onMouseOverNode={onMouseOver} onMouseOutNode={onMouseOut} />);

    //const sicn = bShowSICN ? (nodeClass === "OutputNode") ? (<SICN {...props} />) : (<div className="SICN" />) : "";    

    let textNodeTitleStyle = {};
    if (nodeFontStyle !== undefined && nodeFontStyle !== null) textNodeTitleStyle = { ...nodeFontStyle };
    if (nodeInfo !== undefined && nodeInfo !== null && nodeInfo.alignText !== undefined) textNodeTitleStyle.textAlign = nodeInfo.alignText;
    if (textNodeTitleStyle.fontSize !== undefined)
        textNodeTitleStyle.fontSize += 1;
    else if (props.fontStyle && props.fontStyle.fontSize)           // LDC 3/18/2021 Bug S672
        textNodeTitleStyle.fontSize = props.fontStyle.fontSize + 1; // props.fontStyle is diagram font style

    const textNodeTitle = (nodeClass === "Text" && title !== "" && title !== undefined) ? <div className="TextNodeTitle" style={textNodeTitleStyle}>{title}</div> : (<></>);

    if (x === undefined || y === undefined || w === undefined || h === undefined)
        return null;

    let style = rect;
    if (fontColor !== undefined) style.color = fontColor;
    const zz = typeof (zIndex) == "number" ? zIndex : (nodeClass === "Picture" || nodeClass === "Text" || nodeClass === "Frame") ? -1 : zIndex;
    if (typeof (zz) == "number") {
        // In the event of a tie in z-index, picture nodes should be under text nodes, and text nodes should be under all others.
        // Then in Oid order. To make room for these, multiply by 3.
        const nudge = nodeClass === "Picture" ? -2 : nodeClass === "Text" ? -1 : 0; // LDC 7/20/2020 Suan bug 91
        style.zIndex = 1000000 + 3 * (zz < 0 ? (zz - 1) : zz + 1) + nudge;      // Skip z=-1 for arrows    
    }
    const imgTag = pict !== undefined && pict !== null ? (<img className="nodePict" width={pict.width} height={pict.height} alt="" src={"data:" + pict.mime + ";base64," + pict.base64} />) : "";

    const bEmbedded = bIsTallNode && embeddedContent && (embeddedContent.computed || embeddedContent.view === "DFNM");
    const titleAndControlCls = "titleAndControl" + ControlOnStyle(nodeInfo, nodeClass, bIsTallNode) + (bEmbedded && !bScalarSubTable ? " HasEmbedded" : "");
    const tallCls = bIsTallNode ? " Tall" : "";
    const fullCls = nodeClass + 'Node Node SelState' + selState + nodeAliasCls + clickableCls + disabledCls + tallCls + " HoverState" + mouseEntered + " HoverColor" + props.hoverHighlightColor;

    let nodeContentCls = bEmbedded && !bScalarSubTable ? "nodeContent Embedded" : "nodeContent";

    function handleRightClick(e) {
        if (!document?.acpServerConfig?.EW1477_uncertainty_context_menu || nodeClass !== "OutputNode" || bIsTallNode) return;
        console.log("right click!!!", e)
        e.preventDefault();
        setShowContextMenu(true);
        setContextMenuX(e.clientX);
        setContextMenuY(e.clientY);
        setShowHelpBalloon(false);
    }

    const rectCopy = { ...rect };

    //console.log(bIsTallNode);


    if (style && props?.cloudStylesDiag?.display === "flex" && !props.isInFlexBox) {
        //console.log("UNSETTING ", nodeClass0)
        // Adjust Flex Box Items
        const margin = props?.cloudStyles?.flex_gap ? Number(props?.cloudStyles?.flex_gap)/4 : 4; 
        style.position = "relative";
        style.top = "unset";
        style.left = "unset"
        style.x = "unset"
        style.y = "unset";
        style.margin = margin;
        style.padding = margin;
    }

    let nodeContentStyle = nodeFontStyle ? {...nodeFontStyle} : null;
    if (props?.cloudStylesDiag?.display === "flex") {
        if (!nodeContentStyle)
            nodeContentStyle = { width: style?.width, height: style?.height };
        else {
            nodeContentStyle.width = style.width;
            nodeContentStyle.height = style.height;
        }
        nodeContentStyle.top = 0;
        nodeContentStyle.left = 0;
        nodeContentStyle.transform = "unset";
    }

    const flexItemKids = flexBoxItemChildren?.length ? flexBoxItemChildren.map(x => x.oid) : flexBoxItemChildren;
    //console.log("flexBoxItemChildren", flexBoxItemChildren);

    const filter = props.frameNodeContents && props.frameNodeContents.filter((fnc) => ((fnc.frameNodeContent === orig && nodeClass !== "InputNode") || (nodeClass === "InputNode" && fnc.frameNodeContent === oid && fnc.frameView === 'DFNM')));
    const bNodeShowingInFrame = filter && filter.length > 0 ? true : false;

	return ( 
        <div className={fullCls} id={identifier} ref={nodeRef} key={oid} oid={oid} style={style}
            draggable={!props.browseOnly} onDragStart={startDrag}
            onClick={onClick} onMouseDown={handleOnMouseDown}
            onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onContextMenu={handleRightClick}
			>
            {(bHasHoverRect || selState === 1 || bNodeShowingInFrame) && <Acp1HoverRect {...props} glowColor={fillColor} mouseIsOver={mouseIsOver} nodeClass={nodeClass} selState={selState === 1 || bNodeShowingInFrame ? 1 : 0} nodeInfo={nodeInfo} />}
			{NodeShape(nodeClass,size,fillColor,borderColor,nodeInfo,props.cloudStyles)}
            {props.isFrameNode ?
                <FrameNodeContent {...props} oid={orig} nodeClass0={nodeClass0} onMouseOver={onMouseOver} onMouseOut={onMouseOut} mouseEntered={mouseEntered} onMouseOverNode={onMouseOver} onMouseOutNode={onMouseOut}  descriptionFrameNode={descriptionFrameNode} setDescriptionFrameNode={setDescriptionFrameNode} />
            :
                <div className={nodeContentCls} style={nodeContentStyle}>  { /* LDC 2/17/2021 Suan bug 611 */ }
                    {textNodeTitle}
                    {imgTag}
                    {nodeClass !== "Picture" && <div className={titleAndControlCls}>
                        {editingTitle ? titleEditHtml : titleTxtHtml}
                        {(bShowLabel && titleTxtStyle.display !== "none") && <Units oid={orig} nodeClass={nodeClass} multilineInput={multilineInput} fontStyle={nodeFontStyle} defType={defType}/>}
                        {control}
                        {/*sicn*/}
                    </div>}
                    {props?.cloudStylesDiag?.display === "flex" && flexItemKids && nodeClass0 === "Text" && !props.isInFlexBox && flexItemKids.map((oid) => <Node {...props} key={oid} oid={oid} isInFlexBox={true} flexBoxContainerRect={rectCopy } />)}
                </div>
            }
			{SelectionHandle(0,0,selState)}
			{SelectionHandle(w,0,selState)}
			{SelectionHandle(0,h,selState)}
			{SelectionHandle(w,h,selState)}
			{hoverIcons()}
            {nodeClass !== "Frame" && <ParentsPopupMenu oid={oid} jumpTo={props.jumpToNodeInDiagram} zoom={props.zoom} />}
            {nodeClass !== "Frame" && <KidsPopupMenu oid={oid} jumpTo={props.jumpToNodeInDiagram} zoom={props.zoom} />}
            {bShowHelpBalloon && <HelpBalloon nodeRef={nodeRef} nodeStyle={style} nodeId={identifier} idOrig={idOrig}
                showIdInBalloon={props.cloudStyles.show_id_in_balloon} zoom={props.zoom} bIsTallNode={bIsTallNode} descriptionFrameNode={descriptionFrameNode} isFrameNode={props.isFrameNode}  />}
            {/* <div>some text</div>
            <div>some text</div>
            <div>some text</div>
            <div onClick={() => {alert("hi") } }>some text</div>*/}
            {showContextMenu && <ContextMenu oidOrig={orig} setPendingCalcReq={props.setPendingCalcReq} contextMenuX={contextMenuX}
                contextMenuY={contextMenuY} setShowContextMenu={setShowContextMenu} />}
		</div>
	);
}

function ContextMenu(props) {
    
    const [viewType, setViewType] = useObjAtt(props.oidOrig, "_resultViewType");
    const [contextMenuRef, contextMenuRect] = useLayoutRect();
    const [topAdjustment, setTopAdjust] = useState(0);
    //console.log("context menu, view type", viewType, props)

    function doClick(view) {
        console.log("ContextMenu do click", view)
        props.setShowContextMenu(false);
        setViewType(view);
        props.setPendingCalcReq({ oid: props.oidOrig, fromCalc: true, reqCount: IncReqCalc() });
    }

    function handleRightClick(e) {
        console.log("right click on context menu");
        e.stopPropagation();
    }

    let loc = props?.contextMenuY && props.contextMenuX ? { top: props.contextMenuY - 10, left: props.contextMenuX - 10 }:  {};
    loc.top = loc.top - topAdjustment;

    useEffect(() => {
        if (contextMenuRect) {
            const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            const bottomOfMenu = contextMenuRect.y + contextMenuRect.height
            if (bottomOfMenu > viewportHeight && topAdjustment === 0)
                setTopAdjust(bottomOfMenu - viewportHeight);
        }
    }
    );

    const contextMenuHtml = (
        <div className="ContextMenuPortal" style={loc} onContextMenu={handleRightClick} onMouseLeave={() => props.setShowContextMenu(false)} ref={contextMenuRef}> 
            <div className="ContextMenuItem" onClick={() => doClick("MIDM")}>Mid Value</div>
            <div className="ContextMenuItem" onClick={() => doClick("MEAN")}>Mean Value</div>
            <div className="ContextMenuItem" onClick={() => doClick("STAT")}>Statistics</div>
            <div className="ContextMenuItem" onClick={() => doClick("CONF")}>Probability Bands</div>
            <div className="ContextMenuItem" onClick={() => doClick("PDFP")}>Probability Density</div>
            <div className="ContextMenuItem" onClick={() => doClick("CDFP")}>Cumulative Probability</div>
            <div className="ContextMenuItem" onClick={() => doClick("XCDZ")}>Exceedence Probability</div>
            <div className="ContextMenuItem" onClick={() => doClick("SAMP")} >Sample</div>
        </div>
    );

    if (!contextMenuHtml) return "";

    return ReactDOM.createPortal(contextMenuHtml, document.getElementById('modal-root'));
}
