// resultTable.js
//

import React from 'react';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';

import { useObjAtt, useObjAtts, useQuery/*, rawSetObjAtts*/, useCloudStyles, useTopLevelOid, useEval } from './serverState.js';
import { WebSocket_Send } from './webSocketClient.js';
import { TableByFullTable, AcceptCancelButtons } from './editTable.js';
import { ToolTip } from './toolTip.js';
import './styles/resultTable.scss';
import { Lineify, paddingDiff, ModalPopupControl, ValueToHTML, useLayoutRect } from './miscTools.js';

export function ResultTitle(props)
{
    //console.log("ResultTitle", props)
    const [title0, units0] = useObjAtts(props.oid, ["_title", "units"]);
    const [unitsOrig] = useObjAtts(props?.oidOrig, ["units"]);

    function OnAcceptChanges(ev) {
        console.log("OnAcceptChanges", props)
        WebSocket_Send({ fn: 'Exec', vid: props.vid, cmd: 'acceptChanges' });
    }

    function OnCancelChanges(ev) {
        WebSocket_Send({ fn: 'Exec', vid: props.vid, cmd: 'dropChanges' });
        //const oldQ = props.q;
        //props.resetQ(undefined);
        //props.resetQ(oldQ);
    }

    let viewName = props.view;
    if (viewName === undefined && props.state !== undefined && props.state.view !== undefined)
        viewName = props.state.view;

    let noTitle = props.noTitle;
    if (props.view === "edit" && props.bTallNode === 1) noTitle = false;

    if (noTitle) return null;

    let imageSource = "img/toggleToGraphButton.png";
    if (props.isGraph === "yes")
        imageSource = "img/toggleToTableButton.png";

    const className = props?.state?.view === "edit" ? "ResultTitle Edit" : "ResultTitle ShowToggle";

    const title = props?.bShowTitle === false ? "" : title0;
    const units1 = unitsOrig ? unitsOrig : units0;
    const units = props?.bShowTitle === false ? "" : units1;

    const state = props && props.state;
    const rowIndexOid = !(state && state.rows) || state.rows.length === 0 ? null : state.rows[0];
    const colIndexOid = !(state && state.cols) || state.cols.length === 0 ? null : state.cols[0];

    return (
        <div className={className}>{title} {units && units !== "" && (<span id="Units">({units})</span>)}
            <ViewSelector view={viewName} setView={props.setView} isFrameNode={props.isFrameNode} cloudStyles={props.cloudStyles}
                topCloudStyles={props.topCloudStyles} cloudStylesOrig={props.cloudStylesOrig} frameNodeCloudStyles={props.frameNodeCloudStyles} bShowTitle={props?.bShowTitle} />
            {(((props.isFrameNode || props.bTallNode === 1) && props.mouseEntered) || props.hasAcp1Tabs === false)
                && <TableGraphButtons imageSource={imageSource} {...props} rowIndexOid={rowIndexOid} colIndexOid={colIndexOid} zoom={props.zoom} OnAcceptChanges={OnAcceptChanges} OnCancelChanges={OnCancelChanges} />}
            {props?.state?.view === "edit" && false && <AcceptCancelButtons vid={props.vid} onAccept={OnAcceptChanges} onCancel={OnCancelChanges} />}
        </div>
    );
}
/*
function ToggleTableGraph(props) {

    return (
        <img className="ToggleTableGraphButton" onClick={props.toggleTableGraphResult} src={props.imageSource} alt="Toggle Table/Graph" />
    );
}
*/
function TableGraphButtons(props) {
    //console.log("TableGraphButtons", props);
    const [showCopyMenu, setShowCopyMenu] = useState(false);
    const [rowIndexId,] = useObjAtt(props.rowIndexOid, "identifier");
    const [rowIndexIsIn,] = useObjAtt(props.rowIndexOid, "isin");
    const [colIndexId,] = useObjAtt(props.colIndexOid, "identifier");
    const [colIndexIsIn,] = useObjAtt(props.colIndexOid, "isin");
    const [tableGraphId] = useObjAtt(props.oid, "identifier");
    const objViewBtnOid = useEval("HandleFromIdentifier('object_view_btn_acp')")?.oid;
    const [frameNodeId,] = useObjAtt(props.frameNodeOid, "identifier");
    const [idOfContent] = useObjAtt(props.oidOrig, "identifier");
    //const [onClickObjViewBtnNode, setOnClick] = useObjAtt(objViewBtnOid, "OnClick");
    //const indexesOfTable = useEval("IndexesOf(" + tableGraphId + ")");
    const [tableGraphTitle] = useObjAtt(props.oid, "_title");
    const [copyRef, copyRect] = useLayoutRect();
    const [showCopyTip, setShowCopyTip] = useState(false);
    const [copyTimeout, setCopyTimeout] = useState(0);
    const [pivotRef, pivotRect] = useLayoutRect();
    const [showPivotTip, setShowPivotTip] = useState(false);
    const [pivotTimeout, setPivotTimeout] = useState(0);
    const [toggleRef, toggleRect] = useLayoutRect();
    const [showToggleTip, setShowToggleTip] = useState(false);
    const [toggleTimeout, setToggleTimeout] = useState(0);
    const [objectRef, objectRect] = useLayoutRect();
    const [showObjectTip, setShowObjectTip] = useState(false);
    const [objectTimeout, setObjectTimeout] = useState(0);

    let showIndexCloudStyle = props?.topCloudStyles?.show_index_menus;
    if (props?.frameNodeCloudStyles?.show_index_menus) showIndexCloudStyle = props?.frameNodeCloudStyles?.show_index_menus;
    if (props?.cloudStylesOrig?.show_index_menus) showIndexCloudStyle = props?.cloudStylesOrig?.show_index_menus;

    let showObjectBtn = props?.topCloudStyles?.object_in_frame;
    if (props.bTallNode && props?.cloudStylesOrig?.object_in_frame)
        showObjectBtn = props?.cloudStylesOrig?.object_in_frame === "yes" ? true : false;
    else if (props.isFrameNode && props.frameNodeCloudStyles?.object_in_frame)
        showObjectBtn = props?.frameNodeCloudStyles?.object_in_frame === "yes" ? true : false;

    let visibilityOfPivotersBtn = true;  // default, i.e. fly in

    if (showIndexCloudStyle === "yes" || showIndexCloudStyle === "no" || showIndexCloudStyle === "not_even_slicers")
        visibilityOfPivotersBtn = false;

    if (props.hasAcp1Tabs === false) visibilityOfPivotersBtn = false; // affects that are not embedded

    let showPivotBtnClass = visibilityOfPivotersBtn ? "ShowPivotersBtn" : "ShowPivotersBtn Hide";
    const copyTableBtnClass = document?.acpServerConfig?.showCopyTableBtn ? "CopyTableBtn" : "CopyTableBtn Hide";
    const objectWindowBtnClass = showObjectBtn ? "ObjectWindowBtn" : "ObjectWindowBtn Hide";

    const style = props.tableMargin ? { right: (props.tableMargin + "px"), top: (props.tableMargin + "px")} : {};

    const toggleButtonCls = props.showGraphTableIcon !== "no" ? "ToggleTableGraphButton" : "ToggleTableGraphButton Hide";

    function onClickToggle(e) {
        if (props.isFrameNode || props.bTallNode === 1)
            props.toggleTableGraphResult();
        else if (props?.q?.req === "resultTable")
            props.setSelectedTab('graph');
        else
            props.setSelectedTab('result');
    }

    function copyToClipboard() {
        if (props.tableOrGraph !== "table") {
            // copy graph
            const plotHolderDiv = document.getElementById(props.graphId);
            plotHolderDiv.setAttribute("contenteditable", true);
            SelectText(plotHolderDiv);
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
            plotHolderDiv.removeAttribute("contenteditable");
        } else {
            // copy table
            const table = document.getElementById(props.tableId);
            let tableForClipboard = "";
            for (var i = 0, row; row = table.rows[i]; i++) {
                for (var j = 0, col; col = row.cells[j]; j++) {
                    const numCols = row.cells.length;
                    let cellHtml = col.innerHTML;
                    // cell html examples
                    // blue link:  <span class="OidHyperLink">Va1</span><div class="RowResizer"></div>
                    while (cellHtml.indexOf("<") === 0 && cellHtml.indexOf(">") !== -1)
                        cellHtml = cellHtml.substr(cellHtml.indexOf(">") + 1); // trim off leading tags if present
                    let cell = cellHtml.split("<")[0]; // trim off html tags present after cell value
                    tableForClipboard += cell !== "&nbsp;" ? cell : "";

                    // add delimiters
                    if (j < numCols - 1)
                        tableForClipboard += "\t";
                    else
                        tableForClipboard += "\n";
                }
            }
            // copy table to clipboard
            const elem = document.createElement('textarea');
            elem.value = tableForClipboard;
            document.body.appendChild(elem);
            elem.select();
            document.execCommand('copy');
            document.body.removeChild(elem);
        }

        setShowCopyMenu(false);

        /*
         * Another way to copy table which includes formatting styles
         *
        // create a Range object
        var range = document.createRange();
        // set the Node to select the "range"
        range.selectNode(table);

        // add the Range to the set of window selections
        window.getSelection().addRange(range);

        // execute 'copy', can't 'cut' in this case
        document.execCommand('copy');

        window.getSelection().removeAllRanges();
        */
    }

    function downloadCsvFile() {
        console.log("downloadCsvFile()");
        setShowCopyMenu(false)

        if (props?.state?.view !== "edit") {

            let downloadCmd = "EvaluateScript('description of orphans: csv download in progress');";

            // if "isin" is undefined, then it's probably a local index
            const colIndexParam = colIndexIsIn === undefined ? tableGraphId + "." + colIndexId : colIndexId;
            const rowIndexParam = rowIndexIsIn === undefined ? tableGraphId + "." + rowIndexId : rowIndexId;

            if (colIndexId !== undefined && rowIndexId !== undefined) {
                downloadCmd += "WriteTextFile('" + tableGraphTitle + ".csv', MakeCSV(" + idOfContent + ", " + colIndexParam + ", " + rowIndexParam + ",  columnHeaders: IndexValue(" + colIndexParam + "), rowHeaders: IndexValue(" + rowIndexParam + ")), download: True)";
            }
            else if (colIndexId === undefined)
                downloadCmd = "localindex i:= [1]; local x := AddIndex(" + idOfContent + ",i); WriteTextFile('" + tableGraphTitle + ".csv', MakeCSV(" + idOfContent + ", i, " + rowIndexParam + ", rowHeaders: IndexValue(" + rowIndexParam + ")), download: True)";
            else
                downloadCmd += "localindex i:= [1]; local x := AddIndex(" + idOfContent + ",i); WriteTextFile('" + tableGraphTitle + ".csv', MakeCSV(" + idOfContent + ", " + colIndexParam + ", i, columnHeaders: IndexValue(" + colIndexParam + ")), download: True)";

            downloadCmd += ";EvaluateScript('description of orphans: csv download done')"

            console.log("downloadCmd", downloadCmd)
            WebSocket_Send({ fn: 'Exec', cmd: downloadCmd });
        }
        else {
            const table = document.getElementById(props.tableId);
            console.log("tableId", props.tableId, table);
            let tableForClipboard = "";
            for (var i = 0, row; row = table.rows[i]; i++) {
                for (var j = 0, col; col = row.cells[j]; j++) {
                    const numCols = row.cells.length;
                    let cellHtml = col.innerHTML;

                    // cell html examples
                    // blue link:  <span class="OidHyperLink">Va1</span><div class="RowResizer"></div>
                    while (cellHtml.indexOf("<") === 0 && cellHtml.indexOf(">") !== -1)
                        cellHtml = cellHtml.substr(cellHtml.indexOf(">") + 1); // trim off leading tags if present
                    let cell = cellHtml.split("<")[0]; // trim off html tags present after cell value

                    tableForClipboard += cell !== "&nbsp;" ? cell : "";

                    // add delimiters
                    if (j < numCols - 1)
                        tableForClipboard += ",";
                    else
                        tableForClipboard += "\n";
                }
            }

            //console.log("tableForClipboard", tableForClipboard);
            const downloadCmd = 'WriteTextFile("' + tableGraphTitle + '.csv", "' + tableForClipboard + '" , download: True)';

            //console.log(downloadCmd)
            WebSocket_Send({ fn: 'Exec', cmd: downloadCmd });

            //alert(downloadCmd)
        }
    }

    function ShowObjectiveView() {
        console.log("ShowObjectiveView", objViewBtnOid, props);
        //const cmd = "Att_FrameNodeShowing of Te944675411:= Array( Sys_FrameNodeShowIdx,[ handle(va1), 'OBJV', Null, Null ])";
        //const cmd = "Att_FrameNodeShowing:Table(Sys_FrameNodeShowIdx)(Va2,'OBJV',Null,Null)";
        const cmd = "onclick of object_view_btn_acp:=:=" +  "\"Att_FrameNodeShowing of Te944675411:= Array(Sys_FrameNodeShowIdx, [va3, 'OBJV', Null, Null])\""
        //console.log("objViewBtnOid", objViewBtnOid)
        //WebSocket_Send({ fn: 'Exec', cmd: cmd });

        if (props.bTallNode) {
            props.setShowObjectView(true);
            return;
        }

        //setOnClick("Att_FrameNodeShowing of " + frameNodeId + ":= Array( Sys_FrameNodeShowIdx,[ handle(" + idOfContent + "), 'OBJV', Null, Null ])");
        let showObjectiveCmd = "Att_FrameNodeShowing of " + frameNodeId + ":= Array( Sys_FrameNodeShowIdx,[ handle(" + idOfContent + "), 'OBJV', Null, Null ])";
        //let showObjectiveCmd = "msgbox(9+9)";
        showObjectiveCmd = "onclick of object_view_btn_acp:=" + showObjectiveCmd;
        console.log("showObjectiveCmd", showObjectiveCmd);
        WebSocket_Send({ fn: 'Exec', cmd: showObjectiveCmd });
        WebSocket_Send({ fn: 'DoOnClick', oid: objViewBtnOid, x: 10, y: 10 });
        //setTimeout(() => { setOnClick("Att_FrameNodeShowing of " + frameNodeId + ":=msgbox(7+7)")}, 1000);
    }

    function CopyMenu(props) {
                console.log(copyToClipboard)

        return (
            <div className="CopyMenu" onMouseLeave={() => setShowCopyMenu(false)}>
                <div className="CopyMenuItem" onClick={copyToClipboard}>Copy</div>
                <div className="CopyMenuItem" onClick={downloadCsvFile}>Download CSV</div>
            </div>
        );
    }

    function mouseEnteredCopy(evt) {
        setCopyTimeout(setTimeout(() => { setShowCopyTip(true) }, 500));
    }
    function mouseLeaveCopy(evt) {
        setShowCopyTip(false);
        if (copyTimeout !== 0) clearTimeout(copyTimeout);
    }
    function mouseEnteredPivot(evt) {
        setPivotTimeout(setTimeout(() => { setShowPivotTip(true) }, 500));
    }
    function mouseLeavePivot(evt) {
        setShowPivotTip(false);
        if (pivotTimeout !== 0) clearTimeout(pivotTimeout);
    }
    function mouseEnteredToggle(evt) {
        setToggleTimeout(setTimeout(() => { setShowToggleTip(true) }, 500));
    }
    function mouseLeaveToggle(evt) {
        setShowToggleTip(false);
        if (toggleTimeout !== 0) clearTimeout(toggleTimeout);
    }
    function mouseEnteredObject(evt) {
        console.log("mouseEnteredObject")
        setObjectTimeout(setTimeout(() => { setShowObjectTip(true) }, 500));
    }
    function mouseLeaveObject(evt) {
        console.log("mouseLeaveObject")
        setShowObjectTip(false);
        if (objectTimeout !== 0) clearTimeout(objectTimeout);
    }

    const bIsAtomic = rowIndexId === undefined && colIndexId === undefined;

    useEffect(() => {
        //console.log("use effect once for creating obj view button", objViewBtnOid);

        // create a button in orphans for showing object window in frame nodes (hackish, I hope to find a better way)
        let objViewBtnCmd = 'local objViewBtn:= HandleFromIdentifier("object_view_btn_acp");';
        objViewBtnCmd += 'if (isNull(objViewBtn)) then(';
        objViewBtnCmd += 'objViewBtn := CreateNewObject(Button, orphans, "object view button", "object_view_btn_acp");';
        objViewBtnCmd += 'onclick of objViewBtn:= "msgbox(1+1)")';
        WebSocket_Send({ fn: 'Exec', cmd: objViewBtnCmd });

    },[]);

    return (
        <>
        <div className="TableGraphButtons" style={style}>
                <img className={copyTableBtnClass} ref={copyRef} src="img/copyDownload.png" onClick={() => { setShowCopyMenu(!showCopyMenu); mouseLeaveCopy() }} alt="Copy download table" onMouseEnter={mouseEnteredCopy} onMouseLeave={mouseLeaveCopy} />
                <img className={showPivotBtnClass} ref={pivotRef} src="img/showPivotersBtn.png" onClick={() => { props.showHidePivoters(); mouseLeavePivot() }} alt="Show pivot controls" onMouseEnter={mouseEnteredPivot} onMouseLeave={mouseLeavePivot} />
                {props?.state?.view !== "edit" && <img className={toggleButtonCls} ref={toggleRef} onClick={onClickToggle} src={props.imageSource} alt="Toggle Table/Graph" onMouseEnter={mouseEnteredToggle} onMouseLeave={mouseLeaveToggle} />}
                <img className={objectWindowBtnClass} src="img/objectWindow.ico" ref={objectRef} onClick={ShowObjectiveView} alt="Show object view" onMouseEnter={mouseEnteredObject} onMouseLeave={mouseLeaveObject} />
                <AcceptCancelButtons vid={props.vid} onAccept={props.OnAcceptChanges} onCancel={props.OnCancelChanges} />
            </div>
            {showCopyMenu && <div className="CopyMenu" onMouseLeave={() => setShowCopyMenu(false)}>
                <div className="CopyMenuItem" onClick={copyToClipboard}>Copy</div>
                {props.tableOrGraph === "table" && !bIsAtomic && <div className="CopyMenuItem" onClick={downloadCsvFile}>Download CSV</div>}
            </div>}
            {copyRect && showCopyTip && <ToolTip objRect={copyRect} control="copy download" tableOrGraph={props?.tableOrGraph} zoom={props.zoom}/>}
            {pivotRect && showPivotTip && <ToolTip objRect={pivotRect} control="pivot" tableOrGraph={props?.tableOrGraph} zoom={props.zoom}/>}
            {toggleRect && showToggleTip && <ToolTip objRect={toggleRect} control="toggle" tableOrGraph={props?.tableOrGraph} zoom={props.zoom} />}
            {objectRect && showObjectTip && <ToolTip objRect={objectRect} control="object view" tableOrGraph={props?.tableOrGraph} zoom={props.zoom} />}
        </>
    );
}

//Cross-browser function to select content
function SelectText(element) {
    var doc = document;
    if (doc.body.createTextRange) {
        var range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
    } else if (window.getSelection) {
        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    }
}

function SlicerSelValControl({ oid, opts,changeSlicer, zoom })
{
    const [vis, setVis] = useState(false);
    const ref = useRef(null);
    if (!opts || !opts.indVal) return null;
    const indVal = opts.indVal;

    function show(ev)
    {
        setVis(!vis);
    }

    function select(ev)
    {
        const pos = ev.currentTarget.value;
        changeSlicer(oid, pos);
        setVis(false);
    }
    function selectAll(ev)
    {
        changeSlicer(oid, -1);      // Code for All
        setVis(false);
    }
    function selectTotal(ev)
    {
        changeSlicer(oid, -2);       // code for total
        setVis(false);
    }

    function handleKey(ev)
    {
        // ** TO DO **
    }

    const cls = "SelValPopup " + (vis ? "Opened" : "Closed");

    return (
        <span className="SelValControl" ref={ref}>
            <img id="SelVal" alt="select value" src="img/slicerDropdown.png" onClick={show} />
            <ModalPopupControl className={cls} parentRef={ref} setOpen={setVis} onKeyDown={handleKey} zoom0={zoom}>
                <ul>
                    {indVal.map((txt, i) => (<li key={i} value={i + 1} onClick={select}><ValueToHTML val={txt} /></li>) ) }
                    {opts.inclTotal && <li key="total" value="total" onClick={selectTotal}>{'\u00abTotals\u00bb'}</li>}
                    {opts.inclAll && <li key="all" value="all" onClick={selectAll}>{'\u00abAll\u00bb'}</li>}
                </ul>
            </ModalPopupControl>
        </span>
    );
}

function ViewSelector(props)
{
    //console.log("ViewSelector", props)
    const [topOid,] = useTopLevelOid();
    const topCloudStyles = useCloudStyles(topOid);
    const [viewSelRef, viewSelRect] = useLayoutRect();
    const [showToolTip, setShowToolTip] = useState(false);
    const [toolTipTimeout, setToolTipTimeout] = useState(0);

    let view = props.view;
    if (view === "SMPL") view = "SAMP";

    const showUncertaintyView = getCloudStyleSetting("show_uncertainty_view", topCloudStyles, props?.frameNodeCloudStyles, props.cloudStylesOrig, "yes");

    if (showUncertaintyView === "no" || props?.view === "edit") return "";

    function opt(val,txt)
    {
        //return (<option value={val}><img className="SICN" src={"img/SICN_" + val + ".png"} />{txt}</option>);
        return (<option value={val}>{txt}</option>);
    }

    const viewSelectorClass = props.bShowTitle ? "ViewSelector" : "ViewSelector NoTitle";

    function mouseEnter() {
        setToolTipTimeout(setTimeout(() => {setShowToolTip(true)}, 500));
    }
    function mouseLeave() {
        setShowToolTip(false);
        if (toolTipTimeout !== 0) clearTimeout(toolTipTimeout);
    }
    function onClick() {
        mouseLeave();
    }

    return (
        <>
            <select className="ViewSelector" ref={viewSelRef} value={view} onChange={(ev) => props.setView(ev.currentTarget.value)}
                onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={onClick}>
            {opt("MIDM", "Mid value")}
            {opt("MEAN", "Mean value")}
            {opt("STAT","Statistics")}
            {opt("CONF", "Probability bands")}
            {opt("PDFP", "Probability density")}
            {opt("CDFP", "Cumulative probability")}
            {opt("XCDZ", "Exceedance probability")}
            {opt("SAMP","Sample")}
            {view==="DEFA" && (<option value="DEFA">DEFA</option>)}
            {view==="DFNM"&& (<option value="DFNM">DFNM</option>)}
            </select>
            {viewSelRect && showToolTip && <ToolTip objRect={viewSelRect} control="ViewSelector" />}
        </>
    );
}

export function Slicer({ vid, oid, pos,changeSlicer, zoom })
{
    const [title, units0] = useObjAtts(oid, ["_title", "units"]);
    const opts = useQuery({ req: "slicerOptions", "oid": oid }, vid);             // LDC 4/19/2021 ER S-730
    const indVal = opts && opts.indVal;
    const len = opts && opts.len;

    const units = units0 !== undefined && units0 != null ? " (" + units0 + ")" : "";
    const label =
        pos === "all" ? "\u00abAll\u00bb" :
            (pos === "total" ? "\u00abTotals\u00bb" :
                (Array.isArray(indVal) && 0 < pos && pos <= indVal.length ? indVal[pos - 1] : ""));

    function onInc(ev)
    {
        if (!Array.isArray(indVal)) return;

        const newPos = !Number.isInteger(pos) || pos + 1 > len ? 1 : pos + 1;
        changeSlicer(oid, newPos);
    }
    function onDec(ev)
    {
        if (!Array.isArray(indVal)) return;

        const newPos = !Number.isInteger(pos) || pos <= 1 ? len : pos - 1;
        changeSlicer(oid, newPos);
    }

    return (
        <div className="Slicer" onClick={ev=>ev.stopPropagation()}>
            <span className="SlicerTitle">{title}{units}</span>
            <SlicerSelValControl oid={oid} changeSlicer={changeSlicer} opts={opts} zoom={zoom}/>
            <span className="SlicerValue"><ValueToHTML val={label} /></span>
            <img id="DecVal" alt="previous" src="img/slicerDec.png" onClick={onDec}/>
            <img id="IncVal" alt="next" src="img/slicerInc.png" onClick={onInc}/>
        </div>
    );
}

function IndexPivoterOption(props) {
    const oid = props.oid;
    const [label,] = useObjAtt(oid, "_title");

    return (<option value={oid}>{label}</option>);
}

function IndexPivoter(props) {
    const inds = props.inds;

    function onChange(ev)
    {
        ev.stopPropagation();
        const oid = typeof(ev.currentTarget.value)==='string' ? parseInt(ev.currentTarget.value) : ev.currentTarget.value;        // LDC 3/21/2023 Bug S-1571.
        if (props.pivotRow) {
            props.pivotRow(oid);
        } else if (props.pivotCol) {
            props.pivotCol(oid);
        }
    }

    return (
        <select id={props.id} className="Pivoter" value={!props.sel?"0":props.sel} onChange={onChange} onClick={ev=>ev.stopPropagation()} >
            {inds.map((sub, i) => (<IndexPivoterOption key={sub} oid={sub} />))}
            <option value="0"></option>
        </select>
    );
}

function IndexName({ indexOid, alwaysVisible, rowIndexNameRef })
{
    const [title,] = useObjAtt(indexOid, "_title");
    if (!indexOid) return null;

    const className = alwaysVisible ? "IndexName AlwaysVisible" : "IndexName";

    return <div ref={rowIndexNameRef} className={className}>{title}</div>;

}

function ResultDescription(props)
{
    const [desc,] = useObjAtt(props.oid, "description");
    if (!desc) return null;

    return (<div className="Description" style={{maxHeight:props.height, marginBottom: 5}} ><Lineify text={desc}/></div>);
}

function PivoterTotal(props)
{
    const total = useQuery({ req: "hasTotal", index:props.ind }, props.vid);
    if (total === undefined || total === null) return null;

    function onToggle(ev)
    {
        WebSocket_Send({ fn:'Exec', cmd:"toggleTotal", index: props.ind, bOn: !total, vid:props.vid });
        ev.stopPropagation();
    }

    return (
        <>
            <input type="checkbox" id={props.id} className="PivotTotal" checked={total} onChange={onToggle} />
            <label htmlFor={props.id}>Totals</label>
        </>
    );
}

function TotalsButton({ indexOid, vid })
{
    const total = useQuery({ req: "hasTotal", index: indexOid }, vid);
    if (total === undefined || total === null) return null;

    function onToggle(ev) {
        WebSocket_Send({ fn: 'Exec', cmd: "toggleTotal", index: indexOid, bOn: !total, vid: vid });
        ev.stopPropagation();
        ev.currentTarget.blur();
    }

    const cls = "TotalsIcon Total" + (total ? "On" : "Off");
    return <button className={cls} onClick={onToggle}>&Sigma;</button>;
}

export function getCloudStyleSetting(styleName, globalCS, frameCS, origCS, defaultVal) {
    //console.log("getCloudStyleSetting")
    //console.log(styleName, globalCS, frameCS, origCS)
    let res = defaultVal;
    if (globalCS?.[styleName]) res = globalCS?.[styleName];
    if (frameCS?.[styleName]) res = frameCS?.[styleName];
    if (origCS?.[styleName]) res = origCS?.[styleName];
    return res;
}

export function getShowIndexMenus(globalCS, frameCS, origCS, props) {
    // ACP1 tabs defaults or when showing result that is not embedded
    let bShowPivoters = true;
    let bShowSlicers = true;

    //console.log("getShowIndexMenus", props)
    //console.log(globalCS, frameCS, origCS)

    if (props.isFrameNode || props.bTallNode === 1) {
        bShowPivoters = false; // default, fly-in, i.e. don't show pivoters unless fly-in icon pressed
        const showIndexMenus = getCloudStyleSetting("show_index_menus", globalCS, frameCS, origCS, "fly-in");
        if (showIndexMenus === "fly-in" || showIndexMenus === "no") { bShowPivoters = false; bShowSlicers = true; }
        if (showIndexMenus === "not_even_slicers") { bShowPivoters = false; bShowSlicers = false; }
        if (showIndexMenus === "yes") { bShowPivoters = true; bShowSlicers = true; }
        if (props.togglePivoters) { bShowPivoters = !bShowPivoters; }
    }

    return [bShowPivoters, bShowSlicers];
}

export function TableHeader(props) {
    const [oidOriginal,] = useObjAtt(props.oid, "_original");
    if (oidOriginal === undefined) return "";
    return <TableHeader1 {...props} oidOriginal={oidOriginal.oid}/>
}

function TableHeader1(props)
{
    //console.log("TableHeader1", props)
    const [description,] = useObjAtt(props.oidOriginal, "description")
    const state = props.state;
    let bShowRowPivoter = useRowPivoterShouldShowInHeaderArea(props.oid, props.cloudStyles);
    const origCloudStyles = useCloudStyles(props.oidOriginal);
    if (state === undefined) return null;
    const descriptionHeight = props.showDescription;
    const bShowDescription = typeof (descriptionHeight) === "number" && descriptionHeight > 0;

    function onCalculateClick()
    {
        WebSocket_Send({ fn: "RequestCalc", oid: props.oid });
    }

    function showHidePivoters() {

        props.setTogglePivoters(!props.togglePivoters);

        // hackish way of getting embedded view to update
        setTimeout(() => { props.onMouseOut(); }, 100);
        setTimeout(() => { props.onMouseOver(); }, 200);
    }

    const showTitleStyle = getCloudStyleSetting("show_title", props?.topCloudStyles, props?.frameNodeCloudStyles, origCloudStyles, "yes")
    const bShowTitle = (props.bTallNode === 1 || props.isFrameNode) && showTitleStyle === "no" ? false : true;

    const calcButton = (<button id="ResultCalculateButton" onClick={onCalculateClick}>Calculate</button>);
    const rowSel = state.rows.len === 0 ? null : state.rows[0];
    const colSel = state.cols.len === 0 ? null : state.cols[0];

    const pivotersSlicersClass = "PivotersSlicers";

    const [bShowPivoters, bShowSlicers] = getShowIndexMenus(props.topCloudStyles, props.frameNodeCloudStyles, origCloudStyles, props);
    const showGraphTableIcon = getCloudStyleSetting("show_graph_table_icon", props?.topCloudStyles, props?.frameNodeCloudStyles, origCloudStyles, "yes")

    const holderCls = "PivotersHolder" + (bShowRowPivoter ? "" : " NoRowPivot");
    const pivWrapperClass = props?.state?.view !== "edit" ? "PivotersSlicersWrapper" /*+ showHeader*/ : "PivotersSlicersWrapper";

    function mouseEnter(e) {
        console.log("mouse enter title", props)
        const showDescription = props?.cloudStylesOrig?.show_description ? props?.cloudStylesOrig?.show_description : props?.cloudStyles?.show_description;
        if ((showDescription === "no" || showDescription === "0") && props.onMouseOverNode && !props.isFrameNode) {
            props?.onMouseOverNode("embedded node title");
        }
        else if (props.isFrameNode) {
            const showDesc = props?.frameNodeCloudStyles?.show_description ? props?.frameNodeCloudStyles?.show_description : props?.topCloudStyles?.show_description;
            if ((showDesc === "no" || showDesc === "0") && props.onMouseOverNode) {
                props?.setDescriptionFrameNode(description);
                props?.onMouseOverNode("embedded node title");
            }
        }
    }

    function mouseLeave(e) {
        //console.log("mouse leave title", props)
        if (!props.isFrameNode) {// tall node
            const showDescription = props?.cloudStylesOrig?.show_description ? props?.cloudStylesOrig?.show_description : props?.cloudStyles?.show_description;
            if ((showDescription === "no" || showDescription === "0") && props.onMouseOutNode)
                props.onMouseOutNode("embedded node title");
        } else if (props.isFrameNode) {
            const showDesc = props?.frameNodeCloudStyles?.show_description ? props?.frameNodeCloudStyles?.show_description : props?.topCloudStyles?.show_description;
            if ((showDesc === "no" || showDesc === "0") && props.onMouseOutNode)
                props.onMouseOutNode("embedded node title");
        }
    }

    const pivoters = (bShowPivoters) ?
        (<div className={holderCls}>
            {bShowRowPivoter &&
                <div id="RowPivotGrp">
                    <IndexPivoter id="RowPivoter" inds={state.inds} sel={rowSel} pivotRow={props.pivotRow} />
                    {rowSel && <PivoterTotal id="RowTotal" ind={state.rows[0]} vid={props.vid} />}
                </div>
            }
            <div id="ColPivotGrp" style={{ marginLeft: props.rowHeadersWidth }}>
                <IndexPivoter id="ColumnPivoter" inds={state.inds} sel={colSel} pivotCol={props.pivotCol} />
                {colSel && bShowRowPivoter && <PivoterTotal id="ColTotal" ind={state.cols[0]} vid={props.vid} />}
                {colSel && !bShowRowPivoter && <TotalsButton indexOid={state.cols[0]} vid={props.vid} />}
            </div>
        </div>) : "";

    const slicers = bShowSlicers && Array.isArray(state.slicers) ?
        (<>
            {state.slicers.map((oid_pos) => (<Slicer {...oid_pos} vid={props.vid} key={oid_pos.oid} changeSlicer={props.changeSlicer} zoom={props.zoom}/>) )}
        </>
        ) : "";

    const cls = props.bEmbeddedOutput ? "TableHeader Embedded" : "TableHeader";

    let style = {};
    if (props.isFrameNode || (props.bTallNode === 1 && props.tableMargin)) {
        const tableMargin = props.tableMargin + "px";
        style = { paddingBottom: "1px", paddingLeft: 8 /* 1998 */, paddingRight: tableMargin, paddingTop: tableMargin, borderTopLeftRadius: tableMargin, borderTopRightRadius: tableMargin }
    }

    return (
        <>
            <div className={cls} style={style} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            <ResultTitle {...props} showHidePivoters={showHidePivoters} showGraphTableIcon={showGraphTableIcon} cloudStylesOrig={origCloudStyles} bShowTitle={bShowTitle} tableOrGraph="table" />
        </div>
        <div className="TableHeader2">
            {bShowDescription && <ResultDescription oid={props.oidOriginal} height={descriptionHeight} bShowTitle={bShowTitle} />}
            <div className={pivWrapperClass} >
                <div className={pivotersSlicersClass}>
                    {state.computed ? slicers : calcButton}
                    {pivoters}
                </div>
            </div>
        </div>
        </>
    );
}

function ResultScalarView(props)
{
    const state = props.state;

    return (
        <div className="ResultScalarView">
            {state.type}
        </div>
    );
}


//function RowIndexLabel(props)
//{
//    const [title,] = useObjAtt(props.oid, "identifier");

//    return (<span className="RowIndexLabels"><i className="fas fa-angle-double-down"/> {title}</span>);
//}


//=========================================================================================================================

export function EmptyCellRenderer(col,row)
{
    return (<>&nbsp;</>);
}


function BodyCellRenderer(props)
{
    const val = props.cells;

    if (Array.isArray(val))
        return (col, row) => {
            const val_r = val[row];
            return Array.isArray(val_r) ? val_r[col] : "";
        }
    else
        return EmptyCellRenderer;

        //"(" + col.toString() + "," + row.toString() + ")";
}

function RowHeaderRenderer(props)
{
    //console.log("RowHeaderRenderer", props);
    const indVal = props.rowIndVal;

    if (props.rowOid === 0 || !Array.isArray(indVal))
        return EmptyCellRenderer;

    const len = indVal.length;

    return (col, row) => {
        if (row > 0 && row <= len && /*props.nodeClassOrig === "Index"*/ props.isIndexTable === true)
            return "";
        else if (row > 0 && row <= len)
            return (<ValueToHTML val={indVal[row - 1]} {...props} />);
        else if (row===len+1 && props.bHasRowTotal)
            return (<>Totals</>);
        else
            return null;
    }
}

// LDC 8/31/2020 Suan ER 192
function ColumnResizer({setW,w})
{

    function onDown(ev)
    {
        console.log("onDown column resizer")
        if ((ev.buttons & 1) !== 1) {
            setW(null,null);
            return;
        }

        let width;
        if (Number.isFinite(w))
            width = w;
        else {
            const th = ev.currentTarget.parentElement;
            width = th.offsetWidth - paddingDiff(th);
        }
        setW(width,ev.pageX);
    }

    function clickColumnResizer(ev) {
        console.log("click column resizer, just stops bubble up for now");
        ev.stopPropagation();
        ev.preventDefault();
    }

    return <div className="ColumnResizer" onMouseDown={onDown} onClick={clickColumnResizer}/>;
}

// LDC 8/31/2020 Suan ER 193
function RowResizer({row,curH,h,setH,vid})
{
    const [bDragging, setDragging] = useState(false);
    const [y0, setY0] = useState(0);

    function onDown(ev)
    {
        const tr = ev.currentTarget.parentElement.parentElement;
        const height = Number.isFinite(curH) ? curH : tr.offsetHeight;
        setH(height);
        setY0(ev.pageY - height);
        setDragging(true);
    }

    function onClick(ev) {
        console.log("on click row resizer");
        ev.stopPropagation();
        ev.preventDefault();
    }

    useEffect(() => {

        function onMove(ev) {
            if ((ev.buttons & 1) !== 1 || !Number.isFinite(h)) {
                setDragging(false);
                setH(null);
                return;
            }

            const height = Math.max(0, ev.pageY - y0);
            setH(height);
        }
        function onUp(ev) {
            if (!bDragging) return;

            const height = Math.max(0, ev.pageY - y0);

            setDragging(false);
            setH(false);

            WebSocket_Send({ fn: 'Exec', cmd: 'setRowHeight', row: row, height: height, vid: vid });
        }

        if (bDragging) {
            document.addEventListener('mousemove', onMove);
            document.addEventListener('mouseup', onUp);
            return ()=> {
                document.removeEventListener('mousemove', onMove);
                document.removeEventListener('mouseup', onUp);
            }
        }
    }, [bDragging,h,setH,row,vid,y0]);

    return <div className="RowResizer" onMouseDown={onDown} onClick={onClick}/>;
}

function SortIcon({ bOn, dir, DoNewSort })
{
    if (bOn && dir === "ascending")
        return (<div className="SortIcon Ascending">&darr;<button className="NewSort" onClick={(e) => DoNewSort(e, "descending")}>&uarr;</button></div>);
    if (bOn && dir === "descending")
        return (<div className="SortIcon Descending">&uarr;<button className="NewSort" onClick={(e) => DoNewSort(e, "")}>x</button></div>);

    return (<div className="SortIcon NoSort">&nbsp;<button className="NewSort" onClick={(e) => DoNewSort(e, "ascending")}>&darr;</button></div>);     // This won't appear until hover. The down arrow indicates what will happen when clicked
}


function RowIndexSortIcon({ sortStatus,iRowInd,vid })
{
    if (!sortStatus) return null;
    const bOn = (sortStatus.colType === "rowInd" && sortStatus.iRow === iRowInd);

    function DoNewSort(e, dir) {
        e.stopPropagation();
        WebSocket_Send({ fn:'Exec', cmd:'sortOnRowIndex', dir: dir, iRowInd: iRowInd, vid:vid });
    }

    return <SortIcon bOn={bOn} dir={sortStatus.dir} DoNewSort={DoNewSort} />;
}

function RowSortByColumnIcon({ sortStatus, colNum, vid,bTotals })
{
    if (!sortStatus) return null;
    const bOn = (sortStatus.colType === "col" && sortStatus.iCol === colNum);

    function DoNewSort(e, dir) {
        e.stopPropagation();
        WebSocket_Send({ fn: 'Exec', cmd: 'sortOnCol', dir: dir, iCol: colNum, bTotals:bTotals, vid: vid });
    }

    return <SortIcon bOn={bOn} dir={sortStatus.dir} DoNewSort={DoNewSort} />;
}

function useRowPivoterShouldShowInHeaderArea( tableOid, globalAcpStyle ) {
    const cloudStylesThisTable = useCloudStyles(tableOid);

    if (cloudStylesThisTable && cloudStylesThisTable.row_pivoter_loc) {
        return cloudStylesThisTable.row_pivoter_loc === "header";
    }
    return globalAcpStyle && globalAcpStyle.row_pivoter_loc === "header";
}

function ColumnHeaderCell({ vid, cls, colNum, bTotCol, val, width, sortStatus, nRows, setW, jumpToNodeInDiagram, view, setCellSelAnchor, setCellSelection, setTableCellsOnClipboard })
{
    //console.log("ColumnHeaderCell", view);
    const widthField = width && width.type !== "undefined" ? { columnWidth: width, width: width, minWidth: width, maxWidth: width } : undefined;
    const fmts = useQuery( {req:'cell', col:colNum-1, row:0, bColHeader:true, bTotal:bTotCol, cellInfo:'vC'}, vid );           // LDC 11/9/2022 ER S-183
    const style = fmts ? {...fmts.cellFmt,...widthField} : widthField;
    if (!cls) cls = bTotCol ? "PreTotalCol" : undefined;

    function columnHeaderOnClick(e, colNum) {
        console.log("column header on click", colNum);

        if (document.acpServerConfig.EW2041_enable_table_header_cell_clicks === false)
            return;

        setCellSelection({ left: colNum - 1, right: colNum - 1, top: - 1, bottom: nRows - 1 }); // minus one for header cells
        setCellSelAnchor({ left: colNum - 1, top: -1 });
        setTableCellsOnClipboard(false);
    }

    const thId = "v" + vid.toString() + "_-1_" + (colNum-1);

    return (
        <th id={thId} key={colNum} style={style} className={cls} onClick={e => columnHeaderOnClick(e, colNum)}><ValueToHTML val={val} jumpToNodeInDiagram={jumpToNodeInDiagram} />
            {nRows > 1 && view != "edit" && <RowSortByColumnIcon sortStatus={sortStatus} colNum={colNum} vid={vid} bTotals={false} />}
            <ColumnResizer setW={setW} w={width} />
        </th>
    );
}

export function TableColumnHeaders(props) {
    //console.log("TableColumnHeaders", props)
    const indVal = props.colIndVal;
    const [rowIndexNameRef, rowIndexNameRect] = useLayoutRect();
    const len = Array.isArray(indVal) ? indVal.length : 0;
    const colWidths = props.colWidths; // useQuery({ req: "colWidths" }, props.vid);
    const [colBeingResized, setColBeingResized] = useState(null);
    const [resizingWidth, setResizingWidth] = useState(null);
    const [x0, setX0] = useState(null);
    const [w0, setW0] = useState(null);
    const sortStatus = useQuery({ req: "sortStatus" }, props.vid);      // LDC 3/26/2021 S-681
    const bShowRowPivoter = !useRowPivoterShouldShowInHeaderArea(props.oid, props.cloudStyles);

    const nRows = props.nRows;
    const state = props && props.state;
    const rowSel = !(state && state.rows) || state.rows.length === 0 ? null : state.rows[0];
    const rowHeadCellRef = props.rowHeadCellRef;

    function onMove(ev) {
        if ((ev.buttons & 1) !== 1) {
            setResizingWidth(null);
            setColBeingResized(null);
            return;
        }
        if (!Number.isFinite(resizingWidth)) return;

        const width = Math.max(0, ev.pageX - x0 + w0);

        setResizingWidth(width);
    }
    function onUp(ev) {
        if (colBeingResized !== null) {
            const width = Math.max(0, ev.pageX - x0 + w0);
            WebSocket_Send({ fn: 'Exec', cmd: 'setColWidth', col: colBeingResized, width: width, vid: props.vid });

            setColBeingResized(null);
            setResizingWidth(null);
        }
    }

    function dragEffect() {
        if (colBeingResized !== null) {
            document.addEventListener('mousemove', onMove);
            document.addEventListener('mouseup', onUp);
            return () => {
                document.removeEventListener('mousemove', onMove);
                document.removeEventListener('mouseup', onUp);
            }
        }
    }

    useEffect(dragEffect, [colBeingResized]);

    function Sizer(i) {
        return (w, startX) => {
            setX0(startX);
            setW0(w);
            if (w === null && colBeingResized === i)
                setColBeingResized(null);
            else if (Number.isFinite(w)) {
                if (colBeingResized !== i)
                    setColBeingResized(i);
                setResizingWidth(w);
            }
        };
    }

    function* headers(n, bTruncated) {
        //console.log("heades()", props)
        const isEmbedded = props.isFrameNode || props.bTallNode === 1;
        let titleVisible = false;
        if (isEmbedded) {
            const showIndexMenusCS = getCloudStyleSetting("show_index_menus", props?.topCloudStyles, props?.frameNodeCloudStyles, props?.cloudStylesOrig, "fly-in");
            titleVisible = true;
            if (showIndexMenusCS === "yes") titleVisible = false;
            if (showIndexMenusCS === "no" || showIndexMenusCS === "not_even_slicers") titleVisible = true;
            if (props.togglePivoters) titleVisible = !titleVisible;
        }

        const rowPivoterClassName = titleVisible ? "RowPivoterInvis" : "RowPivoter";
        const paddingRight = rowIndexNameRect?.width ? rowIndexNameRect?.width + 15 : 15;
        const style = titleVisible ? { paddingRight: paddingRight } : {}; // +15 empiracal

        yield (
            <th key={0} className="RowColHeader" ref={rowHeadCellRef} style={style} >
                {bShowRowPivoter && props.isIndexTable !== true && <IndexName id="RowPivoter" indexOid={rowSel} alwaysVisible={titleVisible} rowIndexNameRef={rowIndexNameRef}/>}
                {bShowRowPivoter && nRows > 1 && <TotalsButton indexOid={state.rows[0]} vid={props.vid} />}
                {bShowRowPivoter && <IndexPivoter id={rowPivoterClassName} inds={state.inds} sel={rowSel} pivotRow={props.pivotRow} />}
                {nRows > 1 && <RowIndexSortIcon sortStatus={sortStatus} rowIndNum={0} vid={props.vid} />}
            </th>);

        for (let i = 1; i <= n && i<=len ; ++i) {
            const width = colBeingResized===i ? resizingWidth : Array.isArray(colWidths) ? (i-1<colWidths.length ? colWidths[i-1] : null) : colWidths;
            // LDC 10/11/2022 ER S-183
            yield (<ColumnHeaderCell key={i} vid={props.vid} colNum={i} bTotCol={props.bHasColTotal && i === len} val={indVal[i - 1]}
                width={width} sortStatus={sortStatus} nRows={nRows} setW={Sizer(i)} jumpToNodeInDiagram={props.jumpToNodeInDiagram} view={props.view}
                setCellSelAnchor={props.setCellSelAnchor} setCellSelection={props.setCellSelection} setTableCellsOnClipboard={props.setTableCellsOnClipboard}/>);
        }

        for (let i = len + 1; i <= n; ++i) {
            const width = colBeingResized === i ? resizingWidth : Array.isArray(colWidths) ? (i - 1 < colWidths.length ? colWidths[i-1] : undefined) : colWidths;
            const widthField = width && width.type !== "undefined" ? { columnWidth:width,width: width, minWidth: width, maxWidth: width } : undefined;
            yield (
                <th key={i} style={widthField}>
                    &nbsp;
                    {nRows > 1 && len === 0 && i === 1 && props.view !=="edit" && <RowSortByColumnIcon sortStatus={sortStatus} colNum={i} vid={props.vid} bTotals={false} />}
                    <ColumnResizer setW={Sizer(i)} w={width} />
                </th>);
        }

        if (bTruncated) {
            yield (
                <th key="Trunc">&hellip;</th>
            );
        }

        if (props.bHasColTotal) {
            let i = n + 1;
            const width = colBeingResized === i ? resizingWidth : Array.isArray(colWidths) ? (i - 1 < colWidths.length ? colWidths[i - 1] : null) : colWidths;
            //const widthField = width && width.type !== "undefined" ? { columnWidth: width, width: width, minWidth: width, maxWidth: width } : undefined;
            yield (
                <ColumnHeaderCell key={i} cls="TotalCol" vid={props.vid} colNum={i} bTotCol={true} val="Totals" width={width}
                                  sortStatus={sortStatus} nRows={nRows} setW={Sizer(i)} jumpToNodeInDiagram={props.jumpToNodeInDiagram} />
                //<th key={i} className="TotalCol" style={widthField}>
                //    Totals
                //    {nRows > 1 && <RowSortByColumnIcon sortStatus={sortStatus} colNum={i} vid={props.vid} bTotals={true} />}
                //    <ColumnResizer setW={Sizer(i)} w={width} />
                //</th>
            );
        }
    }

    const nCols = props.maxBodyCols ? Math.min(props.maxBodyCols, props.nCols) : props.nCols;       // LDC 6/28/2021 ER S-720

    return (
        <tr>{[...headers(nCols, nCols<props.nCols)]}</tr>
    );
}

function TableRow(props)
{
    const nColsShown = props.maxBodyCols ? Math.min(props.nCols, props.maxBodyCols) : props.nCols;          // LDC 6/28/2021 ER S-720
    //const nCols = props.nCols ? props.nCols + (props.bHasColTotal ? 1 : 0) : undefined;
    const row = props.row;
    const nCols = props.nCols;
    const rowHeaderRender = props.rowHeaderRender;
    const cellRender = props.bodyCellRender;
    const bCellRenderPrintsTD = 'bodyCellRenderPrintsTD' in props && props.bodyCellRenderPrintsTD;
    const fn = bCellRenderPrintsTD ? ((_ignore, i) => cellRender(i, row - 1)) : ((_ignore, i) => (<td key={i + 1}>{cellRender(i, row - 1)}</td>));
    const rowHeight = useQuery({ req: "rowHeight", row: row-1 }, props.vid);
    const [draggingHeight, setDraggingHeight] = useState(null);
    const rowHeaderFmts = useQuery( { req:"cell", row:row-1, bRowHeader:true, col:0, cellInfo:'vC'}, props.vid);        // LDC 11/10/2022 ER S-183
    const sty = Number.isFinite(draggingHeight) ? {height:draggingHeight} : Number.isFinite(rowHeight) ? { height: rowHeight } : undefined;
    const cls = props.bIsTotal ? "TotalRow" : (props.bHasRowTotal && row===props.nRows) ? "PreTotalRow" : undefined;

    const totCell = props.bHasColTotal ? fn(row,nColsShown) : null;

    function mouseEnterRowHeader(e, row) {
        //console.log("mouseEnterRowHeader()",e, row, props)
    }

    function mouseClickRowHeader(e, row) {
        console.log("mouseClick row header", e, row, nCols);

        if (document.acpServerConfig.EW2041_enable_table_header_cell_clicks === false)
            return;

        props.setCellSelection({ left: -1, right: nCols - 1, top: row - 1, bottom: row - 1 }); // minus one for header cells
        props.setCellSelAnchor({ left: -1, top:  row - 1});
        props.setTableCellsOnClipboard(false);
    }

    const thId = "v" + + props.vid.toString() + "_" + (row - 1) + "_-1";

    return (
        <tr key={row} style={sty} className={cls}>
            <th id={thId} style={rowHeaderFmts?.cellFmt} onMouseEnter={e => mouseEnterRowHeader(e, row)} onClick={e => mouseClickRowHeader(e, row) }>{rowHeaderRender(0, row)}<RowResizer {...props} curH={rowHeight} h={draggingHeight} setH={setDraggingHeight} /></th>
            {[...Array(nColsShown).keys()].map(fn)}
            {nColsShown < props.nCols && row === 1 && <td className="OmittedColumnSpan" rowSpan={props.nRows}>&hellip; {props.nCols - nColsShown} columns are not shown due to excessive table size&hellip;</td>}
            {totCell}
        </tr>
    );
}

//const gOmittedCellRenderer = (i, j) => (<span className="omittedCell">&hellip;{i},{j}</span>);

export function TableRows(props) {
    //console.log("TableRows", props.rowIndVal, props.rowOid);
    const rowIndVal0 = useQuery({ req: 'formattedIndVal', oid: props.rowOid }, props.vid);  // LDC 3/26/2021 S-681. This is sorted by whatever row sort is in effect, if any
    const rowIndVal = props.rowOid ? rowIndVal0 : undefined;  // 1915 - after a pivot on one d-table

    const bodyCellRenderer = props.bodyCellRenderer;
    const rowTotalsRenderer = props.rowTotalsRenderer || props.bodyCellRenderer;
    const nRows = props.maxBodyRows ? Math.min(props.maxBodyRows, props.nRows) : props.nRows;   // LDC 6/28/2021 ER S-720

    const nonTotalRows = [...Array(nRows).keys()].map((_ignore, i) => (
            <TableRow
                key={i+1}
                row={i+1}
                rowHeaderRender={RowHeaderRenderer({ ...props , rowIndVal: rowIndVal })}
                bodyCellRender={bodyCellRenderer}
                bIsTotal={false}
                {...props} />
    ));

    const omitted = (nRows >= props.nRows) ? null :
        (<tr className="OmittedRow"><th>&hellip;</th><td colSpan={props.nCols}>&hellip; <i>{props.nRows - nRows} rows are not shown due to excessive table size&hellip;</i></td></tr>);

    if (!props.bHasRowTotal || !rowTotalsRenderer)
        return <>{nonTotalRows}{omitted}</>;

    const totalRow =
        <TableRow key={props.nRows + 1}
                row={nRows + 1}
                bIsTotal={true}
                rowHeaderRender={(i, j) => (<span className="TotalsLabel">Totals</span>)}
                bodyCellRender={rowTotalsRenderer}
                {...props} />
    ;

    return (
        <>
            {nonTotalRows}
            {omitted}
            {totalRow}
        </>
    );
}

function ResultTableRows(props)
{
    const [body,] = useObjAtt(props.oid, "_ResultSlice");
    return TableRows({ bodyCellRenderer: BodyCellRenderer({ cells: body, ...props }), ...props });
}

function ResultTableGrid(props)
{
    return (
        <div className="TableContainer">
            <table className="ATable">
                <thead>
                    <TableColumnHeaders {...props}/>
                </thead>
                <tbody>
                    <ResultTableRows {...props}/>
                </tbody>
            </table>
        </div>
    );
}

function ResultTableByArrayValue(props)
{
    //console.log("ResultTableByArrayValue")
    const [state,] = useObjAtt(props.oid, '_curResultState');
    const [rowHeadersWidth, setRowHeadersWidth] = useState(100);    // So we can align the left edge of the column pivoter with the left edge of the body cells
    const rowHeadCellRef = useRef(null);

    // This layout is to measure the width of the row headers after layout so we can position the column pivot control's left edge above the body cell's left edge.
    useLayoutEffect(() => {
        if (rowHeadCellRef && rowHeadCellRef.current && setRowHeadersWidth) {
            setRowHeadersWidth(rowHeadCellRef.current.clientWidth);
        }
    });         // intentionally no dependencies. Want to measure any time it redoes the layout.

    //if (state === undefined) return null;
    const rowOid = state && state.type === "array" && state.rows.length > 0 ? state.rows[0] : 0;
    const rowIndVal = useObjAtt(rowOid, "_FormattedIndexValue")[0];
    const colOid = state && state.type === "array" && state.cols.length > 0 ? state.cols[0] : 0;
    const colIndVal = useObjAtt(colOid, "_FormattedIndexValue")[0];
    const [wndSize,] = useObjAtt(props.oid,"_resultWindowSize");
    if (state === undefined) return null;
    const nRows = Array.isArray(rowIndVal) ? rowIndVal.length : 1;  // These don't count headers
    const nCols = Array.isArray(colIndVal) ? colIndVal.length : 1;

    const style = !props.isFrameNode && !props.bEmbeddedOutput && props.uiStyle!=="ACP1" && wndSize && { width: wndSize.width, height: wndSize.height };

    const props2 = {
        rowOid: rowOid, rowIndVal: rowIndVal, colOid: colOid, colIndVal: colIndVal, nCols: nCols, nRows: nRows,
        state: state, rowHeadersWidth: rowHeadersWidth, setRowHeadersWidth: setRowHeadersWidth, rowHeadCellRef:rowHeadCellRef,
        ...props
    };

    return (
        <div className="ResultTable Table" style={style}>
            <TableHeader {...props2} />
            {state.type === "array" ? (<ResultTableGrid {...props2} />) : (<ResultScalarView {...props2}/>)}
        </div>
    );
}


function ResultTableByFullTable(props)
{
    //console.log("ResultTableByFullTable", props)
    const curQ = { req: 'resultTable', oid: props.oid, view: props.view, proactive:props.proactive };
    const [q, setQ] = useState(curQ);       // We need to keep this ===, always pointing to the same json, not identical copies. That is the only purpose of making an extra state variable here.
    const [wndSize0,] = useObjAtt(props.oid, "_resultWindowSize");

    const wndSize = !props.isFrameNode && !props.bEmbeddedOutput && props.uiStyle !== "ACP1" ? wndSize0 : undefined;

    if (q && q.view !== props.view) {
        setQ(curQ);
        return null;
    }

    return (<TableByFullTable wndSize={wndSize} view={props.view} q={q} resetQ={setQ} {...props} />);
}

function ResultTable2(props)
{
    //console.log("ResultTable2", props);

    function msgBoxReplyHandler() {
        console.log("msgBoxReplyHandler");
        props.setMsgBoxInfoClient(null);
        props.setView("MIDM");
    }

    const isProb = props.view === "MIDM" ? false : IsProb(props.identifier, props.runOid);

    if (props.view !== "MIDM" && isProb === false && props.runOid !== undefined) {
        console.log("Value is not probabilistic.  Mid value will be shown instead.");
        if (props.msgBoxInfoClient === null)
            props.setMsgBoxInfoClient({ body: 'Value is not probabilistic.  Mid value will be shown instead.', buttons: 0, caption: "Explanation", responseHandler: msgBoxReplyHandler });
        return null;
    }

    if (props.resultTableVersion === "value")
        return (<ResultTableByArrayValue {...props} />);

    else if (props.resultTableVersion === "table")
        return (<ResultTableByFullTable {...props} />);

    else
        return (<div>Unimplemented table type: {typeof (props.resultTableVersion)} {props.resultTableVersion}</div>);
}

export function ResultTable(props)
{
    const [oidOriginal,] = useObjAtt(props.oid, "_original");
    if (oidOriginal === undefined) return "";
    return <ResultTable1 {...props} oidOriginal={oidOriginal.oid} />

}

function ResultTable1(props) {
    //console.log("ResultTable1", props)
    const [viewType, setViewType] = useObjAtt(props.oidOriginal, "_resultViewType");
    const runOid = useEval("HandleFromIdentifier('run')")?.oid;
    const [identifier,] = useObjAtt(props.oidOriginal, "identifier");


    if (viewType && identifier)
        return (<ResultTable2 {...props} view={viewType} setView={setViewType} identifier={identifier} runOid={runOid} />);
    else
        return null;
}

export function IsProb(id, runOid) {
    const indexes = useEval("IndexesOf(Sample(" + id + "))");
    console.log("id", id, indexes)
    if (Array.isArray(indexes)) {
        for (let i = 0; i < indexes.length; i++) {
            if (indexes[i].oid === runOid) {
                //console.log("run index found, so is prob true");
                return true;
            }
        }
    }
    else
        return "not ready";

    return false;
}
